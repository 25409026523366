import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { message, notification as notify, Spin } from 'antd'
import findIndex from 'lodash/findIndex'
import { translate } from 'react-i18next'
import { Route, Switch, withRouter } from 'react-router-dom'
import { Prompt } from 'react-router'
import i18n from 'i18next'
import LngDetector from 'i18next-browser-languagedetector'
import { actions as advSearchActions } from 'redux/modules/advancedSearch'
import { actions as loadingActions } from 'redux/modules/loading'
import RoutePrivate from './utility/RoutePrivate'
import RoutePublic from './utility/RoutePublic'
import Header from './components/Header'
import Footer from './components/Footer'
import { actions } from './redux/modules/user'
import { actions as utilsActions } from './redux/modules/utils'
import HomeResultView from './views/homeResult'
import BolgarIndexView from './views/bolgarIndex'
import BolgarCategoriesView from './views/bolgarCategories'
import WhatView from './views/what'
import WhenView from './views/when'
import WhereView from './views/where'
import './index.scss'

const NotFoundView = React.lazy(() => import('views/notFound'))
const HomeView = React.lazy(() => import('views/home'))
const LoginView = React.lazy(() => import('views/login'))
const SignUpView = React.lazy(() => import('views/signup'))
const ShowView = React.lazy(() => import('views/show'))
const ShowSelectionView = React.lazy(() => import('views/showSelection'))
const ShowAuthorView = React.lazy(() => import('views/showAuthor'))
const GSearchView = React.lazy(() => import('views/quickSearch'))
const CreateView = React.lazy(() => import('views/create'))
const ProfileView = React.lazy(() => import('views/profile'))
const RecoveryView = React.lazy(() => import('views/recovery'))
const NewIndexView = React.lazy(() => import('views/newIndex'))
const ArchiveView = React.lazy(() => import('views/archive'))
const LibraryView = React.lazy(() => import('views/library'))
const OrganizationCreateView = React.lazy(() =>
  import('views/organizationCreate'),
)
const AdvancedSearchView = React.lazy(() => import('views/advancedSearch'))
const EditSelectionView = React.lazy(() => import('views/editSelection'))
const ToolView = React.lazy(() => import('views/tools'))
const ToolPageView = React.lazy(() => import('views/toolPage'))
const MergeView = React.lazy(() => import('views/merge'))

const mapStateToProps = state => ({
  user: state.user,
  notification: state.notification,
})

const mapDispatchToProps = {
  getCurrentUser: actions.getCurrentUser,
  handleExit: actions.logOut,
  resetLoading: loadingActions.reset,
  changeLanguage: utilsActions.changeLanguage,
  resetAdvSearchState: advSearchActions.resetState,
}

@translate()
@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class Routes extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    notification: PropTypes.array.isRequired,
    getCurrentUser: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.notifications = new Map()
  }

  componentDidMount() {
    this.props.getCurrentUser()
    i18n.use(LngDetector).init({
      whitelist: ['be','cs','de','hu','lt','pl','ru','uk','lv', 'sr', 'hr', 'tr', 'tt', 'en'],
      fallbackLng: 'en',
      preload: ['be','cs','de','hu','lt','pl','ru','uk','lv', 'sr', 'hr', 'tr', 'tt', 'en'],
    })
    console.log('Routes -> used language: ', i18n.language)
  }

  componentDidUpdate(prevProps) {
    console.log('Routes -> root component updated: ')
    if (
      prevProps.location.pathname === '/profile' &&
      this.props.location.pathname !== '/profile'
    ) {
      notify.close('is_you')
    }
    if (
      prevProps.location.pathname === '/advanced-search' &&
      this.props.location.pathname !== '/advanced-search'
    ) {
      this.props.resetAdvSearchState()
    }
    const { t, notification } = this.props
    if (prevProps.location.pathname !== this.props.location.pathname) {
      console.log(
        'RESET',
        prevProps.location.pathname,
        this.props.location.pathname,
      )
      this.props.resetLoading()
    }
    this.notifications.forEach((item, key) => {
      const index = findIndex(notification, { id: key })
      if (index < 0) {
        item()
        this.notifications.delete(key)
      }
    })
    notification.forEach(item => {
      if (!this.notifications.has(item.id)) {
        this.notifications.set(item.id, message[item.type](t(item.message), 0))
      }
    })
  }

  render() {
    const { user, t } = this.props
    return (
      <div className="container clearfix">
        <Header />
        <div className="container__content">
          <Suspense fallback={<Spin />}>
            <Switch>
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/"
                exact
                component={NewIndexView}
              />
{/*               <Route
                isAuthenticated={user.isAuthenticated}
                path="/bolgar-sviyazhsk"
                exact
                component={BolgarIndexView}
              /> */}
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/bolgar-categories/:location"
                exact
                component={BolgarCategoriesView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/what"
                exact
                component={WhatView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/where"
                exact
                component={WhereView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/when"
                exact
                component={WhenView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/home_result"
                exact
                component={HomeResultView}
              />
              <RoutePublic
                isAuthenticated={user.isAuthenticated}
                path="/login"
                exact
                component={LoginView}
              />
              <RoutePublic
                isAuthenticated={user.isAuthenticated}
                path="/signup"
                exact
                component={SignUpView}
              />
              <RoutePublic
                isAuthenticated={user.isAuthenticated}
                path="/recovery"
                exact
                component={RecoveryView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/edit/selection/:objId"
                exact
                component={EditSelectionView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/show/author/:objId"
                exact
                component={ShowAuthorView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/show/selection/:objId"
                exact
                component={ShowSelectionView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/show/:objCategory/:objType"
                exact
                component={ShowView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/show/:objCategory/:objType/:objId"
                exact
                component={ShowView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/quick-search/:location/category/:catId"
                exact
                component={GSearchView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/quick-search/"
                exact
                component={GSearchView}
              />
              <RoutePrivate
                isAuthenticated={user.isAuthenticated}
                path="/advanced-search/"
                exact
                component={AdvancedSearchView}
              />
              <RoutePrivate
                isAuthenticated={user.isAuthenticated}
                path="/data-entry"
                exact
                component={CreateView}
              />
              <RoutePrivate
                isAuthenticated={user.isAuthenticated}
                path="/merge"
                exact
                component={MergeView}
              />
              <RoutePrivate
                isAuthenticated={user.isAuthenticated}
                path={['/profile', '/profile/:section']}
                exact
                component={ProfileView}
              />
              <RoutePrivate
                isAuthenticated={user.isAuthenticated}
                path="/organization/create"
                exact
                component={OrganizationCreateView}
              />
              <RoutePrivate
                isAuthenticated={user.isAuthenticated}
                path="/tools/"
                exact
                component={ToolView}
              />
              <RoutePrivate
                isAuthenticated={user.isAuthenticated}
                path="/tools/:toolName"
                exact
                component={ToolPageView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/home"
                exact
                component={HomeView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/archive"
                exact
                component={ArchiveView}
              />
              <Route
                isAuthenticated={user.isAuthenticated}
                path="/library"
                exact
                component={LibraryView}
              />
              <Route component={NotFoundView} />
            </Switch>
          </Suspense>
        </div>
        <Footer t={t} />
      </div>
    )
  }
}

export default Routes
