import React from 'react';

const SignUpSuccess = ({ t }) => (
  <div className="sign_up_success">
    <h3>{ t('signUp.title_success') }</h3>
    <span>{ t('signUp.success_message') }</span>
  </div>
)

export default SignUpSuccess;
