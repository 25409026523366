const termQueries = {
  Lexeme: {
    q: `query postQuery($uid: String) {
        terms(uid_Exact: $uid) {
          edges {
            node {
              name
              uid
              lexeme {
                edges {
                  node {
                    name
                    uid
                    research {
                      edges {
                        node {
                          edgeSubject {
                            etimDescription
                          }
                        }
                      }
                    }
                    attributes {
                      edges {
                        node {
                          label
                          ru_name: ruName
                        }
                      }
                    }
                    variant {
                      edges {
                        node {
                          names
                          uid
                          sense
                          edgeLexeme {
                            groupNumber
                          }
                          locality {
                            edges {
                              node {
                                spatialReference {
                                  edges {
                                    node {
                                      points
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`,
    dataPath: [
      'terms',
      'lexeme',
    ],
    spatialPath: [
      'terms',
      'lexeme',
      'variant',
      'locality',
      'spatialReference',
    ],
    dataForSpatial: [
      {
        title: 'lexemeIndex',
        node: 'lexeme',
        fieldPath: 'index',
      },
      {
        title: 'groupNumber',
        node: 'variant',
        fieldPath: ['edgeLexeme', 'groupNumber'],
      },
      {
        title: 'variantIndex',
        node: 'variant',
        fieldPath: 'index',
      },
      {
        title: 'title',
        node: 'variant',
        fieldPath: ['names'],
      },
      {
        title: 'subject_id',
        node: 'lexeme',
        fieldPath: ['uid'],
      },
    ],
  },
};

export default termQueries
