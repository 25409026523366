/**
 * @flow
 * @relayHash d3f88d0e25f109815233cc2960da4713
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessObjectEnum = "PRIVATE" | "PUBLIC" | "SHARED" | "%future added value";
export type AccessRoleEnum = "ADMIN" | "AUTHOR" | "CREATOR" | "MODERATOR" | "NOT_ACCESSED" | "PUBLIC_USER" | "SHARED_USER" | "%future added value";
export type SelectionQueryVariables = {||};
export type SelectionQueryResponse = {|
  +me: ?{|
    +mySel: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +uid: ?string,
          +accessType: ?number,
          +permission: ?AccessObjectEnum,
          +createdAt: ?any,
          +creator: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +uid: ?string,
                +publicName: ?string,
              |}
            |}>
          |},
          +accessRole: ?AccessRoleEnum,
          +description: ?string,
          +model: ?string,
          +name: ?string,
          +resultCount: ?number,
        |}
      |}>
    |},
    +sharedSel: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +uid: ?string,
          +accessType: ?number,
          +permission: ?AccessObjectEnum,
          +createdAt: ?any,
          +creator: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +uid: ?string,
                +publicName: ?string,
              |}
            |}>
          |},
          +accessRole: ?AccessRoleEnum,
          +description: ?string,
          +model: ?string,
          +name: ?string,
          +resultCount: ?number,
        |}
      |}>
    |},
  |}
|};
export type SelectionQuery = {|
  variables: SelectionQueryVariables,
  response: SelectionQueryResponse,
|};
*/


/*
query SelectionQuery {
  me {
    mySel: selections {
      edges {
        node {
          uid
          accessType
          permission
          createdAt
          creator {
            edges {
              node {
                uid
                publicName
                id
              }
            }
          }
          accessRole
          description
          model
          name
          resultCount
          id
        }
      }
    }
    sharedSel: sharedSelections {
      edges {
        node {
          uid
          accessType
          permission
          createdAt
          creator {
            edges {
              node {
                uid
                publicName
                id
              }
            }
          }
          accessRole
          description
          model
          name
          resultCount
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accessType",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permission",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accessRole",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "model",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "resultCount",
  "args": null,
  "storageKey": null
},
v10 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "SelectionNodeEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "creator",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorSearchNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AuthorSearchNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorSearchNode",
                    "plural": false,
                    "selections": [
                      v0,
                      v4
                    ]
                  }
                ]
              }
            ]
          },
          v5,
          v6,
          v7,
          v8,
          v9
        ]
      }
    ]
  }
],
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v12 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "SelectionNodeEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "creator",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorSearchNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AuthorSearchNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorSearchNode",
                    "plural": false,
                    "selections": [
                      v0,
                      v4,
                      v11
                    ]
                  }
                ]
              }
            ]
          },
          v5,
          v6,
          v7,
          v8,
          v9,
          v11
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SelectionQuery",
  "id": null,
  "text": "query SelectionQuery {\n  me {\n    mySel: selections {\n      edges {\n        node {\n          uid\n          accessType\n          permission\n          createdAt\n          creator {\n            edges {\n              node {\n                uid\n                publicName\n                id\n              }\n            }\n          }\n          accessRole\n          description\n          model\n          name\n          resultCount\n          id\n        }\n      }\n    }\n    sharedSel: sharedSelections {\n      edges {\n        node {\n          uid\n          accessType\n          permission\n          createdAt\n          creator {\n            edges {\n              node {\n                uid\n                publicName\n                id\n              }\n            }\n          }\n          accessRole\n          description\n          model\n          name\n          resultCount\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SelectionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "mySel",
            "name": "selections",
            "storageKey": null,
            "args": null,
            "concreteType": "SelectionNodeConnection",
            "plural": false,
            "selections": v10
          },
          {
            "kind": "LinkedField",
            "alias": "sharedSel",
            "name": "sharedSelections",
            "storageKey": null,
            "args": null,
            "concreteType": "SelectionNodeConnection",
            "plural": false,
            "selections": v10
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectionQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "mySel",
            "name": "selections",
            "storageKey": null,
            "args": null,
            "concreteType": "SelectionNodeConnection",
            "plural": false,
            "selections": v12
          },
          {
            "kind": "LinkedField",
            "alias": "sharedSel",
            "name": "sharedSelections",
            "storageKey": null,
            "args": null,
            "concreteType": "SelectionNodeConnection",
            "plural": false,
            "selections": v12
          },
          v11
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e4229250b4d6f0743051d19a51c78497';
module.exports = node;
