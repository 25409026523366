import { getResearchUrl } from 'utility/transformData/constants'

export const getLinkByModel = (model, label) => {
  switch (model) {
    case 'SOURCE':
      return `source/${label}`
    case 'RESEARCH':
      return getResearchUrl(label)
    case 'SELECTION':
      return 'selection'
    case 'SUBJECT':
    default:
      return `subjects/${label}`
  }
};


export const textColorTag = (t, rec) => {
  let color = 'yellow';
  let text = t('request_access.table.decision.in_process');
  if (rec.wasDecision) {
    if (rec.decision) {
      color = 'green';
      text = t('request_access.table.decision.approve');
    } else {
      color = 'volcano';
      text = t('request_access.table.decision.decline');
    }
  }
  return {
    text,
    color,
  }
};
