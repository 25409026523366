import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { makeRadiocarbonSummary } from 'redux/api/subject'
import { formItemLayout, tailFormItemLayout } from 'utility/constants'
import { Select, Form, Button } from 'antd'
import getRadiocarbonSummaryFields from 'utility/transformData/fields/radiocarbonSummary'
import { TAB_TYPE } from 'utility/transformData/constants'

import ListContent from '../ListContent'

import './index.css'

const FormItem = Form.Item
const { Option } = Select

const TYPES = [
  {
    value: 'intcal13',
    displayName: 'IntCal13',
  },
  {
    value: 'marine13',
    displayName: 'Marine13',
  },
  {
    value: 'shcal13',
    displayName: 'ShCal13',
  },
  {
    value: 'intcal09',
    displayName: 'IntCal09',
  },
  {
    value: 'marine09',
    displayName: 'Marine09',
  },
  {
    value: 'intcal04',
    displayName: 'IntCal04',
  },
  {
    value: 'marine04',
    displayName: 'Marine04',
  },
  {
    value: 'shcal04',
    displayName: 'ShCal04',
  },
  {
    value: 'intcal98',
    displayName: 'IntCal98',
  },
  {
    value: 'marine98',
    displayName: 'Marine98',
  },
]

@translate()
class RadioTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      type: TYPES[0].value,
      data: props.content,
    }
  }

  handleChange = value => {
    this.setState({
      type: value,
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { mainTabUid } = this.props
    const { type } = this.state
    makeRadiocarbonSummary(mainTabUid, type).then(({ response, error }) => {
      if (error) {
        this.setState({
          error: true,
        })
      }
      if (response) {
        const content = getRadiocarbonSummaryFields(
          { result: response },
          TAB_TYPE.RADIO
        )
        this.setState({
          data: content,
        })
      }
    })
  }

  renderError = () => {
    const { t } = this.props
    return (
      <div className="error-text">
        {t('dataPanel.radiocarbonsummary.no_radio')}
      </div>
    )
  }

  renderForm = () => {
    const { t } = this.props
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem
          {...formItemLayout}
          label={t('dataPanel.radiocarbonsummary.curve_type')}
        >
          <Select defaultValue={TYPES[0].value} onChange={this.handleChange}>
            {TYPES.map(type => (
              <Option key={type.value} value={type.value}>
                {type.displayName}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t('dataPanel.radiocarbonsummary.submit')}
          </Button>
        </FormItem>
      </Form>
    )
  }

  render() {
    const { subjectType, t } = this.props
    const { error, data } = this.state
    if (!data) {
      return (
        <div>
          <div>Error, content is not loaded.</div>
        </div>
      )
    }
    if (data.length > 0) {
      return (
        <div className="radiocarbon-summary">
          {error && this.renderError()}
          {this.renderForm()}
          <ListContent subjectType={subjectType} content={data} />
        </div>
      )
    }
    return (
      <div>
        {error && this.renderError()}
        {this.renderForm()}
        <div>{t('dataPanel.radiocarbonsummary.empty')}</div>
      </div>
    )
  }
}

export default RadioTab
