// size 0-20 // > 20
export const getExcavation = (type = {}) => {
  let epoch
  if (type.epoch && type.epoch.sort) {
    epoch = type.epoch.sort
  }
  const epochObj = type.attributes
    ? type.attributes.filter(a => a.type === 'Epoch')[0]
    : null
  const img =
    (type.nodeAttributes && type.nodeAttributes.epoch) || epochObj
      ? epochObj.sort || type.nodeAttributes.epoch.sort
      : 0
  epoch = img
  const res = {
    type: (type.area && type.area) > 20 ? 1 : 0,
    epoch,
  }
  console.log('getExc', type, res)
  return res
}
