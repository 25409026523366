export const textFieldFormatters = {
  onTextAreaPaste: (e, form, label) => {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');

    const editedPastedData = pastedData.replace(/(\r\n|\n|\r)/gm, " ");
    const newValue = e.target.value + editedPastedData;
    form.setFieldsValue({ [label]: newValue })
  },
}

export default textFieldFormatters;
