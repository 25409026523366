import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters';

import '../../index.scss';
import AutoInput from '../fields/autoInput';
import LexemGroup from './lexemGroups'
import { commonFunctions } from '../../functions/commonFunctions';
import CommonSelect from '../../../GSearchPanel/components/commonSelect';

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class LexemTab extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 1;
  }

  addTab = () => {
    const key = this.newTabIndex++;
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSearch = (value) => {
    // fetch(value, data => this.setState({ data }));
  }

  handleChangeName = (input) => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      getFieldDecorator,
      stateTabs,
      subjects,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props;

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };

    const formItemLayout2 = {
      wrapperCol: { span: 10, offset: 8 },
    };

    const config = {
      req: {
        rules: [{
          required: true, message: t('common.default_error'),
        }],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel header={`${t('create_panel.tab.lexem.lexem')} #${1 + key}`} key={title + item.key}>
              <AutoInput
                getFieldDecorator={getFieldDecorator}
                formItemLayout={formItemLayout}
                selectkey={`lexem_select_${key}`}
                objkey={key}
                label={t('create_panel.tab.lexem.choose')}
                addoptiontext={t('create_panel.tab.lexem.add')}
                handleSearch={this.handleSearch}
                handleSelect={commonFunctions.handleAutoInputChange}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                title={this.props.title}
                path="api/linguistic/lexeme/list"
                params={['name']}
                textField="name"
                method="POST"
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') &&
              <div>
                <FormItem
                  {...formItemLayout}
                  label={t('create_panel.tab.lexem.name')}
                >
                  {getFieldDecorator(`lexem_name_${key}`, config.req)(
                    <Input onChange={this.handleChangeName} data-objkey={key} />)}
                </FormItem>
                <AutoInput
                  getFieldDecorator={getFieldDecorator}
                  formItemLayout={formItemLayout}
                  selectkey={`lexem_term_${key}`}
                  stateTabs={stateTabs}
                  objkey={key}
                  rules={config.req}
                  label={t('create_panel.tab.lexem.term-select')}
                  addoptiontext={t('create_panel.tab.lexem.term-add')}
                  selectType="culture"
                  title={this.props.title}
                  path="api/linguistic/term/list"
                  params={['name']}
                  textField="name"
                  method="POST"
                  t={t}
                />
                <AutoInput
                  getFieldDecorator={getFieldDecorator}
                  formItemLayout={formItemLayout}
                  selectkey={`lexem_lang_${key}`}
                  stateTabs={stateTabs}
                  objkey={key}
                  rules={config.req}
                  label={t('create_panel.tab.lexem.lang-select')}
                  addoptiontext={t('create_panel.tab.lexem.lang-add')}
                  selectType="culture"
                  title={this.props.title}
                  path="api/linguistic/language/list"
                  params={['ru_name']}
                  textField="ru_name"
                  method="POST"
                  t={t}
                />
                <FormItem
                  {...formItemLayout}
                  label={t('create_panel.tab.lexem.desc')}
                >
                  {getFieldDecorator(`lexem_desc_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `lexem_desc_${key}`)} />)}
                </FormItem>
                <LexemGroup
                  getFieldDecorator={getFieldDecorator}
                  t={t}
                  key1={key}
                  stateTabs={stateTabs}
                  formItemLayout={formItemLayout}
                />
              </div>
              }
            </Panel>
          ))}
        </Collapse>

        <FormItem
          wrapperCol={{ span: 8, offset: 8 }}
        >
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.lexem.add_button')}
          </Button>
        </FormItem>
      </div>
    );
  }
}

export default LexemTab;
