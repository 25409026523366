import { createAction, handleActions } from 'redux-actions'

const CREATE_ORGANIZATION = 'organization/CREATE_ORGANIZATION'
const CREATE_ORGANIZATION_SUCCESS = 'organization/CREATE_ORGANIZATION_SUCCESS'
const GET_ORGUID_SUCCESS = 'organization/CREATE_ORGUID_SUCCESS'
const CREATE_ORGANIZATION_FAIL = 'organization/CREATE_ORGANIZATION_FAIL'
const CREATE_ORGANIZATION_ACCOUNT = 'organization/CREATE_ORGANIZATION_ACCOUNT'
const CREATE_ORGANIZATION_ACCOUNT_SUCCESS =
  'organization/CREATE_ORGANIZATION_ACCOUNT_SUCCESS'
const CREATE_ORGANIZATION_ACCOUNT_FAIL =
  'organization/CREATE_ORGANIZATION_ACCOUNT_FAIL'
const RESET_FORM = 'organization/RESET_FORM'
const GET_ORGANIZATION = 'organization/GET_ORGANIZATION'
const GET_ORGUID = 'organization/GET_ORGUID'
const GET_ORGANIZATION_SUCCESS = 'organization/GET_ORGANIZATION_SUCCESS'
const GET_ORGANIZATION_FAILED = 'organization/GET_ORGANIZATION_FAILED'
const EDIT_ORGANIZATION = 'organization/EDIT_ORGANIZATION'
const EDIT_ORGANIZATION_SUCCESS = 'organization/EDIT_ORGANIZATION_SUCCESS'
const EDIT_ORGANIZATION_FAILED = 'organization/EDIT_ORGANIZATION_FAILED'
const ADD_ORGANIZATION_RESEARCHER = 'organization/ADD_ORGANIZATION_RESEARCHER'
const ADD_ORGANIZATION_RESEARCHER_PROCCESS =
  'organization/ADD_ORGANIZATION_RESEARCHER_PROCCESS'
const ADD_ORGANIZATION_RESEARCHER_SUCCESS =
  'organization/ADD_ORGANIZATION_RESEARCHER_SUCCESS'
const ADD_ORGANIZATION_RESEARCHER_FAILED =
  'organization/ADD_ORGANIZATION_RESEARCHER_FAILED'

export const constants = {
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAIL,
  CREATE_ORGANIZATION_ACCOUNT,
  CREATE_ORGANIZATION_ACCOUNT_SUCCESS,
  CREATE_ORGANIZATION_ACCOUNT_FAIL,
  RESET_FORM,
  GET_ORGANIZATION,
  GET_ORGUID,
  GET_ORGUID_SUCCESS,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAILED,
  EDIT_ORGANIZATION,
  EDIT_ORGANIZATION_SUCCESS,
  EDIT_ORGANIZATION_FAILED,
  ADD_ORGANIZATION_RESEARCHER,
  ADD_ORGANIZATION_RESEARCHER_PROCCESS,
  ADD_ORGANIZATION_RESEARCHER_SUCCESS,
  ADD_ORGANIZATION_RESEARCHER_FAILED,
}

// ------------------------------------
// Actions
// ------------------------------------
export const createOrganization = createAction(CREATE_ORGANIZATION)
export const createOrganizationSuccess = createAction(
  CREATE_ORGANIZATION_SUCCESS,
)
export const createOrganizationFail = createAction(CREATE_ORGANIZATION_FAIL)
export const createOrganizationAccount = createAction(
  CREATE_ORGANIZATION_ACCOUNT,
)
export const createOrganizationAccountFail = createAction(
  CREATE_ORGANIZATION_ACCOUNT_FAIL,
)
export const resetForm = createAction(RESET_FORM)
export const getOrganization = createAction(GET_ORGANIZATION)
export const getOrgUid = createAction(GET_ORGUID)
export const getOrgUidSuccess = createAction(GET_ORGUID_SUCCESS)
export const getOrganizationSuccess = createAction(GET_ORGANIZATION_SUCCESS)
export const getOrganizationFailed = createAction(GET_ORGANIZATION_FAILED)
export const editOrganization = createAction(EDIT_ORGANIZATION)
export const editOrganizationSuccess = createAction(EDIT_ORGANIZATION_SUCCESS)
export const editOrganizationFailed = createAction(EDIT_ORGANIZATION_FAILED)
export const addResearcher = createAction(ADD_ORGANIZATION_RESEARCHER)
export const addResearcherProccess = createAction(
  ADD_ORGANIZATION_RESEARCHER_PROCCESS,
)
export const addResearcherSuccess = createAction(
  ADD_ORGANIZATION_RESEARCHER_SUCCESS,
)
export const addResearcherFailed = createAction(
  ADD_ORGANIZATION_RESEARCHER_FAILED,
)

export const actions = {
  createOrganization,
  createOrganizationSuccess,
  createOrganizationFail,
  createOrganizationAccount,
  createOrganizationAccountFail,
  resetForm,
  getOrganization,
  getOrgUid,
  getOrgUidSuccess,
  getOrganizationSuccess,
  getOrganizationFailed,
  editOrganization,
  editOrganizationSuccess,
  editOrganizationFailed,
  addResearcher,
  addResearcherProccess,
  addResearcherSuccess,
  addResearcherFailed,
}

export const reducers = {
  [CREATE_ORGANIZATION_FAIL]: (state, { payload }) => ({
    ...state,
    isError: payload,
  }),
  [CREATE_ORGANIZATION_ACCOUNT_FAIL]: (state, { payload }) => ({
    ...state,
    isError: payload,
  }),
  [CREATE_ORGANIZATION_SUCCESS]: state => ({
    ...state,
    isDone: true,
    isError: null,
  }),
  [RESET_FORM]: state => ({
    ...state,
    isDone: false,
    isError: null,
  }),
  [GET_ORGUID_SUCCESS]: (state, { payload }) => {
    console.log('skoka mozhno', payload)
    return {
      orgUid: payload,
      isError: null,
    }
  },
  [GET_ORGANIZATION_SUCCESS]: (state, { payload }) => ({
    ...state,
    info: payload,
    isError: null,
  }),
  [GET_ORGANIZATION_FAILED]: state => ({
    ...state,
    info: {},
    isError: null,
  }),
  [EDIT_ORGANIZATION_SUCCESS]: (state, { payload }) => ({
    ...state,
    info: payload,
    isError: null,
  }),
  [EDIT_ORGANIZATION_FAILED]: (state, { payload }) => ({
    ...state,
    isError: payload,
  }),
  [ADD_ORGANIZATION_RESEARCHER_FAILED]: (state, { payload }) => ({
    ...state,
    isError: payload,
  }),
  [ADD_ORGANIZATION_RESEARCHER_SUCCESS]: state => ({
    ...state,
    addingResearcher: false,
    isError: null,
  }),
  [ADD_ORGANIZATION_RESEARCHER_PROCCESS]: state => ({
    ...state,
    isError: null,
    addingResearcher: true,
  }),
}

export const initialState = () => {
  const state = {
    isDone: false,
    isError: null,
    orgUid: '',
    addingResearcher: false,
    info: {},
  }
  return state
}

export default handleActions(reducers, initialState())
