import React, { Component } from 'react'
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd'
import encQueries from 'components/GSearchPanel/Queries/encQueries'
import { listQueries } from 'components/CreatePanel/queries/getList'
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters'

import '../../index.scss'
import AutoInputGQL from '../fields/autoInputGQL'
import FileUpload from '../fields/fileUpload'
import CoordinatesInput from '../fields/coordinatesInput'
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions'

const FormItem = Form.Item
const { Panel } = Collapse
const { Option } = Select
const { TextArea } = Input

class BuildingTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
    }
  }

  addTab = () => {
    const key = this.newTabIndex++
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state
    newName[key] = data.typed
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = input => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      filelistChanged,
      getFieldDecorator,
      formInputLayout,
      stateTabs,
      subjects,
      createData,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props

    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel
              header={`${t('create_panel.tab.monument.monument')} #${1 + key}`}
              key={title + item.key}
            >
              <AutoInputGQL
                key={`building_select_${key}`}
                handleSelect={data => {
                  this.handleSelect(data, key)
                }}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.monument.choose')}
                selectkey={`building_select_${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.monument.add')}
                query={encQueries.monumentSearch()}
                params={[{ key: 'search', val: 'value' }]}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel="Monument"
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') && (
                <div>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.monument.name')}
                  >
                    {getFieldDecorator(`building_name_${key}`, {
                      ...config.req,
                      initialValue: this.state.newName[key],
                    })(
                      <Input
                        onChange={this.handleChangeName}
                        data-objkey={key}
                      />,
                    )}
                  </FormItem>
                  <CoordinatesInput
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={title}
                    subjname="building"
                    objkey={key}
                    handleAddMarker={handleAddMarker}
                    interactiveMarkers={interactiveMarkers}
                    form={form}
                    t={t}
                  />
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.monument.year')}
                  >
                    {getFieldDecorator(
                      `building_year_${key}`,
                      {},
                    )(
                      <div>
                        <InputNumber min={0} max={new Date().getFullYear()} />
                      </div>,
                    )}
                  </FormItem>
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="Monument"
                    rightLabel="ArchitecturalEnsemble"
                    connectionField="architectural_ensemble"
                    label={t('common.ensemble')}
                    selectkey={`building_ensemble_${key}`}
                    selectType="ensemble"
                    stateTabs={stateTabs}
                    objkey={key}
                    addOptionText={t('create_panel.tab.ensemble.add')}
                    newKeyForNew
                    saveTypedText
                    query={listQueries.ensembleSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.monument.architect')}
                  >
                    {getFieldDecorator(
                      `building_architect_${key}`,
                      {},
                    )(<Input />)}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.monument.style')}
                  >
                    {getFieldDecorator(
                      `building_style_${key}`,
                      {},
                    )(
                      <TextArea
                        onPaste={e =>
                          textFieldFormatters.onTextAreaPaste(
                            e,
                            form,
                            `building_style_${key}`,
                          )
                        }
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.monument.address_ru')}
                  >
                    {getFieldDecorator(
                      `building_address_ru_${key}`,
                      {},
                    )(<TextArea />)}
                  </FormItem>
                  {/* <FormItem */}
                  {/* {...formInputLayout} */}
                  {/* label={t('create_panel.tab.monument.address_en')} */}
                  {/* > */}
                  {/* {getFieldDecorator(`building_address_en_${key}`, { */}
                  {/* })( */}
                  {/* <TextArea />)} */}
                  {/* </FormItem> */}
                  {/* <FormItem */}
                  {/* {...formInputLayout} */}
                  {/* label={t('common.date_address')} */}
                  {/* > */}
                  {/* {getFieldDecorator(`building_address_since_${key}`, {})( */}
                  {/* <InputNumber placeholder={t('common.date_since')} min={-100000} max={new Date().getFullYear()} />)} */}
                  {/* {getFieldDecorator(`building_address_to_${key}`, {})( */}
                  {/* <InputNumber placeholder={t('common.date_to')} min={-100000} max={new Date().getFullYear()} />)} */}
                  {/* </FormItem> */}
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.monument.desc')}
                  >
                    {getFieldDecorator(
                      `building_desc_${key}`,
                      {},
                    )(
                      <TextArea
                        onPaste={e =>
                          textFieldFormatters.onTextAreaPaste(
                            e,
                            form,
                            `building_desc_${key}`,
                          )
                        }
                      />,
                    )}
                  </FormItem>
                  <FileUpload
                    getFieldDecorator={getFieldDecorator}
                    formInputLayout={formInputLayout}
                    filelistChanged={filelistChanged}
                    fileLists={createData.sourceFilelist}
                    label={`building_files_${key}`}
                    fileType="photo"
                    title={t('common.photo.title')}
                    t={t}
                  />
                  <RelationComponent
                    handleAddExtraRelation={handleAddExtraRelation}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    objkey={key}
                    title="Monument"
                    tabName="monuments"
                    stateTabs={stateTabs}
                    form={form}
                    t={t}
                  />
                </div>
              )}
            </Panel>
          ))}
        </Collapse>

        <FormItem wrapperCol={{ span: 8, offset: 8 }}>
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.monument.add_button')}
          </Button>
        </FormItem>
      </div>
    )
  }
}

export default BuildingTab
