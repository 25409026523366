import React from 'react'
import {
  TAB_TYPE,
  createCommonField,
  createImageField,
  createLinkField,
  newLineReg,
} from 'utility/transformData/constants';

export default function getHeritageFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.COLLAPSE: {
      // console.log('getHeritageFields', d)
      const data = d.results;
      return data.map((item, index) => {
        const itemObj = {}
        const heritage = d.results[0];
        const ofdoc = heritage.officialdocument[0];
        itemObj.content = [
          createLinkField('name', ofdoc.file, '', heritage.ru_name, false),
        ]
        itemObj.name = heritage.ru_name
        return itemObj;
      })
    }
    default:
      return [];
  }
}
