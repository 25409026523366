import React, { PureComponent, Suspense } from 'react'
import { translate } from 'react-i18next'
import { List, Spin } from 'antd'
import i18n from 'i18next'

import './index.css'

const CommonField = React.lazy(() => import('../CommonField'))
const LinkField = React.lazy(() => import('../LinkField'))
const ChartField = React.lazy(() => import('../ChartField'))
const ImageField = React.lazy(() => import('../ImageField'))
const GalleryField = React.lazy(() => import('../GalleryField'))
const ButtonField = React.lazy(() => import('../ButtonField'))
const ArchiveReference = React.lazy(() => import('../ArchiveReference'))
const VSplit = React.lazy(() => import('../VSplit'))
const Rowline = React.lazy(() => import('../VSplit'))
const fields = {
  common: CommonField,
  link: LinkField,
  chart: ChartField,
  image: ImageField,
  gallery: GalleryField,
  button: ButtonField,
  reference: ArchiveReference,
  vSplit: VSplit,
  rowline: Rowline,
}

function FieldComponent(props) {
  const Component = fields[props.type]

  return (
    <Suspense fallback={<Spin />}>
      <Component
        content={props.content}
        subjectType={props.subjectType}
        func={FieldComponent}
      />
    </Suspense>
  )
}

@translate()
class ListContent extends PureComponent {
  getItemTitle = (subjectType, value) => {
    // Trying to load from dataPanel : if not found get just value
    if (value) {
      if (!i18n.exists(`dataPanel.${subjectType}.${value}`)) {
        return value
      }
      return i18n.t(`dataPanel.${subjectType}.${value}`)
    }
    return null
  }

  render() {
    const { subjectType, content } = this.props

    return (
      <List
        locale={{ emptyText: ' ' }}
        dataSource={content}
        split=""
        size="small"
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={this.getItemTitle(subjectType, item.fieldName)}
              description={
                <FieldComponent
                  type={item.type}
                  content={item}
                  subjectType={subjectType}
                />
              }
            />
          </List.Item>
        )}
      />
    )
  }
}

export default ListContent
