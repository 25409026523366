export const words = [
  { word: 'бобовой кӧжы' },
  { word: 'бӧбыкӧжы' },
  { word: 'нэмычкӧӝы' },
  { word: 'с\'эткэжъ' },
  { word: 'шайэркӧжъ' },
  { word: 'сит\'пӱжъ ' },
  { word: 'пунысит\' ' },
  { word: 'арßа ' },
  { word: 'оп\'онка' },
  { word: 'йалдоргуби' },
  { word: 'лыйалгуби' },
  { word: 'мырыкгӱби' },
  { word: 'д\'а.т\'эл ' },
  { word: 'с\'ис\'кокчас\' ' },
  { word: 'писпукокас\' ' },
  { word: 'с\'ис\'корас\' ' },
  { word: 'с\'ис\'кыр' },
  { word: 'ухват кук' },
  { word: 'конгыл\'о' },
  { word: 'кырыш кук' },
  { word: 'пумлäн\' пъд ' },
  { word: 'жэрэбий куштыны ' },
  { word: 'жэрэбэй лэс\'тънъ ' },
  { word: 'пусйас\'кънъ ' },
  { word: 'пус потънъ' },
  { word: 'йылэ потыны' },
  { word: 'ки кутылыны' },
  { word: 'шабага кутыны' },
  { word: 'шыбага куштыны' },
  { word: 'гын шар' },
  { word: 'сага' },
  { word: 'с\'эс\'кыны ' },
  { word: 'вамэнйас\'кыны ' },
  { word: 'ўацкыны ' },
  { word: 'таныны' },
  { word: 'пэрочинной пурт' },
  { word: 'ӟърийо пурт' },
  { word: 'куасано пурт ' },
  { word: 'пäки пурт' },
  { word: 'с\'эн\'къ' },
  { word: 'с\'эн\'кигӱби' },
  { word: 'кис\'пъгибъ' },
  { word: 'мэкан' },
  { word: 'ботан' },
  { word: 'боды' },
  { word: 'чорыг кышкатон ' },
  { word: 'верховой вал' },
  { word: 'ворӟэм вал' },
  { word: 'вылаз ветлон вал' },
  { word: 'кручок' },
  { word: 'кърик къл' },
  { word: 'визнан йыл' },
  { word: 'визнан вэн\'' },
  { word: 'кармак кыл' },
  { word: 'кл\'эват\' карыны' },
  { word: 'зэгатыны' },
  { word: 'йӧтскылыны' },
  { word: 'пӧйшурас\'' },
  { word: 'сунэрас\' ' },
  { word: 'пукы' },
  { word: 'мэкан' },
  { word: 'лӧптэт' },
  { word: 'лӧ°пкытэт' },
  { word: 'мӧ°шка' },
  { word: 'чэк' },
  { word: 'калкаучъс\'' },
]

export default words;
