const ICONS = {
  1: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <rect x="17" y="17" width="32" height="32" fill="white" />
    </svg>
  `,
  2: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <circle cx="33" cy="33" r="16" fill="white" />
    </svg>
  `,
  3: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        d="M33.8944 51.2111C33.5259 51.9482 32.4741 51.9482 32.1056 51.2111L17.7236 22.4472C17.3912 21.7823 17.8747 21 18.618 21L47.382 21C48.1253 21 48.6088 21.7823 48.2764 22.4472L33.8944 51.2111Z"
        fill="white"
      />
    </svg>
  `,
  4: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path d="M13 27H53L45 43H21L13 27Z" fill="white" />
    </svg>
  `,
  5: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33 25C40.8112 25 47.315 30.5974 48.7192 38H53V41H49H17H13V38H17.2808C18.685 30.5974 25.1888 25 33 25Z"
        fill="white"
      />
    </svg>
  `,
  6: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49 17H17V49H49V17ZM32 22H22V44H32V22Z"
        fill="white"
      />
    </svg>
  `,
  7: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 17L33 33L20 49H46L33 33L46 17L20 17Z"
        fill="white"
      />
    </svg>
  `,
  8: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        d="M16.7 33.6C16.4333 33.2444 16.4333 32.7556 16.7 32.4L31.2 13.0667C31.6 12.5333 32.4 12.5333 32.8 13.0667L47.3 32.4C47.5667 32.7556 47.5667 33.2444 47.3 33.6L32.8 52.9333C32.4 53.4667 31.6 53.4667 31.2 52.9333L16.7 33.6Z"
        fill="white"
      />
    </svg>
  `,
  9: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        d="M42.0411 18.8781C35.154 11.9911 27.6707 11.7787 23.1207 12.8612C22.1677 13.0879 22.2988 14.3263 23.2376 14.606C30.5896 16.7958 37.9751 22.9441 34.2629 26.6563L42.0411 18.8781Z"
        fill="white"
      />
      <path
        d="M47.6979 24.5349C54.585 31.422 54.7974 38.9053 53.7149 43.4553C53.4881 44.4083 52.2498 44.2772 51.9701 43.3384C49.7803 35.9864 43.632 28.6009 39.9198 32.3131L47.6979 24.5349Z"
        fill="white"
      />
      <path
        d="M43.4553 17.4639L49.1121 23.1208V25.9492L45.9302 26.3027L20.1208 52.1121L14.4639 46.4553L40.2733 20.6459L40.6269 17.4639H43.4553Z"
        fill="white"
      />
    </svg>
  `,
  10: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.876 28C39.3354 26.6353 41 24.012 41 21C41 16.5817 37.4183 13 33 13C28.5817 13 25 16.5817 25 21C25 24.012 26.6646 26.6353 29.124 28H29L22 47H17V53H49V47H44L37 28H36.876Z"
        fill="white"
      />
    </svg>
  `,
  11: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        d="M29.2749 39.8379C29.2892 37.8327 29.5828 36.3646 30.1558 35.4336C30.743 34.5026 31.9032 33.3711 33.6362 32.0391C34.6532 31.2083 35.4624 30.3132 36.064 29.3535C36.6799 28.3939 36.9878 27.3197 36.9878 26.1309C36.9878 24.8132 36.6369 23.7819 35.9351 23.0371C35.2476 22.2923 34.2593 21.9199 32.9702 21.9199C31.9103 21.9199 31.008 22.235 30.2632 22.8652C29.5327 23.4954 29.1603 24.4193 29.146 25.6367H23.0444L23.0015 25.5078C22.9585 22.7578 23.8608 20.6523 25.7085 19.1914C27.5705 17.7305 29.991 17 32.9702 17C36.1785 17 38.6922 17.8164 40.5112 19.4492C42.3446 21.0677 43.2612 23.2734 43.2612 26.0664C43.2612 27.8854 42.7241 29.5684 41.6499 31.1152C40.59 32.6621 39.258 33.9583 37.6538 35.0039C36.7801 35.6771 36.2072 36.3574 35.9351 37.0449C35.6629 37.7181 35.5269 38.6491 35.5269 39.8379H29.2749ZM35.5698 48.7324H29.2534V43.3613H35.5698V48.7324Z"
        fill="white"
      />
    </svg>
  `,
  12: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 25V46H16V49H48V46H42V25V24H41.9506C41.4489 18.9467 37.1853 15 32 15C26.8147 15 22.5511 18.9467 22.0494 24H22V25Z"
        fill="white"
      />
    </svg>
  `,
}
ICONS['default'] = ICONS[11]

export default ICONS
