import {
  TAB_TYPE,
  createCommonField,
  createImageField,
  createLinkField,
  getAttribute,
  getLangTitle,
} from 'utility/transformData/constants';

export default function getEncorganizationFields(d, tabType) {
  console.log('locality orgs', d)
  const r = d;
  switch (tabType) {
    case TAB_TYPE.MAIN:
      return [
        createCommonField('name', getLangTitle(r)),
        createImageField('photo', r.photo),
        createCommonField('year', r.date_start),
        createCommonField('type', getAttribute(r.attributes, 'EncOrganizationType', 'ru_name')),
        createCommonField('desc', getLangTitle(r.interpretations[0], 'desc')),
      ];
    case TAB_TYPE.COLLAPSE: {
      return d.results.map((item) => {
        const itemObj = {}
        const interpretation = (item.interpretations) ? item.interpretations[0] : item.research;
        itemObj.content = [
          createLinkField('name', item.uid, 'subjects/EncOrganization',
            getLangTitle(item)),
          createImageField('photo', item.photo),
          createCommonField('year', item.date_start),
          createCommonField('type', getAttribute(item.attributes, 'EncOrganizationType', 'ru_name')),
          createCommonField('desc', getLangTitle(interpretation, 'desc')),
        ]
        itemObj.name = getLangTitle(item)
        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
