import { createAction, handleActions } from 'redux-actions';

const GET_HOME_PAGE_INFO = 'home/GET_HOME_PAGE_INFO';
const GET_HOME_PAGE_INFO_SUCCESS = 'home/GET_HOME_PAGE_INFO_SUCCESS';
const GET_HOME_PAGE_INFO_FAILED = 'home/GET_HOME_PAGE_INFO_FAILED';

export const constants = {
  GET_HOME_PAGE_INFO,
  GET_HOME_PAGE_INFO_SUCCESS,
  GET_HOME_PAGE_INFO_FAILED,
};

// ------------------------------------
// Actions
// ------------------------------------
export const getHomePageInfo = createAction(GET_HOME_PAGE_INFO);
export const getHomePageInfoSuccess = createAction(GET_HOME_PAGE_INFO_SUCCESS);
export const getHomePageInfoFailed = createAction(GET_HOME_PAGE_INFO_FAILED);

export const actions = {
  getHomePageInfo,
  getHomePageInfoSuccess,
  getHomePageInfoFailed,
};

export const reducers = {
  [GET_HOME_PAGE_INFO_SUCCESS]: (state, { payload }) =>
    ({
      ...state,
      isLoading: false,
      data: payload,
    }),
  [GET_HOME_PAGE_INFO_FAILED]: state =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      data: [],
    }),
}

export const initialState = () => {
  const state = {
    isLoading: true,
    data: [],
  }
  return state;
}

export default handleActions(reducers, initialState());
