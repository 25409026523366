import { getVersionId, getMasterId } from 'components/GSearchPanel/Queries/constants'

const dnaQueries = {
  sampleSearch: () => {
    return {
      query: `
      query postQuery($search: String="") {
        results:dnaSamples(versionId_Icontains: $search) {
          totalCount
          edges {
            node {
              uid
              versionId
            }
          }
        }
      }`,
      url: 'subjects/DNASample',
      fields: {
        name: {
          data: getVersionId,
          sort: 'versionId',
        },
      },
    }
  },
  individualSearch: () => {
    return {
      query: `
      query postQuery($search: String="") {
        results:individuals(masterId_Icontains: $search) {
          totalCount
          edges {
            node {
              uid
              masterId
            }
          }
        }
      }`,
      url: 'subjects/Individual',
      fields: {
        name: {
          data: getMasterId,
          sort: 'masterId',
        },
      },
    }
  },
}

export default dnaQueries
