import React, { Component } from 'react';
import { Button, Icon, Form, InputNumber, Select, Collapse } from 'antd';
import { connect } from 'react-redux';

const FormItem = Form.Item;
const { Option } = Select;

const mapStateToProps = state => ({
  drawnPolygon: state.example.drawnPolygon
});

const mapDispatchToProps = {};

const { Panel } = Collapse;
const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};

@connect(mapStateToProps, mapDispatchToProps)
class CoordinatesInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coordsType: 'point',
      polygonPoints: [{ lat: 0, lng: 0 }],
      saveNewPolygon: false,
    };
  }

  componentDidUpdate() {
    const {
      title,
      objkey,
      subjname,
      interactiveMarkers,
      form,
    } = this.props;

    const { coordsType, polygonPoints, saveNewPolygon } = this.state;

    if (coordsType === 'point') {
      const coords = interactiveMarkers.filter(m => m.uid === `${this.props.title}_${objkey}`)[0];
      const key1 = `${subjname}_coords_n_${objkey}`;
      const key2 = `${subjname}_coords_e_${objkey}`;

      const newCoords = {};
      newCoords[key1] = (coords) ? coords.coords.lat : null;
      newCoords[key2] = (coords) ? coords.coords.lng : null;

      const oldCoords = {};
      oldCoords[key1] = this.props.form.getFieldValue(key1)
      oldCoords[key2] = this.props.form.getFieldValue(key2)

      if (newCoords[key1] !== oldCoords[key1] || newCoords[key2] !== oldCoords[key2]) {
        if (newCoords[key1] !== null && newCoords[key2] !== null) {
          form.setFieldsValue(newCoords)
        }
      }
    }

    if (coordsType === 'polygon' && saveNewPolygon) {
      const newPolygonValues = {};
      polygonPoints.forEach((point, id) => {
        const keyn = `${subjname}_coords_n_${objkey}_${id}`;
        const keye = `${subjname}_coords_e_${objkey}_${id}`;
        console.log('polygonPoints111', point, keye, keyn)

        newPolygonValues[keyn] = point.lat;
        newPolygonValues[keye] = point.lng;

        form.setFieldsValue(newPolygonValues)
      })
      this.setState({ saveNewPolygon: false })
    }
  }

  handleChangeType = (newType) => {
    this.setState({ coordsType: newType })
  }

  handleGetDrawnPolygon = () => {
    const polygon = this.props.drawnPolygon;
    console.log('handleGetDrawnPolygon', polygon)

    if (polygon && polygon[0]) {
      this.setState({ polygonPoints: polygon[0], saveNewPolygon: true })
      this.forceUpdate()
    }
  }

  render() {
    const {
      getFieldDecorator,
      formLayout,
      handleAddMarker,
      title,
      objkey,
      subjname,
      rules,
      defaultActive,
      t,
    } = this.props;

    const { coordsType, polygonPoints } = this.state;

    const active = (defaultActive) ? ['1'] : [];

    return (
      <div>
        <Collapse
          bordered={false}
          defaultActiveKey={active}
          expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
        >
          <Panel header="Add own coordinates" key="1" style={customPanelStyle}>
            <FormItem
              {...formLayout}
              label={t('common.coords_type')}
            >
              {getFieldDecorator(`${subjname}_coords_type_${objkey}`, {
                initialValue: 'point',
              })(
                <Select onChange={this.handleChangeType}>
                  <Option value="point">{t('common.point')}</Option>
                  <Option value="polygon">{t('common.polygon')}</Option>
                </Select>)}
            </FormItem>

            {coordsType === 'point' &&
              <FormItem
                {...formLayout}
                label={t('common.coords')}
              >
                {getFieldDecorator(`${subjname}_coords_n_${objkey}`, rules)(
                  <InputNumber placeholder="N" />)}
                {getFieldDecorator(`${subjname}_coords_e_${objkey}`, rules)(
                  <InputNumber placeholder="E" />)}
                <Button
                  type="primary"
                  onClick={handleAddMarker}
                  className="add-interactive-marker-button"
                  data-title={title}
                  data-key={objkey}
                >
                  <Icon type="environment" />
                </Button>
              </FormItem>
            }

            {coordsType === 'polygon' &&
              <div>
                {polygonPoints.map((point, id) => (
                  <FormItem
                    {...formLayout}
                    label={`${t('common.point')} #${id+1}`}
                  >
                    {getFieldDecorator(`${subjname}_coords_n_${objkey}_${id}`)(
                      <InputNumber placeholder="N" />)}
                    {getFieldDecorator(`${subjname}_coords_e_${objkey}_${id}`)(
                      <InputNumber placeholder="E" />)}
                  </FormItem>
                ))}
                <FormItem
                  {...formLayout}
                  wrapperCol={{ offset: 8 }}
                >
                  <Button
                    type="primary"
                    onClick={this.handleGetDrawnPolygon}
                    >
                      {t('common.polygon_map_save')}
                  </Button>
                </FormItem>
              </div>
            }

            <FormItem
              {...formLayout}
              label={t('common.coords_a')}
            >
              {getFieldDecorator(`${subjname}_coords_a_${objkey}`, {
                initialValue: '4',
              })(
                <Select>
                  <Option value="4">{t('common.coords_a_type.1')}</Option>
                  <Option value="3">{t('common.coords_a_type.2')}</Option>
                  <Option value="2">{t('common.coords_a_type.3')}</Option>
                  <Option value="1">{t('common.coords_a_type.4')}</Option>
                  <Option value="0">{t('common.coords_a_type.5')}</Option>
                </Select>)}
            </FormItem>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default CoordinatesInput;
