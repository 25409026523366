const ICONS = {
  0: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.0876 23.9128C37.8991 22.5778 39.1022 20.2211 39.1022 17.5365C39.1022 13.3742 36.21 10 32.6424 10C29.0747 10 26.1825 13.3742 26.1825 17.5365C26.1825 20.2211 27.3856 22.5778 29.1971 23.9128V25.718C21.0175 26.7437 18 31.1021 18 31.1021C23.3832 36.7008 23.3832 41.8686 23.3832 41.8686H42.3322C42.3322 41.8686 42.3322 36.7008 47.5 31.1021C47.5 31.1021 44.4296 26.6672 36.0876 25.6919V23.9128ZM24.8905 44.022H40.3942V49.6205H24.8905V44.022ZM21.4453 51.3431H43.8394V54.7884H21.4453V51.3431Z"
        fill="white"
      />
    </svg>
  `,
}
ICONS['default'] = ICONS[0]

export default ICONS
