import React, { PureComponent } from 'react';

import ListContent from '../ListContent';
import './index.css';

class CollapseTab extends PureComponent {
  render() {
    const { subjectType, content } = this.props;

    return (
      <ListContent
        subjectType={subjectType}
        content={content}
      />
    )
  }
}

export default CollapseTab;
