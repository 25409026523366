import * as request from '../../utility/request';

export const getTagList = async () => {
  const path = 'api/tags/list/';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getTag = async (id) => {
  const path = `api/tags/uid/${id}/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const createTag = async (body) => {
  const path = 'api/tags/';
  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH());
  return { response, error };
}
