import {
  TAB_TYPE,
  createCommonField,
  getLangTitle,
  createLinkField,
} from 'utility/transformData/constants';

export default function getCultureFields(d, tabType) {
  console.info('culture transf', d);
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      console.info('culture item', item);
      const content = [
        createCommonField('name', getLangTitle(item)),
      ]
      if (item.attributes.Epoch && item.attributes.Epoch[0]) {
        content.push(
          createCommonField('Epoch', getLangTitle(item.attributes.Epoch[0])),
        )
      }
      return content
    }
    case TAB_TYPE.COLLAPSE: {
      return d.results.map((item, index) => {
        const itemObj = {}
        let artName = item.ru_name || (item.research ? (getLangTitle(item.research)) : '');
        itemObj.content = [
          createLinkField('title', item.uid, 'subjects/Culture',
            'Перейти к культуре'),
        ]
        if (item.research) {
          if (item.research.date_end || item.research.date_start) {
            itemObj.content.push(
              createCommonField('date', `${item.research.date_start || '?'} - ${item.research.date_end || '?'}`),
            )
          }
          if (item.research.ru_desc) {
            itemObj.content.push(
              createCommonField('description', getLangTitle(item.research, 'desc')),
            )
          }
        }
        if (item.attributes.Epoch && item.attributes.Epoch[0]) {
          itemObj.content.push(
            createCommonField('Epoch', getLangTitle(item.attributes.Epoch[0])),
          )
          artName += ` (${getLangTitle(item.attributes.Epoch[0])}) `
        }
        itemObj.name = artName || '-'
        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
