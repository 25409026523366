import React from 'react'
import {
  TAB_TYPE,
  createCommonField,
  createImageField,
  newLineReg,
} from 'utility/transformData/constants';

export default function getRadiocarbonSummaryFields(d, tabType) {
  console.log('RadiocarbonSummary', d);
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const result = [
        createCommonField('author', d.author),
        createCommonField('year', d.year),
      ];
      return result;
    }
    case TAB_TYPE.RADIO: {
      const item = d.result;
      const result = [];
      if (item.curve_type) {
        result.push(
          createCommonField('curve_type', item.curve_type),
        )
      }
      if (item.description) {
        result.push(
          createCommonField('description', <pre>{item.description.replace(newLineReg, '\n')}</pre>),
        )
      }
      if (item.photo) {
        result.push(
          createImageField('photo', item.photo),
        )
      }
      return result;
    }
    default:
      return [];
  }
}
