import React from 'react'
import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  sortArray,
} from 'utility/transformData/constants';
import getVariantFields from 'utility/transformData/fields/variant';

function sortVariants(variants) {
  const ret = {}
  console.log('sortVariants', variants)
  variants.forEach(variant => {
    const attrs = (variant.node.lexemeName.edges[0]) ? variant.node.lexemeName.edges[0].node.attributes.edges : []
    const lang = attrs.filter(a => a.node.label === 'Language')[0].node.ru_name
    if (!ret[lang]) {
      ret[lang] = [];
    }
    ret[lang].push(variant);
  })

  console.log('variants', variants, ret)
  return ret;
}

export default function getVariantLanguageFields(d, tabType) {
  console.log(tabType);
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      console.log(item);
      return []
    }
    case TAB_TYPE.COLLAPSE: {
      console.log('getVariantLanguageFields', d);
      const result = [];
      const groups = sortVariants(d.results)
      Object.keys(groups).forEach((key) => {
        const itemObj = {}
        console.log(key);
        itemObj.content = []
        itemObj.childrenItem = {
          subjectType: 'variant',
          content: getVariantFields({ results: groups[key] }, TAB_TYPE.COLLAPSE, key),
        }
        itemObj.name = key;
        result.push(itemObj);
      })
      return sortArray(result)
    }
    default:
      return [];
  }
}
