import React from 'react';
import { Card, Tag, Icon } from 'antd';
import i18n from 'i18next';

import './index.css';

const getFullName = (obj) => (`${obj.last_name || ''} ${obj.first_name || ''} ${obj.parent_name || ''}`);

const getLang = () => {
  const langs = ['ru', 'tat', 'en'];
  if (langs.includes(i18n.language)) {
    return `${['ru','en'].includes(i18n.language) ? i18n.language: 'local'}_name`
  }
  return 'ru_name';
}

const UserItem = ({
  user, handleUserRemove, tags, children,
}) => (
  <Card
    type="inner"
    title={getFullName(user)}
    extra={<button onClick={() => handleUserRemove(user.uid)}><Icon type="close" /></button>}
    className="user-card"
  >
    <div>{user.birthday}</div>
    <div>
      { user.scientific_interests && user.scientific_interests.map(item => (
        <Tag key={item}>{tags.get(item)[getLang()]}</Tag>
      ))}
    </div>
    { children }
  </Card>
)

export default UserItem;
