import React from 'react'
import i18n from 'i18next'
import {
  getPropertyByNameString,
  removeEmptyElements,
} from 'utility/transformData/constants'
import {
  getLangName,
  getItem,
  getInterpretation, getAuthorName,
} from 'components/GSearchPanel/Queries/constants'
import MapIcon from 'components/MapIcon'
import { getLayer } from 'distLibs/images/monTypes/index'
import { page, searchPolygonArg, searchPolygonVar } from './queries'

const lang = ['ru','en'].includes(i18n.language) ? i18n.language: 'local'

export const siteTypeFragment = `
fragment siteType on SiteTypeNodeConnection{
  edges {
    node {
      uid
      sort
      ruName, enName, localName
    }
  }
}`

export const epochFragment = `
fragment epoch on EpochNodeConnection{
  edges {
    node {
      uid
      sort
      ruName, enName, localName
    }
  }
}`

const assemblageSearchFragment = (searchPolygon, searchByLayer) => {
  if (searchByLayer) {
    return `
    query postQuery($search: String="") {
          results: assemblageSearchByLayer (search: $search, first: 25, last: 25) {
    `
  }

  return `
    query postQuery(${searchPolygonArg(
      searchPolygon,
    )} $search: String="", $assemblageType: UUID, ${page.def}) {
          results: assemblages (${searchPolygonVar(
            searchPolygon,
          )} commonSearch: $search, assemblagetype_Has: $assemblageType, ${
    page.params
  }) {
    `
}

const archQueries = {
  authorSearch: searchPolygon => {
    return {
      query: `
          query postQuery(${searchPolygonArg(
            searchPolygon,
          )} $search: String="", ${page.def}) {
          results:searchAuthors(${searchPolygonVar(
            searchPolygon,
          )} search: $search, ${page.params}) {
            totalCount
            edges {
              node {
                uid
                publicName
                publicNameEn
              }
            }
          }
        }`,
      url: 'author',
      fields: {
        author: {
          data: getAuthorName,
          sort: 'public_name',
        },
      },
    }
  },

  siteSearch: searchPolygon => {
    return {
      query: `query postQuery(${searchPolygonArg(
        searchPolygon,
      )} $search: String="", ${page.def}) {
        results:searchSites(${searchPolygonVar(
          searchPolygon,
        )} nameIcontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              interpretation {
                ${lang}Name
              }
            }
          }
        }
      }`,
      url: 'subjects/Site',
      fields: {
        name: {
          data: getInterpretation,
          sort: `common_${lang}_name`,
        },
      },
    }
  },

  layerSearch: searchPolygon => {
    return {
      query: `
    ${siteTypeFragment}
    ${epochFragment}
    query postQuery(${searchPolygonArg(
      searchPolygon,
    )} $search: String="", $epoch: ID, $siteType: ID, ${page.def}) {
      results:searchLayers(${searchPolygonVar(
        searchPolygon,
      )} nameIcontains: $search,
                           epoch: $epoch,
                           siteType: $siteType, ${page.params}) {
        totalCount
        edges {
          node {
            uid
            label
            commonCultures {
              count
              culture {
                ${lang}Name
              }
            }
            commonRuName
            interpretation {
              ${lang}Name
            }
            sitetype {
              ...siteType
            }
            epoch {
              ...epoch
            }
          }
        }
      }
    }`,
      url: 'subjects/Layer',
      fields: {
        name: {
          data: getInterpretation,
          sort: `common_${lang}_name`,
        },
        sitetype: {
          data: getItem(`sitetype.edges.0.node.${lang}Name`),
          sort: `sitetype__${lang}_name`,
        },
        epoch: {
          data: getItem(`epoch.edges.0.node.${lang}Name`),
          sort: `epoch__${lang}_name`,
        },
      },
    }
  },

  heritageSearch: searchPolygon => {
    return {
      query: `
    query postQuery(${searchPolygonArg(searchPolygon)} $search: String="", ${
        page.def
      }) {
      results:searchHeritages(${searchPolygonVar(
        searchPolygon,
      )} ${lang}Name_Icontains: $search, ${page.params}) {
        totalCount
        edges {
          node {
            ${lang}Name
            uid
          }
        }
      }
    }`,
      url: 'subjects/Heritage',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
      },
    }
  },
  assemblageSearch: (searchPolygon, searchByLayer = false) => {
    const Lang = lang.charAt(0).toUpperCase() + lang.slice(1)
    return {
      query: `
      ${siteTypeFragment}
      ${epochFragment}

      ${assemblageSearchFragment(searchPolygon, searchByLayer)}
          totalCount
          edges {
            node {
              uid
              ${lang}Name
              layer {
                edges {
                  node {
                    commonCultures {
                      count
                      culture {
                        ${lang}Name
                      }
                    }
                    sitetype {
                      ...siteType
                    }
                    epoch {
                      ...epoch
                    }
                    common${Lang}Name
                    site {
                      edges {
                        node {
                          common${Lang}Name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'subjects/Assemblage',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        layer: {
          data: data => {
            const layer = getPropertyByNameString(data, 'layer.edges.0')
            const layerName = getPropertyByNameString(
              layer,
              `node.common${Lang}Name`,
            )
            const imageLayer = <MapIcon icon="layer" {...getLayer(layer)} />
            return (
              <>
                {imageLayer}
                {layerName}
              </>
            )
          },
          sort: `layer__common_${lang}_name`,
        },
        site: {
          data: data => {
            const layer = getPropertyByNameString(data, 'layer.edges.0')
            const site = getPropertyByNameString(layer, 'node.site.edges.0')
            const siteName = getPropertyByNameString(
              site,
              `node.common${Lang}Name`,
            )
            const imageSite = <MapIcon icon="site" {...getLayer(layer)} />
            return (
              <>
                {imageSite}
                {siteName}
              </>
            )
          },
        },
      },
    }
  },

  artifactSearch: searchPolygon => {
    return {
      query: `
      query postQuery(${searchPolygonArg(searchPolygon)} $search: String="", ${
        page.def
      }) {
        results: searchArtifacts (${searchPolygonVar(
          searchPolygon,
        )} ${lang}Name_Icontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
              research {
                edges {
                  node {
                    year
                    author {
                      edges {
                        node {
                          publicName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
   `,
      url: 'subjects/Artifact',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        author: {
          data: getItem('research.edges.0.node.publicName'),
        },
      },
    }
  },

  cultureSearch: searchPolygon => {
    const Lang = lang.charAt(0).toUpperCase() + lang.slice(1)
    return {
      query: `
    query postQuery(${searchPolygonArg(searchPolygon)} $search: String="", ${
        page.def
      }) {
      results:searchCulture(${searchPolygonVar(
        searchPolygon,
      )} nameIcontains: $search,
                            ${page.params}) {
        totalCount
        edges {
          node {
            uid
            ${lang}Name
            commonNames {
              ${lang}Name
            }
            common${Lang}Names {
              name
              count
            }
          }
        }
      }
    }`,
      url: 'subjects/Culture',
      fields: {
        name: {
          data: data => {
            const field = `common${Lang}Names`
            const names = getPropertyByNameString(data, field)

            let main = 'N/A'
            if (Array.isArray(names) && names[0]) {
              main = names[0].name
            }

            const alternativeNames = []
            let alternativeNamesString = ''
            let counter = 1

            while (names[counter]) {
              alternativeNames.push(names[counter].name)
              counter++
            }

            if (alternativeNames.length) {
              alternativeNamesString = `(${alternativeNames.join(', ')})`
            }

            return `${main} ${alternativeNamesString}`
          },
          sort: `${lang}_name`,
        },
      },
    }
  },

  excavationSearch: searchPolygon => {
    const Lang = lang.charAt(0).toUpperCase() + lang.slice(1)

    return {
      query: `
    query postQuery(${searchPolygonArg(searchPolygon)} $search: String="", ${
        page.def
      }) {
      results:excavations(${searchPolygonVar(
        searchPolygon,
      )} commonSearch: $search,
                               ${page.params}) {
        totalCount
        edges {
          node {
            uid
            ${lang}Name
            year
            layer {
              edges {
                node {
                  site {
                    edges {
                      node {
                        common${Lang}Name
                      }
                    }
                  }
                }
              }
            }
            supervisor {
              edges {
                node {
                  publicName
                }
              }
            }
          }
        }
      }
    }`,
      url: 'subjects/Excavation',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        year: {
          data: getItem('year'),
          sort: 'year',
        },
        supervisor: {
          data: data => {
            let supervisors = []
            if (data.supervisor) {
              supervisors = data.supervisor.edges.map(v => {
                if (v.node.publicName) {
                  return `${v.node.publicName}`
                }
                return ''
              })
            }
            const supervisorsNames = supervisors.length
              ? `(${supervisors.join(', ')})`
              : ''
            return supervisorsNames
          },
          sort: 'supervisor__public_name',
        },
      },
    }
  },

  radiocarbonSearch: searchPolygon => {
    return {
      query: `
    query postQuery(${searchPolygonArg(searchPolygon)} $search: String="", ${
        page.def
      }) {
      results:radiocarbons(${searchPolygonVar(
        searchPolygon,
      )} ${lang}Name_Icontains: $search,
                               ${page.params}) {
        totalCount
        edges {
          node {
            uid
            displayName
          }
        }
      }
    }`,
      url: 'subjects/Radiocarbon',
      fields: {
        name: {
          data: getItem('displayName'),
          sort: 'display_name',
        },
      },
    }
  },

  researchSearch: searchPolygon => {
    return {
      query: `
    query postQuery(${searchPolygonArg(searchPolygon)}
                    $search: String="", $author: String="",
                    $year: Int,
                    ${page.def}) {
      results:searchResearch(${searchPolygonVar(searchPolygon)}
                             nameIcontains: $search,
                             author: $author,
                             year: $year,
                             ${page.params}) {
        totalCount
        edges {
          node {
            uid
            label
            ${lang}Name
            year
            type {
              edges {
                node {
                  sort
                  ${lang}Name
                }
              }
            }
            author {
              edges {
                node {
                  publicName
                }
              }
            }
          }
        }
      }
    }`,
      url: 'research',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        label: {
          data: getItem('label'),
          sort: 'label',
        },
        year: {
          data: getItem('year'),
          sort: 'year',
        },
        type: {
          data: getItem(`type.edges.0.node.${lang}Name`),
          sort: `type__${lang}_name`,
        },
        author: {
          data: getItem('author.edges.0.node.publicName'),
          sort: 'author__public_name',
        },
      },
    }
  },
}

export default archQueries
