import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { actions } from '../../redux/modules/signup';
import SignUpForm from '../../components/SignUp/Form';
import SignUpSuccess from '../../components/SignUp/Success';
import './index.css';

const mapStateToProps = state => {
  return {
    sign_up: state.signup,
  }
};

const mapDispatchToProps = {
  handleSubmit: actions.signUp,
  handleExistingSubmit: actions.signUpExisting,
  signUpHandleCaptcha: actions.signUpHandleCaptcha,
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class SignUpView extends Component {
  static propTypes = {
    sign_up: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    signUpHandleCaptcha: PropTypes.func.isRequired,
  };

  render() {
    const {
      handleSubmit,
      signUpHandleCaptcha,
      sign_up,
      t,
    } = this.props;

    return (
      <div className="container sign_up">
        <h1>{ t('signUp.title') }</h1>
        {
          sign_up.isOk ?
            <SignUpSuccess t={t} />
            :
            <SignUpForm
              t={t}
              handleSubmit={handleSubmit}
              signUpHandleCaptcha={signUpHandleCaptcha}
              isCaptchaOk={sign_up.captchaOk}
              isError={sign_up.error}
              isLoading={sign_up.isLoading}
            />
        }
      </div>
    )
  }
}

export default SignUpView;
