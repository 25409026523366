import React, { useState, useEffect, Suspense } from 'react'
import { translate } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Spin, Tabs, List, Table, Button, Radio } from 'antd'
import nanoid from 'nanoid'
import RequestAccess from 'components/RequestAccess'
import { graphql } from 'relay-runtime'
import useDataApi from 'utility/fetchGraphHook'
import AccessHoc from 'components/AccessHoc'
import MapPanel from 'components/MapPanel'
import i18n from 'i18next'
import {
  getPropertyByNameString,
  getResearchUrl,
} from 'utility/transformData/constants'
import { getSelectionInfo } from 'utility/transformData/objects/selection'
import { getMarkers, getPolygons } from 'utility/points'
import { fetchGrapqlMutation } from 'utility/request'
import {
  getAdvSearchSchema,
  postAdvSearch,
  downloadAdvSearch,
  postAdvSearchSpatials,
} from 'redux/api/advSearch'
import { AdvancedSearchDownload } from 'components/AdvSearch'

import './index.scss'

const CustomSelect = React.lazy(() => import('components/CustomSelect'))
const CommonField = React.lazy(() =>
  import('components/TabContent/CommonField'),
)
const LinkField = React.lazy(() => import('components/TabContent/LinkField'))
const ChartField = React.lazy(() => import('components/TabContent/ChartField'))
const ImageField = React.lazy(() => import('components/TabContent/ImageField'))
const GalleryField = React.lazy(() =>
  import('components/TabContent/GalleryField'),
)

const { TabPane } = Tabs
const LANG = ['ru','en'].includes(i18n.language) ? i18n.language: 'local'

const getPointId = item => `${item.uid}-${item.latitude}-${item.longitude}`

const TABLE_CELL_WIDTH = 180
const ACCESS_ROLE = ['CREATOR', 'ADMIN', 'AUTHOR']

const getLinkByLabel = (label, uid) => {
  switch (label) {
    case 'Author':
      return `/show/author/${uid}`
    case 'ArchaeologyResearch':
    case 'Research':
    case 'EncyclopediaResearch':
      return `/show/${getResearchUrl(label)}/${uid}`
    default:
      return `/show/subjects/${label}/${uid}`
  }
}
const getUniqPoints = array => {
  const result = new Map()
  array.forEach(item => {
    const name = item.interpretation[`${LANG}_name`]
      || item.interpretation[`sub_${LANG}_name`]
      || item.interpretation[`sub_common_${LANG}_name`]
    const id = getPointId(item)
    if (result.has(id)) {
      return
    }
    result.set(id, {
      ...item,
      title: name,
      subject_id: item.uid,
    })
  })
  return [...result.values()]
}

const getLinkByLabelPoints = label => {
  switch (label) {
    case 'Author':
      return 'author'
    case 'ArchaeologyResearch':
    case 'Research':
    case 'EncyclopediaResearch':
      return getResearchUrl(label)
    default:
      return `subjects/${label}`
  }
}

const fields = {
  common: CommonField,
  link: LinkField,
  chart: ChartField,
  image: ImageField,
  gallery: GalleryField,
}

function FieldComponent(props) {
  const Component = fields[props.type]

  return (
    <Suspense fallback={<Spin />}>
      <Component content={props.content} />
    </Suspense>
  )
}

const QUERY = graphql`
  query showSelectionQuery($selectionId: ID!) {
    selection(id: $selectionId) {
      uid
      accessType
      createdAt
      returnedFields
      creator {
        edges {
          node {
            uid
            publicName
            publicNameEn
          }
        }
      }
      query
      description
      model
      name
      resultCount
      uids
      sharedUsers {
        uid
        publicName
        publicNameEn
      }
      permission
      isOpen
      accessRole
      polygonBounds
      specifications
    }
  }
`

const MUTATION = graphql`
  mutation showSelectionMutation($data: updateSelectionIT!, $uid: UUID!) {
    updateSelection(data: $data, uid: $uid) {
      uid
      description
      model
      name
      uids
    }
  }
`

const ACCESS_MUTATION = graphql`
  mutation showSelectionAccessMutation($data: ChangeAccessInputType) {
    accessChange(data: $data) {
      permission
      sharedUsers {
        uid
        publicName
        publicNameEn
      }
    }
  }
`

export const ACCESS = ['PUBLIC', 'PRIVATE', 'SHARED']

export const SelectionAccess = props => {
  const { t, sharedUsers, permission, uid, model } = props
  const [isLoading, setIsLoading] = useState(false)
  const [isSelectLoading, setIsSelectLoading] = useState(false)
  const [currentSharedUsers, setCurrentSharedUsers] = useState(
    sharedUsers || [],
  )
  const [currentPermission, setCurrentPermission] = useState(permission)

  const fetchAccessMutation = (variables, callback) =>
    fetchGrapqlMutation(ACCESS_MUTATION, variables, callback)

  const handleChange = e => {
    setIsLoading(true)
    console.log(e.target.value)
    const body = {
      model: model || 'SELECTION',
      permission: e.target.value,
      sharedUsers: [],
      uid,
    }
    const variables = {
      data: body,
    }
    fetchAccessMutation(variables, response => {
      console.log(response)
      if (response && response.accessChange) {
        setCurrentPermission(response.accessChange.permission)
      }
      setIsLoading(false)
    })
  }

  const getUserUidList = userList => userList.map(item => item.uid)

  const handleChangeSharedUser = newSharedUsersId => {
    const body = {
      model: model || 'SELECTION',
      permission: currentPermission,
      sharedUsers: newSharedUsersId,
      uid,
    }
    const variables = {
      data: body,
    }
    console.log(variables)
    fetchAccessMutation(variables, response => {
      console.log(response)
      if (response && response.accessChange) {
        setCurrentSharedUsers(response.accessChange.sharedUsers)
      }
      setIsSelectLoading(false)
    })
  }

  const handleRemoveSharedUser = removeUid => {
    const newSharedUsersId = currentSharedUsers.filter(
      item => item.uid !== removeUid,
    )
    handleChangeSharedUser(getUserUidList(newSharedUsersId))
  }

  const handleUserSelect = user => {
    if (user) {
      setIsSelectLoading(true)
      const newSharedUsersId = [...getUserUidList(currentSharedUsers), user.uid]
      handleChangeSharedUser(newSharedUsersId)
    }
  }

  const renderUserList = () => {
    if (currentPermission !== 'SHARED') return null
    return (
      <List
        className="show-selection-user-list"
        itemLayout="horizontal"
        header={<div>{t('access.sharedList.title')}</div>}
        dataSource={currentSharedUsers}
        renderItem={user => (
          <List.Item
            key={user.uid}
            actions={[
              <Button
                onClick={() => {
                  handleRemoveSharedUser(user.uid)
                }}
              >
                {t('access.delete')}
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <Link to={`/show/author/${user.uid}/`}>{LANG === 'ru'? user.publicName:user.publicNameEn }</Link>
              }
            />
          </List.Item>
        )}
      />
    )
  }

  const renderUserSelect = () => {
    if (currentPermission !== 'SHARED') return null
    return (
      <Spin spinning={isSelectLoading}>
        <div className="show-selection-user-select">
          <div>{t('access.selectUsers')}</div>
          <Suspense fallback={<Spin />}>
            <CustomSelect
              handleSelect={handleUserSelect}
              placeholder={t('access.selectUsers')}
            />
          </Suspense>
        </div>
      </Spin>
    )
  }

  return (
    <div className="selection-access">
      <div className="selection-access-control">
        <Spin spinning={isLoading}>
          <h3>{t('access.title')}</h3>
          <Radio.Group
            defaultValue={permission}
            value={currentPermission}
            buttonStyle="solid"
            onChange={handleChange}
          >
            {ACCESS.map(access => (
              <Radio.Button value={access} key={access}>
                {t(`access.${access}`)}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Spin>
        {renderUserSelect()}
      </div>
      {renderUserList()}
    </div>
  )
}

export const SelectionTable = props => {
  const {
    t,
    data,
    model,
    pagination,
    sorter,
    parsedQuery,
    returnedFields,
    handleTableChange,
    uid,
    selected = ['uid'],
    deleteShow,
    tableClassName,
  } = props

  const [isDeleting, setIsDeleting] = useState(deleteShow || false)
  const [selectedToRemove, setSelectedToRemove] = useState([])

  const getDefaultColumns = () =>
    selected.map(item => {
      return {
        title: item,
        dataIndex: item,
        key: item,
        render: (text, record) => (
          <Link to={getLinkByLabel(model, record.uid)}>{text}</Link>
        ),
      }
    })

  const getColumns = () => {
    let result = []
    try {
      const parsed = JSON.parse(returnedFields)
      console.log(parsed)
      if (parsed && Object.keys(parsed).length > 0) {
        console.log('inner')
        const keys = Object.keys(parsed)
        result = keys.map(key => ({
          title: parsed[key][LANG],
          dataIndex: key,
          key,
          sorter: true,
          render: (text, record) => (
            <Link to={getLinkByLabel(model, record.uid)}>{text}</Link>
          ),
        }))
      } else {
        result = getDefaultColumns()
      }
    } catch (error) {
      console.error(error)
      result = getDefaultColumns()
    }
    console.log(result)
    console.log(selected)
    return result
  }

  const getSource = () =>
    data.map((item, index) => ({
      ...item,
      key: `${pagination.currentPage}-${index}`,
    }))

  const toggleRemove = () => {
    setIsDeleting(!isDeleting)
  }

  const removeRows = () => {
    const uids = selectedToRemove.map(item => item.uid)
    const body = {
      uids,
    }
    const variables = {
      data: body,
      uid,
    }

    fetchGrapqlMutation(MUTATION, variables, response => {
      console.log(response)
      // if (response && response.saveSelection) {
      // }
      toggleRemove()
    })
  }

  const renderDeleteControl = () => {
    if (deleteShow) {
      return null
    }
    if (isDeleting) {
      return (
        <>
          <Button type="primary" onClick={removeRows}>
            {t('selection.show.button.delete')}
          </Button>
          <Button onClick={toggleRemove}>
            {t('selection.show.button.cancel')}
          </Button>
        </>
      )
    }
    return (
      <Button type="primary" onClick={toggleRemove}>
        {t('selection.show.button.choose_to_delete')}
      </Button>
    )
  }

  const getTitle = () => t('selection.show.labels.deleting')

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      )
      setSelectedToRemove(selectedRows)
    },
  }
  const columns = data ? getColumns() : []
  const dataSource = data ? getSource() : []
  return (
    <>
      <div className="show-selection-button-group">
        <AdvancedSearchDownload
          t={t}
          parsed={{
            ...parsedQuery,
            order_by: sorter,
          }}
        >
          {renderDeleteControl()}
        </AdvancedSearchDownload>
      </div>
      <Table
        className={tableClassName || 'shadow-table'}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        onChange={handleTableChange}
        title={isDeleting ? getTitle : undefined}
        rowSelection={isDeleting ? rowSelection : undefined}
        scroll={{ x: TABLE_CELL_WIDTH * columns.length }}
      />
    </>
  )
}

const SelectionMainInfo = props => {
  const { t, data } = props
  const content = getSelectionInfo(data)

  return (
    <List
      locale={{ emptyText: ' ' }}
      dataSource={content}
      split=""
      size="small"
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            title={
              item.fieldName
                ? t(`selection.show.labels.${item.fieldName}`)
                : null
            }
            description={<FieldComponent type={item.type} content={item} />}
          />
        </List.Item>
      )}
    />
  )
}

const decode = obj => {
  if (!obj) return {}
  const result = {}

  const fieldKeys = {
    inputType: 'type',
    blockType: 'blockType',
    model: 'model',
    field: 'fieldName',
    isNot: 'isNot',
  }
  Object.keys(fieldKeys).forEach(key => {
    if (obj[key] !== undefined) {
      result[fieldKeys[key]] = obj[key]
    }
  })
  if (obj.inputType) {
    switch (obj.inputType) {
      case 'select':
        result.selectValue = obj.v || ''
        break
      case 'input':
        result.selectValue = obj.type
        result.inputValue = obj.v || ''
        break
      default:
        break
    }
  }

  const getSubGroup = subGroup => {
    if (!subGroup) return {}
    const subResult = {}
    subGroup.selections.forEach(item => {
      subResult[nanoid(3)] = decode(item)
    })
    return subResult
  }

  const REVERT_OPERATORS = {
    '&': 'all',
    '|': 'any',
  }

  const selections = {
    ...getSubGroup(obj.relation),
    ...getSubGroup(obj.fields),
  }
  if (Object.keys(selections).length > 0) {
    result.selections = selections
  }
  if (obj.relation) {
    result.operator = REVERT_OPERATORS[obj.relation.operator]
    if (obj.relation.isNot !== undefined) {
      result.isNot = obj.relation.isNot
    }
  } else if (obj.fields) {
    result.operator = REVERT_OPERATORS[obj.fields.operator]
    if (obj.fields.isNot !== undefined) {
      result.isNot = obj.fields.isNot
    }
  }

  return result
}

const ShowSelectionView = props => {
  const { t, match } = props
  const { objId } = match.params
  const variables = {
    selectionId: `SelectionNode:${objId}`,
  }
  const { data, isLoading, isError } = useDataApi(QUERY, variables)
  const [parsedData, setParsedData] = useState()
  const [pagination, setPagination] = useState({
    defaultPageSize: 50,
  })
  const [tableResult, setTableResult] = useState()
  const [sorter, setSorter] = useState({})
  const [resultPoints, setResultPoints] = useState([])

  const getResultTable = (query, paginate = pagination, sortSets = sorter) => {
    const queryToSend = { ...query }
    const currentPage = paginate.current || 1
    queryToSend.order_by = sortSets
    queryToSend.paginate = {
      first: 50 * currentPage,
      last: 50,
    }
    postAdvSearch(queryToSend).then(({ response }) => {
      console.log(response)
      if (response) {
        const { data: tableData, count } = response
        if (pagination.count !== count) {
          setPagination({
            ...pagination,
            total: count,
          })
        }
        setTableResult(tableData)
      }
    })
  }

  useEffect(() => {
    if (data && data.selection) {
      const creator =
        getPropertyByNameString(data.selection, 'creator.edges.0.node') || {}
      let parsedQuery
      try {
        parsedQuery = JSON.parse(data.selection.query)
        console.log(decode(parsedQuery))
      } catch (e) {
        console.log(e)
      }
      parsedQuery.polygon_bounds = data.selection.polygonBounds || []
      const result = {
        ...data.selection,
        creator,
        parsedQuery,
      }
      setParsedData(result)
      const { model } = data.selection
      console.log(data)

      getResultTable(parsedQuery)

      postAdvSearchSpatials(parsedQuery).then(({ response }) => {
        if (response) {
          const points = getUniqPoints(response)

          const markers = getMarkers(points, getLinkByLabelPoints(model), model)
          const polygons = getPolygons(
            points,
            getLinkByLabelPoints(model),
            model,
          )
          setResultPoints([
            {
              markers,
              polygons,
              name: model.toLowerCase(),
            },
          ])
        }
      })
    }
  }, [data])

  const handleTableChange = (newPagination, filters, newSorter) => {
    const sortSets = { ...sorter }
    if (sortSets[newSorter.field] !== newSorter.order) {
      sortSets[newSorter.field] = newSorter.order
    }
    const paginate = {
      ...pagination,
      current: newPagination.current,
    }
    setPagination(paginate)
    setSorter(sortSets)
    getResultTable(parsedData.parsedQuery, paginate, sortSets)
  }

  const renderContent = () => {
    if (!data || !data.selection) {
      return <RequestAccess />
    }
    const {
      model,
      uid,
      permission,
      accessRole,
      returnedFields,
      sharedUsers = [],
    } = (data && data.selection) || {}
    return (
      <div className="show-selection-content">
        <div className="show-selection">
          <h1>{parsedData.name}</h1>
          <AccessHoc match={match} />
          {ACCESS_ROLE.includes(accessRole) && (
            <Link
              to={`/edit/selection/${uid}/`}
              className="show-selection-edit"
            >
              {t('advanced.button.edit')}
            </Link>
          )}
          <Tabs defaultActiveKey="1">
            <TabPane tab={t('selection.show.tabs.main')} key="1">
              <SelectionMainInfo t={t} data={data} />
            </TabPane>
            <TabPane tab={t('selection.show.tabs.selection')} key="2">
              <SelectionTable
                t={t}
                data={tableResult}
                pagination={pagination}
                sorter={sorter}
                model={model}
                uid={uid}
                parsedQuery={parsedData.parsedQuery}
                returnedFields={returnedFields}
                handleTableChange={handleTableChange}
              />
            </TabPane>
            {ACCESS_ROLE.includes(accessRole) && (
              <TabPane tab={t('selection.show.tabs.access')} key="3">
                <SelectionAccess
                  uid={uid}
                  permission={permission}
                  sharedUsers={sharedUsers}
                  t={t}
                />
              </TabPane>
            )}
          </Tabs>
        </div>
        <MapPanel content={resultPoints} t={t} />
      </div>
    )
  }
  console.log(data, isLoading, isError)
  return (
    <div className="show-selection">
      {isLoading ? <Spin size="large" /> : renderContent()}
    </div>
  )
}

export default translate()(ShowSelectionView)
