import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Layout } from 'antd';


const { Content } = Layout;

@translate()
class NotFoundView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t } = this.props;

    return (
      <Content style={{ padding: '0 20px', marginTop: 35 }}>
        <h1>{ t('notFound.title') }</h1>
      </Content>

    )
  }
}

export default NotFoundView;
