import React from 'react'
import {
  Collapse,
} from 'antd';

import './index.scss'

const CustomCollapse = (props) => {
  return (
    <Collapse
      className="custom_collapse"
      {...props}
    >
      {props.children}
    </Collapse>
  )
}

export default CustomCollapse
