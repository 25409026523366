import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Input, Select } from 'antd';
import { udmurtResearchUid } from 'utility/constants';
import getUrl from 'utility/images'
import TCloud from '../../components/TagCloud';
import { words } from './words';

import './index.css';

const indexBg = getUrl('ling_index/header-bg.png')


const InputGroup = Input.Group;
const { Option } = Select;
const { Search } = Input;

@translate()
class NewIndexView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  state = {
    selectValue: 'term',
    udmurtResearchUid: '-',
  }

  componentDidMount = () => {
    udmurtResearchUid.then(uid => {
      this.setState({
        udmurtResearchUid: uid,
      })
    })
  }

  select = React.createRef();

  handleSubmit = (e) => {
    const { selectValue } = this.state;
    this.props.history.push(`/quick-search?type=${selectValue}&search=${e}`)
  }

  handleSubmitForm = (e) => {
    e.preventDefault();
  }

  handleSelect = (selectValue) => {
    this.setState({ selectValue })
  }

  goToPage = (e) => {
    e.preventDefault();
    const pageUrl = e.target.href.split('/');
    this.props.history.push(pageUrl[pageUrl.length - 1])
  }

  render() {
    const { t } = this.props;
    const Options = [
      'term',
      'lexeme',
      'variant',
      'vernacular',
      'locality',
    ]

    return (
      <div className="container ling-index">
        <div className="ling-index-bg">
          <img className="ling-index-bg-img" src={indexBg} alt="culturetat" />
        </div>
        <main>
          <header>
            <h1>
              <a href={`/show/research/${this.state.udmurtResearchUid}`} className="ling-index-research-link" >
                Диалектологический атлас
              </a>
              <br />удмуртского языка</h1>
            <p>Онлайн-версия</p>
          </header>
          <div className="search-block">
            <form onSubmit={this.handleSubmitForm}>
              <InputGroup
                compact
                style={{ width: '100%' }}
              >
                <Select
                  defaultValue="term"
                  style={{ width: '25%' }}
                  ref={this.select}
                  onSelect={this.handleSelect}
                >
                  { Options.map(item => (
                    <Option value={item} key={item}>
                      {t(`search_panel.form.select.options.${item}`)}
                    </Option>
                  )) }
                </Select>
                <Search
                  style={{ width: '75%' }}
                  placeholder={t(`search_panel.form.select.subj_placeholder.${this.state.selectValue}`)}
                  onSearch={this.handleSubmit}
                  enterButton
                />
              </InputGroup>
            </form>
          </div>
          <div className="short-column">
            <div
              className="ling-what ling-tile"
            >
              <TCloud
                words={words}
              />
            </div>
          </div>
        </main>
      </div>
    )
  }
}

export default NewIndexView;
