import React from 'react';

import Profile from '../../components/Profile/Container';
import './index.css';

const ProfileView = (props) => (
  <div className="container login">
    <Profile {...props} />
  </div>
)

export default ProfileView;
