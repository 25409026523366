import React from 'react'
import { AdvSearchBlock } from 'components/AdvSearch'
import './index.scss'

function AdvSearchGroup(props) {
  return (
    <div className="adv-search-group">
      <AdvSearchBlock {...props} isGroup />
    </div>
  )
}

export { AdvSearchGroup }
