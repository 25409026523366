import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List, Form, Col, DatePicker, Button, Icon, Checkbox } from 'antd'
import moment from 'moment'
import i18n from 'i18next'

import { formItemLayout } from '../../../utility/constants'

const FormItem = Form.Item
const dateFormat = 'DD.MM.YYYY';

const disabledDate = current => {
  // Can not select days after today
  return current && current > moment()
}

class DatePeriod extends Component {
  static propTypes = {
    getFieldDecorator: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      isChecked: props.from ? !props.to : false,
      from: props.from ? moment(props.from, dateFormat) : undefined,
      to: props.to ? moment(props.to, dateFormat) : undefined,
    }
    this.baseState = this.state
  }

  onCheckboxChange = () => {
    this.setState(prevState => {
      return { isChecked: !prevState.isChecked };
    });
  }

  resetForm = () => {
    this.setState(this.baseState)
    this.props.form.setFieldsValue({
      organization_date_from: undefined,
      organization_date_to: undefined,
    })
  }

  render() {
    const { isChecked, from, to } = this.state
    const { getFieldDecorator, t } = this.props
    return (
      <React.Fragment>
        <FormItem
          label={t('signUp.form.organizations.workDate')}
          {...formItemLayout}
        >
          <Col span={11}>
            <FormItem>
              {getFieldDecorator('organization_date_from', {
                rules: [{
                  type: 'object',
                  required: true,
                  message: t('form.date.error.required'),
                }],
                initialValue: from,
              })(<DatePicker disabledDate={disabledDate} />)}
            </FormItem>
          </Col>

          {!isChecked && [
            <Col span={2} key="divider">
              <span
                style={{
                  display: 'inline-block',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                -
              </span>
            </Col>,
            <Col span={11} key="to_date">
              <FormItem>
                {getFieldDecorator('organization_date_to', {
                  rules: [{
                    type: 'object',
                    required: true,
                    message: t('form.date.error.required'),
                  }],
                  initialValue: to,
                })(<DatePicker disabledDate={disabledDate} />)}
              </FormItem>
            </Col>,
          ]}
        </FormItem>
        <FormItem>
          <Checkbox
            checked={isChecked}
            onChange={this.onCheckboxChange}
          >
            {t('signUp.form.organizations.tillNow')}
          </Checkbox>
        </FormItem>
      </React.Fragment>
    )
  }
}

export default DatePeriod
