import * as request from '../../utility/request';
import { fetchGrapqlMutation } from 'utility/request';

export const QUERY_TYPE = {
  QUERY: 0,
  MUTATION: 1
}

export const graphqlPost = async (params) => {
  const path = 'api/graphql/';
  const body = {
    operationName: 'postQuery',
    query: params.query,
    variables: params.vars,
  }
  console.log('graphqlPost', params)
  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const graphqlPostEnv = async (params) => {
  const queryType = params.queryType || QUERY_TYPE.QUERY
  console.log('graphqlPostEnv', params, queryType)
  if (queryType === QUERY_TYPE.MUTATION) {
    const response = await request.fetchGrapqlMutation(params.query, params.vars, response => {
      console.log('graphqlPostEnv mutation', response)
    });
    return response;
  }
  const response = await request.fetchGrapqlRequest(params.query, params.vars);
  return response;
}
