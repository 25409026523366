interface Metric extends Partial<{
  width: number;
  first: string;
  second: string;
}> {}

const maxWidth = 150;

const _getRoundNum = (t:number): string => {
  if (t >= 2) {
    const e = Math.pow(10, (Math.floor(t) + "").length - 1);
    let i = t / e;
    return String((i = i >= 10
      ? 10
      : i >= 5
        ? 5
        : i >= 3
        ? 3
        : i >= 2
          ? 2
          : 1, e * i))
  }
  return (Math.round(100 * t) / 100).toFixed(1)
}

export const drawScale = (map, ctx, dimensions) => {
  // count map scale params
  const bounds = map.getBounds();
  const centerLat = bounds.getCenter().lat;
  const mapSize = map.getSize();
  const i = 6378137 * Math.PI * Math.cos(centerLat * Math.PI / 180);
  const n = i * (bounds.getNorthEast().lng - bounds.getSouthWest().lng) / 180;
  const a = mapSize.x > 0 ? n * (maxWidth / mapSize.x) : 0;
  const scaleMetric = _updateMetric(a);
  // draw canvas scale
  const width = scaleMetric.width;
  const height = 7;
  const startX = 10;
  const startY = dimensions.y - 30;
  ctx.fillStyle = "white";
  ctx.fillRect(startX, startY, width, height);
  ctx.strokeRect(startX, startY, width, height);
  ctx.fillStyle = "black";
  let xOffset = startX;
  ctx.fillRect(xOffset, startY, width / 4, height / 2);
  xOffset += width / 4;
  ctx.fillRect(xOffset, startY + height / 2, width / 4, height / 2);
  xOffset += width / 4;
  ctx.fillRect(xOffset, startY, width / 4, height / 2);
  xOffset += width / 4;
  ctx.fillRect(xOffset, startY + height / 2, width / 4, height / 2);

  ctx.fillStyle = "black";
  const fontSize = 12;
  ctx.font = `bold ${fontSize}px Arial`;
  ctx.shadowOffsetX = 1;
  ctx.shadowOffsetY = 1;
  ctx.shadowColor = "white";
  ctx.shadowBlur = 1;

  let yOffset = startY + height + 1.2 * fontSize;
  xOffset = startX;
  ctx.fillText('0', xOffset, yOffset);
  xOffset += width / 2 - fontSize / 2;
  ctx.fillText(scaleMetric.first, xOffset, yOffset);
  xOffset += width / 2 - fontSize / 2;
  ctx.fillText(scaleMetric.second, xOffset, yOffset);
  // north arrow
  ctx.fillStyle = "black";
  ctx.fillText('N', 22, 20);
  ctx.beginPath();
  ctx.moveTo(10, 50);
  ctx.lineTo(25, 40);
  ctx.lineTo(25, 25);
  ctx.fill();
  ctx.beginPath();
  ctx.moveTo(40, 50);
  ctx.lineTo(25, 40);
  ctx.lineTo(25, 25);
  ctx.lineTo(40, 50);
  ctx.stroke();
}

const _updateMetric = (t: number) => {
  const result: Metric = {};
  let e, i, n, a = t;
  if (a > 500) {
    e = a / 1000;
    i = _getRoundNum(e);
    result.width = _getScaleWidth(i / e);
    result.first = _getRoundNum(e / 2);
    result.second = i + "km";
  } else {
    n = _getRoundNum(a);
    result.width = _getScaleWidth(n / a);
    result.first = _getRoundNum(a / 2);
    result.second = n + "m";
  }
  return result;
}

const _getScaleWidth = (t) => {
  return Math.round(maxWidth * t) - 10
}