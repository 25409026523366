/**
 * @flow
 * @relayHash bc749339f891968a572b6af19c753b93
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createResearchSubjectsQueryVariables = {|
  ruid: string
|};
export type createResearchSubjectsQueryResponse = {|
  +research: ?{|
    +subjects: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +uid: ?string,
          +label: ?string,
        |}
      |}>
    |}
  |}
|};
export type createResearchSubjectsQuery = {|
  variables: createResearchSubjectsQueryVariables,
  response: createResearchSubjectsQueryResponse,
|};
*/


/*
query createResearchSubjectsQuery(
  $ruid: ID!
) {
  research(id: $ruid) {
    subjects {
      edges {
        node {
          uid
          label
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ruid",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "ruid",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "createResearchSubjectsQuery",
  "id": null,
  "text": "query createResearchSubjectsQuery(\n  $ruid: ID!\n) {\n  research(id: $ruid) {\n    subjects {\n      edges {\n        node {\n          uid\n          label\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createResearchSubjectsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "research",
        "storageKey": null,
        "args": v1,
        "concreteType": "ResearchNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subjects",
            "storageKey": null,
            "args": null,
            "concreteType": "SubjectNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SubjectNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SubjectNode",
                    "plural": false,
                    "selections": [
                      v2,
                      v3
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createResearchSubjectsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "research",
        "storageKey": null,
        "args": v1,
        "concreteType": "ResearchNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subjects",
            "storageKey": null,
            "args": null,
            "concreteType": "SubjectNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SubjectNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SubjectNode",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      v4
                    ]
                  }
                ]
              }
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc94f873363ed4b228cc723bd7e2202b';
module.exports = node;
