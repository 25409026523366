import { createAction, handleActions } from 'redux-actions';

const SELECT_RESEARCH = 'create/SELECT_RESEARCH';
const SET_RESEARCH_SUBJECTS = 'create/SET_RESEARCH_SUBJECTS';
const CLEAR_RESEARCH_SUBJECTS = 'create/CLEAR_RESEARCH_SUBJECTS';
const CHANGE_DATA_TYPE = 'create/CHANGE_DATA_TYPE';
const NEW_RESEARCH = 'create/NEW_RESEARCH';
const CREATE_RESEARCH = 'create/CREATE_RESEARCH';
const CREATE_HERITAGE = 'create/CREATE_HERITAGE';
const CREATE_AUTHOR = 'create/CREATE_AUTHOR';
const CREATE_AUTHOR_SUCCESS = 'create/CREATE_AUTHOR_SUCCESS';
const CREATE_AUTHOR_FAIL = 'create/CREATE_AUTHOR_FAIL';
const CREATE_SOURCE = 'create/CREATE_SOURCE';
const CREATE_SOURCE_SUCCESS = 'create/CREATE_SOURCE_SUCCESS';
const CREATE_SOURCE_FAIL = 'create/CREATE_SOURCE_FAIL';
const CREATE_SITE = 'create/CREATE_SITE';
const CREATE_SUBJECT_RELATIONS = 'create/CREATE_SUBJECT_RELATIONS';
const NEW_AUTHOR_SELECTED = 'create/NEW_AUTHOR_SELECTED';
const NEW_SOURCE_SELECTED = 'create/NEW_SOURCE_SELECTED';
const AUTHOR_CHANGED = 'create/AUTHOR_CHANGED';
const COAUTHOR_CHANGED = 'create/v';
const SOURCE_CHANGED = 'create/SOURCE_CHANGED';
const SOURCE_FILELIST_CHANGED = 'create/SOURCE_FILELIST_CHANGED';
const SOURCE_FILELIST_CLEAR = 'create/SOURCE_FILELIST_CLEAR';
const SOURCE_FILELIST_LOADBAR_CHANGED = 'create/SOURCE_FILELIST_LOADBAR_CHANGED';

const UPDATE_ERRORS_IN_SUBJECT_FIELDS = 'create/UPDATE_ERRORS_IN_SUBJECT_FIELDS'

const UPLOAD_FILES = 'create/UPLOAD_FILES';
const UPLOAD_FILES_START = 'create/UPLOAD_FILES_START';
const UPLOAD_FILES_SUCCESS = 'create/UPLOAD_FILES_SUCCESS';
const UPLOAD_SPATIALS = 'create/UPLOAD_SPATIALS';

const CREATE_PERIODICAL_ISSUE = 'create/CREATE_PERIODICAL_ISSUE';
const CREATE_PERIODICAL_ISSUE_SUCCESS = 'create/CREATE_PERIODICAL_ISSUE_SUCCESS';
const CREATE_PERIODICAL_ISSUE_FAIL = 'create/CREATE_PERIODICAL_ISSUE_FAIL';
const NEW_PERIODICAL_ISSUE_SELECTED = 'create/NEW_PERIODICAL_ISSUE_SELECTED';

const CREATE_PERIODICAL = 'create/CREATE_PERIODICAL';
const CREATE_PERIODICAL_SUCCESS = 'create/CREATE_PERIODICAL_SUCCESS';
const CREATE_PERIODICAL_FAIL = 'create/CREATE_PERIODICAL_FAIL';
const NEW_PERIODICAL_SELECTED = 'create/NEW_PERIODICAL_SELECTED';

const CREATE_BOOK_SERIES = 'create/CREATE_BOOK_SERIES';
const CREATE_BOOK_SERIES_SUCCESS = 'create/CREATE_BOOK_SERIES_SUCCESS';
const CREATE_BOOK_SERIES_FAIL = 'create/CREATE_BOOK_SERIES_FAIL';
const NEW_BOOK_SERIES_SELECTED = 'create/NEW_BOOK_SERIES_SELECTED';

const CREATE_BOOK = 'create/CREATE_BOOK';
const CREATE_BOOK_SUCCESS = 'create/CREATE_BOOK_SUCCESS';
const CREATE_BOOK_FAIL = 'create/CREATE_BOOK_FAIL';
const NEW_BOOK_SELECTED = 'create/NEW_BOOK_SELECTED';

const SAVE_RESEARCH_YEAR = 'create/SAVE_RESEARCH_YEAR';
const SAVE_RESEARCH_AUTHOR = 'create/SAVE_RESEARCH_AUTHOR';

export const constants = {
  SELECT_RESEARCH,
  SET_RESEARCH_SUBJECTS,
  CLEAR_RESEARCH_SUBJECTS,
  CHANGE_DATA_TYPE,
  NEW_RESEARCH,
  CREATE_RESEARCH,
  CREATE_HERITAGE,
  CREATE_AUTHOR,
  CREATE_AUTHOR_SUCCESS,
  CREATE_AUTHOR_FAIL,
  CREATE_SOURCE,
  CREATE_SOURCE_SUCCESS,
  CREATE_SOURCE_FAIL,
  CREATE_SITE,
  CREATE_SUBJECT_RELATIONS,
  NEW_AUTHOR_SELECTED,
  AUTHOR_CHANGED,
  COAUTHOR_CHANGED,
  NEW_SOURCE_SELECTED,
  SOURCE_CHANGED,
  SOURCE_FILELIST_CHANGED,
  SOURCE_FILELIST_CLEAR,
  SOURCE_FILELIST_LOADBAR_CHANGED,
  UPLOAD_FILES,
  UPLOAD_FILES_START,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_SPATIALS,

  UPDATE_ERRORS_IN_SUBJECT_FIELDS,

  CREATE_PERIODICAL_ISSUE,
  CREATE_PERIODICAL_ISSUE_SUCCESS,
  CREATE_PERIODICAL_ISSUE_FAIL,
  NEW_PERIODICAL_ISSUE_SELECTED,
  CREATE_PERIODICAL,
  CREATE_PERIODICAL_SUCCESS,
  CREATE_PERIODICAL_FAIL,
  NEW_PERIODICAL_SELECTED,

  CREATE_BOOK_SERIES,
  CREATE_BOOK_SERIES_SUCCESS,
  CREATE_BOOK_SERIES_FAIL,
  NEW_BOOK_SERIES_SELECTED,
  CREATE_BOOK,
  CREATE_BOOK_SUCCESS,
  CREATE_BOOK_FAIL,
  NEW_BOOK_SELECTED,

  SAVE_RESEARCH_YEAR,
  SAVE_RESEARCH_AUTHOR,
};

// ------------------------------------
// Actions
// ------------------------------------
export const selectResearch = createAction(SELECT_RESEARCH);
export const setResearchSubjects = createAction(SET_RESEARCH_SUBJECTS);
export const clearResearchSubjects = createAction(CLEAR_RESEARCH_SUBJECTS);
export const changeDataType = createAction(CHANGE_DATA_TYPE);
export const newResearch = createAction(NEW_RESEARCH);
export const createResearch = createAction(CREATE_RESEARCH);
export const createHeritage = createAction(CREATE_HERITAGE);
export const createAuthor = createAction(CREATE_AUTHOR);
export const createAuthorSuccess = createAction(CREATE_AUTHOR_SUCCESS);
export const createAuthorFail = createAction(CREATE_AUTHOR_FAIL);
export const createSource = createAction(CREATE_SOURCE);
export const createSourceSuccess = createAction(CREATE_SOURCE_SUCCESS);
export const createSourceFail = createAction(CREATE_SOURCE_FAIL);
export const createSite = createAction(CREATE_SITE);
export const createSubjectRelations = createAction(CREATE_SUBJECT_RELATIONS);
export const newAuthorSelected = createAction(NEW_AUTHOR_SELECTED);
export const authorChanged = createAction(AUTHOR_CHANGED);
export const coauthorChanged = createAction(COAUTHOR_CHANGED);
export const newSourceSelected = createAction(NEW_SOURCE_SELECTED);
export const sourceChanged = createAction(SOURCE_CHANGED);
export const sourceFilelistChanged = createAction(SOURCE_FILELIST_CHANGED);
export const sourceFilelistClear = createAction(SOURCE_FILELIST_CLEAR);
export const sourceFilelistLoadbarChanged = createAction(SOURCE_FILELIST_LOADBAR_CHANGED);
export const uploadFiles = createAction(UPLOAD_FILES);
export const uploadFilesStart = createAction(UPLOAD_FILES_START);
export const uploadFilesSuccess = createAction(UPLOAD_FILES_SUCCESS);
export const uploadSpatials = createAction(UPLOAD_SPATIALS);

export const updateErrorsInSubjectFields = createAction(UPDATE_ERRORS_IN_SUBJECT_FIELDS);

export const createPeriodicalIssue = createAction(CREATE_PERIODICAL_ISSUE);
export const createPeriodicalIssueSuccess = createAction(CREATE_PERIODICAL_ISSUE_SUCCESS);
export const createPeriodicalIssueFail = createAction(CREATE_PERIODICAL_ISSUE_FAIL);
export const newPeriodicalIssueSelected = createAction(NEW_PERIODICAL_ISSUE_SELECTED);

export const createPeriodical = createAction(CREATE_PERIODICAL);
export const createPeriodicalSuccess = createAction(CREATE_PERIODICAL_SUCCESS);
export const createPeriodicalFail = createAction(CREATE_PERIODICAL_FAIL);
export const newPeriodicalSelected = createAction(NEW_PERIODICAL_SELECTED);

export const createBookSeries = createAction(CREATE_BOOK_SERIES);
export const createBookSeriesSuccess = createAction(CREATE_BOOK_SERIES_SUCCESS);
export const createBookSeriesFail = createAction(CREATE_BOOK_SERIES_FAIL);
export const newBookSeriesSelected = createAction(NEW_BOOK_SERIES_SELECTED);

export const createBook = createAction(CREATE_BOOK);
export const createBookSuccess = createAction(CREATE_BOOK_SUCCESS);
export const createBookFail = createAction(CREATE_BOOK_FAIL);
export const newBookSelected = createAction(NEW_BOOK_SELECTED);

export const saveResearchYear = createAction(SAVE_RESEARCH_YEAR);
export const saveResearchAuthor = createAction(SAVE_RESEARCH_AUTHOR);

export const actions = {
  selectResearch,
  setResearchSubjects,
  clearResearchSubjects,
  changeDataType,
  newResearch,
  createResearch,
  createHeritage,
  createAuthor,
  createAuthorSuccess,
  createAuthorFail,
  createSource,
  createSourceSuccess,
  createSourceFail,
  createSite,
  createSubjectRelations,
  newAuthorSelected,
  authorChanged,
  coauthorChanged,
  newSourceSelected,
  sourceChanged,
  sourceFilelistChanged,
  sourceFilelistClear,
  sourceFilelistLoadbarChanged,
  uploadFiles,
  uploadFilesStart,
  uploadFilesSuccess,
  uploadSpatials,

  updateErrorsInSubjectFields,

  createPeriodicalIssue,
  createPeriodicalIssueSuccess,
  createPeriodicalIssueFail,
  newPeriodicalIssueSelected,
  createPeriodical,
  createPeriodicalSuccess,
  createPeriodicalFail,
  newPeriodicalSelected,

  createBookSeries,
  createBookSeriesSuccess,
  createBookSeriesFail,
  newBookSeriesSelected,
  createBook,
  createBookSuccess,
  createBookFail,
  newBookSelected,

  saveResearchYear,
  saveResearchAuthor,
};

export const reducers = {
  [SELECT_RESEARCH]: (state, { payload }) =>
    ({
      ...state,
      newResearch: false,
      selectedResearch: true,
      data: {
        ...state.data,
        researchUid: payload.uid,
        researchLabel: payload.label,
      },
    }),
  [SET_RESEARCH_SUBJECTS]: (state, { payload }) =>
    ({
      ...state,
      researchConnectedSubjects: payload,
    }),
  [CLEAR_RESEARCH_SUBJECTS]: (state ) =>
    ({
      ...state,
      researchConnectedSubjects: [],
    }),
  [CHANGE_DATA_TYPE]: (state, { payload }) =>
    ({
      ...state,
      dataType: payload,
    }),
  [NEW_RESEARCH]: (state) =>
    ({
      ...state,
      newResearch: true,
      selectedResearch: false,
    }),
  [CREATE_HERITAGE]: (state) =>
    ({
      ...state,
      requestSent: true,
    }),
  [CREATE_RESEARCH]: (state) =>
    ({
      ...state,
      requestSent: true,
    }),
  [CREATE_AUTHOR]: (state) =>
    ({
      ...state,
      isLoading: true,
    }),
  [CREATE_AUTHOR_SUCCESS]: (state, { payload }) => {
    console.log('CREATE_AUTHOR_SUCCESS', payload)
    const ret = {
      ...state,
      isLoading: false,
      newAuthor: true,
      data: {
        ...state.data,
      },
    };
    const coauthorId = payload.coauthorId;

    if (coauthorId !== null) {
      const { collaborators } = state.data;
      collaborators[coauthorId] = payload;
      ret.newCoauthorId = coauthorId;
      ret.data.collaborators = collaborators;
    } else {
      ret.newCoauthorId = null;
      ret.data.author = payload;
    }

    return ret;
  },
  [CREATE_AUTHOR_FAIL]: (state) =>
    ({
      ...state,
      isLoading: false,
      newAuthor: false,
    }),
  [NEW_AUTHOR_SELECTED]: (state) =>
    ({
      ...state,
      newAuthor: false,
      newCoauthorId: null,
    }),
  [AUTHOR_CHANGED]: (state, { payload }) =>
    ({
      ...state,
      data: {
        ...state.data,
        author: payload,
      },
    }),
  [COAUTHOR_CHANGED]: (state, { payload }) => {
    const { collaborators } = state.data;
    console.log('COAUTHOR_CHANGED', payload)
    collaborators[payload.coauthorId] = { uid: payload.value };
    return {
      ...state,
      data: {
        ...state.data,
        collaborators,
      },
    }
  },
  [CREATE_SOURCE]: (state) =>
    ({
      ...state,
      isLoading: true,
    }),
  [CREATE_SOURCE_SUCCESS]: (state, { payload }) =>
    ({
      ...state,
      isLoading: false,
      newSource: true,
      data: {
        ...state.data,
        source: payload,
      },
    }),
  [CREATE_SOURCE_FAIL]: (state) =>
    ({
      ...state,
      isLoading: false,
      newSource: false,
    }),
  [NEW_SOURCE_SELECTED]: (state) =>
    ({
      ...state,
      newSource: false,
    }),
  [SOURCE_CHANGED]: (state, { payload }) =>
    ({
      ...state,
      data: {
        ...state.data,
        source: payload,
      },
    }),
  [CREATE_PERIODICAL_ISSUE]: (state) =>
    ({
      ...state,
      isLoading: true,
    }),
  [CREATE_PERIODICAL_ISSUE_SUCCESS]: (state, { payload }) =>
    ({
      ...state,
      isLoading: false,
      newPeriodicalIssue: true,
      data: {
        ...state.data,
        periodicalIssue: payload,
      },
    }),
  [CREATE_PERIODICAL_ISSUE_FAIL]: (state) =>
    ({
      ...state,
      isLoading: false,
      newPeriodicalIssue: false,
    }),
  [NEW_PERIODICAL_ISSUE_SELECTED]: (state) =>
    ({
      ...state,
      newPeriodicalIssue: false,
    }),
  [CREATE_PERIODICAL]: (state) =>
    ({
      ...state,
      isLoading: true,
    }),
  [CREATE_PERIODICAL_SUCCESS]: (state, { payload }) =>
    ({
      ...state,
      isLoading: false,
      newPeriodical: true,
      data: {
        ...state.data,
        periodical: payload,
      },
    }),
  [CREATE_PERIODICAL_FAIL]: (state) =>
    ({
      ...state,
      isLoading: false,
      newPeriodical: false,
    }),
  [NEW_PERIODICAL_SELECTED]: (state) =>
    ({
      ...state,
      newPeriodical: false,
    }),
  [CREATE_BOOK_SERIES]: (state) =>
    ({
      ...state,
      isLoading: true,
    }),
  [CREATE_BOOK_SERIES_SUCCESS]: (state, { payload }) =>
    ({
      ...state,
      isLoading: false,
      newBookSeries: true,
      data: {
        ...state.data,
        bookSeries: payload,
      },
    }),
  [CREATE_BOOK_SERIES_FAIL]: (state) =>
    ({
      ...state,
      isLoading: false,
      newBookSeries: false,
    }),
  [NEW_BOOK_SERIES_SELECTED]: (state) =>
    ({
      ...state,
      newBookSeries: false,
    }),
  [CREATE_BOOK]: (state) =>
    ({
      ...state,
      isLoading: true,
    }),
  [CREATE_BOOK_SUCCESS]: (state, { payload }) =>
    ({
      ...state,
      isLoading: false,
      newBook: true,
      data: {
        ...state.data,
        book: payload,
      },
    }),
  [CREATE_BOOK_FAIL]: (state) =>
    ({
      ...state,
      isLoading: false,
      newBook: false,
    }),
  [NEW_BOOK_SELECTED]: (state) =>
    ({
      ...state,
      newBook: false,
    }),
  [SOURCE_FILELIST_CLEAR]: (state, { payload }) => {
    console.log('SOURCE_FILELIST_CLEAR', payload)
    const newFilelist = { ...state.sourceFilelist }
    newFilelist[payload.label] = {};
    return {
      ...state,
      sourceFilelist: newFilelist,
    }
  },
  [SOURCE_FILELIST_CHANGED]: (state, { payload }) => {
    console.log('SOURCE_FILELIST_CHANGED', payload)
    const newFilelist = { ...state.sourceFilelist }
    newFilelist[payload.label] = payload.list;
    return {
      ...state,
      sourceFilelist: newFilelist,
    }
  },
  [SOURCE_FILELIST_LOADBAR_CHANGED]: (state, { payload }) => {
    const fileList = { ...state.sourceFilelist };
    console.log('SOURCE_FILELIST_LOADBAR_CHANGED', fileList, payload)
    fileList[payload.fileLabel].fileList[payload.fileId].percent = payload.percent * 100;
    fileList[payload.fileLabel].fileList[payload.fileId].status = 'uploading';
    return {
      ...state,
      sourceFilelist: fileList,
    }
  },
  [UPLOAD_FILES_START]: (state) =>
    ({
      ...state,
      filesUploaded: false,
    }),
  [UPLOAD_FILES_SUCCESS]: (state) =>
    ({
      ...state,
      filesUploaded: true,
    }),
  [SAVE_RESEARCH_AUTHOR]: (state, { payload }) =>
    ({
      ...state,
      researchAuthor: payload,
    }),
  [SAVE_RESEARCH_YEAR]: (state, { payload }) =>
    ({
      ...state,
      researchYear: payload,
    }),
  [UPDATE_ERRORS_IN_SUBJECT_FIELDS]: (state, { payload }) =>
    ({
      ...state,
      errors: payload,
    }),
}

export const initialState = () => {
  const state = {
    isLoading: false,
    requestSent: false,
    newAuthor: false,
    newCoauthorId: null,
    newResearch: false,
    selectedResearch: false,
    newPeriodicalIssue: false,
    newPeriodical: false,
    newBookSeries: false,
    newBook: false,
    researchConnectedSubjects: [],
    researchAuthor: "",
    researchYear: null,
    data: {
      collaborators: [],
    },
    dataType: 'research',
    sourceFilelist: {},
    filesUploaded: false,
    errors: {},
  }
  return state;
}

export default handleActions(reducers, initialState());
