const localityQueries = {
  VariantWithoutGroup: {
    q: `query postQuery($uid: String) {
      localities (uid_Exact: $uid) {
        edges {
          node {
            variant {
              edges {
                node {
                  names
                  uid
                  sense
                  lexemeName: lexeme {
                    edges {
                      node {
                        uid
                        name
                        attributes {
                          edges {
                            node {
                              label
                              ru_name: ruName
                            }
                          }
                        }
                        term {
                          edges {
                            node {
                              name
                              uid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,
    dataPath: [
      'localities',
      'variant',
    ],
  },
};

export default localityQueries
