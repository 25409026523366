const ICONS = {
  0: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}"/>
      <path d="M29.2749 39.8379C29.2892 37.8327 29.5828 36.3646 30.1558 35.4336C30.743 34.5026 31.9032 33.3711 33.6362 32.0391C34.6532 31.2083 35.4624 30.3132 36.064 29.3535C36.6799 28.3939 36.9878 27.3197 36.9878 26.1309C36.9878 24.8132 36.6369 23.7819 35.9351 23.0371C35.2476 22.2923 34.2593 21.9199 32.9702 21.9199C31.9103 21.9199 31.008 22.235 30.2632 22.8652C29.5327 23.4954 29.1603 24.4193 29.146 25.6367H23.0444L23.0015 25.5078C22.9585 22.7578 23.8608 20.6523 25.7085 19.1914C27.5705 17.7305 29.991 17 32.9702 17C36.1785 17 38.6922 17.8164 40.5112 19.4492C42.3446 21.0677 43.2612 23.2734 43.2612 26.0664C43.2612 27.8854 42.7241 29.5684 41.6499 31.1152C40.59 32.6621 39.258 33.9583 37.6538 35.0039C36.7801 35.6771 36.2072 36.3574 35.9351 37.0449C35.6629 37.7181 35.5269 38.6491 35.5269 39.8379H29.2749ZM35.5698 48.7324H29.2534V43.3613H35.5698V48.7324Z" fill="white"/>
    </svg>
  `,
  1: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28 17H38V49H28V17ZM17 34H27V49H17V34ZM49 25H39V49H49V25Z" fill="white"/>
    </svg>
  `,
  2: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.7715 12.1035L33.8209 12H36.4448L36.5275 12.218L40.6485 23.0825L41.7556 25.875H55.75V28.875H40.7367H39.7178L39.3423 27.9278L37.8556 24.1778L37.8515 24.1674L37.8475 24.157L34.9666 16.562L24.4785 38.5215L24.25 39H22.0204L21.7955 38.5696L16.7307 28.875H12.25V25.875H17.6394H18.5481L18.9689 26.6804L23.0648 34.5205L33.7715 12.1035Z"
        fill="#FC3F27"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.7715 27.1035L33.8209 27H36.4448L36.5275 27.218L40.6485 38.0825L41.7556 40.875H55.75V43.875H40.7367H39.7178L39.3423 42.9278L37.8556 39.1778L37.8515 39.1674L37.8475 39.157L34.9666 31.562L24.4785 53.5215L24.25 54H22.0204L21.7955 53.5696L16.7307 43.875H12.25V40.875H17.6394H18.5481L18.9689 41.6804L23.0648 49.5205L33.7715 27.1035Z"
        fill="#2570E1"
        fill-opacity="0.8"
      />
    </svg>
  `,
  4: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.3826 15.4092C37.7691 15.0228 38.2973 14.8113 38.8437 14.824L49.4714 15.0711C50.0055 15.0836 50.4362 15.5136 50.4486 16.0476L50.6958 26.6764C50.7085 27.2229 50.497 27.7507 50.1105 28.1372L42.6482 35.5995C42.2577 35.99 41.6245 35.99 41.234 35.5995L36.9914 31.3568L23.5563 44.7918L23.5563 50.0345C23.5563 50.2997 23.451 50.5541 23.2634 50.7416L19.3137 54.6913C18.9232 55.0819 18.29 55.0819 17.8995 54.6913L10.8284 47.6203C10.4379 47.2298 10.4379 46.5966 10.8284 46.2061L14.7782 42.2563C14.9657 42.0688 15.2201 41.9634 15.4853 41.9634L20.7279 41.9634L34.1629 28.5284L29.9203 24.2857C29.5298 23.8952 29.5298 23.2621 29.9203 22.8715L37.3826 15.4092ZM16.4853 44.7918L20.7279 49.0345L18.6066 51.1558L14.364 46.9132L16.4853 44.7918Z" fill="white"/>
    </svg>
  `,
  5: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.0707 41.192C34.8535 42.9501 32.0494 44 29 44C21.8203 44 16 38.1797 16 31C16 23.8203 21.8203 18 29 18C36.1797 18 42 23.8203 42 31C42 33.6861 41.1853 36.182 39.7895 38.254L47.3848 45.8492C47.7753 46.2397 47.7753 46.8729 47.3848 47.2634L45.9706 48.6776C45.58 49.0682 44.9469 49.0682 44.5563 48.6776L37.0707 41.192ZM38 31C38 35.9706 33.9706 40 29 40C24.0294 40 20 35.9706 20 31C20 26.0294 24.0294 22 29 22C33.9706 22 38 26.0294 38 31Z" fill="white"/>
    </svg>
  `,
  11: (color: string, size: number) => `
  <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="33" cy="33" r="33" fill="${color}"/>
    <circle cx="33.0001" cy="25.6047" r="5.16589" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33 42.2093C42.1705 42.2093 49.6047 34.7751 49.6047 25.6047C49.6047 16.4342 42.1705 9 33 9C23.8295 9 16.3954 16.4342 16.3954 25.6047C16.3954 34.7751 23.8295 42.2093 33 42.2093ZM33 37.4125C39.5212 37.4125 44.8077 32.126 44.8077 25.6047C44.8077 19.0835 39.5212 13.797 33 13.797C26.4787 13.797 21.1922 19.0835 21.1922 25.6047C21.1922 32.126 26.4787 37.4125 33 37.4125Z" fill="white"/>
    <path d="M33.1846 52.1721L27.8342 41.1023L37.9814 41.1023L33.1846 52.1721Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.0001 56.6001C39.7251 56.6001 45.1769 54.4524 45.1769 51.8032C45.1769 50.1427 39.7251 49.2202 33.0001 49.2202C26.2751 49.2202 20.8234 50.1427 20.8234 51.8032C20.8234 54.4524 26.2751 56.6001 33.0001 56.6001ZM32.7197 49.5892C36.6433 49.5892 40.011 50.3981 40.011 51.9876C40.011 52.9839 36.6433 54.0171 32.7197 54.0171C28.7961 54.0171 25.9893 52.9839 25.9893 51.9876C25.9893 50.3981 28.7961 49.5892 32.7197 49.5892Z" fill="white"/>
  </svg>
  `,
}
ICONS['default'] = ICONS[0]

export default ICONS
