import React from 'react';
import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  fullNameToShort,
} from 'utility/transformData/constants';
import { Link } from 'react-router-dom';
import getLayerFields from 'utility/transformData/fields/layer';
import getSiteFields from 'utility/transformData/fields/site';
import { GRADATION_TYPE } from 'utility/constants/'

const getLinkArray = (array) => array.map(link => (
  <Link key={link.uid} to={`/show/subjects/Site/${link.uid}`}>{getLangTitle(link)}</Link>
))

export default function getExcFields(d, tabType) {
  console.log(tabType);
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      const result = [
        createCommonField('name', getLangTitle(item)),
        createCommonField('year', item.year || '-'),
        createLinkField('research_author', d.research.author.uid, 'author',
          fullNameToShort(d.research.author)),
        createLinkField('research_title', d.research.uid, 'research',
          getLangTitle(d.research)),
        createCommonField('research_type', getLangTitle(d.research.type)),
        createLinkField('supervisor', item.supervisor.uid, 'author',
          fullNameToShort(item.supervisor)),
        createCommonField('area', item.area),
        createCommonField('sites', getLinkArray(item.connected_sites)),
        createCommonField('gradation_type', d.gradation_type ? GRADATION_TYPE[d.gradation_type] : '-'),
        createCommonField('description', getLangTitle(d.research, 'desc')),
      ]
      return result;
    }
    case TAB_TYPE.COLLAPSE: {
      console.log(d.results);
      const result = [];
      d.results.forEach((item, index) => {
        console.log(item);
        const itemObj = {}
        itemObj.content = [
          createLinkField('name', item.uid, 'subjects/Excavation',
            getLangTitle(item)),
          createCommonField('year', item.year || '-'),
        ]
        const research = (item.research && item.research.uid) ? item.research : item.inter || {};
        if (research.date_end || research.date_start) {
          itemObj.content.push(
            createCommonField('date', `${research.date_start || '?'} - ${research.date_end || '?'}`),
          )
        }
        if (research.ru_desc) {
          itemObj.content.push(
            createCommonField('description', getLangTitle(research, 'desc')),
          )
        }
        if (item.interpretations && item.interpretations[0]) {
          const inter = item.interpretations[0];
          itemObj.content.push(
            createLinkField('research_author', inter.research.author.uid, 'author',
              fullNameToShort(inter.research.author)),
          )
        }
        if (item.area) {
          itemObj.content.push(
            createCommonField('area', item.area),
          )
        }
        if (item.supervisor) {
          itemObj.content.push(
            createLinkField('supervisor', item.supervisor.uid, 'author',
              fullNameToShort(item.supervisor)),
          )
        }
        if (item.layers) {
          itemObj.childrenItem = {
            subjectType: 'layer',
            content: getLayerFields({ response: { results: item.layers } }, TAB_TYPE.COLLAPSE,
              getLangTitle(item)),
          }
        }
        if (item.sites) {
          itemObj.childrenItem = {
            subjectType: 'site',
            content: getSiteFields({ results: item.sites }, TAB_TYPE.COLLAPSE,
              getLangTitle(item)),
          }
        }
        itemObj.name = getLangTitle(item)
        result.push(itemObj);
      })
      return result;
    }
    default:
      return [];
  }
}
