import React, { Component } from 'react'
import {
  Input, Select, Form, InputNumber, Button, Radio
} from 'antd'
import { connect } from 'react-redux'
import { getPropertyByNameString } from 'utility/transformData/constants'
import CommonSelect from 'components/GSearchPanel/components/commonSelect'
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries'
import archQueries from 'components/GSearchPanel/Queries/archQueries'
import archiveQueries from 'components/GSearchPanel/Queries/archiveQueries'
import { listQueries } from 'components/CreatePanel/queries/getList'
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters'
import HeritageTab from 'components/CreatePanel/templates/tabs/heritage'

import AutoInputGQL from '../fields/autoInputGQL'
import AddAuthorModal from '../modals/newAuthorModal'
import AddReportModal from '../modals/newReportModal'
import { actions } from '../../../../redux/modules/create'

const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input

const mapStateToProps = state => ({
  createData: state.create,
})

const mapDispatchToProps = {
  selectResearch: actions.selectResearch,
  clearResearchSubjects: actions.clearResearchSubjects,
  changeDataType: actions.changeDataType,
  newResearch: actions.newResearch,
  createAuthor: actions.createAuthor,
  createSource: actions.createSource,
  authorChanged: actions.authorChanged,
  coauthorChanged: actions.coauthorChanged,
  sourceChanged: actions.sourceChanged,
  newAuthorSelected: actions.newAuthorSelected,
  newSourceSelected: actions.newSourceSelected,
  sourceFilelistClear: actions.sourceFilelistClear,
  sourceFilelistChanged: actions.sourceFilelistChanged,
  saveResearchAuthor: actions.saveResearchAuthor,
  saveResearchYear: actions.saveResearchYear,
}

@connect(mapStateToProps, mapDispatchToProps)
class ResearchTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addRuName: false,
      addLocName: false,
      addRuDesc: false,
      newAuthorModal: false,
      newReportModal: false,
      newResearch: false,
      newResearchUid: null,
      newResearchName: '',
      newReportName: '',
      coauthorsCount: [0],
      newCoauthorId: null,
      dataType: 'research',
      researchType: '',
    }
  }

  handleChangeDataType = data => {
    const newType = data.target.value
    this.setState({ dataType: newType })
    this.props.changeDataType(newType)
  }

  handleSelectResearch = data => {
    if (data.value === 'new') {
      this.setState({
        newResearchName: data.typed,
      })
      this.props.newResearch()
      this.props.clearResearchSubjects()
    } else {
      this.props.selectResearch({
        uid: data.value,
        label: data.option.props.allData.label,
      })
    }
  }

  handleChangeResearchYear = year => {
    console.log('handleChangeResearchYear', year)
    this.props.saveResearchYear(year)
  }

  handleChangeAuthorSelector = data => {
    console.log('handleChangeAuthorSelector', data)
    if (data.value === 'new') {
      this.setState({
        newAuthorModal: true,
        newCoauthorId: null,
      })
    } else {
      this.setState({ newAuthorModal: false })
      this.props.saveResearchAuthor(data.option.props.text)
    }
  }

  handleSelectCoauthor = (data, coauthorId) => {
    console.log('handleSelectCoathor', data, coauthorId)

    if (data.value === 'new') {
      this.setState({
        newAuthorModal: true,
        newCoauthorId: coauthorId,
      })
      // this.props.coauthorChanged({ coauthorId, value: data.value })
    } else {
      this.setState({ newAuthorModal: false })
      if (data.value) {
        this.props.coauthorChanged({ coauthorId, value: data.value })
      }
    }
  }

  addCoauthor = () => {
    const coauthors = this.state.coauthorsCount
    coauthors.push(0)
    this.setState({
      coauthorsCount: coauthors,
    })
    this.forceUpdate()
  }

  hideNewAuthorModal = e => {
    this.setState({ newAuthorModal: false })
    if (this.props.form.getFieldValue('research_author') === 'new') {
      this.props.form.setFieldsValue({
        research_author: null,
      })
    }
  }

  handleChangeReportSelector = data => {
    if (data.value === 'new') {
      this.setState({
        newReportName: data.typed,
      })
      this.setState({ newReportModal: true })
    } else {
      this.setState({ newReportModal: false })
    }
  }

  hideNewReportModal = () => {
    this.setState({ newReportModal: false })
    this.props.sourceFilelistClear('source')

    if (this.props.form.getFieldValue('source_name') === 'new') {
      this.props.form.setFieldsValue({
        source_name: null,
      })
    }
  }

  handleChangeResearchType = value => {
    this.setState({ researchType: value })
  }

  render() {
    const {
      getFieldDecorator,
      formInputLayout,
      createAuthor,
      createSource,
      authorChanged,
      sourceChanged,
      newAuthorSelected,
      newSourceSelected,
      sourceFilelistChanged,
      filelistChanged,
      filelistClear,
      handleChangeInteractiveMarker,
      handleAddMarker,
      interactiveMarkers,
      createData,
      form,
      saveResearchAuthor,
      t,
    } = this.props

    const {
      coauthorsCount, newReportName, newResearchName, dataType, researchType
    } = this.state;
    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    const authorId = createData.data.author ? createData.data.author.uid : null
    const { research_author } = form.getFieldsValue(['research_author'])

    const formButton = {
      wrapperCol: { offset: 8, span: 10 },
    }

    if (createData.newAuthor) {
      console.log('newAuthor', createData)
      if (createData.newCoauthorId === null) {
        this.props.form.setFieldsValue({
          research_author: createData.data.author.public_name,
        })
        saveResearchAuthor(research_author)
      } else {
        const coauthorId = createData.newCoauthorId
        const couathorName =
          createData.data.collaborators[coauthorId].public_name
        this.props.form.setFieldsValue({
          [`research_coauthor_${coauthorId}`]: couathorName,
        })
      }

      newAuthorSelected()
    }

    if (createData.newSource) {
      this.props.form.setFieldsValue({
        source_name: createData.data.source.ru_name,
      })
      newSourceSelected()
    }

    return (
      <div>
        <FormItem {...formButton}>
          {getFieldDecorator('dataTypeRadio', { initialValue: 'research' })(
            <Radio.Group
              onChange={this.handleChangeDataType}
              buttonStyle="solid"
            >
              <Radio.Button value="research">
                {t('common.data_type.research')}
              </Radio.Button>
              <Radio.Button value="heritage">
                {t('common.data_type.heritage')}
              </Radio.Button>
            </Radio.Group>,
          )}
        </FormItem>

        {dataType === 'heritage' && (
          <HeritageTab
            title="heritage"
            handleAddMarker={handleAddMarker}
            interactiveMarkers={interactiveMarkers}
            getFieldDecorator={getFieldDecorator}
            formInputLayout={formInputLayout}
            filelistClear={filelistClear}
            filelistChanged={filelistChanged}
            createData={createData}
            form={form}
            t={t}
          />
        )}

        {createData.newResearch === false && dataType === 'research' && (
          <AutoInputGQL
            handleSelect={this.handleSelectResearch}
            getFieldDecorator={getFieldDecorator}
            formLayout={formInputLayout}
            title={this.props.title}
            label={t('create_panel.tab.research.choose')}
            selectkey="research_select"
            addOptionText={t('create_panel.tab.research.add')}
            query={listQueries.researchSearch()}
            params={[{ key: 'search', val: 'value' }]}
            rules={config.req}
            t={t}
          />
        )}
        {createData.newResearch === true && dataType === 'research' && (
          <div>
            <AutoInputGQL
              handleSelect={this.handleChangeAuthorSelector}
              newObjChanged={authorChanged}
              getFieldDecorator={getFieldDecorator}
              formLayout={formInputLayout}
              label={t('create_panel.tab.research.author')}
              selectkey="research_author"
              addOptionText={t('create_panel.tab.research.add_author')}
              query={archQueries.authorSearch()}
              params={[{ key: 'search', val: 'value' }]}
              rules={config.req}
              t={t}
            />
            {coauthorsCount.map((coauthor, id) => (
              <AutoInputGQL
                handleSelect={data => {
                  this.handleSelectCoauthor(data, id)
                }}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                label={t('create_panel.tab.research.coauthor')}
                selectkey={`research_coauthor_${id}`}
                addOptionText={t('create_panel.tab.research.add_author')}
                query={archQueries.authorSearch()}
                params={[{ key: 'search', val: 'value' }]}
                t={t}
              />
            ))}
            <FormItem {...formButton}>
              {getFieldDecorator(
                'add_coauthor',
                {},
              )(
                <Button type="primary" onClick={this.addCoauthor}>
                  {t('create_panel.tab.research.add_coauthor')}
                </Button>,
              )}
            </FormItem>

            {researchType !== 'da836d00-c455-40a8-8064-3342fc4fcadf' && ( // If type === CurrentResearch
              <AutoInputGQL
                handleSelect={this.handleChangeReportSelector}
                newObjChanged={sourceChanged}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                label={t('create_panel.tab.research.source_name')}
                selectkey="source_name"
                addOptionText={t('create_panel.tab.research.add_source')}
                query={archiveQueries.reportSearch()}
                params={[{ key: 'search', val: 'value' }]}
                rules={config.req}
                t={t}
              />
            )}
            <FormItem
              {...formInputLayout}
              label={t('create_panel.tab.research.research_name_en')}
            >
              {getFieldDecorator('en_name', {
                ...config.req,
                initialValue: newResearchName,
              })(<TextArea />)}
            </FormItem>
            {!this.state.addRuName && (
              <FormItem {...formButton}>
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ addRuName: true })
                  }}
                >
                  {t('create_panel.tab.research.add_ru_name')}
                </Button>
              </FormItem>
            )}
            {this.state.addRuName &&
              <FormItem
                {...formInputLayout}
                label={t('create_panel.tab.research.research_name_ru')}
              >
                {getFieldDecorator('ru_name', {
              }) (<TextArea />)}
              </FormItem>}
            {!this.state.addLocName && (
              <FormItem {...formButton}>
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ addLocName: true })
                  }}
                >
                  {t('create_panel.tab.research.add_loc_name')}
                </Button>
              </FormItem>
            )}
            {this.state.addLocName &&
              <FormItem
                {...formInputLayout}
                label={t('create_panel.tab.research.research_name_loc')}
              >
                {getFieldDecorator('local_name', {
              }) (<TextArea />)}
              </FormItem>}
            <CommonSelect
              getFieldDecorator={getFieldDecorator}
              formLayout={formInputLayout}
              inputName="research_type"
              label={t('create_panel.tab.research.res_type')}
              selectQuery={attributeQueries.defaultSearch('researchTypes')}
              dontNeedEmptyOption="true"
              onSelect={this.handleChangeResearchType}
              rules={config.req}
              t={t}
            />
            <FormItem
              {...formInputLayout}
              label={t('create_panel.tab.research.study_subject')}
            >
              {getFieldDecorator('study_subject', {
                initialValue: 'archaeology',
              })(
                <Select>
                  <Option value="archaeology">
                    {t('create_panel.tab.research.s_types.arch')}
                  </Option>
                  {/* <Option value="history">{t('create_panel.tab.research.s_types.history')}</Option> */}
                  <Option value="encyclopedia">
                    {t('create_panel.tab.research.s_types.local_lore')}
                  </Option>
                  <Option value="linguistics">
                    {t('create_panel.tab.research.s_types.ling')}
                  </Option>
                  {/* <Option value="folklore">{t('create_panel.tab.research.s_types.folklore')}</Option> */}
                </Select>,
              )}
            </FormItem>
            <FormItem
              {...formInputLayout}
              label={t('create_panel.tab.research.year')}
            >
              {getFieldDecorator(
                'res_year',
                config.req,
              )(
                <InputNumber
                  min={1700}
                  max={new Date().getFullYear()}
                  onChange={this.handleChangeResearchYear}
                />,
              )}
            </FormItem>
             <FormItem
              {...formInputLayout}
              label={t('common.en_desc')}
             >
              {getFieldDecorator('research_desc_en', {
              })(
                <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `research_desc`)}/>)}
             </FormItem>
            {!this.state.addRuDesc && (
              <FormItem {...formButton}>
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ addRuDesc: true })
                  }}
                >
                  {t('create_panel.tab.research.add_ru_desc')}
                </Button>
              </FormItem>
            )}
            {this.state.addRuDesc &&
              <FormItem
              {...formInputLayout}
              label={t('common.ru_desc')}
            >
              {getFieldDecorator('research_desc_ru', {
              })(
                <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `research_desc`)}/>)}
            </FormItem>}
            {!this.state.addLocDesc && (
              <FormItem {...formButton}>
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ addLocDesc: true })
                  }}
                >
                  {t('create_panel.tab.research.add_loc_desc')}
                </Button>
              </FormItem>
            )}
            {this.state.addLocDesc &&
              <FormItem
              {...formInputLayout}
              label={t('common.loc_desc')}
            >
              {getFieldDecorator('research_desc_loc', {
              })(
                <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `research_desc`)}/>)}
            </FormItem>}
            <FormItem
              {...formInputLayout}
              label={t('create_panel.tab.research.access')}
            >
              {getFieldDecorator('access', {
                initialValue: '0',
              })(
                <Select>
                  <Option value="0">
                    {t('create_panel.tab.research.a_types.public')}
                  </Option>
                  <Option value="2">
                    {t('create_panel.tab.research.a_types.shared')}
                  </Option>
                  <Option value="1">
                    {t('create_panel.tab.research.a_types.private')}
                  </Option>
                </Select>,
              )}
            </FormItem>

            <AddAuthorModal
              visible={this.state.newAuthorModal}
              hide={this.hideNewAuthorModal}
              loading={createData.isLoading}
              authorCreated={createData.newAuthor}
              newCoauthorId={this.state.newCoauthorId}
              handleSubmit={createAuthor}
              t={t}
            />
            <AddReportModal
              visible={this.state.newReportModal}
              hide={this.hideNewReportModal}
              loading={createData.isLoading}
              sourceCreated={createData.newSource}
              handleSubmit={createSource}
              authorId={authorId}
              authorName={research_author}
              fileLists={createData.sourceFilelist}
              handleFileListChange={sourceFilelistChanged}
              newReportName={newReportName}
              createData={createData}
              t={t}
            />
          </div>
        )}
      </div>
    )
  }
}

export default ResearchTab
