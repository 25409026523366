import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';
import archQueries from 'components/GSearchPanel/Queries/archQueries';
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters';

import '../../index.scss';
import AutoInputGQL from '../fields/autoInputGQL';
import FileUpload from '../fields/fileUpload';
import CoordinatesInput from '../fields/coordinatesInput';
import { commonFunctions } from '../../functions/commonFunctions';
import RelationComponent from '../relations/relations'

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class SiteTab extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 1;
    this.state = {
      newName: []
    }
  }

  addTab = () => {
    const key = this.newTabIndex++;
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state;
    newName[key] = data.typed;
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = (input) => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      getFieldDecorator,
      filelistChanged,
      createData,
      formInputLayout,
      stateTabs,
      subjects,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props;

    const config = {
      req: {
        rules: [{
          required: true, message: t('common.default_error'),
        }],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel header={`${t('create_panel.tab.site.site')} #${1 + key}`} key={title + item.key}>
              <AutoInputGQL
                handleSelect={(data) => {this.handleSelect(data, key)}}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.site.choose')}
                selectkey={`site-select-${key}`}
                addOptionText={t('create_panel.tab.site.add')}
                objkey={item.key}
                params={[{ key: 'search', val: 'value' }]}
                query={archQueries.siteSearch()}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel={'Site'}
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') &&
                <div>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.site.name')}
                  >
                    {getFieldDecorator(`site_name_${key}`, {
                      ...config.req,
                      initialValue: this.state.newName[key],
                    })(
                      <Input onChange={this.handleChangeName} data-objkey={item.key} />)}
                  </FormItem>
                  <CoordinatesInput
                    defaultActive
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={title}
                    rules={(item.status === 'new') ? config.req : {}}
                    subjname="site"
                    objkey={key}
                    handleAddMarker={handleAddMarker}
                    interactiveMarkers={interactiveMarkers}
                    form={form}
                    t={t}
                  />
                  <FormItem
                    {...formInputLayout}
                    label={t('common.description')}
                  >
                    {getFieldDecorator(`site_desc_${key}`, {
                    })(
                      <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `site_desc_${key}`)} />)}
                  </FormItem>
                  <FileUpload
                    getFieldDecorator={getFieldDecorator}
                    formInputLayout={formInputLayout}
                    filelistChanged={filelistChanged}
                    fileLists={createData.sourceFilelist}
                    label={`site_files_${key}`}
                    fileType={`photo`}
                    title={t(`common.topo.title`)}
                    t={t}
                  />
                  <FileUpload
                    getFieldDecorator={getFieldDecorator}
                    formInputLayout={formInputLayout}
                    filelistChanged={filelistChanged}
                    fileLists={createData.sourceFilelist}
                    label={`site_files_photo_${key}`}
                    fileType={`photo`}
                    title={t(`common.photo.title`)}
                    t={t}
                  />
                  <RelationComponent
                    handleAddExtraRelation={handleAddExtraRelation}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    objkey={key}
                    title="Site"
                    tabName="sites"
                    stateTabs={stateTabs}
                    form={form}
                    t={t}
                  />
                </div>
              }
            </Panel>
          ))}
        </Collapse>

        <FormItem
          wrapperCol={{ span: 8, offset: 8 }}
        >
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.site.add_button')}
          </Button>
        </FormItem>
      </div>
    );
  }
}

export default SiteTab;
