import React, { useState } from 'react'
import { Drawer, Button, Collapse, Tooltip } from 'antd'
import { translate } from 'react-i18next'
import MapIcon, { ICON_SIZE } from 'components/MapIcon'
import { data as legendData } from './data'
import getUrl from 'utility/images'
import SettingPanelDrawer from 'components/SettingPanel/Points'
import { actions as actionsE } from 'redux/modules/example';
import { connect } from 'react-redux'

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  editDeleteSpatial: actionsE.editDeleteSpatial,
};

const { Panel } = Collapse
const MapLegend = props => {
  const { t, settings, editDeleteSpatial } = props
  const [isVisible, setIsVisible] = useState(false)
  const [isHistoryVisible, setHistoryIsVisible] = useState(false)

  const toggleDrawer = () => {
    setIsVisible(!isVisible)
  }
  const toggleHistoryDrawer = () => {
    setHistoryIsVisible(!isHistoryVisible)
  }

  const points = settings && settings.points && settings.points.length > 0
  const pointHistory = settings ? settings.points : null

  return (
    <div className="map_legend">
      <div>
        <Tooltip placement="left" title={t(`map_legend.title`)}>
          <Button type="link" onClick={toggleDrawer}>
            <img src={getUrl('common_icons/map_legend.webp')} width={26}/>
          </Button>
        </Tooltip>
      </div>
      {points &&
        <div>
          <Tooltip placement="left" title={t(`settings.menu.points`)}>
            <Button type="link" onClick={toggleHistoryDrawer}>
              <img src={getUrl('common_icons/spatial_history.webp')} width={26}/>
            </Button>
          </Tooltip>
        </div>
      }
      <SettingPanelDrawer
        t={t}
        visible={isHistoryVisible}
        toggle={toggleHistoryDrawer}
        pointHistory={pointHistory}
        editDeleteSpatial={editDeleteSpatial}
      />
      <Drawer
        title={t('map_legend.title')}
        width={300}
        placement="right"
        closable
        onClose={toggleDrawer}
        visible={isVisible}
        className="map_legend_drawer"
      >
        <Collapse bordered={false} defaultActiveKey={['0', '1', '2', '3']}>
          {Object.keys(legendData).map((key, index) => (
            <Panel header={t(`${legendData[key].path}.title`)} key={`${index}`}>
              {legendData[key].items.map(item => (
                <div className="legend_item">
                  <MapIcon
                    icon={item.icon || legendData[key].icon}
                    size={ICON_SIZE.middle}
                    {...item.props}
                  />
                  <span className="legend_item_title">
                    {t(`${legendData[key].path}.${item.title}`)}
                  </span>
                </div>
              ))}
            </Panel>
          ))}
        </Collapse>
      </Drawer>
    </div>
  )
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(MapLegend))

