import { put, fork, takeLatest, call, all } from 'redux-saga/effects'

import { getResearchSpatialReference } from 'redux/api/research'
import { getSubjectSpatials } from 'redux/api/subject'
import { getName } from 'utility/name'
import { getSearchCount, getSearchList } from 'redux/api/search'
import { graphqlPost } from 'redux/api/graphql'

import {
  constants as exampleConstants,
  actions as exampleActions,
} from '../modules/example'

function* fetchCount(action) {
  yield put(exampleActions.simpleSearchStart())
  const { select, params } = action.payload

  if (action.payload.newQuery) {
    console.log('getcount1')
    const { response, error } = yield call(getSearchCount, select, params)
    console.log('getcount', response, error)
    yield put(
      exampleActions.simpleSearchSaveQuery({
        params: action.payload.params,
        count: response.count || 0,
        select: action.payload.select,
      }),
    )
  }
}

function* fetchReseachSpatials(response) {
  const spatials = yield all(
    response.results.map(item => call(getResearchSpatialReference, item.uid)),
  )
  console.log('spatials', spatials)
  const pointsObj = {
    points: spatials.reduce((result, item, index) => {
      console.log(item)
      return result.concat(
        item.response.results.map(point => ({
          title: getName(response, index, 'research'),
          subject_id: response.results[index].uid,
          points: point.points,
          uid: point.uid,
          research_type: response.results[index].type.edges[0].node.sort,
        })),
      )
    }, []),
    name: 'research',
    type: 'research',
  }
  console.log('pointsObj', pointsObj)
  yield put(exampleActions.simpleSearchNewSpatials(pointsObj))
}

function* fetchSubjectSpatials(response, select) {
  const spatials = yield all(
    response.results.map(item => call(getSubjectSpatials, item.uid, select)),
  )
  console.log('spatials', spatials)
  const pointsObj = {
    points: spatials.reduce((result, item, index) => {
      console.log(item, index)
      const points = item.response.results || item.response
      const localPoints =
        points[0] && points[0].localities ? points[0].localities : points
      if (!item.error) {
        return result.concat(
          localPoints.map(point => ({
            title: getName(response, index, select.split('/')[1]),
            subject_id: response.results[index].uid,
            area: response.results[index].area,
            points: point.points,
            group_number: point.group_number || '',
            index,
            attributes: point.attributes,
            uid: point.uid,
          })),
        )
      }
      return []
    }, []),
    name: select.split('/')[1],
    type: select,
  }

  console.log('pointsObj', pointsObj)
  yield put(exampleActions.simpleSearchNewSpatials(pointsObj))
}

export function* fetchSearch(action) {
  yield fetchCount(action)
  const { select, params } = action.payload
  const { response, error } = yield call(getSearchList, select, params)

  if (response) {
    console.log('fetchSearch', response, select)
    response.type = select
    if (select !== 'subjects/Heritage') {
      yield put(exampleActions.simpleSearchSuccess(response))
    } else {
      yield put(
        exampleActions.simpleSearchSuccess({
          results: response,
          count: response.length,
          type: 'heritage',
        }),
      )
    }
    console.log('fetchSearchSUC', response, select)

    if (select === 'research') {
      yield fetchReseachSpatials(response)
    } else if (
      select.split('/')[0] === 'subjects' ||
      select.split('/')[0] === 'linguistic'
    ) {
      yield fetchSubjectSpatials(response, select)
    }
  } else {
    yield put(exampleActions.simpleSearchFailed())
  }
  console.log('fetchSearchEND', response, select)
}

export function* fetchSearchGraphql(action) {
  console.log('fetchSearchGraphql', action.payload)
  yield put(exampleActions.simpleSearchStart())
  const { q, queryType, objLink } = action.payload.query
  const { response, error } = yield call(graphqlPost, {
    query: q,
    vars: action.payload.vars,
  })

  if (response) {
    console.log('fetchSearchGraphql2', response)
    yield put(
      exampleActions.simpleSearchGraphqlSuccess({
        data: response.data,
        queryType,
        objLink,
      }),
    )
  } else {
    console.log('fetchSearchGraphql error', error)
  }
}

function* watchUserAccountActions() {
  yield takeLatest(exampleConstants.SIMPLE_SEARCH, fetchSearch)
  yield takeLatest(exampleConstants.SIMPLE_SEARCH_GRAPHQL, fetchSearchGraphql)
}

export const searchSaga = [fork(watchUserAccountActions)]
