/**
 * @flow
 * @relayHash 4b113b6474535e380f845f7d40671f74
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createSelectionIT = {
  name: string,
  description?: ?string,
  query: any,
  uids?: ?$ReadOnlyArray<?any>,
  stickedUids?: ?$ReadOnlyArray<?any>,
  resultCount: number,
  polygonBounds?: ?$ReadOnlyArray<?$ReadOnlyArray<?number>>,
};
export type SaveModalMutationVariables = {|
  data: createSelectionIT
|};
export type SaveModalMutationResponse = {|
  +saveSelection: ?{|
    +uid: ?string,
    +description: ?string,
    +model: ?string,
    +name: ?string,
  |}
|};
export type SaveModalMutation = {|
  variables: SaveModalMutationVariables,
  response: SaveModalMutationResponse,
|};
*/


/*
mutation SaveModalMutation(
  $data: createSelectionIT!
) {
  saveSelection(data: $data) {
    uid
    description
    model
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "createSelectionIT!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "data",
    "type": "createSelectionIT!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "model",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveModalMutation",
  "id": null,
  "text": "mutation SaveModalMutation(\n  $data: createSelectionIT!\n) {\n  saveSelection(data: $data) {\n    uid\n    description\n    model\n    name\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveModalMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "saveSelection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveModalMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "saveSelection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '34a46441f740ec9f06e9f05ce7892a0c';
module.exports = node;
