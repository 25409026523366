import React from 'react'
import i18n from 'i18next';
import { url, GRADATION_TYPE } from 'utility/constants/index';
import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  getDistinctNameArray,
  getCultures,
  sortLayerByAttributeEpoch,
} from 'utility/transformData/constants';
import { getLayer } from 'distLibs/images/monTypes/index';
import { getMapIconSrc } from 'components/MapIcon'

export default function getLayerFields(d, tabType, name = '') {
  // console.info('layer field', d);
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      const interpretationNameArray = getDistinctNameArray(item.interpretations);
      const result = [
        createCommonField('name', interpretationNameArray.map(title => (<div>{title}</div>))),
        createCommonField('gradation_type', d.gradation_type ? GRADATION_TYPE[d.gradation_type] : '-'),
      ];
      if (item.site) {
        result.push(
          createLinkField('site_name', item.site.uid, 'subjects/Site', getLangTitle(item.site))
        )
      }
      if (d.culture && d.culture.length > 0) {
        result.push(
          createCommonField('culture', getCultures(d.culture)),
        )
      }
      if (item.attributes.Epoch) {
        result.push(
          createCommonField('epoch', getLangTitle(item.attributes.Epoch[0])),
        )
      }
      if (item.attributes.SiteType) {
        result.push(
          createCommonField('siteType', getLangTitle(item.attributes.SiteType[0]))
        )
      }
      if (item.excavated_area) {
        result.push(
          createCommonField('excavated_area', item.excavated_area)
        )
      }
      return result;
    }
    case TAB_TYPE.COLLAPSE: {
      const array = d.response.results || d.response;
      array.sort(sortLayerByAttributeEpoch)
      return array.map((item, index) => {
        const itemObj = {}
        let layerName = name;
        let image = null;
        itemObj.content = []
        // todo rewrite api!
        if (item.interpretation) {
          const interpretation = item.interpretation[0] ?
            item.interpretation[0] : item.interpretation;
          // itemObj.content.push(
          //   createCommonField('description', getLangTitle(interpretation, 'desc'))
          // )
          if (item.attributes) {
            item.attributes.forEach((attribute, attrIndex) => {
              // todo item.type[1] hack
              itemObj.content.push(
                createCommonField(attribute.type, getLangTitle(attribute)),
              )
            })
            image = getMapIconSrc(getLayer(item))
          }
        } else if (item.interpretations) {
          // itemObj.content.push(
          //   createCommonField('description', getLangTitle(item.interpretations[0], 'desc')),
          // )
          let epoch = {};
          let siteType = {};
          if (item.attributes.Epoch) {
            epoch = item.attributes.Epoch[0] || {}
            itemObj.content.push(
              createCommonField('epoch', getLangTitle(item.attributes.Epoch[0])),
            )
            layerName = `${getLangTitle(item.interpretations[0])}`
          }
          if (item.attributes.SiteType) {
            siteType = item.attributes.SiteType[0] || {}
            itemObj.content.push(
              createCommonField('siteType', getLangTitle(item.attributes.SiteType[0]))
            )
          }
          // Todo: https://culturetat.info/api/subjects/Layer/list/
          const copyOfItem = { ...item };
          epoch.type = 'Epoch';
          siteType.type = 'SiteType';
          copyOfItem.attributes = [epoch, siteType];
          image = getMapIconSrc(getLayer(copyOfItem))
        } else if (item.attributes) {
          item.attributes.forEach((attribute, attrIndex) => {
            // todo item.type[1] hack
            itemObj.content.push(
              createCommonField(attribute.type, getLangTitle(attribute)),
            )
          })
          image = getMapIconSrc(getLayer(item))
          layerName = getLangTitle(item.research)
        }
        itemObj.content.unshift(
          createLinkField('name', item.layer || item.uid, 'subjects/Layer', layerName),
        )
        const layerLabel = {
          ru: 'слой',
          en: 'layer',
        }
        itemObj.name = (<span>
          {image && <img src={image} alt="layer" />}
          {`${index + 1} ${layerLabel[i18n.language] || layerLabel.ru}. ${layerName} `}
        </span>);

        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
