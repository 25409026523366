/**
 * @flow
 * @relayHash ceb779949d4fa3ed5efe744db4776512
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type editAddToSelectionMutationVariables = {|
  selectionUid: any,
  subjectUid?: ?$ReadOnlyArray<?any>,
|};
export type editAddToSelectionMutationResponse = {|
  +updateSelection: ?{|
    +uid: ?string,
    +stickedUids: ?$ReadOnlyArray<?string>,
  |}
|};
export type editAddToSelectionMutation = {|
  variables: editAddToSelectionMutationVariables,
  response: editAddToSelectionMutationResponse,
|};
*/


/*
mutation editAddToSelectionMutation(
  $selectionUid: UUID!
  $subjectUid: [UUID]
) {
  updateSelection(uid: $selectionUid, addStickedUids: $subjectUid) {
    uid
    stickedUids
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "selectionUid",
    "type": "UUID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "subjectUid",
    "type": "[UUID]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "addStickedUids",
    "variableName": "subjectUid",
    "type": "[UUID]"
  },
  {
    "kind": "Variable",
    "name": "uid",
    "variableName": "selectionUid",
    "type": "UUID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "stickedUids",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "editAddToSelectionMutation",
  "id": null,
  "text": "mutation editAddToSelectionMutation(\n  $selectionUid: UUID!\n  $subjectUid: [UUID]\n) {\n  updateSelection(uid: $selectionUid, addStickedUids: $subjectUid) {\n    uid\n    stickedUids\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "editAddToSelectionMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSelection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "editAddToSelectionMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSelection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54bbeafe748edd0c8995bfdc9a6dd0ff';
module.exports = node;
