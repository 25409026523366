import React, { Component } from 'react';
import { Form, Upload, Icon, Input, InputNumber, Select } from 'antd';
import { getPropertyByNameString } from 'utility/transformData/constants';
import { url } from 'utility/constants'
import * as request from 'utility/request';
import { getRenderTitle } from 'components/GSearchPanel/Results/renderTitle'
import { message } from 'antd/lib/index';
import { connect } from 'react-redux';

const FormItem = Form.Item;

const mapStateToProps = state => ({
  researchAuthor: state.create.researchAuthor,
  researchYear: state.create.researchYear,
});

@connect(mapStateToProps, {})
class FileUpload extends Component {
  beforeUpload = (file) => {
    const { t, fileType } = this.props;

    console.log('beforeUpload', file)

    if (fileType === 'source' || fileType === 'officialDocument') {
      const isJPGorPDF = file.type === 'application/pdf';
      if (!isJPGorPDF) {
        message.error(t('create_panel.modal.addNewReport.file.error_type'));
      }
      const isLt2M = file.size / 1024 / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error(t('create_panel.modal.addNewReport.file.error_size'));
      }
      return isJPGorPDF && isLt2M;
    } else {
      const isJPGorPNG = file.type === 'image/png';
      const isJPGorJPG = file.type === 'image/jpeg';
      if (!isJPGorPNG && !isJPGorJPG) {
        message.error(t('create_panel.modal.addNewReport.file.error_type_img'));
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        message.error(t('create_panel.modal.addNewReport.file.error_size_10'));
      }
      return (isJPGorPNG || isJPGorJPG) && isLt10M;
    }
  }

  uploadFile = (e) => {
    console.log('uploadFile subject', e)
  }

  retrieveImageFromClipboardAsBase64 = (pasteEvent) => {
    if (pasteEvent.clipboardData === false || pasteEvent.clipboardData.items === false){
      return
    }

    const items = pasteEvent.clipboardData.items;
    const { filelistChanged, fileLists, label } = this.props;

    console.log('getImage', fileLists)
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") === -1) continue;

      const blob = items[i].getAsFile();
      console.log('blob', blob)

      if (!fileLists[label]) {
        fileLists[label] = { fileList: [] }
      }

      const newFile = {
        lastModifed: blob.lastModified,
        name: blob.name,
        originFileObj: blob,
        percent: 0,
        size: blob.size,
        status: 'uploading',
        type: blob.type,
        uid: blob.lastModified,
      }

      fileLists[label].fileList.push(newFile)
      fileLists[label].file = newFile

      console.log('getImage2', fileLists)

      filelistChanged({ list: fileLists[label], label })

      this.forceUpdate()
    }
  }

  valueFormatter = () => {
    return ''
  }

  render() {
    const {
      getFieldDecorator,
      formInputLayout,
      filelistChanged,
      dontNeedBufferField,
      fileLists,
      label,
      fileType,
      title,
      researchAuthor,
      researchYear,
      t,
    } = this.props;

    const fileList = (fileLists[label]) ? fileLists[label].fileList : [];
    console.log('fileList upload', fileLists, fileList)
    return (
      <div>
        <FormItem
          {...formInputLayout}
          label={title}
        >
          {getFieldDecorator(label, {
          })(
            <Upload.Dragger
              name="files"
              onChange={(list) => {
                console.log('upload dragger', list)
                filelistChanged({list, label})
              }}
              listType="picture"
              beforeUpload={this.beforeUpload}
              customRequest={this.uploadFile}
              fileList={fileList}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">{t('common.hints.file.drag_drop')}</p>
            </Upload.Dragger>)}
        </FormItem>
        {!dontNeedBufferField &&
          <FormItem
            {...formInputLayout}
            wrapperCol={{ span: 10, offset: 8 }}
            label={``}
          >
            {getFieldDecorator(`${label}_buffer`, {
              getValueFromEvent: this.valueFormatter,
            })(
              <Input
                onPaste={this.retrieveImageFromClipboardAsBase64}
                placeholder={t('common.photo.buffer')}
              />)}
          </FormItem>
        }
        {fileType === 'photo' &&
          <div>
            {fileList.map((file, id) => (
              <div className={"file-upload-fields"}>
                <FormItem
                  {...formInputLayout}
                  label={`${t('common.photo.year')} ${id + 1}`}
                >
                  {getFieldDecorator(`${label}_year_${id}`, {initialValue: researchYear})(
                    <InputNumber min={-4000} max={new Date().getFullYear()} />)}
                </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={`${t('common.photo.author')} ${id + 1}`}
                >
                  {getFieldDecorator(`${label}_author_${id}`, {initialValue: researchAuthor})(
                    <Input />)}
                </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={`${t('common.photo.name')} ${id + 1}`}
                >
                  {getFieldDecorator(`${label}_name_${id}`)(
                    <Input />)}
                </FormItem>
              </div>
            ))}
          </div>
        }
        {fileType === 'officialDocument' &&
          <div>
            {fileList.map((file, id) => (
              <div>
                <FormItem
                  {...formInputLayout}
                  label={`${t('common.official_document.name')} ${id + 1}`}
                >
                  {getFieldDecorator(`${label}_name_${id}`)(
                    <Input />)}
                </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={`${t('common.official_document.number')} ${id + 1}`}
                >
                  {getFieldDecorator(`${label}_number_${id}`)(
                    <Input />)}
                </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={t('common.official_document.type.title')}
                >
                  {getFieldDecorator(`${label}_type_${id}`, {})(
                    <Select>
                      <Option value={0}>{t('common.official_document.type.0')}</Option>
                      <Option value={1}>{t('common.official_document.type.1')}</Option>
                      <Option value={2}>{t('common.official_document.type.2')}</Option>
                      <Option value={3}>{t('common.official_document.type.3')}</Option>
                    </Select>)}
                </FormItem>
              </div>
            ))}
          </div>
        }
      </div>
    );
  }
}

export default FileUpload;
