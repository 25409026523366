import React from 'react'
import {
 Spin, Icon, Popover, Divider, Tag
} from 'antd'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import CustomPanel from 'components/Custom/Panel'
import CustomCollapse from 'components/Custom/Collapse'

import './index.scss'

const mapStateToProps = state => ({
  user: state.user,
  loading: state.loading,
})

const antIcon = <Icon type="loading" style={{ fontSize: 20 }} spin />

const warningIcon = (
  <Icon type="warning" theme="twoTone" twoToneColor="#fa8c16" />
)
const successIcon = (
  <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
)
const errorIcon = (
  <Icon type="close-circle" theme="twoTone" twoToneColor="#f5222d" />
)
const loadingIcon = <Spin size="small" />

const ICONS = {
  warning: warningIcon,
  success: successIcon,
  error: errorIcon,
  loading: loadingIcon,
}

const STATUS_LIST = {
  warning: 'warning',
  success: 'success',
  error: 'error',
  loading: 'loading',
}

const formatError = error => {
  const path = error.path || error.locations || []
  return `${error.message} (${path.map(item => JSON.stringify(item))})`
}

const isAdmin = user => user && user.role === 'ad'

const LoadingSubjectContent = props => {
  const { t, loading, user } = props

  const renderItem = (item, label) => (
    <div className="loading-subject-list-item" key={item.title}>
      <span>{t(`tab.${item.title.toLowerCase()}`)}</span>
      {ICONS[label]}
    </div>
  )

  const renderItems = label => {
    const result = []
    loading[label].forEach(item => result.push(renderItem(item, label)))
    return result
  }

  const renderItemsError = label => {
    const result = []
    loading[label].forEach((item, index) =>
      result.push(
        <CustomPanel title={renderItem(item, label)} key={index}>
          {item.data &&
            item.data.map((err, key) => (
              <Tag color="red" key={key}>
                {formatError(err)}
              </Tag>
            ))}
        </CustomPanel>,
      ),
    )
    return result
  }

  const renderList = label => {
    if (loading[label].size === 0) return null
    return (
      <div className="loading-subject-list">
        <Divider>{t(`loading.list.titles.${label}`)}</Divider>
        {renderItems(label)}
      </div>
    )
  }

  const renderErrorList = () => {
    if (loading[STATUS_LIST.error].size === 0) return null
    return (
      <div className="loading-subject-list">
        <Divider>{t('loading.list.titles.error')}</Divider>
        <CustomCollapse>{renderItemsError(STATUS_LIST.error)}</CustomCollapse>
      </div>
    )
  }
  console.log('ADMIN', isAdmin(user), user)
  return (
    <div className="loading-subject-content">
      {renderList(STATUS_LIST.loading)}
      {renderList(STATUS_LIST.success)}
      {isAdmin(user) ? renderErrorList() : renderList(STATUS_LIST.error)}
    </div>
  )
}

const LoadingSubjectTitle = props => (
  <div className="loading-subject-title">{props.t('loading.list.title')}</div>
)

const renderStatus = status => {
  switch (status) {
    case STATUS_LIST.success:
      return successIcon
    case STATUS_LIST.warning:
      return warningIcon
    // case STATUS_LIST.error:
    //   return errorIcon
    case STATUS_LIST.loading:
    default:
      return <Spin indicator={antIcon} />
  }
}

const LoadingSubject = props => {
  const { t, loading } = props

  if (!loading.isStarted) return null

  return (
    <div className="loading-subject">
      <Popover
        content={<LoadingSubjectContent {...props} />}
        title={<LoadingSubjectTitle t={t} />}
        overlayClassName="loading-subject-popover"
        placement="bottomRight"
        trigger="click"
      >
        {renderStatus(loading.status)}
      </Popover>
    </div>
  )
}

export default translate()(
  connect(
    mapStateToProps,
    null,
  )(LoadingSubject),
)
