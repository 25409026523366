/**
 * @flow
 * @relayHash 387f1d7048cd9739e9df0de2011b9f5a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelfAddSelectionQueryVariables = {|
  subjectId: string
|};
export type SelfAddSelectionQueryResponse = {|
  +selection: ?{|
    +creator: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +uid: ?string
        |}
      |}>
    |},
    +query: ?any,
    +model: ?string,
    +uids: ?$ReadOnlyArray<?string>,
    +name: ?string,
    +uid: ?string,
  |}
|};
export type SelfAddSelectionQuery = {|
  variables: SelfAddSelectionQueryVariables,
  response: SelfAddSelectionQueryResponse,
|};
*/


/*
query SelfAddSelectionQuery(
  $subjectId: ID!
) {
  selection(id: $subjectId) {
    creator {
      edges {
        node {
          uid
          id
        }
      }
    }
    query
    model
    uids
    name
    uid
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "subjectId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subjectId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "query",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "model",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uids",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SelfAddSelectionQuery",
  "id": null,
  "text": "query SelfAddSelectionQuery(\n  $subjectId: ID!\n) {\n  selection(id: $subjectId) {\n    creator {\n      edges {\n        node {\n          uid\n          id\n        }\n      }\n    }\n    query\n    model\n    uids\n    name\n    uid\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SelfAddSelectionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "selection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "creator",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorSearchNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AuthorSearchNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorSearchNode",
                    "plural": false,
                    "selections": [
                      v2
                    ]
                  }
                ]
              }
            ]
          },
          v3,
          v4,
          v5,
          v6,
          v2
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SelfAddSelectionQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "selection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "creator",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorSearchNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AuthorSearchNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorSearchNode",
                    "plural": false,
                    "selections": [
                      v2,
                      v7
                    ]
                  }
                ]
              }
            ]
          },
          v3,
          v4,
          v5,
          v6,
          v2,
          v7
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5a898b3f2980ba6d1c8c81475629649b';
module.exports = node;
