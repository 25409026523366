/**
 * @flow
 * @relayHash c321aa8ba775b6b779a3acfae0a32d64
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessObjectEnum = "PRIVATE" | "PUBLIC" | "SHARED" | "%future added value";
export type ResearchQueryVariables = {||};
export type ResearchQueryResponse = {|
  +me: ?{|
    +writtenResearches: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +uid: ?string,
          +label: ?string,
          +ruName: ?string,
          +enName: ?string,
          +localName: ?string,
          +year: ?number,
          +permission: ?AccessObjectEnum,
          +type: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +ruName: ?string,
                +enName: ?string,
                +localName: ?string,
                +sort: ?number,
              |}
            |}>
          |},
        |}
      |}>
    |},
    +researches: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +uid: ?string,
          +label: ?string,
          +ruName: ?string,
          +enName: ?string,
          +localName: ?string,
          +year: ?number,
          +permission: ?AccessObjectEnum,
          +type: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +ruName: ?string,
                +enName: ?string,
                +localName: ?string,
                +sort: ?number,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |}
|};
export type ResearchQuery = {|
  variables: ResearchQueryVariables,
  response: ResearchQueryResponse,
|};
*/


/*
query ResearchQuery {
  me {
    writtenResearches {
      edges {
        node {
          uid
          label
          ruName
          enName
          localName
          year
          permission
          type {
            edges {
              node {
                ruName
                enName
                localName
                sort
                id
              }
            }
          }
          id
        }
      }
    }
    researches {
      edges {
        node {
          uid
          label
          ruName
          enName
          localName
          year
          permission
          type {
            edges {
              node {
                ruName
                enName
                localName
                sort
                id
              }
            }
          }
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ruName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "localName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "year",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permission",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sort",
  "args": null,
  "storageKey": null
},
v8 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "ResearchNodeEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "ResearchNode",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "type",
            "storageKey": null,
            "args": null,
            "concreteType": "ResearchTypeNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ResearchTypeNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ResearchTypeNode",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      v4,
                      v7
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v10 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "ResearchNodeEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "ResearchNode",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "type",
            "storageKey": null,
            "args": null,
            "concreteType": "ResearchTypeNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ResearchTypeNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ResearchTypeNode",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      v4,
                      v7,
                      v9
                    ]
                  }
                ]
              }
            ]
          },
          v9
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ResearchQuery",
  "id": null,
  "text": "query ResearchQuery {\n  me {\n    writtenResearches {\n      edges {\n        node {\n          uid\n          label\n          ruName\n          enName\n          localName\n          year\n          permission\n          type {\n            edges {\n              node {\n                ruName\n                enName\n                localName\n                sort\n                id\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    researches {\n      edges {\n        node {\n          uid\n          label\n          ruName\n          enName\n          localName\n          year\n          permission\n          type {\n            edges {\n              node {\n                ruName\n                enName\n                localName\n                sort\n                id\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ResearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "writtenResearches",
            "storageKey": null,
            "args": null,
            "concreteType": "ResearchNodeConnection",
            "plural": false,
            "selections": v8
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "researches",
            "storageKey": null,
            "args": null,
            "concreteType": "ResearchNodeConnection",
            "plural": false,
            "selections": v8
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ResearchQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "writtenResearches",
            "storageKey": null,
            "args": null,
            "concreteType": "ResearchNodeConnection",
            "plural": false,
            "selections": v10
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "researches",
            "storageKey": null,
            "args": null,
            "concreteType": "ResearchNodeConnection",
            "plural": false,
            "selections": v10
          },
          v9
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1603f0668a20132511e7d26c79cec83a';
module.exports = node;
