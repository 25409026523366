import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
 Button, Table, Empty, message, Popconfirm 
} from 'antd'
import CustomPanel from 'components/Custom/Panel'
import CustomCollapse from 'components/Custom/Collapse'
import { getLangTitleGraph } from 'utility/transformData/constants'
import { deleteSource } from 'redux/api/source'

// import './index.scss'

const sortAuthor = (a, b) => {
  const first = (a.author && a.author[0] && a.author[0].publicName) || ''
  const second = (b.author && b.author[0] && b.author[0].publicName) || ''
  return first.localeCompare(second)
}

const ProfileTabSourceTable = props => {
  const { t, data, type, isLoading } = props
  const currentData = data ? data.get(type) : new Map()
  const [filtered, setFiltered] = useState([])

  const removeItem = record => {
    deleteSource(record.uid).then(({ response, error }) => {
      if (!error) {
        setFiltered([...filtered, record.uid])
        message.success(t('selection.delete.message'))
      }
    })
  }

  const COLUMNS = [
    {
      title: t('profile.research.title'),
      dataIndex: 'ru_name',
      key: 'title',
      sorter: (a, b) =>
        getLangTitleGraph(a).localeCompare(getLangTitleGraph(b)),
      render: (text, record) => (
        <Link
          key={record.uid}
          to={`/show/source/${record.label}/${record.uid}/`}
        >
          {getLangTitleGraph(record) || '-'}
        </Link>
      ),
    },
    {
      title: t('profile.research.author'),
      dataIndex: 'author',
      sorter: sortAuthor,
      key: 'author',
      render: text => {
        console.log(text)
        if (!text) return null
        return text.map(item => (
          <Link key={item.uid} to={`/show/author/${item.uid}/`}>
            {item.publicName}
          </Link>
        ))
      },
    },
    {
      title: t('profile.research.coauthor'),
      dataIndex: 'coauthors',
      key: 'coauthors',
      render: text => {
        console.log(text)
        if (!text) return null
        return text.map(item => (
          <Link key={item.uid} to={`/show/author/${item.uid}/`}>
            {item.publicName}
          </Link>
        ))
      },
    },
    {
      title: t('profile.research.year'),
      dataIndex: 'year',
      sorter: (a, b) => a.year - b.year,
      key: 'year',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Popconfirm
            title={t('selection.delete.title')}
            onConfirm={() => removeItem(record)}
            okText={t('selection.delete.ok')}
            cancelText={t('selection.delete.cancel')}
          >
            <Button>{t('selection.table.delete')}</Button>
          </Popconfirm>
        </span>
      ),
    },
  ]

  const renderPanels = () => {
    const result = []
    currentData.forEach((value, key) => {
      console.log(value)
      result.push(
        <CustomPanel
          count={value.length}
          title={t(`profile.tabs.${key}`)}
          key={key}
        >
          <Table
            loading={isLoading}
            columns={COLUMNS}
            dataSource={value.filter(item => !filtered.includes(item.uid))}
            rowKey="uid"
          />
        </CustomPanel>,
      )
    })
    return result
  }

  if (!isLoading && (!currentData || currentData.size === 0)) {
    return <Empty />
  }

  return (
    <div>
      <CustomCollapse>{renderPanels()}</CustomCollapse>
    </div>
  )
}

export default ProfileTabSourceTable
