import * as request from '../../utility/request';

const queryType = {
  'linguistic/variant/search': 'GET',
}

export const getSearchCount = async (select, data) => {
  const path = `api/${select}/list/count/`;
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}

export const getSearchList = async (select, data) => {
  const qType = queryType[select] || 'POST'
  console.log('search', data)

  if (qType === 'GET') {
    const params = Object.keys(data).map(key => {
      switch (key) {
        case '__limit__':
          return `${'limit'}=${data[key]}`;
        case '__offset__':
          return `${'offset'}=${data[key]}`;
        default:
          return `${key}=${data[key]}`
      }
    })
    const path = `api/${select}/list/?${params.join('&')}`;
    console.log('path', path)
    const { response, error } = await request.safelyGet(path);
    return { response, error };
  }

  const path = `api/${select}/list/`;
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}
