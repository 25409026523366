import { Component } from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';

import { actions } from '../redux/modules/utils';

const mapDispatchToProps = {
  handlePageChange: actions.pageChange,
};

@connect(undefined, mapDispatchToProps)
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
      this.props.handlePageChange();
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
