import React from 'react';
import { SelectionAccess } from 'views/showSelection'
import {
  Spin,
  Modal,
} from 'antd';
import { graphql } from 'relay-runtime'
import useDataApi from 'utility/fetchGraphHook'

// import './index.scss'

const QUERY = graphql`
query ModalPermissionQuery($researchId: ID!) {
  archaeologyResearch(id: $researchId) {
    uid
    permission
    sharedUsers {
      uid
      publicName
    }
  }
}
`

const ProfileTabResearchModal = (props) => {
  const {
    t,
    uid,
  } = props;

  const variables = {
    researchId: `ArchaeologyResearchNode:${uid}`,
  }
  const { data, isLoading, isError } = useDataApi(QUERY, variables);

  if (isLoading) {
    return (<Spin size="large" />)
  }
  const {
    permission,
    sharedUsers,
  } = data.archaeologyResearch || {}
  return (
    <Modal
      title={t('profile.research.access-edit')}
      visible
      onCancel={props.handleClose}
      footer={[]}
    >
      <SelectionAccess
        uid={uid}
        t={t}
        sharedUsers={sharedUsers}
        permission={permission}
        model="RESEARCH"
      />
    </Modal>
  )
}

export default ProfileTabResearchModal
