import React from 'react'
import { withFormik } from 'formik'
import { Button, Form, Select, Checkbox } from 'antd'

import '../index.scss'
import { transformSelectToQuery } from '../Queries/queries'
import { attributeQueries } from '../Queries/attributeQueries'
import {
  defaultKnowledgeType,
  knowledgeTypes,
  knowledgeTypesSpatials,
  TypePolygonSearch,
} from './searchOptions'
import { commonInput, commonSelect, numberInput } from '../components'
import { generateConfig } from './searchFormLayers.py'
import { getFirstLastElements } from '../Pagination/utils'

const FormItem = Form.Item

const inputs = {
  common: commonInput,
  number: numberInput,
  select: commonSelect,
}

function RenderFormComponent(props) {
  const { searchType, t, optionMap } = props
  console.log('RenderFormComponent', searchType)
  const FComponent = optionMap[searchType].map(field => {
    const params = { ...field }
    if (field.type === 'select') {
      params.selectQuery = attributeQueries.defaultSearch(params.selectType)
    }
    return { field: inputs[field.type], params }
  })
  return (
    <div>
      {FComponent.map((field, index) => (
        <field.field
          initialValue={index === 0 ? props.search : ''}
          key={field.params.inputName}
          text={props.searchType}
          getFieldDecorator={props.getFieldDecorator}
          formLayout={props.formItemLayout}
          title={field.params.title}
          inputName={field.params.inputName}
          inputType={field.params.inputType}
          selectQuery={field.params.selectQuery}
          searchType={searchType}
          t={t}
        />
      ))}
    </div>
  )
}

const InnerForm = ({
  values,
  touched,
  errors,
  handleChangeKnowledgeSelect,
  handleChangeSelectSearchObject,
  handleChangeResult,
  typeOptions,
  handleSubmit,
  handleClean,
  handleChange,
  handleBlur,
  isSubmitting,
  isError,
  searchPolygon,
  searchType,
  search,
  form,
  t,
  handleChangeSelect,
  knowledgeType,
}) => {
  const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }

  let objectTypes = []
  let isKnowledgeTypeShow = true
  const { getFieldDecorator } = form
  knowledgeType = knowledgeType || defaultKnowledgeType
  const knowledges = Object.keys(knowledgeTypes)
  let optionMap = knowledgeTypes[knowledgeType]
  if (typeOptions) {
    isKnowledgeTypeShow = false
    objectTypes = typeOptions
    optionMap = knowledgeTypes.linguistics
  } else {
    objectTypes = Object.keys(optionMap)
  }
  searchType = searchType || objectTypes[0]
  // generate config params for form
  const config = generateConfig({
    knowledgeType,
    searchType,
    t,
    objectTypes,
  })

  // sort
  objectTypes = objectTypes.sort((a, b) => {
    return t(`search_panel.form.select.options.${a}`).localeCompare(
      t(`search_panel.form.select.options.${b}`),
    )
  })

  return (
    <Form onSubmit={handleSubmit} className="search-form">
      {isKnowledgeTypeShow ? (
        <FormItem
          label={t('search_panel.form.knowledge_type.label')}
          {...formItemLayout}
        >
          {getFieldDecorator('select_knowledge', config.selectKnowledge)(
            <Select
              onChange={value =>
                handleChangeKnowledgeSelect(
                  value,
                  form,
                  Object.keys(knowledgeTypes[value])[0],
                )
              }
            >
              {knowledges.map((item, index) => (
                <Select.Option
                  value={`${item}`}
                  key={`knowledge_option_${index}`}
                >
                  {t(`knowledge.${item}`)}
                </Select.Option>
              ))}
            </Select>,
          )}
        </FormItem>
      ) : null}
      <FormItem label={t('search_panel.form.select.label')} {...formItemLayout}>
        {getFieldDecorator('select_object', config.selectObject)(
          <Select
            placeholder={t('search_panel.form.select.placeholder')}
            onChange={handleChangeSelectSearchObject}
          >
            {objectTypes.map(item => (
              <Select.Option value={item} key={item}>
                {t(`search_panel.form.select.options.${item}`)}
              </Select.Option>
            ))}
          </Select>,
        )}
      </FormItem>

      <RenderFormComponent
        knowledgeType={knowledgeType}
        searchType={searchType}
        search={search === null ? '' : search}
        optionMap={optionMap}
        getFieldDecorator={getFieldDecorator}
        formItemLayout={formItemLayout}
        t={t}
      />

      <FormItem
        label={t('search_panel.form.select.spatialSearch.label')}
        {...formItemLayout}
      >
        {getFieldDecorator('spatialSearch', { initialValue: false })(
          <Checkbox disabled={!TypePolygonSearch.includes(searchType)} />,
        )}
      </FormItem>

      <FormItem wrapperCol={{ span: 6, offset: 9 }}>
        <Button
          type="primary"
          htmlType="submit"
          className="search-form-button"
          disabled={isSubmitting}
        >
          {t('search_panel.form.submit')}
        </Button>
      </FormItem>
    </Form>
  )
}

// Wrap our form with the using withFormik HoC
const Index = Form.create()(
  withFormik({
    handleSubmit: (values, { props, setSubmitting }) => {
      values.form.validateFields((err, fieldsValue) => {
        if (err) {
          setSubmitting(false)
          return
        }

        const params = {}
        Object.keys(fieldsValue).forEach(key => {
          if (
            !key.includes('select') &&
            fieldsValue[key] &&
            fieldsValue[key] !== -1
          ) {
            console.log('fl', key, fieldsValue[key])
            params[key] = fieldsValue[key] || ''
          }
        })
        const pageProps = getFirstLastElements(props.pageData)
        params.first = pageProps.first
        params.last = pageProps.last
        params.searchPolygon = props.searchPolygon
        const { spatialSearch } = params.searchPolygon.length
          ? fieldsValue
          : false

        const queryObject = transformSelectToQuery(
          fieldsValue.select_object,
          spatialSearch,
        )
        const body = {
          params,
          newQuery: true,
          query: queryObject.query,
          fields: queryObject.fields,
          url: queryObject.url,
        }
        props.handleClean()
        props.handleSubmit(body)
        setTimeout(setSubmitting(false), 1000)
      })
    },
  })(InnerForm),
)

export default Index
