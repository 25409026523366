/**
 * @flow
 * @relayHash 5489f7afd7301fd9f45be4d3f243441d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelfResearchQueryVariables = {|
  subjectId: string
|};
export type SelfResearchQueryResponse = {|
  +author: ?{|
    +writtenResearches: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +type: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +enName: ?string
              |}
            |}>
          |},
          +label: ?string,
          +ruName: ?string,
          +uid: ?string,
        |}
      |}>
    |}
  |}
|};
export type SelfResearchQuery = {|
  variables: SelfResearchQueryVariables,
  response: SelfResearchQueryResponse,
|};
*/


/*
query SelfResearchQuery(
  $subjectId: ID!
) {
  author(id: $subjectId) {
    writtenResearches {
      edges {
        node {
          type {
            edges {
              node {
                enName
                id
              }
            }
          }
          label
          ruName
          uid
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "subjectId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subjectId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ruName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SelfResearchQuery",
  "id": null,
  "text": "query SelfResearchQuery(\n  $subjectId: ID!\n) {\n  author(id: $subjectId) {\n    writtenResearches {\n      edges {\n        node {\n          type {\n            edges {\n              node {\n                enName\n                id\n              }\n            }\n          }\n          label\n          ruName\n          uid\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SelfResearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "author",
        "storageKey": null,
        "args": v1,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "writtenResearches",
            "storageKey": null,
            "args": null,
            "concreteType": "ResearchNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ResearchNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ResearchNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "type",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ResearchTypeNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ResearchTypeNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ResearchTypeNode",
                                "plural": false,
                                "selections": [
                                  v2
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      v3,
                      v4,
                      v5
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SelfResearchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "author",
        "storageKey": null,
        "args": v1,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "writtenResearches",
            "storageKey": null,
            "args": null,
            "concreteType": "ResearchNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ResearchNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ResearchNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "type",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ResearchTypeNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ResearchTypeNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ResearchTypeNode",
                                "plural": false,
                                "selections": [
                                  v2,
                                  v6
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      v3,
                      v4,
                      v5,
                      v6
                    ]
                  }
                ]
              }
            ]
          },
          v6
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac1eadcdd00967a96d7da35f16e6c7d1';
module.exports = node;
