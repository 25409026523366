import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { Spin } from 'antd'
import { actions } from '../../redux/modules/example'

const DataPanel = React.lazy(() => import('components/DataPanel'))
const MapPanel = React.lazy(() => import('components/MapPanel'))
const SourcePanel = React.lazy(() => import('components/SourcePanel'))

const getDataTitle = (data = {}) => {
  if (data[0]) {
    return {
      type: data[0].subjectType,
      name: data[0].title,
    }
  }

  return data
}

const getField = (data = {}, field) => {
  if (data[0]) {
    return data[0][field]
  }
  return ''
}

const mapStateToProps = state => ({
  tabs: state.example.showPage.data,
  isLoading: state.example.showPage.isLoading,
  isError: state.example.showPage.isError,
  title: getDataTitle(state.example.showPage.data),
  mapContent: state.example.points,
  model3d: getField(state.example.showPage.data, 'model3d'),
  sourceItem: getField(state.example.showPage.data, 'sourceItem'),
  settings: getField(state.example.showPage.data, 'settings'),
  leftPanelButtons: getField(state.example.showPage.data, 'leftPanelButtons'),
  sourceFileId: getField(state.example.showPage.data, 'sourceFileId'),
  sourceFileLink: getField(state.example.showPage.data, 'sourceFileLink'),
})

const mapDispatchToProps = {
  getShowPageData: actions.getShowPageData,
}

@translate()
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class ShowView extends Component {
  componentDidMount() {
    const { objCategory, objType, objId } = this.props.match.params
    console.log(objCategory, objType, objId)
    this.props.getShowPageData({
      category: objCategory,
      type: objType,
      id: objId,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { objCategory, objType, objId } = this.props.match.params
    const { objType: objTypeOld, objId: objIdOld } = prevProps.match.params
    if (objType !== objTypeOld || objId !== objIdOld) {
      console.log('UPDATE SHOW')
      this.props.getShowPageData({
        category: objCategory,
        type: objType,
        id: objId,
      })
    }
  }

  renderSource = () => {
    const { t, sourceFileId, sourceItem, sourceFileLink } = this.props
    return (
      <SourcePanel
        sourceItem={sourceItem}
        sourceFileId={sourceFileId}
        sourceFileLink={sourceFileLink}
        t={t}
      />
    )
  }

  render() {
    const {
      t,
      title,
      tabs,
      mapContent,
      isLoading,
      isError,
      model3d,
      settings,
      leftPanelButtons,
    } = this.props
    const { objCategory } = this.props.match.params
    return (
      <div>
        <Suspense fallback={<Spin />}>
          <DataPanel
            title={title}
            tabs={tabs}
            settings={settings}
            leftPanelButtons={leftPanelButtons}
            isLoading={isLoading}
            isError={isError}
            match={this.props.match}
          />
          {objCategory === 'source' ? (
            this.renderSource()
          ) : (
            <MapPanel content={mapContent} model3d={model3d} t={t} settings={settings} />
          )}
        </Suspense>
      </div>
    )
  }
}

export default ShowView
