import React, { useState } from 'react'
import i18n from 'i18next'
import { Tree } from 'antd'
import { getPropertyByNameString } from 'utility/transformData/constants'

const { TreeNode } = Tree
const LANG = ['ru','en'].includes(i18n.language) ? i18n.language: 'local'

export const AdvancedSearchSelectFields = props => {
  const { schema, subject, t, column } = props
  const { fields = {} } = schema.get(subject.toLowerCase()) || {}

  const selectFields = props.selectedFields.filter(item => fields[item].column === column) || [];

  const onCheck = checkedKeys => {
    let commonSelectFields = props.selected || []
    let difference = Array(...new Set(commonSelectFields.filter(x => !new Set(selectFields).has(x))))
    props.selectFields({
      selected: difference.concat(checkedKeys.filter(x => x !== 'parent')),
    })
    console.log('After', props.selected)
  }

  const renderNodes = () => {
    return selectFields.map(item => (
      <TreeNode title={getPropertyByNameString(fields[item], `t.${LANG}`) || item} key={item} />
    ))
  }
  if (selectFields.length > 0) {
    return (
      <Tree
        checkable
        defaultCheckedKeys={props.selected}
        defaultExpandedKeys={['parent']}
        defaultExpandParent={ true }
        onCheck={onCheck}
      >
        <TreeNode title={t(`advanced.all_fields.${column}`)} key="parent">
          {renderNodes()}
        </TreeNode>
      </Tree>
    )
  }
  return (null)
}

export default AdvancedSearchSelectFields
