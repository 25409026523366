import React, { Component } from 'react';
import {
  LayersControl,
  Map,
  TileLayer,
} from 'react-leaflet';
import { Button } from 'antd';
import 'leaflet-editable';
import 'leaflet.path.drag';

import 'leaflet/dist/leaflet.css';
import HeatmapLayer from 'react-leaflet-heatmap-layer';

import './index.css';

const { BaseLayer } = LayersControl;

class WhereMap extends Component {
  // parentMapState from index view
  state = {
    isRectangleCreate: false,
    ...this.props.parentMapState,
  };

  lMap = React.createRef();
  rectangle = null;

  handleCreateRectangle = () => {
    this.rectangle = this.lMap.current.leafletElement.editTools.startRectangle();
    this.setState({
      isRectangleCreate: true,
    })
  }

  handleChooseArea = () => {
    this.props.handleChooseArea(this.rectangle._bounds);
  }

  render() {
    const center = [this.state.lat, this.state.lng];
    const {
      zoom,
      crs,
      minZoom,
      isRectangleCreate,
    } = this.state;
    const {
      maps,
      tempPoints,
      t,
    } = this.props;

    return [
      <div key="where_button" className="where-button-container">
        {
          isRectangleCreate ?
            <Button
              type="primary"
              icon="search"
              className="where-button"
              onClick={this.handleChooseArea}
            >
              {t('index.show_results')}
            </Button>
            :
            <Button
              type="primary"
              icon="search"
              className="where-button"
              onClick={this.handleCreateRectangle}
            >
              {t('index.select_area')}
            </Button>
        }
      </div>,
      <Map
        key="where_map"
        center={center}
        zoom={zoom}
        animate
        onBaseLayerChange={this.handleChangeCrs}
        crs={crs}
        maxZoom={19}
        minZoom={minZoom}
        ref={this.lMap}
        editable
      >
        <LayersControl position="topright">
          {maps && maps.map(item => (
            <BaseLayer
              checked={item.checked}
              name={item.name}
              key={item.name}
            >
              <TileLayer
                minZoom={item.minZoom}
                maxZoom={item.maxZoom}
                zoomOffset={item.zoomOffset || 0}
                subdomains={item.subdomains}
                attribution={item.attribution}
                url={item.url}
                key={item.url}
                crs={item.crs}
                tms={item.tms}
                center={item.center}
                {...item.options}
              />
            </BaseLayer>
          ))}
        </LayersControl>
        <HeatmapLayer
          points={tempPoints}
          longitudeExtractor={m => m.longitude}
          latitudeExtractor={m => m.latitude}
          intensityExtractor={m => 50}
        />
      </Map>,
    ]
  }
}

export default WhereMap;
