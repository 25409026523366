import { createAction, handleActions } from 'redux-actions';

const RECOVERY = 'user/RECOVERY';
const RESET = 'user/RESET';
const RECOVERY_SUCCESS = 'user/RECOVERY_SUCCESS';
const RECOVERY_FAILED = 'user/RECOVERY_FAILED';

export const constants = {
  RECOVERY,
  RESET,
  RECOVERY_SUCCESS,
  RECOVERY_FAILED,
};

// ------------------------------------
// Actions
// ------------------------------------
export const recovery = createAction(RECOVERY);
export const resetRecovery = createAction(RESET);
export const recoverySuccess = createAction(RECOVERY_SUCCESS);
export const recoveryFailed = createAction(RECOVERY_FAILED);

export const actions = {
  recovery,
  resetRecovery,
  recoverySuccess,
  recoveryFailed,
};

export const reducers = {
  [RECOVERY_SUCCESS]: state =>
    ({
      ...state,
      isSuccess: true,
      error: null,
    }),
  [RECOVERY_FAILED]: (state, { payload }) =>
    ({
      ...state,
      error: payload || 'Ошибка восстановления',
    }),
  [RESET]: state =>
    ({
      ...state,
      isSuccess: false,
      error: null,
    }),
}

export const initialState = () => {
  const state = {
    isSuccess: false,
    error: null,
  };
  return state;
}

export default handleActions(reducers, initialState());
