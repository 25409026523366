import React, { useEffect, useState } from 'react'
import {
 Table, Popconfirm, Button, message, Tag
} from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { graphql } from 'relay-runtime'
// eslint-disable-next-line import/no-cycle
import { fetchGrapqlMutation, fetchGrapqlRequest } from 'utility/request'
import { PERMISSION_COLORS } from 'utility/access'

import { useDispatch, useSelector } from 'react-redux'
import { getPropertyByNameString } from '../../../../../utility/transformData/constants'
import { RootState } from '../../../../../rootReducer'
import {
  cleanUids,
  fetchUids,
  setBody,
  setCreator,
  setCurrentResearch,
  setIsFetching,
  setLabel,
  setModel,
  setResearches,
} from './SelfSlice'
import './index.css'

const MUTATION = graphql`
  mutation SelfMutation($uid: UUID!) {
    deleteSelection(uid: $uid) {
      status
    }
  }
`

const researchQuery = graphql`
  query SelfResearchQuery($subjectId: ID!) {
    author(id: $subjectId) {
      writtenResearches {
        edges {
          node {
            type {
              edges {
                node {
                  enName
                }
              }
            }
            label
            ruName
            uid
          }
        }
      }
    }
  }
`

const nquery = graphql`
  query SelfAddSelectionQuery($subjectId: ID!) {
    selection(id: $subjectId) {
      creator {
        edges {
          node {
            uid
          }
        }
      }
      query
      model
      uids
      name
      uid
    }
  }
`

const ProfileTabSelectionSelf = props => {
  let i = 0
  const dispatch = useDispatch()
  const {
    query,
    label,
    researches,
    model,
    creator,
    uids,
    currentResearch,
    isFetching,
  } = useSelector((state: RootState) => state.selection)
  const { t, data, isLoading } = props
  const [filtered, setFiltered] = useState([])
  const [isModal, setIsModal] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [isOk, setIsOk] = useState(false)
  const [isErr, setIsErr] = useState(false)
  console.log('ISFETCHING', isFetching)
  const addSelectionToResearch = async body => {
    const token = document.cookie.split(';')
    const res = fetch(
      'https://www.anthropology-gis.com/api/add_selection_to_research/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${token[2].slice(7)}`,
        },
        // headers: {
        //   'Content-Type': 'application/json',
        //   Authorization: `token ${token[0].slice(6)}`,
        // },
        body: JSON.stringify(body),
      },
    )
    res.then(r => {
      setIsLoad(false)
      if (r.ok) {
        setIsOk(true)
      } else {
        setIsErr(true)
      }
    })
    return (await res).json()
  }
  useEffect(() => {
    let newQuery = String(query).slice(0, -1)
    newQuery = `${newQuery}, "paginate":{"first": ${'10000'},"last": ${'10000'}}}`
    console.log('QUERY', newQuery)
    dispatch(fetchUids(newQuery))
  }, [dispatch, query])
  useEffect(() => {
    if (creator) {
      const researchVars = {
        subjectId: `AuthorNode:${creator}`,
      }
      fetchGrapqlRequest(researchQuery, researchVars).then(res => {
        dispatch(setLabel(res.author.writtenResearches.edges[0].node.label))
        dispatch(setResearches(res.author.writtenResearches.edges))
      })
    }
  }, [creator, isFetching])
  useEffect(() => {
    if (currentResearch) {
      addSelectionToResearch({
        research_type: label,
        research_uid: currentResearch,
        model,
        uids,
      })
      dispatch(setCurrentResearch(''))
    }
  }, [currentResearch, dispatch, label, model, uids])
  console.log('RESEARCHES', researches)
  const removeSelection = record => {
    const variables = {
      uid: record.uid,
    }
    return fetchGrapqlMutation(MUTATION, variables, response => {
      if (response.deleteSelection) {
        setFiltered([...filtered, record.uid])
        message.success(t('selection.delete.message'))
      }
    })
  }
  const addToResearch = record => {
    console.log('RECORD', record)
    const variables = {
      subjectId: `SelectionNode:${record.uid}`,
    }
    fetchGrapqlRequest(nquery, variables).then(body => {
      dispatch(setBody(body.selection.query))
      dispatch(setCreator(body.selection.creator.edges[0].node.uid))
      dispatch(setModel(body.selection.model))
    })
  }
  const COLUMNS = [
    {
      title: t('selection.table.title'),
      dataIndex: 'name',
      key: 'title',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <Link key={record.uid} to={`/show/selection/${record.uid}`}>
          {record.name}
        </Link>
      ),
    },
    {
      title: t('selection.table.type'),
      dataIndex: 'model',
      sorter: (a, b) => a.model.localeCompare(b.model),
      key: 'type',
    },
    {
      title: t('selection.table.num'),
      dataIndex: 'resultCount',
      sorter: (a, b) => a.resultCount - b.resultCount,
      key: 'num',
    },
    {
      title: t('selection.table.created'),
      dataIndex: 'createdAt',
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      key: 'created',
      render: created => moment(created).format('LLL'),
    },
    {
      title: t('access.name'),
      dataIndex: 'permission',
      sorter: (a, b) => a.permission.localeCompare(b.permission),
      key: 'permission',
      render: permission => (
        <Tag color={PERMISSION_COLORS[permission]}>
          {t(`access.${permission}`)}
        </Tag>
      ),
    },
    {
      title: t('selection.add.title'),
      key: 'add',
      render: (text, record) => (
        <span className="modal-container">
          <Button
            onClick={() => {
              addToResearch(record)
              setIsModal(!isModal)
              dispatch(setIsFetching(true))
            }}
          >
            {t('selection.table.add')}
          </Button>
        </span>
      ),
    },
    {
      title: t('selection.delete.title'),
      key: 'action',
      render: (text, record) => (
        <span>
          <Popconfirm
            title={t('selection.delete.title')}
            onConfirm={() => removeSelection(record)}
            okText={t('selection.delete.ok')}
            cancelText={t('selection.delete.cancel')}
          >
            <Button>{t('selection.table.delete')}</Button>
          </Popconfirm>
        </span>
      ),
    },
  ]
  console.log('DATA', data)
  return (
    <>
      <Table
        loading={isLoading}
        columns={COLUMNS}
        dataSource={data.filter(item => !filtered.includes(item.uid))}
        rowKey="uid"
      />
      {isModal &&
        researches && !isFetching && (
            <div className="selection-modal">
              <div
                className="close-btn"
                title="Закрыть"
                onClick={() => {
                  setIsModal(false)
                  dispatch(setIsFetching(false))
                  dispatch(cleanUids())
                }}
              >
                X
              </div>
              {researches.length !== 0 ?
                researches.map((res, index) => {
                  return (
                    <div
                      key={index}
                      className="selection-item"
                      onClick={() => {
                        setIsLoad(true)
                        setIsModal(!isModal)
                        dispatch(setCurrentResearch(res.uid))
                        dispatch(setIsFetching(false))
                        dispatch(cleanUids())
                      }}
                    >
                      {res.name}
                    </div>
                  )
                }) : <div>У вас нет текущих исследований</div>}
            </div>
          )}
      {isLoad && (
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      {isErr && (
        <div className="isOkModal">
          <span>Произошла ошибка! Несовместимые сущности</span>
          <div
            className="confirm-btn"
            onClick={() => {
              setIsErr(false)
            }}
          >
            Подтвердить
          </div>
        </div>
      )}
      {isOk && (
        <div className="isOkModal">
          <span>Успешное добавление!</span>
          <div
            className="confirm-btn"
            onClick={() => {
              setIsOk(false)
            }}
          >
            Подтвердить
          </div>
        </div>
      )}
    </>
  )
}

export default ProfileTabSelectionSelf
