import { graphql } from 'relay-runtime'

export const ResearchSubjectsQuery = graphql`
  query createResearchSubjectsQuery($ruid: ID!) {
    research(id: $ruid) {
      subjects {
        edges {
          node {
            uid
            label
          }
        }
      }
    }
  }
`
