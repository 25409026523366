/**
 * @flow
 * @relayHash 0c124d37d685a1bf88209b426a90ea85
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateSelectionIT = {
  name?: ?string,
  description?: ?string,
  query?: ?any,
  uids?: ?$ReadOnlyArray<?any>,
  stickedUids?: ?$ReadOnlyArray<?any>,
  resultCount?: ?number,
  polygonBounds?: ?$ReadOnlyArray<?$ReadOnlyArray<?number>>,
};
export type showSelectionMutationVariables = {|
  data: updateSelectionIT,
  uid: any,
|};
export type showSelectionMutationResponse = {|
  +updateSelection: ?{|
    +uid: ?string,
    +description: ?string,
    +model: ?string,
    +name: ?string,
    +uids: ?$ReadOnlyArray<?string>,
  |}
|};
export type showSelectionMutation = {|
  variables: showSelectionMutationVariables,
  response: showSelectionMutationResponse,
|};
*/


/*
mutation showSelectionMutation(
  $data: updateSelectionIT!
  $uid: UUID!
) {
  updateSelection(data: $data, uid: $uid) {
    uid
    description
    model
    name
    uids
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "updateSelectionIT!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "uid",
    "type": "UUID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "data",
    "type": "updateSelectionIT"
  },
  {
    "kind": "Variable",
    "name": "uid",
    "variableName": "uid",
    "type": "UUID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "model",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uids",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "showSelectionMutation",
  "id": null,
  "text": "mutation showSelectionMutation(\n  $data: updateSelectionIT!\n  $uid: UUID!\n) {\n  updateSelection(data: $data, uid: $uid) {\n    uid\n    description\n    model\n    name\n    uids\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "showSelectionMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSelection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "showSelectionMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSelection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5d601914e69f5db384d7268899c492d3';
module.exports = node;
