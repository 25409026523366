import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon, Col } from 'antd';
import { getAuthor } from 'redux/api/author'
import UserInfo from 'components/User/Info'
import { patchOrganizationResearcher, postOrganizationResearcher } from 'redux/api/organization'
import ResearcherForm from '../Form'

import './index.css';

class ResearcherItem extends Component {
  state = {
    data: {},
    visible: false,
    confirmLoading: false,
    researcherData: this.props.item || {},
    isError: null,
  }

  componentDidMount() {
    // todo: add preloader
    const { item } = this.props;
    getAuthor(item.uid).then(({ response, error }) => {
      this.setState({
        data: response,
      })
    })
  }

  confirmUser = () => {
    const { item, orgId } = this.props;
    postOrganizationResearcher(orgId, item.uid).then(({ response, error }) => {
      if (response) {
        const { data } = this.state;
        data.is_confirmed = true;
        this.setState({
          data,
        });
      }
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = () => {
    document.getElementById('researcher_form_submit').click()
  }

  handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({
      visible: false,
    });
  }

  handleSubmit = (body) => {
    this.setState({
      confirmLoading: true,
    });
    const { item, orgId } = this.props;
    patchOrganizationResearcher(orgId, item.uid, body.date).then(({ response, error }) => {
      if (response) {
        this.setState({
          visible: false,
          confirmLoading: false,
          researcherData: response,
        });
      } else {
        this.setState({
          confirmLoading: false,
          isError: error,
        });
      }
    })
  }

  render() {
    const {
      visible, confirmLoading, data, researcherData, isError,
    } = this.state;
    const controls = [
      <Icon type="edit" onClick={this.showModal} />,
    ]
    if (data.is_confirmed === false) {
      controls.push(
        <Icon type="check" theme="outlined" onClick={this.confirmUser} />
      )
    }
    const {
      tags, t, handleResearcherRemove,
    } = this.props;
    return (
      <div className="researcher_item">
        <UserInfo
          user={data}
          handleUserRemove={handleResearcherRemove}
          tags={tags}
          controls={[<Icon type="edit" onClick={this.showModal} />]}
        >
          <div>{`${t('researcher.work_time.title')}: ${researcherData.since} - ${researcherData.to || t('researcher.work_time.till_now')}`}</div>
          <div>{`${t('researcher.last_login')}: ${researcherData.last_login || '-'}`}</div>
          <div>{`${t('researcher.login_count')}: ${researcherData.login_count || '0'}`}</div>
        </UserInfo>
        <Modal
          title={t('researcher.form.edit')}
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <ResearcherForm
            date={researcherData}
            handleSubmit={this.handleSubmit}
            t={t}
            isError={isError}
          />
        </Modal>
      </div>
    );
  }
}

ResearcherItem.propTypes = {};

export default ResearcherItem;
