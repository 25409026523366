import React, { useState, useEffect } from 'react'
import {
  Table,
} from 'antd';
import { Link } from 'react-router-dom'
import {
  getPropertyByNameString as allocator,
} from 'utility/transformData/constants';
import moment from 'moment';
import { graphql } from 'relay-runtime'
import { fetchGrapqlRequest } from 'utility/request';
import { useDataManualApi } from 'utility/fetchGraphHook';

const QUERY = graphql`
  query AllPublicFetchSelectionsQuery($first: Int, $last: Int) {
    selections: publicSelections(first: $first, last: $last, order: "-created_at") {
      total: totalCount
      edges {
        node {
          ... fragmentsSelectionNode @relay(mask:false)
        }
      }
    }
  }
`

const ELEMENTS_SIZE = 25;

const getFirstLast = ({ current, total }) => {
  if (total === 0) {
    return {
      first: ELEMENTS_SIZE,
      last: ELEMENTS_SIZE,
    }
  }
  return {
    first: current * ELEMENTS_SIZE,
    last: (current * ELEMENTS_SIZE > total) ? Math.abs(total - (current - 1) * ELEMENTS_SIZE) : current * ELEMENTS_SIZE,
  };
}

const nodeMapper = (resp) => {
  const fetchedSelections = allocator(resp, 'selections.edges') || [];
  return fetchedSelections.map(({ node }) => node);
}

export default function ProfileTabAllSelections(props) {
  const { t } = props;

  const [dataCount, setDataCount] = useState({
    pageSize: ELEMENTS_SIZE,
    current: 1,
    defaultCurrent: 1,
    total: 0,
  });
  const {
    data, isLoading, isError, doFetch, reset, updateData,
  } = useDataManualApi(QUERY, [], nodeMapper);


  useEffect(() => {
    const fetchData = async () => {
      const resp = await fetchGrapqlRequest(QUERY, getFirstLast(dataCount));
      setDataCount({ total: allocator(resp, 'selections.total') || 0 });
      updateData(resp);
    };
    console.log('Did mount component');
    fetchData();
  }, []);


  const handleTableChange = (pagination) => {
    // TODO: organize search by title of selection
    console.log('Handle change page', getFirstLast(pagination));
    setDataCount(pagination);
    doFetch(getFirstLast(pagination))
  };

  const COLUMNS = [
    {
      title: t('selection.table.title'),
      dataIndex: 'name',
      key: 'title',
      render: (text, record) => (
        <Link key={record.uid} to={`/show/selection/${record.uid}`}>{record.name}</Link>
      ),
    },
    {
      title: t('selection.table.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('selection.table.type'),
      dataIndex: 'model',
      key: 'type',
    },
    {
      title: t('selection.table.num'),
      dataIndex: 'resultCount',
      key: 'num',
    },
    {
      title: t('selection.table.created'),
      dataIndex: 'createdAt',
      key: 'created',
      render: created => moment(created)
        .format('LLL'),
    },
    {
      title: t('selection.table.creator'),
      dataIndex: 'creator',
      key: 'creator',
      render: item => {
        const creator = allocator(item, 'edges.0.node');
        if (creator) {
          return (
            <Link key={creator.uid} to={`/show/author/${creator.uid}`}>{creator.publicName}</Link>
          )
        }
        return t('N/A');
      },
    },
  ];

  return (
    <Table
      loading={isLoading}
      columns={COLUMNS}
      dataSource={data}
      rowKey="uid"
      onChange={handleTableChange}
      pagination={dataCount}
    />
  )
}

