import React from 'react'
import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  sortArray,
} from 'utility/transformData/constants';
import { getVariant } from 'distLibs/images/variant/index';
import { url } from 'utility/constants/index';

export default function getVariantFields(d, tabType) {
  console.log(tabType);
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      console.log(item);
      return []
    }
    case TAB_TYPE.COLLAPSE: {
      console.log('results variants', d.results);
      const result = [];
      let image = null;
      d.results.forEach((item, index) => {
        const variant = item.node
        console.log('variant icon', variant);
        const names = (variant.names) ? variant.names.join(', ') : 'noname'
        const itemObj = {}
        const variantUid = variant.uid;
        itemObj.content = [
          createLinkField('name', variantUid, 'subjects/Variant', names),
          createCommonField('sense', variant.sense),
        ]

        if (variant.lexemeName) {
          const lexemeNode = variant.lexemeName.edges[0].node
          const lexemeName = lexemeNode.name
          const lexemeUid = lexemeNode.uid
          itemObj.content.push(createLinkField('lexeme', lexemeUid, 'subjects/Lexeme', lexemeName))

          const termNode = lexemeNode.term.edges[0].node
          const termName = termNode.name
          const termUid = termNode.uid
          itemObj.content.push(createLinkField('term', termUid, 'subjects/Term', termName))
        }

        image = getVariant({
          lexemeIndex: d.lexemeIndex,
          groupNumber: (variant.edgeLexeme) ? variant.edgeLexeme.groupNumber : 0,
          variantIndex: index,
        });
        itemObj.sortField = names
        itemObj.name = (<span>
          {image && <img src={image} alt="layer" />}
          {names}
        </span>);
        itemObj.sortName = names;
        result.push(itemObj);
      })
      return sortArray(result, 'sortName')
    }
    default:
      return [];
  }
}
