const ICONS = {
  0: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M36.876 28C39.3354 26.6353 41 24.012 41 21C41 16.5817 37.4183 13 33 13C28.5817 13 25 16.5817 25 21C25 24.012 26.6646 26.6353 29.124 28H29L22 47H17V53H49V47H44L37 28H36.876Z" fill="white"/>
    </svg>
  `,
}
ICONS['default'] = ICONS[0]

export default ICONS
