import React from 'react'
import { translate } from 'react-i18next'
import { Collapse, Icon, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { actions } from 'redux/modules/example'
import { Sort, Group } from 'components/Sort'
import { useSort } from 'utility/sortHook'
import groupHook from 'utility/hooks/groupHook'

import TabList from '../ListTab'
import GalleryTab from '../GalleryTab'
import RadioTab from '../RadioTab'
import ListContent from '../ListContent'
import './index.css'

const { Panel } = Collapse

const mapStateToProps = state => ({
  editMode: state.example.editMode,
})

const mapDispatchToProps = {
  deleteInterpretation: actions.editDeleteInterpretation,
  editDeleteSubjectRel: actions.editDeleteSubjectRel,
}

const CollapseTab = props => {
  const { t, subjectType, content, mainTabUid } = props
  // eslint-disable-next-line prefer-const
  let { data, handleSort, sortKeys } = useSort(content)
  const { data: groupData, handleGroup, groupKeys } = groupHook(data, subjectType)
  data = groupData
  const addDeleteIcon = (editInfo, id) => {
    const { deleteInterpretation, editDeleteSubjectRel, editMode } = props

    if (editMode && editInfo && editInfo.subjectUid) {
      return (
        <Tooltip title="Удалить связь">
          <Icon
            type="close"
            style={{ fontSize: '16px', color: '#ff2b2b' }}
            onClick={event => {
              if (editInfo.editType === 'interpretation') {
                deleteInterpretation({
                  ...editInfo,
                  id,
                  subjectType,
                })
              }
              if (editInfo.editType === 'subjectsRel') {
                editDeleteSubjectRel({
                  ...editInfo,
                  id,
                  subjectType,
                })
              }
              event.stopPropagation()
            }}
          />
        </Tooltip>
      )
    }
    return null
  }

  return (
    <>
      {sortKeys && <Sort handleChange={handleSort} keys={sortKeys} />}
      {groupKeys && groupKeys.length > 1 && (
        <Group handleChange={handleGroup} keys={groupKeys} />
      )}
      <Collapse>
        {data.map((item, index) => (
          <Panel
            header={item.name || ''}
            key={item.name ? item.uid || item.name.innerText : index}
            extra={addDeleteIcon(item.editInfo, index)}
          >
            <ListContent subjectType={subjectType} content={item.content} />
            {item.childrenItem && (
              <TabComponent
                type={item.childrenItem.contentType}
                t={t}
                subjectType={item.childrenItem.subjectType}
                content={item.childrenItem.content}
                mainTabUid={mainTabUid}
                parentProps={props}
              />
            )}
          </Panel>
        ))}
      </Collapse>
    </>
  )
}

const tabs = {
  list: TabList,
  collapse: CollapseTab,
  gallery: GalleryTab,
  radio: RadioTab,
}

function TabComponent(props) {
  const Component = tabs[props.type] || CollapseTab

  return (
    <Component
      content={props.content}
      subjectType={props.subjectType}
      mainTabUid={props.mainTabUid}
      deleteInterpretation={props.parentProps.deleteInterpretation}
      editDeleteSubjectRel={props.parentProps.editDeleteSubjectRel}
      editMode={props.parentProps.editMode}
    />
  )
}

export default translate()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CollapseTab),
)
