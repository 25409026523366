import { url } from 'utility/constants'
import L from 'leaflet'

export const icon16 = [32, 32]

export const getIcon = (src, layerName) => {
  return L.icon({
    iconUrl: src,
    iconSize: icon16,
  })
}

export const createIcon = className => {
  return L.divIcon({
    iconSize: icon16,
    // iconAnchor: [15, 15],
    // popupAnchor: [10, 0],
    // shadowSize: [0, 0],
    className,
    // html: '',
  })
}

export const colors = {
  'subjects/Locality': 'yellow',
  'subjects/District': '#3388ff',
  'subjects/Excavation': '#431e05',
}

export const transformCoords = arr => {
  if (typeof arr[0] === 'object') {
    const { length } = arr
    const retArray = []
    for (let i = 0; i < length; i++) {
      retArray[i] = transformCoords(arr[i])
    }
    return retArray
  }

  return [arr[1], arr[0]]
}

export const handleMakerClick = link => {
  if (link) {
    window.open(`${url}/${link}`, '_blank')
  }
}
