import React from 'react'
import moment from 'moment'
import {
  createCommonField,
  createLinkField,
  getLangTitle,
  getPropertyByNameString,
} from 'utility/transformData/constants'

export const getSelectionInfo = selection => {
  const data = selection.selection
  const content = []
  const creator = getPropertyByNameString(data, 'creator.edges.0.node')
  if (creator) {
    content.push(
      createLinkField('creator', creator.uid, 'author', creator.publicName)
    )
  }
  content.push(createCommonField('description', data.description))
  content.push(createCommonField('type', data.model))
  content.push(createCommonField('num', data.resultCount))
  content.push(
    createCommonField('created', moment(data.createdAt).format('LLL'))
  )
  try {
    const { specifications } = data
    const parsed = JSON.parse(specifications)
    if (parsed && parsed.map) {
      const specList = parsed.map(item => {
        const keys = Object.keys(item)
        const filteredKeys = keys.filter(key => key !== 'count')
        const keyValues = filteredKeys.map((fKey, index) => (
          <span>{`${getLangTitle(item[fKey])}${
            index === filteredKeys.length - 1 ? '' : ';'
          } `}</span>
        ))
        return (
          <div>
            {keyValues}
            {`- ${item.count}`}
          </div>
        )
      })
      content.push(createCommonField('specifications', <div>{specList}</div>))
    }
  } catch (error) {
    console.error(error)
  }
  return content
}
