const ICONS = {
  0: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.3565 14.2345C32.7285 13.9218 33.2714 13.9218 33.6434 14.2345L47.7252 26.071C48.4396 26.6715 48.015 27.8365 47.0818 27.8365H44.4286V42.265H48C48.5523 42.265 49 42.7128 49 43.265V46.9793C49 47.5316 48.5523 47.9793 48 47.9793H18C17.4477 47.9793 17 47.5316 17 46.9793V43.265C17 42.7128 17.4477 42.265 18 42.265H21.5714V27.8365H18.9182C17.985 27.8365 17.5604 26.6715 18.2747 26.071L32.3565 14.2345ZM23.8571 27.8365V42.265H28.4286V27.8365H23.8571ZM30.7143 27.8365V42.265H35.2857V27.8365H30.7143ZM37.5714 27.8365V42.265H42.1429V27.8365H37.5714ZM36.4285 22.2651C36.4285 24.1586 34.8935 25.6936 33 25.6936C31.1064 25.6936 29.5714 24.1586 29.5714 22.2651C29.5714 20.3715 31.1064 18.8365 33 18.8365C34.8935 18.8365 36.4285 20.3715 36.4285 22.2651Z" fill="white" />
    </svg>
  `,
}
ICONS['default'] = ICONS[0]

export default ICONS
