const ICONS = {
  0: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19 16.6667C19 16.2985 19.2985 16 19.6667 16H21C21.3682 16 21.6667 16.2985 21.6667 16.6667V19.354C28 15.854 32.1667 17.604 36.3334 19.354C40.5001 21.104 44.6667 22.854 51 19.354V36.0207C44.801 39.5391 40.3704 37.747 36.0152 35.9854C31.7054 34.2421 27.4694 32.5288 21.6667 36.0207L21.6667 50C21.6667 50.3682 21.3682 50.6667 21 50.6667H19.6667C19.2985 50.6667 19 50.3682 19 50V16.6667ZM24.6667 20.5C24.6667 20.2239 24.4428 20 24.1667 20C23.8905 20 23.6667 20.2239 23.6667 20.5V32.8333C23.6667 33.1095 23.8905 33.3333 24.1667 33.3333C24.4428 33.3333 24.6667 33.1095 24.6667 32.8333V20.5ZM26.1667 19.3333C26.4428 19.3333 26.6667 19.5572 26.6667 19.8333V32.1667C26.6667 32.4428 26.4428 32.6667 26.1667 32.6667C25.8905 32.6667 25.6667 32.4428 25.6667 32.1667V19.8333C25.6667 19.5572 25.8905 19.3333 26.1667 19.3333ZM28.6667 19.1667C28.6667 18.8905 28.4428 18.6667 28.1667 18.6667C27.8905 18.6667 27.6667 18.8905 27.6667 19.1667V32.1667C27.6667 32.4428 27.8905 32.6667 28.1667 32.6667C28.4428 32.6667 28.6667 32.4428 28.6667 32.1667V19.1667Z" fill="white" />
    </svg>
  `,
}
ICONS['default'] = ICONS[0]

export default ICONS
