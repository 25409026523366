import { put, fork, takeLatest, call, all } from 'redux-saga/effects';
import { graphqlPostEnv } from 'redux/api/graphql';
import { sendSelection, getParents, getChildren, getMethods } from 'redux/api/tools';
import { postAdvSearch, postAdvSearchSpatials } from 'redux/api/advSearch';
import { getPropertyByNameString, getResearchUrl } from 'utility/transformData/constants';
import { getMarkers, getPolygons, getUniqPoints } from 'utility/points'

import { SelectionQuery } from '../queries/tools'
import { constants as toolsConstants, actions as toolsActions } from '../modules/tools';

const getLinkByLabelPoints = (label) => {
  switch (label) {
    case 'Author':
      return 'author'
    case 'ArchaeologyResearch':
      return getResearchUrl(label)
    default:
      return `subjects/${label}`
  }
}

function* fetchGetParents(action) {
  const r = yield call(getParents, action.payload);
  console.log('fetchGetParents', r.response.result)

  if (r.response && r.response.result) {
    yield put(toolsActions.saveParents(r.response.result));
  }
}

function* fetchGetChildren(action) {
  const r = yield call(getChildren, {
    tool: action.payload.tool,
    parent: action.payload.parent,
  });
  console.log('fetchGetChildren', r.response.result)

  if (r.response && r.response.result) {
    yield put(toolsActions.saveChildren(r.response.result));
  }
}

function* fetchGetMethods(action) {
  const r = yield call(getMethods, {
    tool: action.payload.tool,
    parent: action.payload.parent,
    child: action.payload.child,
  });
  console.log('fetchGetParents', r.response.result)

  if (r.response && r.response.result) {
    yield put(toolsActions.saveMethods(r.response.result));
  }
}

function* fetchFindSelections(action) {
  const response = yield call(graphqlPostEnv, { query: SelectionQuery, vars: {} });
  console.log('fetchFindSelections', response)

  if (response) {
    const selections = getPropertyByNameString(response, 'selections.edges')
    yield put(toolsActions.setSelections(selections));
  }
}

function* fetchSendSelection(action) {
  const { payload } = action;
  const data = {
    'selection_id': payload.selectionId,
    'tool': payload.tool,
    'parent': payload.parent,
    'child': payload.child,
    'method': payload.method,
    'params': payload.parameters,
    'excluded_ids': payload.excluded_ids,
  }
  const response = yield call(sendSelection, data);
  console.log('fetchSendSelection', response)
  if (response) {
    const image = getPropertyByNameString(response, 'response.data.media_files.0.link')
    const report = getPropertyByNameString(response, 'response.data.data')
    console.log('fetchSendSelection2', getPropertyByNameString(response, 'response.data.media_files.0.link'))
    yield put(toolsActions.saveImage(image));
    yield put(toolsActions.saveReport(report));
  }
}

function* fetchGetUids(action) {
  const query = JSON.parse(action.payload.query);
  const { model, uid, resultCount, returnedFields } = action.payload;
  console.log('fetchGetUids', action, query)
  const subjects = yield call(postAdvSearch, query);
  const spatials = yield call(postAdvSearchSpatials, query);

  const points = getUniqPoints(spatials.response);
  const markers = getMarkers(points, getLinkByLabelPoints(model), model);
  const polygons = getPolygons(points, getLinkByLabelPoints(model), model);

  console.log('fetchGetUids11', subjects, [{
    markers,
    polygons,
    name: model.toLowerCase(),
  }]);

  yield put(toolsActions.saveUids(
    {
      subjects,
      spatials: {
        markers,
        polygons,
        name: model.toLowerCase(),
      },
      model,
      uid,
      count: resultCount,
      returnedFields,
    }));
}

function* watchUserAccountActions() {
  yield takeLatest(toolsConstants.FIND_SELECTIONS, fetchFindSelections);
  yield takeLatest(toolsConstants.GET_UIDS, fetchGetUids);
  yield takeLatest(toolsConstants.SEND_SELECTION, fetchSendSelection);
  yield takeLatest(toolsConstants.GET_PARENTS, fetchGetParents);
  yield takeLatest(toolsConstants.GET_CHILDREN, fetchGetChildren);
  yield takeLatest(toolsConstants.GET_METHODS, fetchGetMethods);
}

export const toolsSaga = [
  fork(watchUserAccountActions),
];
