import L from 'leaflet'

const d3 = require('d3');

const RMAX = 30;

const serializeXmlNode = (xmlNode) => {
  if (typeof window.XMLSerializer !== 'undefined') {
    return (new window.XMLSerializer()).serializeToString(xmlNode);
  } else if (typeof xmlNode.xml !== 'undefined') {
    return xmlNode.xml;
  }
  return '';
}

const bakeThePie = (options) => {
  /* data and valueFunc are required */
  if (!options.data || !options.valueFunc) {
    return '';
  }
  const { data, valueFunc } = options;
  const r = options.outerRadius ? options.outerRadius : 28; // Default outer radius = 28px
  const rInner = options.innerRadius ? options.innerRadius : r - 10; // Default inner radius = r-10
  const labelInner = options.labelInner ? options.labelInner : r + 15; // Default inner radius = r-10
  const strokeWidth = options.strokeWidth ? options.strokeWidth : 1; // Default stroke is 1
  const pathClassFunc = options.pathClassFunc ? options.pathClassFunc : () => ''; // Class for each path
  const pathTitleFunc = options.pathTitleFunc ? options.pathTitleFunc : () => ''; // Title for each path
  const pieClass = options.pieClass ? options.pieClass : 'marker-cluster-pie'; // Class for the whole pie
  const pieLabel = options.pieLabel ? options.pieLabel : d3.sum(data, valueFunc); // Label for the whole pie
  const pieLabelClass = options.pieLabelClass ? options.pieLabelClass : 'marker-cluster-pie-label'; // Class for the pie label

  const origo = (r + strokeWidth); // Center coordinate
  const w = origo * 2; // width and height of the svg element
  const h = w;
  const donut = d3.pie();
  const arc = d3.arc().innerRadius(rInner).outerRadius(r);

  // Create an svg element
  const svg = document.createElementNS(d3.namespaces.svg, 'svg');

  // Create the pie chart
  const vis = d3.select(svg)
    .data([data])
    .attr('class', pieClass)

  const arcs = vis.selectAll('g.arc')
    .data(donut.value(valueFunc))
    .enter().append('svg:g')
    .attr('class', 'arc')
    .attr('transform', `translate(${origo},${origo})`);

  arcs.append('svg:path')
    .attr('class', pathClassFunc)
    .attr('stroke-width', strokeWidth)
    .attr('pointer-events', 'visibleStroke')
    .attr('d', arc)
    .append('svg:title')
    .text(pathTitleFunc);

  const textLines = arcs.append('line')
    .attr('class', 'label-line')
    .attr('x1', (d) => arc.centroid(d)[0])
    .attr('y1', (d) => arc.centroid(d)[1])
    .attr('x2', (d) => {
      const centroid = arc.centroid(d);
      const midAngle = Math.atan2(centroid[1], centroid[0]);
      return Math.cos(midAngle) * labelInner;
    })
    .attr('y2', (d) => {
      const centroid = arc.centroid(d);
      const midAngle = Math.atan2(centroid[1], centroid[0]);
      return Math.sin(midAngle) * labelInner;
    });

  const textLabels = arcs.append('text')
    .attr('class', 'label-text')
    .attr('x', (d) => {
      const centroid = arc.centroid(d);
      const midAngle = Math.atan2(centroid[1], centroid[0]);
      const x = Math.cos(midAngle) * labelInner;
      const sign = (x > 0) ? 1 : -1;
      return x + (5 * sign)
    })
    .attr('y', (d) => {
      const centroid = arc.centroid(d);
      const midAngle = Math.atan2(centroid[1], centroid[0]);
      return Math.sin(midAngle) * labelInner;
    })
    .attr('text-anchor', (d) => {
      const centroid = arc.centroid(d);
      const midAngle = Math.atan2(centroid[1], centroid[0]);
      const x = Math.cos(midAngle) * labelInner;
      return (x > 0) ? 'start' : 'end';
    })
    .text((d) => {
      const Epoch = d.data.values[0] ? d.data.values[0].options.epoch : undefined;
      const name = d.data.values[0].options.subjectType
      let epochName = (Epoch && (Epoch.ru_name || Epoch.ruName)) ? (Epoch.ru_name || Epoch.ruName) : 'Неизвестно'
      if (epochName === 'Неизвестно') {
        epochName = name
      }
      return `${epochName} (${d.data.values.length})`;
    });

  vis.append('text')
    .attr('x', origo)
    .attr('y', origo + 1)
    .attr('class', pieLabelClass)
    .attr('text-anchor', 'middle')
    .attr('dy', '.3em')
    .text(pieLabel);
  // Return the svg-markup rather than the actual element
  return serializeXmlNode(svg);
}

export const defineClusterIcon = (cluster) => {
  const children = cluster.getAllChildMarkers();
  const n = children.length; // Get number of markers in cluster
  const strokeWidth = 1; // Set clusterpie stroke width
  let r;
  if (n < 10) {
    r = RMAX - (2 * strokeWidth) - 12; // Calculate clusterpie radius...
  } else if (n < 100) {
    r = RMAX - (2 * strokeWidth) - 8; // Calculate clusterpie radius...
  } else {
    r = RMAX - (2 * strokeWidth) - (n < 1000 ? 4 : 0); // Calculate clusterpie radius...
  }
  // ...and divIcon dimensions (leaflet really want to know the size)
  const iconDim = (r + strokeWidth) * 2;
  const data = d3.nest() // Build a dataset for the pie chart
    .key((d) => {
      const Epoch = d.options.epoch
      return (Epoch && Epoch.sort) ? Epoch.sort : (Epoch || 0);
    })
    .entries(children, d3.map);
  // bake some svg markup
  const html = bakeThePie({
    data,
    valueFunc(d) { return d.values.length; },
    strokeWidth: 1,
    outerRadius: r,
    innerRadius: r - 10,
    pieClass: 'cluster-pie',
    pieLabel: n,
    pieLabelClass: 'marker-cluster-pie-label',
    pathClassFunc(d) {
      const Epoch = d.data.values[0] ? d.data.values[0].options.epoch : undefined;
      // todo change response epoch data in adv search at back
      const epoch = (Epoch && Epoch.sort) ? Epoch.sort : (Epoch || 0)
      return `pie-path category-epoch-${epoch}`;
    },
    pathTitleFunc(d) {
      // not show look in textLabels realization
      const Epoch = d.data.values[0] ? d.data.values[0].options.epoch : undefined;
      const epochName = (Epoch && (Epoch.ru_name || Epoch.ruName)) ? (Epoch.ru_name || Epoch.ruName) : 'Неизвестно'
      return `${epochName} (${d.data.values.length})`;
    },
  })
  // Create a new divIcon and assign the svg markup to the html property
  return new L.DivIcon({
    html,
    className: 'marker-cluster',
    iconSize: new L.Point(iconDim, iconDim),
  });
}
