import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries';
import CommonSelect from 'components/GSearchPanel/components/commonSelect';
import archQueries from 'components/GSearchPanel/Queries/archQueries';
import { listQueries } from 'components/CreatePanel/queries/getList';
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters';

import '../../index.scss';
import FileUpload from '../fields/fileUpload';
import AutoInputGQL from '../fields/autoInputGQL';
import CoordinatesInput from '../fields/coordinatesInput';
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions';

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class AssemblageTab extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 1;
    this.state = {
      newName: []
    }
  }

  addTab = () => {
    const key = this.newTabIndex++;
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state;
    newName[key] = data.typed;
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = (input) => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      filelistChanged,
      getFieldDecorator,
      formInputLayout,
      stateTabs,
      subjects,
      createData,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props;

    const config = {
      req: {
        rules: [{
          required: true, message: t('common.default_error'),
        }],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel header={`${t('create_panel.tab.assemblage.assemblage')} #${1 + key}`} key={title + item.key}>
              <AutoInputGQL
                handleSelect={(data) => {this.handleSelect(data, key)}}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.assemblage.choose')}
                selectkey={`assemblage_select_${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.assemblage.add')}
                query={archQueries.assemblageSearch(false, true)}
                params={[{ key: 'search', val: 'value' }]}
                rules={config.req}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel={'Assemblage'}
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') &&
              <div>
                {item.status === 'new' &&
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel={'Assemblage'}
                    rightLabel={'Layer'}
                    connectionField={'layer'}
                    label={t('create_panel.tab.assemblage.site')}
                    selectkey={`assemblage_site_${key}`}
                    selectType="layers"
                    stateTabs={stateTabs}
                    objkey={key}
                    noAddOption
                    query={archQueries.layerSearch()}
                    params={[{key: 'search', val: 'value'}]}
                    rules={config.req}
                    t={t}
                  />
                }
                {item.status === 'new' &&
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.assemblage.name')}
                  >
                    {getFieldDecorator(`assemblage_name_${key}`, {
                      ...config.req,
                      initialValue: this.state.newName[key],
                    })(
                      <Input onChange={this.handleChangeName} data-objkey={key}/>)}
                  </FormItem>
                }
                <CoordinatesInput
                  getFieldDecorator={getFieldDecorator}
                  formLayout={formInputLayout}
                  title={title}
                  subjname="assemblage"
                  objkey={key}
                  handleAddMarker={handleAddMarker}
                  interactiveMarkers={interactiveMarkers}
                  form={form}
                  t={t}
                />
                <AutoInputGQL
                  handleSelect={commonFunctions.handleAutoInputChange}
                  handleTabAddRelation={this.props.handleTabAddRelation}
                  handleChangeStateTab={this.props.handleChangeStateTab}
                  getFieldDecorator={getFieldDecorator}
                  formLayout={formInputLayout}
                  title={this.props.title}
                  leftLabel={'Assemblage'}
                  rightLabel={'Excavation'}
                  connectionField={'excavation'}
                  label={t('common.subjects.excavation')}
                  selectkey={`assemblage_excavation_${key}`}
                  selectType="excavations"
                  stateTabs={stateTabs}
                  objkey={key}
                  noAddOption
                  query={archQueries.excavationSearch()}
                  params={[{ key: 'search', val: 'value' }]}
                  t={t}
                />
                <FormItem
                  {...formInputLayout}
                  label={t('common.date')}
                >
                  {getFieldDecorator(`assemblage_date_since_${key}`, {})(
                    <InputNumber placeholder={t('common.date_since')} min={-100000} max={new Date().getFullYear()} />)}
                  {getFieldDecorator(`assemblage_date_to_${key}`, {})(
                    <InputNumber placeholder={t('common.date_to')} min={-100000} max={new Date().getFullYear()} />)}
                </FormItem>
                {item.status === 'new' &&
                  <CommonSelect
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    inputName={`assemblage_type_${key}`}
                    label={t('common.assemblage_type')}
                    selectQuery={attributeQueries.defaultSearch('assemblageTypes')}
                    dontNeedEmptyOption="true"
                    rules={config.req}
                    t={t}
                  />
                }
                <FormItem
                  {...formInputLayout}
                  label={t('common.description')}
                >
                  {getFieldDecorator(`assemblage_desc_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `assemblage_desc_${key}`)} />)}
                </FormItem>
                <FileUpload
                  getFieldDecorator={getFieldDecorator}
                  formInputLayout={formInputLayout}
                  filelistChanged={filelistChanged}
                  fileLists={createData.sourceFilelist}
                  label={`assemblage_files_${key}`}
                  fileType={`photo`}
                  title={t(`common.photo.title`)}
                  t={t}
                />
                <RelationComponent
                  handleAddExtraRelation={handleAddExtraRelation}
                  getFieldDecorator={getFieldDecorator}
                  formLayout={formInputLayout}
                  objkey={key}
                  title="Assemblage"
                  tabName="assemblages"
                  stateTabs={stateTabs}
                  form={form}
                  t={t}
                />
              </div>
              }
            </Panel>
          ))}
        </Collapse>

        <FormItem
          wrapperCol={{ span: 8, offset: 8 }}
        >
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.assemblage.add_button')}
          </Button>
        </FormItem>
      </div>
    );
  }
}

export default AssemblageTab;
