import React, { Component } from 'react';
import { Form, Button, Card, Icon } from 'antd';

const ExistingUsersUser = props => {
  const {
    user,
    id,
    t,
    handleYes,
    handleNo,
    existingUser,
    statusCode,
  } = props;
  let status = null
  if (existingUser === user.uid) {
    status = <span className="existing-status">{t(`profile.maybe_you.status.${statusCode}`)}</span>
  }
  return (
    <Card
      key={user.uid}
      type="inner"
      title={<span>
        {user.public_name} {status}
      </span>}
      className="existing-user user-card existing-user-modal"
      extra={existingUser !== user.uid ? [
        <a href={`/show/author/${user.uid}/`} target="_blank">{t('profile.maybe_you.profile')}</a>,
        <Button
          onClick={() => handleYes(id)}
        >{t('profile.maybe_you.yes')}</Button>,
        <Button
          onClick={() => handleNo(id)}
        >{t('profile.maybe_you.no')}</Button>,
      ]
        :
        [
          <a href={`/show/author/${user.uid}/`} target="_blank">{t('profile.maybe_you.profile')}</a>,
        ]
      }
    />
  );
}

export default ExistingUsersUser;
