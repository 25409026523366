import React, { Component } from 'react'
import { Select, Form } from 'antd'
// eslint-disable-next-line import/no-cycle
import { url } from 'utility/constants'
import { getPropertyByNameString } from 'utility/transformData/constants'

// eslint-disable-next-line import/no-cycle
import * as request from '../../../utility/request'

const FormItem = Form.Item

class CommonSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      hide: true,
    }
  }

  componentDidMount() {
    const { selectQuery } = this.props
    const headers = {
      ...request.HEADERS_POST_AUTH(),
      method: 'POST',
      body: JSON.stringify({
        query: selectQuery.query,
      }),
    }

    fetch(`${url}/api/graphql/`, { ...headers })
      .then(response => response.json())
      .then(d => {
        const results =
          getPropertyByNameString(d, 'data.results.edges') ||
          getPropertyByNameString(d, 'data.results')
        if (results) {
          const options = []
          results.forEach(r => {
            if (r.node) {
              options.push({
                uid: r.node.uid,
                text: selectQuery.render(r),
                sort: r.node.sort || 0,
              })
            } else {
              options.push({
                uid: r.uid,
                text: selectQuery.render(r),
                sort: r.sort || 0,
              })
            }
          })
          this.setState({ options })
        }
      })
      .catch(error => {
        console.log(`request failed ${error}`)
      })
  }

  handleChange = value => {
    if (value !== -1) {
      this.setState({ hide: false })
    } else {
      this.setState({ hide: true })
    }

    const { onSelect } = this.props
    if (onSelect) {
      onSelect(value)
    }
  }

  render() {
    const {
      getFieldDecorator,
      inputName,
      formLayout,
      placeholder,
      title,
      label,
      searchType,
      dontNeedEmptyOption,
      rules,
      t,
    } = this.props

    const { hide } = this.state

    const options = []
    if (!dontNeedEmptyOption) {
      options.push({
        value: -1,
        uid: -1,
        text: t('search_panel.form.select.no_data'),
      })
    }
    options.push(
      ...this.state.options.sort((a, b) => (a.sort > b.sort ? 1 : -1)),
    )

    const initValue = options[0] && options[0].uid ? options[0].uid : null

    return (
      <FormItem
        {...formLayout}
        label={label || t(`search_panel.form.field.${searchType}.${title}`)}
      >
        {getFieldDecorator(`${inputName}`, {
          hidden: hide,
          ...rules,
        })(
          <Select
            name={inputName}
            placeholder={placeholder}
            onChange={this.handleChange}
          >
            {options.map(item => (
              <Select.Option value={item.uid} key={item.uid}>
                {item.text.charAt(0).toUpperCase() + item.text.slice(1)}
              </Select.Option>
            ))}
          </Select>,
        )}
      </FormItem>
    )
  }
}

export default CommonSelect
