import { put, fork, takeLatest, call, all } from 'redux-saga/effects';

import { getNotification, errorNotify, successNotify } from '../../utility/notification';
import { actions as notification } from '../modules/notification';
import {
  getProfile,
  getAuthorResearches,
  getAuthorSources,
  updateProfile,
  removePhoto,
  getAuthorApprovers,
} from '../api/author';
import { constants as profileConstants, actions as profileActions } from '../modules/profile';
import { getTag } from '../api/tags';
import { getOrganization } from '../api/organization';
import { getResearchTypeList } from '../api/research';
import { getCurrentUser } from '../api/auth';

const SOURCE_TYPES = [
  'Book', // монографии
  'Report',
  'pReport', // Опубликованный отчет
  'BookSection', // Раздел книги
  'ArticlePeriodical', // статьи
];

export function* fetchUserInfo() {
  const loadNotification = getNotification('profile', 'loading');
  yield put(notification.show(loadNotification));
  const { response, error } = yield call(getProfile);
  if (response) {
    const profile = response;
    if (response.account_confirmed === false) {
      const { response: apprRes, error: apprErr } = yield call(getAuthorApprovers)
      if (apprRes) {
        profile.must_confirm = apprRes.filter(item => !item.status)
      }
    }
    const tags = yield all(response.scientific_interests.map(item =>
      call(getTag, item)
    ));
    const organizations = yield all(response.organizations.map(item =>
      call(getOrganization, item.uid)
    ));
    console.log(profile);
    console.log(tags);
    console.log(organizations);
    yield put(profileActions.userInfo({
      result: profile,
      tags,
      organizations,
    }));
    yield put(notification.hide(loadNotification.id));
  } else {
    yield put(notification.hide(loadNotification.id));
    yield call(errorNotify, 'profile', 'error');
    yield put(profileActions.userInfoFailed({ result: error }))
    console.log(error);
  }
}

export function* fetchResearches() {
  const { response, error } = yield call(getCurrentUser);
  const { response: typeResponse } = yield call(getResearchTypeList);
  if (response) {
    const { uid } = response;
    const { results } = typeResponse;
    const typeMap = new Map();
    results.forEach(item => typeMap.set(item.uid, item))
    console.log(uid);
    console.log(typeMap);
    const { response: resResponse } = yield call(getAuthorResearches, uid);
    if (resResponse) {
      const researchTypeGroupMap = new Map();
      resResponse.results.forEach(item => {
        item.type.forEach(type => {
          if (researchTypeGroupMap.get(type.uid)) {
            researchTypeGroupMap.get(type.uid).push(item)
          } else {
            researchTypeGroupMap.set(type.uid, [item])
          }
        })
      })
      console.log(researchTypeGroupMap);
      yield put(profileActions.researches({ result: researchTypeGroupMap, types: typeMap }))
    } else {
      console.log(error);
      // yield put(userActions.userInfoFailed(result))
    }
    // yield put(userActions.userInfo(result))
  } else {
    console.log(error);
    // yield put(userActions.userInfoFailed(result))
  }
}

export function* fetchSources(action) {
  const { response, error } = yield call(getCurrentUser);
  if (response) {
    const { uid } = response;
    console.log(uid);
    console.log(action.payload);
    const sourceMap = new Map();
    const sourceRes = yield call(getAuthorSources, uid, action.payload)
    console.log(sourceRes);
    sourceMap.set(action.payload, sourceRes.response.results)
    yield put(profileActions.sources(sourceMap))
  } else {
    console.log(error);
  }
}

// export function* fetchRemovePhoto() {
//   const loadNotification = getNotification('profile.form.photo', 'loading', 'removing');
//   yield put(notification.show(loadNotification));
//   const { response, error } = yield call(removePhoto);
//   yield put(notification.hide(loadNotification.id));
//   if (response) {
//     yield call(fetchUserInfo);
//   } else {
//     yield call(errorNotify, 'profile.form.photo', 'removedError');
//     yield put(profileActions.userInfoFailed({ result: error }))
//     console.log(error);
//   }
// }

export function* fetchChangeUserInfo(action) {
  const loadNotification = getNotification('profile', 'loading', 'saving');
  yield put(notification.show(loadNotification));

  const { response, error } = yield call(updateProfile, action.payload);

  if (response) {
    const tags = yield all(response.scientific_interests.map(item =>
      call(getTag, item)
    ));
    const organizations = yield all(response.organizations.map(item =>
      call(getOrganization, item.uid)
    ));
    console.log(response);
    console.log(tags);
    console.log(organizations);
    yield put(profileActions.userInfo({
      result: response,
      tags,
      organizations,
    }));
    yield put(notification.hide(loadNotification.id));
    yield call(successNotify, 'profile', 'saved');
  } else {
    yield put(notification.hide(loadNotification.id));
    yield call(errorNotify, 'profile', 'error');
    yield put(profileActions.userInfoFailed(error));
    console.log(error);
  }
}

function* watchUserAccountActions() {
  yield takeLatest(profileConstants.GET_USER_INFO, fetchUserInfo);
  yield takeLatest(profileConstants.CHANGE_USER_INFO, fetchChangeUserInfo);
  yield takeLatest(profileConstants.LOAD_RESEARCHES, fetchResearches);
  yield takeLatest(profileConstants.LOAD_SOURCES, fetchSources);
  // yield takeLatest(profileConstants.REMOVE_PHOTO, fetchRemovePhoto);
}

export const profileSaga = [
  fork(watchUserAccountActions),
];
