const ICONS = {
  0: (_: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="31" fill="white" stroke="black" stroke-width="2"/>
      <circle cx="33" cy="33" r="17" fill="white" stroke="black" stroke-width="2"/>
    </svg>`,
}
ICONS['default'] = ICONS[0]

export default ICONS
