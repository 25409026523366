import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'antd';

import FetchInputSelect from '../FetchInputSelect';
import UserSelect from '../User/Select';
import UserItem from '../User/Item';

const FormItem = Form.Item;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const url = process.env.REACT_APP_API_URL || 'http://0.0.0.0:8000';

class Confirmation extends Component {
  static propTypes = {
    formItemLayout: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    type: 'user',
    userData: new Map(),
    userSelectValue: [],
    organizationValue: null,
    tags: new Map(),
  };

  componentDidMount() {
    fetch(`${url}/api/tags/list/`, {})
      .then(response => response.json())
      .then((d) => {
        const tags = new Map();
        console.log(d);
        d.results.forEach(item => {
          tags.set(item.uid, item);
        })
        this.setState({
          tags,
        })
      })
      .catch((error) => {
        console.log(`request failed ${error}`);
      });
  }

  getTextField = (obj) => (`${obj.first_name || ''} ${obj.last_name || ''} ${obj.parent_name || ''}`);

  getSearchUrl = (path, value) => (
    `${url}/${path}/?search=${value}`
  )

  getSearchUrlOrganization = (orfUrl, path, value) => (
    `${orfUrl}/${path}/?search=${value}`
  )

  handleChange = (e) => {
    this.setState({
      type: e.target.value,
    })
  };

  handleOrganizationSelect = (organizationValue) => {
    this.setState({ organizationValue });
  }

  handleUserSelect = (user, value) => {
    const { userData, userSelectValue } = this.state;
    if (!userData.has(user.uid)) {
      userSelectValue.push(value);
      userData.set(user.uid, user);
      this.setState({ userData, userSelectValue });
    }
  }

  handleUserRemove = (key) => {
    const { userData } = this.state;
    let { userSelectValue } = this.state;
    userSelectValue = userSelectValue.filter(user => user.key !== key);
    userData.delete(key);
    this.setState({ userData, userSelectValue });
  }

  renderOrganization = (t) => (
    <FetchInputSelect
      getFieldDecorator={this.props.getFieldDecorator}
      inputName="organization"
      title={t('signUp.form.confirmation_users.organizations.title')}
      path="api/organization/registration"
      value={this.state.organizationValue}
      handleSelect={this.handleOrganizationSelect}
      getSearchUrl={this.getSearchUrlOrganization}
      rules={{
        required: true, message: t('signUp.form.confirmation_users.organizations.error'),
      }}
      style={{ width: '100%' }}
    />
  );

  renderUsers = (users) => {
    const result = [];
    users.forEach(user => {
      result.push(
        <UserItem
          key={user.uid}
          user={user}
          handleUserRemove={this.handleUserRemove}
          tags={this.state.tags}
        />
      )
    })
    return result;
  }

  renderUserForm = (t) => (
    <div>
      <UserSelect
        getFieldDecorator={this.props.getFieldDecorator}
        inputName="userOne"
        title={t('signUp.form.confirmation_users.user.first')}
        path="api/author/registration"
        getSearchUrl={this.getSearchUrl}
        handleSelect={this.handleUserSelect}
        value={this.state.userSelectValue}
        tags={this.state.tags}
        rules={{
          required: true, message: t('signUp.form.confirmation_users.user.error'),
        }}
        style={{ width: '100%' }}
      />
      {
        this.renderUsers(this.state.userData)
      }
    </div>
  );

  render() {
    const {
      formItemLayout,
      t,
    } = this.props;

    return (
      <FormItem
        label={t('signUp.form.confirmation_users.title')}
        {...formItemLayout}
      >
        <RadioGroup onChange={this.handleChange} defaultValue="user">
          <RadioButton value="user">{t('signUp.form.confirmation_users.user.title')}</RadioButton>
          <RadioButton value="organization">{t('signUp.form.confirmation_users.organizations.title')}</RadioButton>
        </RadioGroup>
        {
          this.state.type === 'user' ?
            this.renderUserForm(t)
            :
            this.renderOrganization(t)
        }
      </FormItem>

    );
  }
}

export default Confirmation;
