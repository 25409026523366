import * as L from 'leaflet';
import { MapControl } from 'react-leaflet';

import '../../distLibs/BetterScale';
import '../../distLibs/BetterScale/index.css';

export default class BetterScale extends MapControl {
  createLeafletElement = (props) => {
    const options = {
      ...props,
      metric: true,
      imperial: false,
    }
    return L.control.betterscale(options);
  }

  componentDidMount() {
    const { map } = this.context;
    this.leafletElement.addTo(map);
  }
}
