import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { actions } from '../../redux/modules/user';
import LoginForm from '../../components/LoginForm';
import './index.css';

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  handleSubmit: actions.logIn,
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class LoginView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, user: { error }, t } = this.props;
    return (
      <div className="container login">
        <h1>{t('logIn.title')}</h1>
        <LoginForm
          handleSubmit={handleSubmit}
          isError={error}
          t={t}
        />
      </div>
    )
  }
}

export default LoginView;
