/**
 * @flow
 * @relayHash c25cd02154b04698b1fa91a7e1226abc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessRoleEnum = "ADMIN" | "AUTHOR" | "CREATOR" | "MODERATOR" | "NOT_ACCESSED" | "PUBLIC_USER" | "SHARED_USER" | "%future added value";
export type AccessHocSourceQueryVariables = {|
  subjectId: string
|};
export type AccessHocSourceQueryResponse = {|
  +source: ?{|
    +isRequestSend: ?boolean,
    +accessRole: ?AccessRoleEnum,
  |}
|};
export type AccessHocSourceQuery = {|
  variables: AccessHocSourceQueryVariables,
  response: AccessHocSourceQueryResponse,
|};
*/


/*
query AccessHocSourceQuery(
  $subjectId: ID!
) {
  source(id: $subjectId) {
    isRequestSend
    accessRole
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "subjectId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subjectId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isRequestSend",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accessRole",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AccessHocSourceQuery",
  "id": null,
  "text": "query AccessHocSourceQuery(\n  $subjectId: ID!\n) {\n  source(id: $subjectId) {\n    isRequestSend\n    accessRole\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AccessHocSourceQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "source",
        "storageKey": null,
        "args": v1,
        "concreteType": "SourceNode",
        "plural": false,
        "selections": [
          v2,
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccessHocSourceQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "source",
        "storageKey": null,
        "args": v1,
        "concreteType": "SourceNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6c0b61c959b4c11101608c126f787434';
module.exports = node;
