/**
 * @flow
 * @relayHash b2d7f819fdb17ab3ffc2ad843820327e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type authCurrentUserQueryVariables = {||};
export type authCurrentUserQueryResponse = {|
  +me: ?{|
    +uid: ?string,
    +email: ?string,
    +role: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +slug: ?string
        |}
      |}>
    |},
    +firstName: ?string,
    +lastName: ?string,
    +accountConfirmed: ?boolean,
    +emailConfirmed: boolean,
  |}
|};
export type authCurrentUserQuery = {|
  variables: authCurrentUserQueryVariables,
  response: authCurrentUserQueryResponse,
|};
*/


/*
query authCurrentUserQuery {
  me {
    uid
    email
    role {
      edges {
        node {
          slug
          id
        }
      }
    }
    firstName
    lastName
    accountConfirmed
    emailConfirmed
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accountConfirmed",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailConfirmed",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "authCurrentUserQuery",
  "id": null,
  "text": "query authCurrentUserQuery {\n  me {\n    uid\n    email\n    role {\n      edges {\n        node {\n          slug\n          id\n        }\n      }\n    }\n    firstName\n    lastName\n    accountConfirmed\n    emailConfirmed\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "authCurrentUserQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          v0,
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "role",
            "storageKey": null,
            "args": null,
            "concreteType": "RoleNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "RoleNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "RoleNode",
                    "plural": false,
                    "selections": [
                      v2
                    ]
                  }
                ]
              }
            ]
          },
          v3,
          v4,
          v5,
          v6
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "authCurrentUserQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          v0,
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "role",
            "storageKey": null,
            "args": null,
            "concreteType": "RoleNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "RoleNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "RoleNode",
                    "plural": false,
                    "selections": [
                      v2,
                      v7
                    ]
                  }
                ]
              }
            ]
          },
          v3,
          v4,
          v5,
          v6,
          v7
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b59c4ed4fa39e483ae64c72794dceeca';
module.exports = node;
