export const getResearch = (point = {}) => {
  let epoch
  if (point.epoch && point.epoch.sort) {
    epoch = point.epoch.sort
  }
  const epochObj = point.attributes
    ? point.attributes.filter(a => a.type === 'Epoch')[0]
    : null
  const img =
    (point.nodeAttributes && point.nodeAttributes.epoch) || epochObj
      ? epochObj.sort || point.nodeAttributes.epoch.sort
      : 0
  epoch = img
  const type = point.type || point.research_type
  return {
    type,
    epoch,
  }
}
