const ICONS = {
  0: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}"/>
      <path d="M32.1166 18.6651C32.4925 17.9566 33.5075 17.9566 33.8834 18.6651L46.9432 43.2813C47.2966 43.9474 46.8138 44.75 46.0599 44.75H19.9401C19.1862 44.75 18.7034 43.9474 19.0568 43.2813L32.1166 18.6651Z" fill="white"/>
    </svg>
  `,
  1: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1232 17.5989C32.5028 16.9067 33.4972 16.9067 33.8768 17.5989L49.1879 45.5192C49.5534 46.1856 49.0712 47 48.3111 47H17.6889C16.9288 47 16.4466 46.1856 16.8121 45.5192L32.1232 17.5989ZM33 26.1401L24.0475 42.4652H41.9525L33 26.1401Z" fill="white"/>
    </svg>
  `,
  2: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M33 49C41.8366 49 49 41.8366 49 33C49 24.1634 41.8366 17 33 17C24.1634 17 17 24.1634 17 33C17 41.8366 24.1634 49 33 49ZM33 44C39.0751 44 44 39.0751 44 33C44 26.9249 39.0751 22 33 22C26.9249 22 22 26.9249 22 33C22 39.0751 26.9249 44 33 44Z" fill="white"/>
    </svg>
  `,
  3: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M49 33C49 41.8366 41.8366 49 33 49C24.1634 49 17 41.8366 17 33C17 24.1634 24.1634 17 33 17C41.8366 17 49 24.1634 49 33ZM43.5859 36C42.2801 40.617 38.0351 44 33 44C27.9649 44 23.7199 40.617 22.4141 36H43.5859ZM43.5859 30C42.2801 25.383 38.0351 22 33 22C27.9649 22 23.7199 25.383 22.4141 30H43.5859Z" fill="white"/>
    </svg>
  `,
  4: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 18C17 17.4477 17.4477 17 18 17H48C48.5523 17 49 17.4477 49 18V48C49 48.5523 48.5523 49 48 49H18C17.4477 49 17 48.5523 17 48V18ZM22 22V44H44V22H22Z" fill="white"/>
    </svg>
  `,
  5: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18 17C17.4477 17 17 17.4477 17 18V48C17 48.5523 17.4477 49 18 49H48C48.5523 49 49 48.5523 49 48V18C49 17.4477 48.5523 17 48 17H18ZM22 44V22H44V44H22ZM33 36C34.6569 36 36 34.6569 36 33C36 31.3431 34.6569 30 33 30C31.3431 30 30 31.3431 30 33C30 34.6569 31.3431 36 33 36Z" fill="white"/>
    </svg>
  `,
}
ICONS['default'] = ICONS[0]

export default ICONS
