import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';
import { listQueries } from 'components/CreatePanel/queries/getList';
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries';
import { connect } from 'react-redux';
import { actions } from 'redux/modules/create';
import i18n from 'i18next';

import config from '../config';
import AutoInputGQL from '../../fields/autoInputGQL';

const FormItem = Form.Item;

const mapStateToProps = state => ({
  createData: state.create,
});

const mapDispatchToProps = {};

@connect(mapStateToProps, mapDispatchToProps)
class CreateFieldReportForm extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      newPublisher: false,
      newPublisherName: '',
        addRuName: false,
        addLocName: false,
    };
  }

  handleOrgChange = (data) => {
    this.props.form.setFieldsValue({ org_id: data.uid });
  }

  render() {
    const {
      getFieldDecorator,
      formItemLayout,
      createData,
      newName,
      t,
    } = this.props;

    const { newPublisher } = this.state;
    const formButton = {
      wrapperCol: { offset: 8, span: 10 },
    }

    return (
      <div>
        <FormItem
          label={t('create_panel.modal.addNewReport.en_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('en_name', {
            ...config.en_name,
            initialValue: newName,
          })(<Input />)}
        </FormItem>
        {!this.state.addRuName && (
          <FormItem {...formButton}>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ addRuName: true })
              }}
            >
              {t('create_panel.tab.research.add_ru_name')}
            </Button>
          </FormItem>
        )}
        {this.state.addRuName && (
          <FormItem
            label={t('create_panel.modal.addNewReport.ru_name.title')}
            {...formItemLayout}
          >
            {getFieldDecorator('ru_name')(<Input />)}
          </FormItem>
        )}
        {!this.state.addLocName && (
          <FormItem {...formButton}>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ addLocName: true })
              }}
            >
              {t('create_panel.tab.research.add_loc_name')}
            </Button>
          </FormItem>
        )}
        {this.state.addLocName && (
          <FormItem
            label={t('create_panel.modal.addNewReport.loc_name.title')}
            {...formItemLayout}
          >
            {getFieldDecorator('local_name')(<Input />)}
          </FormItem>
        )}
        <FormItem
          label={t('create_panel.modal.addNewReport.doi.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('doi'
          )(<Input />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('create_panel.modal.addNewReport.year_pub.title')}
        >
          {getFieldDecorator('year', config.year)(
            <InputNumber min={1700} max={new Date().getFullYear()} />)}
        </FormItem>
        <AutoInputGQL
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          textField="ru_name"
          selectkey="organization"
          newObjChanged={this.handleOrgChange}
          label={t('create_panel.modal.addNewReport.org.title')}
          noAddOption
          rules={config.req}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.organizationSearch()}
          t={t}
        />
        <FormItem
          label={t('create_panel.modal.addNewReport.save_number.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('save_number',
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.fond_number.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('fond_number',
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.inventory_number.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('inventory_number',
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.sheet_number.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('sheet_number',
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.code.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('code',
          )(<Input />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('org_id', {})(
            <Input hidden />)}
        </FormItem>
      </div>
    );
  }
}

export default CreateFieldReportForm;
