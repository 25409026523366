import React, { Component } from 'react'
import 'react-leaflet-markercluster/dist/styles.min.css'
import SubGroup from 'utility/leaflet-sub-group'
import {
  getIcon,
  colors,
  transformCoords,
  handleMakerClick,
} from 'utility/markers'

class BaseLayerComponent extends Component {
  renderPolygons = polygons => {
    const result = []
    if (polygons) {
      polygons.forEach(item => {
        result.push({
          markerType: 'polygon',
          position: transformCoords(item.coords),
          color: item.color || colors[item.type],
          className: 'marker-class',
          epoch: item.epoch,
          key: item.uid,
          onClick: () => handleMakerClick(item.link),
          icon: item.icon || getIcon(item.img),
          type: item.type,
          tooltip: item.tooltip,
        })
      })
    }
    return result
  }

  renderMarkers = (markers, layerName) =>
    markers.map(item => ({
      markerType: 'marker',
      position: [item.coords.lat, item.coords.lng],
      className: 'marker-class',
      epoch: item.epoch,
      key: item.uid,
      onClick: () => handleMakerClick(item.link),
      icon: item.icon || getIcon(item.img, layerName),
      tooltip: item.tooltip,
    }))

  render() {
    const {
      markers,
      polygons,
      layersControl,
      mcg,
      layerName,
      layerNameValue,
      needCluster,
      isHidden,
    } = this.props
    const markerArr = this.renderMarkers(markers, layerNameValue)
    const polygonArr = this.renderPolygons(polygons)
    return (
      <SubGroup
        layerName={layerName}
        layerNameValue={layerNameValue}
        mcg={needCluster ? mcg : null}
        layersControl={layersControl}
        markers={markerArr}
        polygons={polygonArr}
        needCluster={needCluster}
        isHidden={isHidden}
      />
    )
  }
}

export default BaseLayerComponent
