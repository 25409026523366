import i18n from 'i18next'

export const attributeQueries = {
  defaultSearch: (type, needSort = true) => {
    const sort = needSort ? 'sort' : ''
    return {
      query: `
        query postQuery {
          results:${type} {
            edges {
              node {
                ${['ru','en'].includes(i18n.language) ? i18n.language: 'local'}Name
                uid
                ${sort}
              }
            }
          }
        }`,
      render: item => {
        return `${item.node[`${['ru','en'].includes(i18n.language) ? i18n.language: 'local'}Name`]}`
      },
    }
  },

  radioDatingMethods: () => {
    return {
      query: `
        query postQuery {
          results:radioDatingMethods {
            description
            uid: value
          }
        }`,
      render: item => {
        return item.description
      },
    }
  },
}
