import { createAction, handleActions } from 'redux-actions';
import findIndex from 'lodash/findIndex';

const SHOW_NOTIFICATION = 'notification/SHOW';
const HIDE_NOTIFICATION = 'notification/HIDE';
const SHOW_NOTIFICATION_WITH_TIMEOUT = 'notification/SHOW_NOTIFICATION_WITH_TIMEOUT';

export const constants = {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SHOW_NOTIFICATION_WITH_TIMEOUT,
};

// ------------------------------------
// Actions
// ------------------------------------
export const show = createAction(SHOW_NOTIFICATION);
export const hide = createAction(HIDE_NOTIFICATION);
export const showNotificationWithTimeout = createAction(SHOW_NOTIFICATION_WITH_TIMEOUT);

export const actions = {
  show,
  hide,
  showNotificationWithTimeout,
};

export const reducers = {
  [SHOW_NOTIFICATION]: (state, { payload }) =>
    ([
      ...state,
      payload,
    ]),
  [HIDE_NOTIFICATION]: (state, { payload }) => {
    const index = findIndex(state, { id: payload })
    if (index >= 0) {
      return [
        ...state.slice(0, index),
        ...state.slice(index + 1),
      ]
    }
    return state;
  },
}

export const initialState = () => {
  const state = [];
  return state;
}

export default handleActions(reducers, initialState());
