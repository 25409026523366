import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import './index.css'

import ProfileOrganization from '../Organization'
import ProfileResearcher from '../Researcher'
import { actions } from '../../../redux/modules/user';

const ORG_ROLE = 'oz'

const mapStateToProps = state => ({
  user: state.user,
})

const mapDispatchToProps = {
  getCurrentUser: actions.getCurrentUser,
}

const Profile = (props) => {
  const {
    t,
    user: { role, organization },
    match,
    history,
    getCurrentUser,
  } = props;
  const [isMobile, setIsMobile] = useState(false)

  const updateDimensions = () => {
    if (window.innerWidth < 680) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    getCurrentUser()

    return () => {
      window.removeEventListener('resize', updateDimensions)
    };
  }, [])

  return (
    <div className="profile-container">
      <div className="user-rol">
        <span className="title">{t('profile.roles.title')}</span>
        <span className="role">{t(`profile.roles.${role}`)}</span>
      </div>
      {
      role && role === ORG_ROLE ? (
        <ProfileOrganization
          isMobile={isMobile}
          orgId={organization}
          section={match.params.section}
          history={history}
        />
      ) : (
        <ProfileResearcher
          role={role}
          isMobile={isMobile}
          section={match.params.section}
          history={history}
        />
      )}
    </div>
  )
}

export default translate()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Profile)
)
