// eslint-disable-next-line import/no-cycle
import * as request from '../../utility/request';

export const registration = async (data) => {
  const path = 'api/registration/';
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}

export const getExistingUser = async (id) => {
  const path = `api/registration/existing-user/uid/${id}/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const setExistingUser = async (id, body) => {
  const path = `api/registration/existing-user/uid/${id}/`;
  const { response, error } = await request.safelyPost(
    path,
    body,
    request.HEADERS_POST_AUTH(),
  )
  return { response, error };
}

export const getExistingUserList = async () => {
  const path = 'api/registration/existing-user/list/';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const linkExistingUser = async (data) => {
  const path = 'api/registration/existing-user/';
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH());
  return { response, error };
}
