import React from 'react'
import {
  Collapse,
  Badge,
} from 'antd';

import './index.scss'

const { Panel } = Collapse;
const CustomPanel = (props) => {
  const {
    title,
    count,
  } = props
  return (
    <Panel
      className="custom_panel"
      {...props}
      header={(
        <span className="type_title">
          {title}
          {count && <Badge
            overflowCount={999}
            count={count}
            style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
          />}
        </span>
      )}
    >
      {props.children}
    </Panel>
  )
}

export default CustomPanel
