import React, { Component, Fragment } from 'react'
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd'
import encQueries from 'components/GSearchPanel/Queries/encQueries'
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters'

import '../../index.scss'
import AutoInputGQL from '../fields/autoInputGQL'
import FileUpload from '../fields/fileUpload'
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions'

const FormItem = Form.Item
const { Panel } = Collapse
const { Option } = Select
const { TextArea } = Input

class PersonTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
    }
  }

  addTab = () => {
    const key = this.newTabIndex++
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state
    newName[key] = data.typed
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = input => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      filelistChanged,
      getFieldDecorator,
      formInputLayout,
      stateTabs,
      createData,
      subjects,
      title,
      form,
      t,
    } = this.props

    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel
              header={`${t('create_panel.tab.person.person')} #${1 + key}`}
              key={title + item.key}
            >
              <AutoInputGQL
                handleSelect={data => {
                  this.handleSelect(data, key)
                }}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.person.choose')}
                selectkey={`person_select_${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.person.add')}
                query={encQueries.personSearch()}
                params={[{ key: 'search', val: 'value' }]}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel="Person"
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') && (
                <div>
                  {item.status === 'new' && (
                    <Fragment>
                      <FormItem
                        {...formInputLayout}
                        label={t('create_panel.tab.person.name')}
                      >
                        {getFieldDecorator(`person_name_${key}`, {
                          ...config.req,
                          initialValue: this.state.newName[key],
                        })(
                          <Input
                            onChange={this.handleChangeName}
                            data-objkey={key}
                          />,
                        )}
                      </FormItem>
                      <FormItem
                        {...formInputLayout}
                        rules={[{ required: true }]}
                        label={t('create_panel.tab.person.years')}
                      >
                        {getFieldDecorator(`person_birth_year_${key}`, {
                          ...config.req,
                        })(
                          <InputNumber
                            placeholder={t('common.birthyear')}
                            min={1700}
                            max={new Date().getFullYear()}
                          />,
                        )}
                        {getFieldDecorator(
                          `person_death_year_${key}`,
                          {},
                        )(
                          <InputNumber
                            placeholder={t('common.deathyear')}
                            min={1700}
                            max={new Date().getFullYear()}
                          />,
                        )}
                      </FormItem>
                    </Fragment>
                  )}
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="Person"
                    rightLabel="Locality"
                    connectionField="locality"
                    label={t('common.subjects.locality')}
                    selectkey={`person_locality_${key}`}
                    selectType="localities"
                    stateTabs={stateTabs}
                    objkey={key}
                    noAddOption
                    query={encQueries.localitySelectSearch()}
                    params={[{ key: 'commonSearch', val: 'value' }]}
                    t={t}
                  />
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.person.role')}
                  >
                    {getFieldDecorator(
                      `person_role_${key}`,
                      {},
                    )(
                      <TextArea
                        onPaste={e =>
                          textFieldFormatters.onTextAreaPaste(
                            e,
                            form,
                            `person_role_${key}`,
                          )
                        }
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.person.info')}
                  >
                    {getFieldDecorator(
                      `person_info_${key}`,
                      {},
                    )(
                      <TextArea
                        onPaste={e =>
                          textFieldFormatters.onTextAreaPaste(
                            e,
                            form,
                            `person_info_${key}`,
                          )
                        }
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.person.bio')}
                  >
                    {getFieldDecorator(
                      `person_bio_${key}`,
                      {},
                    )(
                      <TextArea
                        onPaste={e =>
                          textFieldFormatters.onTextAreaPaste(
                            e,
                            form,
                            `person_bio_${key}`,
                          )
                        }
                      />,
                    )}
                  </FormItem>
                  <FileUpload
                    getFieldDecorator={getFieldDecorator}
                    formInputLayout={formInputLayout}
                    filelistChanged={filelistChanged}
                    fileLists={createData.sourceFilelist}
                    label={`person_files_${key}`}
                    fileType="photo"
                    title={t('common.photo.title')}
                    t={t}
                  />
                  <RelationComponent
                    handleAddExtraRelation={handleAddExtraRelation}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    objkey={key}
                    title="Person"
                    tabName="persons"
                    stateTabs={stateTabs}
                    form={form}
                    t={t}
                  />
                </div>
              )}
            </Panel>
          ))}
        </Collapse>

        <FormItem wrapperCol={{ span: 8, offset: 8 }}>
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.person.add_button')}
          </Button>
        </FormItem>
      </div>
    )
  }
}

export default PersonTab
