import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const menuItems = [
  // {
  //   link: '',
  //   label: 'nav.main',
  //   type: 'common',
  // },
  // {
  //   link: 'about',
  //   label: 'nav.about',
  // },
  // {
  //   link: 'admin-panel',
  //   label: 'nav.admin',
  // },
  // {
  //   link: 'data-entry',
  //   label: 'nav.importData',
  // },
  // {
  //   link: 'advanced-search',
  //   label: 'nav.bigSearch',
  // },
  // {
  //   link: 'quick-search',
  //   label: 'nav.search',
  //   type: 'common',
  // },
];

export const signOutItem = (exit, { t }) => (
  <Menu.Item key="exit">
    <button
      className="link"
      onClick={(e) => exit(e)}
    >
      {t('nav.signOut')}
    </button>
  </Menu.Item>
);

export const logoItem = (
  <Menu.Item key="/" className="main-link">
    <Link to="/">AnthropoGIS</Link>
  </Menu.Item>
);

export default menuItems;
