import React, { Component } from 'react'
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd'
import { listQueries } from 'components/CreatePanel/queries/getList'
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries'
import { connect } from 'react-redux'
import { actions } from 'redux/modules/create'
import i18n from 'i18next'

import config from '../config'
import AutoInputGQL from '../../fields/autoInputGQL'
import AddPeriodicalIssueModal from '../newPeriodicalIssue'

const FormItem = Form.Item

const mapStateToProps = state => ({
  createData: state.create,
})

const mapDispatchToProps = {
  createIssue: actions.createPeriodicalIssue,
  createPeriodical: actions.createPeriodical,
  newIssueSelected: actions.newPeriodicalIssueSelected,
  newPeriodicalSelected: actions.newPeriodicalSelected,
}

@connect(mapStateToProps, mapDispatchToProps)
class CreateArticleForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newIssueModal: false,
      newPeriodicalModal: false,
      newPublisher: false,
      newPublisherName: '',
      addRuName: false,
      addLocName: false,
    }
  }

  handleChangeIssueSelector = data => {
    console.log('handleChangeIssueSelector', data)
    if (data.value === 'new') {
      this.setState({ newIssueModal: true })
    } else {
      this.setState({ newIssueModal: false })
    }
  }

  hideNewIssueModal = e => {
    this.setState({ newIssueModal: false })
    if (this.props.form.getFieldValue('issue_select') === 'new') {
      this.props.form.setFieldsValue({
        issue_select: null,
      })
    }
  }

  handleChangePeriodicalSelector = data => {
    console.log('handleChangeIssueSelector', data)
    if (data.value === 'new') {
      this.setState({ newPeriodicalModal: true })
    } else {
      this.setState({ newPeriodicalModal: false })
    }
  }

  hideNewPeriodicalModal = e => {
    this.setState({ newPeriodicalModal: false })
  }

  handleIssueChange = data => {
    console.log('handleIssueChange', data)
    this.props.form.setFieldsValue({ issue: data.uid })
  }

  handleSelectPublisher = data => {
    if (data.value === 'new') {
      this.setState({ newPublisher: true, newPublisherName: data.typed })
    } else {
      this.setState({ newPublisher: false })
    }
  }

  render() {
    const {
      getFieldDecorator,
      formItemLayout,
      createIssue,
      createPeriodical,
      createData,
      newIssueSelected,
      newPeriodicalSelected,
      newName,
      t,
    } = this.props
    const formButton = {
      wrapperCol: { offset: 8, span: 10 },
    }

    if (createData.newPeriodicalIssue) {
      this.props.form.setFieldsValue({
        issue_select:
          createData.data.periodicalIssue[
            `${
              ['ru', 'en'].includes(i18n.language) ? i18n.language : 'local'
            }_name`
          ],
        issue: createData.data.periodicalIssue.uid,
      })
      newIssueSelected()
    }

    return (
      <div>
        <FormItem
          label={t('create_panel.modal.addNewReport.en_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('en_name', {
            ...config.en_name,
            initialValue: newName,
          })(<Input />)}
        </FormItem>
        {!this.state.addRuName && (
          <FormItem {...formButton}>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ addRuName: true })
              }}
            >
              {t('create_panel.tab.research.add_ru_name')}
            </Button>
          </FormItem>
        )}
        {this.state.addRuName && (
          <FormItem
            label={t('create_panel.modal.addNewReport.ru_name.title')}
            {...formItemLayout}
          >
            {getFieldDecorator('ru_name')(<Input />)}
          </FormItem>
        )}
        {!this.state.addLocName && (
          <FormItem {...formButton}>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ addLocName: true })
              }}
            >
              {t('create_panel.tab.research.add_loc_name')}
            </Button>
          </FormItem>
        )}
        {this.state.addLocName && (
          <FormItem
            label={t('create_panel.modal.addNewReport.loc_name.title')}
            {...formItemLayout}
          >
            {getFieldDecorator('local_name')(<Input />)}
          </FormItem>
        )}
        <FormItem
          label={t('create_panel.modal.addNewReport.doi.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('doi')(<Input />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('create_panel.modal.addNewReport.year_pub.title')}
        >
          {getFieldDecorator(
            'year',
            config.year,
          )(<InputNumber min={1700} max={new Date().getFullYear()} />)}
        </FormItem>
        <AutoInputGQL
          handleSelect={this.handleChangeIssueSelector}
          newObjChanged={this.handleIssueChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="issue_select"
          label={t('create_panel.modal.addNewReport.issue.title')}
          addOptionText={t('create_panel.modal.addNewReport.create.issue')}
          rules={config.issue}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.issueSearch()}
          t={t}
        />
        <FormItem
          {...formItemLayout}
          label={t('create_panel.modal.addNewReport.pages_book.title')}
        >
          {getFieldDecorator(
            'page_start',
            {},
          )(
            <InputNumber
              placeholder={t('common.date_since')}
              min={1}
              max={10000}
            />,
          )}
          {getFieldDecorator(
            'page_end',
            {},
          )(
            <InputNumber
              placeholder={t('common.date_to')}
              min={1}
              max={10000}
            />,
          )}
        </FormItem>
        <FormItem>{getFieldDecorator('issue', {})(<Input hidden />)}</FormItem>

        <AddPeriodicalIssueModal
          visible={this.state.newIssueModal}
          hide={this.hideNewIssueModal}
          handleSubmit={createIssue}
          periodicalVisible={this.state.newPeriodicalModal}
          periodicalHide={this.hideNewPeriodicalModal}
          periodicalHandleSubmit={createPeriodical}
          periodicalHandleChangeSelector={this.handleChangePeriodicalSelector}
          newPeriodicalSelected={newPeriodicalSelected}
          handleSelectPublisher={this.handleSelectPublisher}
          newPublisher={this.state.newPublisher}
          newPublisherName={this.state.newPublisherName}
          loading={createData.isLoading}
          createData={createData}
          t={t}
        />
      </div>
    )
  }
}

export default CreateArticleForm
