import React from 'react';
import { Modal, Form, Button } from 'antd';
import { withFormik } from 'formik';
import FileUpload from 'components/CreatePanel/templates/fields/fileUpload';
import { getFiles } from 'components/CreatePanel/functions/collectData';

const FormItem = Form.Item;

const InnerForm = ({
                     values,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     handleEdit,
                     isSubmitting,
                     isError,
                     form,
                     visible,
                     loading,
                     hide,
                     filelistChanged,
                     createData,
                     t,
                   }) => {
  if (createData.filesUploaded) {
    hide()
  }

  const { getFieldDecorator } = form;

  const formInputLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  return (
    <Modal
      visible={visible}
      className="create-panel-modal"
      onCancel={hide}
      width={900}
      footer={null}
    >
      <div className="create-panel-modal-header">
        <h2>
          {t(`dataPanel.button.add_source`)}
        </h2>
      </div>

      <FileUpload
        dontNeedBufferField
        getFieldDecorator={getFieldDecorator}
        formInputLayout={formInputLayout}
        filelistChanged={filelistChanged}
        fileLists={createData.sourceFilelist}
        label={`add_source_modal`}
        fileType={`source`}
        title={t(`common.source.title`)}
        t={t}
      />

      <FormItem
        wrapperCol={{ offset: 10, span: 6 }}
      >
        <Button
          type="primary"
          onClick={handleSubmit}
          className="create-form-button"
          disabled={loading}
        >
          {t('common.add')}
        </Button>
      </FormItem>
    </Modal>
  );
}

const AddSourceModal = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      console.log('addSource', fieldsValue)
      if (err) {
        setSubmitting(false);
        return;
      }

      const params = {};

      Object.keys(fieldsValue).forEach(key => {
        if (fieldsValue[key]) {
          params[key] = fieldsValue[key]
        }
      });

      const files = getFiles(params, props.createData, `add_source_modal`, 'Source')
      files.source = true;
      files.label = 'add_source_modal'
      files.type = props.imageType
      files.objUid = props.objUid
      console.log('addSource files', files)

      props.uploadFiles(files);
      setTimeout(setSubmitting(false), 1000);
    })
  },
})(InnerForm));

export default AddSourceModal;
