import * as request from '../../utility/request';
import { GET_HEADERS, HEADERS_POST_AUTH_SMALL } from '../../utility/request'

export const getOrganizationList = async () => {
  const path = 'api/organization/list/';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getOrganization = async (id) => {
  const path = `api/organization/uid/${id}/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getOrgUid = async () => {
  const path = 'api/get_uid/organization/'
  const res = request.safelyGet(path, request.GET_HEADERS())
  return res
}

export const getOrganizationTypeList = async () => {
  const path = 'api/organization_type/list/';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getOrganizationType = async (id) => {
  const path = `api/organization_type/uid/${id}/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const editOrganization = async (id, body) => {
  const path = `api/organization/uid/${id}/`;
  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH(), 'PATCH');
  return { response, error };
}
export const postOrganization = async (body) => {
  const path = 'api/organization/';
  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const postOrganizationAccount = async (body) => {
  const path = 'api/organization/account/create/';
  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const getOrganizationPartnerList = async (id) => {
  const path = `api/organization/uid/${id}/partner/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getOrganizationResearcherList = async (id) => {
  const path = `api/organization/uid/${id}/researcher/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const postOrganizationResearcher = async (id, researcher, body) => {
  const path = `api/organization/uid/${id}/researcher/uid/${researcher}/`;
  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const postOrganizationResearcherEmail = async (id, researcher, body) => {
  const path = `api/organization/uid/${id}/researcher/uid/${researcher}/`;
  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH(), 'PUT');
  return { response, error };
}

export const patchOrganizationResearcher = async (id, researcher, body) => {
  const path = `api/organization/uid/${id}/researcher/uid/${researcher}/`;
  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH(), 'PATCH');
  return { response, error };
}

export const deleteOrganizationResearcher = async (id, researcher) => {
  const path = `api/organization/uid/${id}/researcher/uid/${researcher}/`;
  const { response, error } = await request.safelyGet(path, request.GET_HEADERS(), 'DELETE');
  return { response, error };
}

export const getOrganizationResearchesList = async (id) => {
  const path = `api/organization/uid/${id}/researches/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getOrganizationSharedMeList = async (id, model) => {
  const path = `api/organization/uid/${id}/shared4me/${model}/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getOrganizationSubjectList = async (id, model) => {
  const path = `api/organization/uid/${id}/${model}/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

