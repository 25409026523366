import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';

import '../../index.scss';
import LexemVariant from './lexemVariant';

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class LexemGroup extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;
    this.state = {
      tabs: [],
    };
  }

  addTab = () => {
    const key = this.newTabIndex++;
    this.state.tabs.push({
      key,
    });
    this.forceUpdate()
  }

  handleChange = (type, e) => {
    this.setState({ type });
  }

  render() {
    const {
      objkey,
      getFieldDecorator,
      stateTabs,
      // formItemLayout,
      t,
    } = this.props;

    const {
      tabs,
    } = this.state;

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };

    return (
      <div>
        <div className="create-panel-relations-title">{t('create_panel.tab.lexem.groups')}</div>
        <Collapse marginTop={50}>
          {tabs.map((item, key) => (
            <Panel header={`${t('create_panel.tab.lexem.group')} #${1 + item.key}`} key={item.key}>
              <LexemVariant
                getFieldDecorator={getFieldDecorator}
                t={t}
                key1={this.props.key1}
                key2={key}
                stateTabs={stateTabs}
                formItemLayout={formItemLayout}
              />
            </Panel>
          ))}
        </Collapse>

        <FormItem
          wrapperCol={{ span: 8, offset: 8 }}
        >
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.lexem.add-group')}
          </Button>
        </FormItem>
      </div>
    );
  }
}

export default LexemGroup;
