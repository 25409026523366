const ICONS = {
  0: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M42.859 24.5465L43.9287 23.5112L32.9594 13.1109L22.0413 23.4626L23.1222 24.4874L32.9594 15.1604L42.859 24.5465ZM29.2082 28.7826V30.2577L24.8711 26.1456L32.9594 18.1929L41.1267 26.2232L36.7107 30.4972V28.7826C36.7107 28.2303 36.263 27.7826 35.7107 27.7826H30.2082C29.6559 27.7826 29.2082 28.2303 29.2082 28.7826ZM32.2245 35.3225L21.2003 24.8701L10.176 35.3225L11.2176 36.3845L21.2003 26.9196L31.183 36.3845L32.2245 35.3225ZM12.9476 38.0665L21.2003 29.952L29.453 38.0665V46.9185H24.9515V40.5418C24.9515 39.9895 24.5038 39.5418 23.9515 39.5418H18.4491C17.8968 39.5418 17.4491 39.9895 17.4491 40.5418V46.9185H12.9476V38.0665ZM55.5181 35.4039L44.7187 24.9515L33.9192 35.4039L34.9395 36.4659L44.7187 27.0011L54.4978 36.4659L55.5181 35.4039ZM36.6342 38.1479L44.7186 30.0335L52.8031 38.1479V47H48.3934V40.6233C48.3934 40.071 47.9457 39.6233 47.3934 39.6233H42.0439C41.4916 39.6233 41.0439 40.071 41.0439 40.6233V47H36.6342V38.1479Z" fill="white" />
    </svg>
  `,
}
ICONS['default'] = ICONS[0]

export default ICONS
