import { createAction, handleActions } from 'redux-actions'

const LOADING_RESET = 'loading/RESET'
const LOADING_START = 'loading/START'
const LOADING_LOADING = 'loading/LOADING'
const LOADING_SUCCESS = 'loading/SUCCESS'
const LOADING_ERROR = 'loading/ERROR'

export const constants = {
  LOADING_RESET,
  LOADING_START,
  LOADING_LOADING,
  LOADING_SUCCESS,
  LOADING_ERROR,
}

// ------------------------------------
// Actions
// ------------------------------------
export const reset = createAction(LOADING_RESET)
export const start = createAction(LOADING_START)
export const loading = createAction(LOADING_LOADING)
export const success = createAction(LOADING_SUCCESS)
export const error = createAction(LOADING_ERROR)

export const actions = {
  reset,
  start,
  loading,
  success,
  error,
}

export const initialState = () => {
  const state = {
    loading: new Map(),
    success: new Map(),
    error: new Map(),
    status: null,
    isStarted: false,
  }
  return state
}

const addToMap = (mapObj, payload) => {
  const newMap = new Map(mapObj)
  newMap.set(payload.title, payload)
  return newMap
}

const moveTo = (state, payload, to) => {
  const newState = { ...state }
  newState.loading.delete(payload.title)
  newState[to].set(payload.title, payload)
  // check if loading list is empty to change loading icon
  if (newState.loading.size === 0) {
    if (!newState.status) {
      newState.status = 'success'
    }
  }
  if (to === 'error') {
    newState.status = 'warning'
  }
  return newState
}

export const reducers = {
  [LOADING_RESET]: () => initialState(),
  [LOADING_LOADING]: (state, { payload }) => {
    return {
      ...state,
      loading: addToMap(state.loading, payload),
    }
  },
  [LOADING_START]: state => {
    return {
      ...state,
      isStarted: true,
    }
  },
  [LOADING_SUCCESS]: (state, { payload }) => {
    return moveTo(state, payload, 'success')
  },
  [LOADING_ERROR]: (state, { payload }) => {
    return moveTo(state, payload, 'error')
  },
}

export default handleActions(reducers, initialState())
