/**
 * @flow
 * @relayHash f66c7b5312b1f28f74f6fc13d90aa537
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessObjectEnum = "PRIVATE" | "PUBLIC" | "SHARED" | "%future added value";
export type ModelPermissionEnum = "RESEARCH" | "SELECTION" | "SOURCE" | "SUBJECT" | "%future added value";
export type MakeDecisionInputType = {
  model: ModelPermissionEnum,
  uid: any,
  decision: boolean,
  requester: any,
};
export type ToMeMutationVariables = {|
  data: MakeDecisionInputType
|};
export type ToMeMutationResponse = {|
  +makeDecisionRequest: ?{|
    +permission: ?AccessObjectEnum
  |}
|};
export type ToMeMutation = {|
  variables: ToMeMutationVariables,
  response: ToMeMutationResponse,
|};
*/


/*
mutation ToMeMutation(
  $data: MakeDecisionInputType!
) {
  makeDecisionRequest(data: $data) {
    permission
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "MakeDecisionInputType!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "makeDecisionRequest",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data",
        "type": "MakeDecisionInputType"
      }
    ],
    "concreteType": "MakeDecisionRequestMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "permission",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ToMeMutation",
  "id": null,
  "text": "mutation ToMeMutation(\n  $data: MakeDecisionInputType!\n) {\n  makeDecisionRequest(data: $data) {\n    permission\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ToMeMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ToMeMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0dd09fd179939a285ea2d1f66f301edd';
module.exports = node;
