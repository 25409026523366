import React, { Component } from 'react'
import { Tabs, Alert, Spin } from 'antd'
import { connect } from 'react-redux'
import { CRS } from 'leaflet'
import LeafletMap from 'components/Map'
import MapLegend from 'components/MapLegend'
import { actions } from 'redux/modules/map'
import './index.scss'
// eslint-disable-next-line import/no-cycle
import { TileLayerList, OverlayTileLayerList } from '../Map/tileLayerList'

const mapStateToProps = state => ({
  user: state.user,
  map: state.map,
})

const mapDispatchToProps = {
  handleMapsFetch: actions.fetchMaps,
}

@connect(mapStateToProps, mapDispatchToProps)
class MapPanel extends Component {
  state = {
    isSnapshot: false,
  }

  componentDidMount() {
    this.props.handleMapsFetch()
  }

  toggleSnapshot = () => {
    this.setState(oldState => ({
      isSnapshot: !oldState.isSnapshot,
    }))
  }

  setNext = () => {
    this.setState(oldState => ({
      i: oldState.i += 1
    }))
  }
setPrev = () => {
  this.setState(oldState => ({
  i: oldState.i -= 1
}))
}

  setIsMap = () => {
    this.setState(oldState => ({
      isMap: true,
      is3d: false,
      isPhoto: false
    }))
  }
  setIs3d = () => {
    this.setState(oldState => ({
      isMap: false,
      is3d: true,
      isPhoto: false
    }))
  }
  setIsPhoto = () => {
    this.setState(oldState => ({
      isPhoto: true,
      isMap: false,
      is3d: false
    }))
  }

  render() {
    const {
      t,
      content,
      handleChangeInteractiveMarker,
      model3d,
      user: { isAuthenticated },
      isPointsLoading,
      initialPolygon,
      className,
      settings,
      map: { mapTabs, overlayMapTabs, isOk: mapsIsOk },
    } = this.props
    const { TabPane } = Tabs
    const interactiveMarkers = this.props.interactiveMarkers || []
    const model3dTab = t('map_panel.tabs.3d')
    return (
      <div className={className || 'map-panel'}>
        {isPointsLoading && <Spin size="large" />}
        <Tabs type="card" className="map-tab-pane">
          {Object.keys(mapTabs).map((tab, id) => (
            <TabPane
              tab={t(`map_panel.tabs.${tab}`)}
              key={tab}
              className="map-tab-pane"
            >
              <div className="map-tab">
                {this.state.isSnapshot && (
                  <div className="map-is-snapshot">
                    <Spin size="large" />
                  </div>
                )}
                {!isAuthenticated && (
                  <div className="map-top-alert">
                    <Alert
                      message={t('common.mapAlert')}
                      type="warning"
                      closable
                      showIcon
                    />
                  </div>
                )}
                <LeafletMap
                  content={content}
                  t={t}
                  maps={mapTabs[tab]}
                  overlayMaps={overlayMapTabs[tab]}
                  interactiveMarkers={interactiveMarkers}
                  initialPolygon={initialPolygon}
                  toggleSnapshot={this.toggleSnapshot}
                  handleChangeInteractiveMarker={handleChangeInteractiveMarker}
                />
              </div>
            </TabPane>
          ))}
          {model3d && (
            <TabPane tab={model3dTab} key={model3dTab} className="map-tab-pane">
              <div className="map-tab">
                <div className="sketchfab-embed-wrapper">
                  <iframe
                    title={model3dTab}
                    src={`${model3d}?autospin=0.2&amp;preload=1`}
                    frameBorder="0"
                    allow="autoplay; fullscreen; vr"
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true"
                  />
                </div>
              </div>
            </TabPane>
          )}
        </Tabs>
        <MapLegend settings={settings} />
      </div>
    )
  }
}

export default MapPanel
