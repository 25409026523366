import { put, fork, takeLatest, call, all } from 'redux-saga/effects'

import { graphqlPost } from 'redux/api/graphql'
import { constants, actions } from '../modules/quickSearch'
import {
  getResearchSpatialReference,
  getHeritageSpatialReference,
} from '../api/research'
import { getName } from '../../utility/name'
import { getSubjectSpatials } from '../api/subject'
import { relayDefinition, getAttributes } from '../../utility/graphql'

function* fetchReseachSpatials(response) {
  console.log('fetchReseachSpatials', response)
  const spatials = yield all(
    response.map(item => call(getResearchSpatialReference, item.node.uid)),
  )
  const uids = []
  const pointsObj = {
    points: spatials.reduce((result, item, index) => {
      if (!item.response) {
        return result || []
      }

      const { node } = response[index]
      const typeDefinition = relayDefinition(node, 'type')
      const uniqCoords = {}
      return result.concat(
        item.response.results.map(point => {
          if (uids.includes(point.uid)) {
            return {}
          }
          if (point.points in uniqCoords) {
            return {}
          }
          uids.push(point.uid)
          uniqCoords[point.points] = point.points
          return {
            title: getName(response, index, 'research'),
            subject_id: node.uid,
            points: point.points,
            inherited: true,
            point: [point.latitude, point.longitude],
            uid: point.uid,
            label: node.label,
            type: typeDefinition[0] ? typeDefinition[0].sort : null,
          }
        }),
      )
    }, []),
    name: 'research',
    type: 'research',
  }
  console.log('pointsObj', pointsObj)
  yield put(actions.simpleSearchSpatials(pointsObj))
}

function* fetchHeritageSpatials(response) {
  console.log('fetchHeritageSpatials', response)
  const spatials = yield all(
    response.map(item => call(getHeritageSpatialReference, item.node.uid)),
  )
  const uids = []
  const pointsObj = {
    points: spatials.reduce((result, item, index) => {
      if (!item.response) {
        return result || []
      }

      const { node } = response[index]
      const typeDefinition = relayDefinition(node, 'type')
      const uniqCoords = {}
      return result.concat(
        item.response.results.map(point => {
          if (uids.includes(point.uid)) {
            return {}
          }
          if (point.points in uniqCoords) {
            return {}
          }
          uids.push(point.uid)
          uniqCoords[point.points] = point.points
          return {
            title: getName(response, index, 'heritage'),
            subject_id: node.uid,
            points: point.points,
            uid: point.uid,
            type: typeDefinition[0] ? typeDefinition[0].sort : null,
          }
        }),
      )
    }, []),
    name: 'heritage',
    type: 'subjects/Heritage',
  }
  yield put(actions.simpleSearchSpatials(pointsObj))
}

function getSubjectSpatial(response, spatials, groupName) {
  console.log('getSubjectSpatial', response, spatials, groupName)
  return {
    points: spatials.reduce((result, item, index) => {
      console.log(item, index, response[index])
      if (!item.response) {
        return result || []
      }

      const { node } = response[index]
      const locType = relayDefinition(node, 'localityType')
      const attributes = getAttributes(node)
      console.log('fetchSubjectSpatials1', node, locType)
      const points = item.response.results || item.response
      const localPoints =
        points[0] && points[0].localities ? points[0].localities : points
      console.log('fetchSubjectSpatials2', node, locType)

      if (!item.error) {
        return result.concat(
          localPoints.map(point => {
            return {
              title: getName(response, index, 'commonRuName') || response[index].node.commonRuName,
              subject_id: response[index].node.uid,
              points: point.points,
              group_number: point.group_number || '',
              index,
              inherited: point.is_inherited,
              point: [point.latitude, point.longitude],
              attributes: point.attributes,
              uid: point.uid,
              locType: locType[0] ? locType[0].sort : null,
              nodeAttributes: attributes,
            }
          }),
        )
      }
      return []
    }, []),
    name: groupName.split('/')[1],
    type: groupName,
  }
}

function* fetchSubjectSpatialForAutoInput(data) {
  const { spatials, select, key } = data.payload
  console.log('fetchSubjectSpatials', data)
  const points = yield all(
    spatials.map(item => call(getSubjectSpatials, item.node.uid, select)),
  )
  const pointsObj = getSubjectSpatial(spatials, points, select)

  console.log('pointsObj', pointsObj)
  yield put(actions.simpleSearchDeleteSpatials())
  yield put(actions.simpleSearchSpatialAutoinputSuccess({ pointsObj, key }))
}

function* fetchSubjectSpatials(response, select) {
  console.log('fetchSubjectSpatials', response, select)
  const spatials = yield all(
    response
      .filter(item => item.node)
      .map(item => {
        if (item.node) {
          return call(getSubjectSpatials, item.node.uid, select)
        }
      }),
  )
  const pointsObj = getSubjectSpatial(response, spatials, select)

  console.log('pointsObj', pointsObj)
  yield put(actions.simpleSearchSpatials(pointsObj))
}

export function* fetchSearch(action) {
  console.log('fetchSearchGraphql', action.payload)
  yield put(actions.simpleSearchStart())
  const { query, params: vars, url: select } = action.payload
  const { response, error } = yield call(graphqlPost, { query, vars })
  const count = response.data.results.totalCount

  if (response) {
    yield put(
      actions.simpleSearchSaveQuery({
        params: vars,
        count: count || 0,
        query,
        url: select,
      }),
    )
    yield put(actions.simpleSearchData(response.data))

    const nodes = response.data.results.edges || []
    if (count > 0) {
      if (select === 'research') {
        console.log('fetchReseachSpatials1', nodes)
        yield fetchReseachSpatials(nodes)
      } else if (select === 'subjects/Heritage') {
        console.log('heritage search spatials')
        yield fetchHeritageSpatials(nodes)
      } else if (
        select.split('/')[0] === 'subjects' ||
        select.split('/')[0] === 'linguistic'
      ) {
        yield fetchSubjectSpatials(nodes, select)
      }
    }
  } else {
    yield put(actions.simpleSearchFail())
  }
}

function* watchActions() {
  yield takeLatest(constants.SEARCH, fetchSearch)

  yield takeLatest(constants.SEARCH_SPATIAL, fetchSubjectSpatialForAutoInput)
}

export const searchSaga = [fork(watchActions)]
