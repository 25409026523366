import React from 'react'
import {
  TAB_TYPE,
  getFullName,
} from 'utility/transformData/constants';
import getResearchFields from 'utility/transformData/fields/research';
import getBookFields from 'utility/transformData/fields/book';
import getTopoFields from 'utility/transformData/fields/topo';
import getReportFields from 'utility/transformData/fields/report';
import getArticlePeriodicalFields from 'utility/transformData/fields/articlePeriodical';
import getPublicationFields from 'utility/transformData/fields/publication';
import getResearchTypeFields from 'utility/transformData/fields/researchType';
import getSiteFields from 'utility/transformData/fields/site';
import getExcFields from 'utility/transformData/fields/excavation';
import getLayerFields from 'utility/transformData/fields/layer';
import getAuthorFields from 'utility/transformData/fields/author';
import getAssemblageFields from 'utility/transformData/fields/assemblage';
import getRadiocarbonFields from 'utility/transformData/fields/radiocarbon';
import getBookSectionFields from 'utility/transformData/fields/bookSection';
import getArtifactFields from 'utility/transformData/fields/artifact';
import getCultureFields from 'utility/transformData/fields/culture';
import getPersonFields from 'utility/transformData/fields/person';
import getLocalityFields from 'utility/transformData/fields/locality';
import getDistrictFields from 'utility/transformData/fields/district';
import getPopulationFields from 'utility/transformData/fields/populations';
import getEncorganizationFields from 'utility/transformData/fields/encorganization';
import getEncOrgTypeFields from 'utility/transformData/fields/encOrgType';
import getBuildingFields from 'utility/transformData/fields/building';
import getLexemeFields from 'utility/transformData/fields/lexeme';
import getLexemeSortFields from 'utility/transformData/fields/lexemeSort';
import getLexemeGroupFields from 'utility/transformData/fields/lexemeGroup';
import getVariantFields from 'utility/transformData/fields/variant';
import getVariantLanguageFields from 'utility/transformData/fields/variantLanguage';
import getRadiocarbonSummaryFields from 'utility/transformData/fields/radiocarbonSummary';
import getTermFields from 'utility/transformData/fields/term';
import getHeritageFields from 'utility/transformData/fields/heritage';

const getFields = {
  research: getResearchFields,
  site: getSiteFields,
  excavation: getExcFields,
  layer: getLayerFields,
  author: getAuthorFields,
  report: getReportFields,
  publication: getPublicationFields,
  book: getBookFields,
  preport: getReportFields,
  radiocarbon: getRadiocarbonFields,
  assemblage: getAssemblageFields,
  booksection: getBookSectionFields,
  articleperiodical: getArticlePeriodicalFields,
  artifact: getArtifactFields,
  culture: getCultureFields,
  person: getPersonFields,
  locality: getLocalityFields,
  district: getDistrictFields,
  population: getPopulationFields,
  encorganization: getEncOrgTypeFields,
  encorgType: getEncOrgTypeFields,
  monument: getBuildingFields,
  lexeme: getLexemeSortFields,
  variant: getLexemeGroupFields,
  variantwithoutgroup: getVariantLanguageFields,
  researchtype: getResearchTypeFields,
  lingresearches: getResearchTypeFields,
  topo: getTopoFields,
  radiocarbonsummary: getRadiocarbonSummaryFields,
  term: getTermFields,
  heritage: getHeritageFields,
};

export function transformData(source) {
  console.info('source', source);
  const objType = source.label ? source.label.toLowerCase() : source.mainTabType;
  const tabType = source.tabType ||
    ((objType === source.mainTabType) ? TAB_TYPE.MAIN : TAB_TYPE.COLLAPSE);
  console.info('types', tabType, objType);
  const content = getFields[objType](source, tabType)
  console.info('fields', content)
  // ToDo rewrite names!!!!!
  const names = (source.results && source.results[0] && source.results[0].names) ? source.results[0].names.join(', ') : '';
  const variantNames = (source.names) ? source.names.join(', ') : '';
  const title = (tabType === TAB_TYPE.MAIN) ? source.name || source.ru_name || names || variantNames ||
    (source.results && source.results[0] && source.results[0].name ? source.results[0].name :
      getFullName(source))
    : '';
  console.info('title', title, source.name)
  let model3d;
  if (tabType === TAB_TYPE.MAIN) {
    model3d = source.model3d || '';
  }
  const sourceResult = source.result || source.results || [];
  return {
    name: {
      title: (tabType === TAB_TYPE.MAIN) ? 'info' : objType,
      count: (tabType === TAB_TYPE.MAIN) ? null : (source.count || sourceResult.length || content.length),
    },
    mainTabUid: source.mainTabUid || null,
    contentType: (tabType === TAB_TYPE.MAIN || objType === 'population') ? 'list' : tabType,
    subjectType: objType,
    model3d,
    title,
    content,
  };
}

// export function transformDataGraph(source) {
//   // console.info('source', source);
//   const objType = source.label ? source.label.toLowerCase() : source.mainTabType;
//   const tabType = source.tabType ||
//     ((objType === source.mainTabType) ? TAB_TYPE.MAIN : TAB_TYPE.COLLAPSE);
//   // console.info('types', tabType, objType);
//   // const content = getFields[objType](source, tabType)
//   // console.info('fields', content)
//   // // ToDo rewrite names!!!!!
//   // const names = (source.results && source.results[0] && source.results[0].names) ? source.results[0].names.join(', ') : '';
//   // const variantNames = (source.names) ? source.names.join(', ') : '';
//   // const title = (tabType === TAB_TYPE.MAIN) ? source.name || source.ru_name || names || variantNames ||
//   //   (source.results && source.results[0] && source.results[0].name ? source.results[0].name :
//   //     getFullName(source))
//   //   : '';
//   // console.info('title', title, source.name)
//   let model3d;
//   if (tabType === TAB_TYPE.MAIN) {
//     model3d = source.model3d || '';
//   }
//   // const sourceResult = source.result || source.results || [];
//   return {
//     name: {
//       title: (tabType === TAB_TYPE.MAIN) ? 'info' : objType,
//       count: (tabType === TAB_TYPE.MAIN) ? null : (source.count || content.length),
//     },
//     mainTabUid: source.mainTabUid || null,
//     contentType: (tabType === TAB_TYPE.MAIN || objType === 'population') ? 'list' : tabType,
//     subjectType: objType,
//     model3d,
//     title,
//     content,
//   };
// }
