import * as request from '../../utility/request';
import { spatialType } from './subject';


export const getResearchList = async (params) => {
  const path = `api/research/list/${params || ''}`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getHeritage = async (id) => {
  const path = `api/subject/uid/${id}/heritage/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getResearch = async (id) => {
  const path = `api/research/uid/${id}/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getResearchExcavations = async (id) => {
  const path = `api/research/uid/${id}/custom_excavations/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getResearchTypeList = async () => {
  const path = 'api/research_type/list/';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getResearchType = async (id) => {
  const path = `api/research_type/uid/${id}/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getResearchInfo = async (id) => {
  const path = `api/research/uid/${id}/info/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getResearchTabs = async (id) => {
  const path = `api/research/uid/${id}/tabs/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getResearchLabelList = async (id, label) => {
  const path = `api/research/uid/${id}/${label}/list/?limit=1000`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getResearchLabelSpatialReference = async (id, label) => {
  const path = `api/research/uid/${id}/${label}/spatial_reference/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getResearchSpatialReference = async (id) => {
  const path = `api/research/uid/${id}/spatial_reference/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getHeritageSpatialReference = async (id) => {
  const path = `api/heritage/uid/${id}/spatial_reference/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}
