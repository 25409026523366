/**
 * @flow
 * @relayHash e7954638ad5899e3117b035821d71c02
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ModelPermissionEnum = "RESEARCH" | "SELECTION" | "SOURCE" | "SUBJECT" | "%future added value";
export type MyQueryVariables = {||};
export type MyQueryResponse = {|
  +me: ?{|
    +myAccessRequestsToObjects: ?$ReadOnlyArray<?{|
      +uid: ?string,
      +model: ?ModelPermissionEnum,
      +name: ?string,
      +label: ?string,
      +decision: ?boolean,
      +createdAt: ?string,
      +wasDecision: ?boolean,
      +author: ?$ReadOnlyArray<?{|
        +uid: ?string,
        +publicName: ?string,
      |}>,
    |}>
  |}
|};
export type MyQuery = {|
  variables: MyQueryVariables,
  response: MyQueryResponse,
|};
*/


/*
query MyQuery {
  me {
    myAccessRequestsToObjects {
      uid
      model
      name
      label
      decision
      createdAt
      wasDecision
      author {
        uid
        publicName
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "model",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "decision",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wasDecision",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MyQuery",
  "id": null,
  "text": "query MyQuery {\n  me {\n    myAccessRequestsToObjects {\n      uid\n      model\n      name\n      label\n      decision\n      createdAt\n      wasDecision\n      author {\n        uid\n        publicName\n        id\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MyQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "myAccessRequestsToObjects",
            "storageKey": null,
            "args": null,
            "concreteType": "MyAccessRequestsNode",
            "plural": true,
            "selections": [
              v0,
              v1,
              v2,
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "author",
                "storageKey": null,
                "args": null,
                "concreteType": "AuthorNode",
                "plural": true,
                "selections": [
                  v0,
                  v7
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MyQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "myAccessRequestsToObjects",
            "storageKey": null,
            "args": null,
            "concreteType": "MyAccessRequestsNode",
            "plural": true,
            "selections": [
              v0,
              v1,
              v2,
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "author",
                "storageKey": null,
                "args": null,
                "concreteType": "AuthorNode",
                "plural": true,
                "selections": [
                  v0,
                  v7,
                  v8
                ]
              }
            ]
          },
          v8
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '953bf5f6e611e804aa8c3b89f416f392';
module.exports = node;
