import React, { Component } from 'react';

const LingFooter = (props) => (
  <footer className="footer">
    <span className="copyright">
      <a href="https://www.osu.eu" target="_blank" rel="noopener noreferrer">© Проект РНФ №16-18-02007</a>

    </span>
    <div className="logos">
      <a href="mailto:vovia@udm.ru">Руководитель проекта - В.В. Напольских vovia@udm.ru</a>
    </div>
  </footer>
)

export default LingFooter;
