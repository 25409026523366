import React from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import { Form, Input, Icon, Button, Alert } from 'antd';

import './index.css';

const FormItem = Form.Item;

const InnerForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  isError,
  t,
}) => (
  <Form onSubmit={handleSubmit} className="login-form">
    {isError && <Alert message={t('logIn.form.error.text')} type="error" />}
    <FormItem
      validateStatus={touched.email && errors.email && 'error'}
      help={touched.email && errors.email && errors.email}
    >
      <Input
        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder={t('logIn.form.username.title')}
        type="text"
        name="email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
      />
    </FormItem>
    <FormItem
      validateStatus={touched.password && errors.password && 'error'}
      help={touched.password && errors.password && errors.password}
    >
      <Input
        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
        type="password"
        placeholder={t('logIn.form.password.title')}
        name="password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
      />
    </FormItem>

    <Button
      type="primary"
      htmlType="submit"
      className="login-form-button"
      disabled={isSubmitting}
    >
      {t('logIn.form.button')}
    </Button>
    <div className="footer_link">
      <Link to="/signup">{ t('signUp.form.title') }</Link>
      <Link to="/recovery">{ t('recovery.title') }</Link>
    </div>
  </Form>
)

// Wrap our form with the using withFormik HoC
const LoginForm = withFormik({
  // Transform outer props into form values
  mapPropsToValues: () => ({ email: '', password: '' }),
  // Add a custom validation function (this can be async too!)
  validate: (values, props) => {
    const errors = {};
    const { t } = props;
    if (!values.email) {
      errors.email = t('logIn.form.email.error');
    }
    if (!values.password) {
      errors.password = t('logIn.form.password.error');
    }
    return errors;
  },
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    props.handleSubmit(values);
    setTimeout(setSubmitting(false), 1000);
  },
})(InnerForm);

export default LoginForm;
