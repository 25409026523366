import React, { PureComponent } from 'react'
import { Tabs, Badge, Tooltip, Icon } from 'antd'
import { translate } from 'react-i18next'

import TabList from '../TabContent/ListTab'
// eslint-disable-next-line import/no-cycle
import CollapseTab from '../TabContent/CollapseTab'
// eslint-disable-next-line import/no-cycle
import GalleryTab from '../TabContent/GalleryTab'
// eslint-disable-next-line import/no-cycle
import RadioTab from '../TabContent/RadioTab'
import './index.css'

const tabs = {
  list: TabList,
  collapse: CollapseTab,
  gallery: GalleryTab,
  radio: RadioTab,
}

function TabComponent(props) {
  console.log(props)
  const Component = tabs[props.type]

  return (
    <Component
      content={props.content}
      mainTabUid={props.mainTabUid}
      subjectType={props.subjectType}
    />
  )
}

@translate()
class DataPanelContent extends PureComponent {
  render() {
    const { t, isLoading } = this.props
    const { TabPane } = Tabs
    console.log('tabs', this.props.tabs)

    return (
      <Tabs type="card">
        {!isLoading &&
          this.props.tabs.map(tab => (
            <TabPane
              tab={
                <span>
                  {t(`tab.${tab.name.title}`)}
                  {tab.name.title !== 'info' && (
                    <Tooltip title={t(`description.${tab.name.title}`)}>
                      <Icon
                        className="description-icon"
                        type="question-circle"
                      />
                    </Tooltip>
                  )}
                  {tab.name.count && (
                    <Badge
                      count={tab.name.count}
                      overflowCount={999}
                      style={{
                        backgroundColor: '#fff',
                        color: '#999',
                        boxShadow: '0 0 0 1px #d9d9d9 inset',
                      }}
                    />
                  )}
                </span>
              }
              key={tab.name.title}
            >
              <TabComponent
                content={tab.content}
                mainTabUid={tab.mainTabUid}
                type={tab.contentType}
                subjectType={tab.subjectType}
              />
            </TabPane>
          ))}
      </Tabs>
    )
  }
}

export default DataPanelContent
