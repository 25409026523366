import {
  TAB_TYPE,
  createCommonField,
  createImageField,
  fullNameToShort,
} from 'utility/transformData/constants';

export default function getAuthorFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN:
    {
      const obj = [
        createCommonField('name', fullNameToShort(d)),
        createCommonField('year', d.birthday),
        createCommonField('biography', d.biography || '-'),
      ];
      if (d.photo) {
        obj.unshift(
          createImageField('photo', d.photo),
        )
      }
      return obj;
    }
    default:
      return [];
  }
}
