import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';
import archQueries from 'components/GSearchPanel/Queries/archQueries';
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters';

import '../../index.scss';
import AutoInputGQL from '../fields/autoInputGQL';
import CoordinatesInput from '../fields/coordinatesInput';
import FileUpload from '../fields/fileUpload';
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions';

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class ExcavationTab extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 1;
    this.state = {
      newName: [],
      layers: [['0']],
    }
  }

  addTab = () => {
    const key = this.newTabIndex++;
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state;
    newName[key] = data.typed;
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  getLayerCounter(key) {
    if (!this.state.layers[key]) {
      this.state.layers[key] = ['0']
    }
    return this.state.layers[key];
  }

  handleAddLayer = (key) => {
    this.state.layers[key].push('0')
    this.forceUpdate()
  }

  handleChangeName = (input) => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      getFieldDecorator,
      filelistChanged,
      formInputLayout,
      stateTabs,
      createData,
      subjects,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props;

    const config = {
      req: {
        rules: [{
          required: true, message: t('common.default_error'),
        }],
      },
    }

    const formButton = {
      wrapperCol: { offset: 8, span: 10 },
    };

    const { layers } = this.state;

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel header={`${t('create_panel.tab.excavation.excavation')} #${1 + key}`} key={title + item.key}>
              <AutoInputGQL
                handleSelect={(data) => {this.handleSelect(data, key)}}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.excavation.choose')}
                selectkey={`excavation_select_${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.excavation.add')}
                query={archQueries.excavationSearch()}
                params={[{ key: 'search', val: 'value' }]}
                rules={config.req}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel={'Excavation'}
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') &&
              <div>
                {item.status === 'new' &&
                  <div>
                    {this.getLayerCounter(key).map((item, i) => (
                      <AutoInputGQL
                        handleSelect={commonFunctions.handleAutoInputChange}
                        handleTabAddRelation={this.props.handleTabAddRelation}
                        handleChangeStateTab={this.props.handleChangeStateTab}
                        getFieldDecorator={getFieldDecorator}
                        formLayout={formInputLayout}
                        title={this.props.title}
                        leftLabel={'Excavation'}
                        rightLabel={'Layer'}
                        connectionField={'layer'}
                        label={`${t('create_panel.tab.excavation.site')} ${i+1}`}
                        selectkey={`excavation_layer_${key}_${i}`}
                        selectType={`layers`}
                        selectTypeKey={`${i}`}
                        stateTabs={stateTabs}
                        objkey={key}
                        noAddOption
                        query={archQueries.layerSearch()}
                        params={[{ key: 'search', val: 'value' }]}
                        t={t}
                      />
                    ))}
                    <FormItem
                      {...formButton}
                    >
                      {getFieldDecorator('add_layer', {})(
                        <Button
                          type="primary"
                          onClick={() => this.handleAddLayer(key)}
                        >
                          {t('create_panel.tab.excavation.add_layer')}
                        </Button>)}
                    </FormItem>
                  </div>
                }
                <FormItem
                  {...formInputLayout}
                  label={t('create_panel.tab.excavation.name')}
                >
                  {getFieldDecorator(`excavation_name_${key}`, {
                    ...config.req,
                    initialValue: this.state.newName[key],
                  })(
                    <Input onChange={this.handleChangeName} data-objkey={key} />)}
                </FormItem>
                <CoordinatesInput
                  getFieldDecorator={getFieldDecorator}
                  formLayout={formInputLayout}
                  title={title}
                  subjname="excavation"
                  objkey={key}
                  handleAddMarker={handleAddMarker}
                  interactiveMarkers={interactiveMarkers}
                  form={form}
                  t={t}
                />
                <AutoInputGQL
                  getFieldDecorator={getFieldDecorator}
                  formLayout={formInputLayout}
                  label={t('create_panel.tab.excavation.boss')}
                  selectkey={`excavation_boss_${key}`}
                  objkey={item.key}
                  handleChange={this.handleRelationChange}
                  placeholder={t('common.hints.author.name')}
                  noAddOption
                  query={archQueries.authorSearch()}
                  params={[{ key: 'search', val: 'value' }]}
                  t={t}
                />
                <FormItem
                  {...formInputLayout}
                  label={t('create_panel.tab.excavation.year')}
                >
                  {getFieldDecorator(`excavation_year_${key}`, config.req)(
                    <InputNumber />)}
                </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={t('create_panel.tab.excavation.area')}
                >
                  {getFieldDecorator(`excavation_area_${key}`, {
                  })(
                    <InputNumber placeholder="m²" />)}
                </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={t('common.description')}
                >
                  {getFieldDecorator(`excavation_desc_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `excavation_desc_${key}`)} />)}
                </FormItem>
                <FileUpload
                  getFieldDecorator={getFieldDecorator}
                  formInputLayout={formInputLayout}
                  filelistChanged={filelistChanged}
                  fileLists={createData.sourceFilelist}
                  label={`excavation_files_${key}`}
                  fileType={`photo`}
                  title={t(`common.topo.title`)}
                  t={t}
                />
                <RelationComponent
                  handleAddExtraRelation={handleAddExtraRelation}
                  getFieldDecorator={getFieldDecorator}
                  formLayout={formInputLayout}
                  objkey={key}
                  title="Excavation"
                  tabName="excavations"
                  stateTabs={stateTabs}
                  form={form}
                  t={t}
                />
              </div>
              }
            </Panel>
          ))}
        </Collapse>

        <FormItem
          wrapperCol={{ span: 8, offset: 8 }}
        >
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.excavation.add_button')}
          </Button>
        </FormItem>
      </div>
    );
  }
}

export default ExcavationTab;
