/**
 * @flow
 * @relayHash 6f01e6e0f3fb1b70636e6cf925b9738f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateSelectionIT = {
  name?: ?string,
  description?: ?string,
  query?: ?any,
  uids?: ?$ReadOnlyArray<?any>,
  stickedUids?: ?$ReadOnlyArray<?any>,
  resultCount?: ?number,
  polygonBounds?: ?$ReadOnlyArray<?$ReadOnlyArray<?number>>,
};
export type SaveModalUpdateMutationVariables = {|
  data: updateSelectionIT,
  uid: any,
|};
export type SaveModalUpdateMutationResponse = {|
  +updateSelection: ?{|
    +uid: ?string,
    +description: ?string,
    +model: ?string,
    +name: ?string,
  |}
|};
export type SaveModalUpdateMutation = {|
  variables: SaveModalUpdateMutationVariables,
  response: SaveModalUpdateMutationResponse,
|};
*/


/*
mutation SaveModalUpdateMutation(
  $data: updateSelectionIT!
  $uid: UUID!
) {
  updateSelection(data: $data, uid: $uid) {
    uid
    description
    model
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "updateSelectionIT!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "uid",
    "type": "UUID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "data",
    "type": "updateSelectionIT"
  },
  {
    "kind": "Variable",
    "name": "uid",
    "variableName": "uid",
    "type": "UUID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "model",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveModalUpdateMutation",
  "id": null,
  "text": "mutation SaveModalUpdateMutation(\n  $data: updateSelectionIT!\n  $uid: UUID!\n) {\n  updateSelection(data: $data, uid: $uid) {\n    uid\n    description\n    model\n    name\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveModalUpdateMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSelection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveModalUpdateMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSelection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40c4c2eecc614e2002cdf23f0065327d';
module.exports = node;
