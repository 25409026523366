import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchGrapqlRequest } from 'utility/request'

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface ISelection {
  nquery: object
  variables: object
}
// const t = 'csrftoken=529X2pjgwFBIzMJzRyWmE7aqWmQFvPKv2LpyQFv7Mwn0IB5tZ5u5U0T7akuvN55a; sessionid=mhtlkib1ltnto79tzopk3cx9i6j3h3sp; token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiYmYxYTRmMjMtMTI4Ny00NTZjLWEwM2UtYWY3M2EwOTNiZDgyIiwiZW1haWwiOiJsLmEudnlhem92QGdtYWlsLmNvbSIsImV4cCI6MTYzNTY4NTE0MH0.2T9YEdk8eHmnErPZiIf94hSmxHeaAONn7rOk863KBR8'
// const nt = t.split(';')
// console.log(nt)

export const getUids = async body => {
  const token = document.cookie.split(';')
  const res = fetch('https://www.anthropology-gis.com/api/advanced/search/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `token ${token[2].slice(7)}`,
    },
    // headers: {
    //   'Content-Type': 'application/json',
    //   Authorization: `token ${token[0].slice(6)}`,
    // },
    body: body,
  })
  return (await res).json()
}
const selectionInit = {
  query: {},
  label: '',
  uids: [],
  creator: '',
  model: '',
  currentResearch: '',
  researches: [],
  isFetching: false,
}

export const fetchUids = createAsyncThunk('fetchUids', (body: string) =>
  getUids(body).then(resolve => resolve.data),
)

const selectionReducer = createSlice({
  name: 'selection',
  initialState: selectionInit,
  reducers: {
    setUids(state, action) {
      state.uids = action.payload
    },
    setBody(state, action) {
      state.query = action.payload
    },
    setCreator(state, action) {
      state.creator = action.payload
    },
    setModel(state, action) {
      state.model = action.payload
    },
    setLabel(state, action) {
      state.label = action.payload
    },
    setResearches(state, action) {
      const arr = []
      action.payload.forEach(item => {
        if (item.node.type.edges[0].node.enName === 'Ongoing research') {
          const obj = {
            uid: item.node.uid,
            name: item.node.ruName,
          }
          arr.push(obj)
        }
      })
      state.researches = arr
      state.isFetching = false
    },
    setCurrentResearch(state, action) {
      state.currentResearch = action.payload
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload
    },
    cleanUids(state) {
      state.uids = []
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUids.fulfilled, (state, action) => {
      const { payload } = action
      const arr = []
      payload.forEach(item => {
        arr.push(item.uid)
      })
      state.uids = arr
    })
  },
})
export const {
  setUids,
  setBody,
  setCreator,
  setModel,
  setLabel,
  setResearches,
  setCurrentResearch,
  setIsFetching,
  cleanUids,
} = selectionReducer.actions

export default selectionReducer.reducer
