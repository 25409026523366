import React from 'react';
import { Link } from 'react-router-dom';

const RecoverySuccess = ({ t }) => (
  <div className="sign_up_success">
    <h3>{ t('recovery.title_success') }</h3>
    <span>{ t('recovery.success_message') }</span>
    <Link to="/login">{t('logIn.form.title')}</Link>
  </div>
)

export default RecoverySuccess;
