import {
  put,
  fork,
  takeLatest,
  call,
} from 'redux-saga/effects';

import { actions, constants } from 'redux/modules/advancedSearch';
// import { getNotification, successNotify, errorNotify } from '../../utility/notification';

const { localStorage } = window;
const ADV_SEARCH_STATE = 'ADV_SEARCH_STATE';

export function* fetchInit() {
  const oldState = localStorage.getItem(ADV_SEARCH_STATE);
  yield put(actions.initState({ isSavedCopy: oldState !== null }))
}
export function* fetchUpdateState(action) {
  localStorage.setItem(ADV_SEARCH_STATE, action.payload);
  yield put(actions.updateState(action.payload))
}
export function* fetchLoadState() {
  const oldState = localStorage.getItem(ADV_SEARCH_STATE) || [];
  yield put(actions.loadState(oldState));
}
export function* fetchGetRequest(action) {
  // generate request from state
  yield put(actions.getRequest());
}

function* watchActions() {
  yield takeLatest(constants.INIT_SAGA, fetchInit);
  yield takeLatest(constants.UPDATE_STATE_SAGA, fetchUpdateState);
  yield takeLatest(constants.LOAD_STATE_SAGA, fetchLoadState);
  yield takeLatest(constants.GET_REQUEST_SAGA, fetchGetRequest);
}

export const advSearchSaga = [
  fork(watchActions),
];
