import * as request from 'utility/request';

export const sendSelection = async (data) => {
  const path = 'api/tools/perform/';
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const getParents = async (data) => {
  const path = `api/tools/get_parents/?tool=${data}`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getChildren = async (data) => {
  const path = `api/tools/get_children/?tool=${data.tool}&parent=${data.parent}`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getMethods = async (data) => {
  const path = `api/tools/get_methods/?tool=${data.tool}&parent=${data.parent}&child=${data.child}`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}
