import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { deleteOrganizationResearcher } from 'redux/api/organization'
import ResearcherItem from '../Item'

import './index.css';

class ResearcherList extends Component {
  handleResearcherRemove = (uid) => {
    const { orgId } = this.props;
    deleteOrganizationResearcher(orgId, uid).then(({ response, error }) => {
      this.props.handleRemove(uid);
    })
  }

  renderItems = () => {
    const {
      tags, t, orgId, researcherList,
    } = this.props
    const result = [];
    researcherList.forEach(item => {
      result.push(<ResearcherItem
        tags={tags}
        item={item}
        orgId={orgId}
        key={item.uid}
        t={t}
        handleResearcherRemove={this.handleResearcherRemove}
      />)
    }
    )
    return result;
  }

  render() {
    return (
      <div className="researcher_list">
        {
          this.renderItems()
        }
      </div>
    );
  }
}

ResearcherList.propTypes = {};

export default ResearcherList;
