import { put } from 'redux-saga/effects';
import nanoid from 'nanoid';
import { delay } from './request';

import { actions as notification } from '../redux/modules/notification';

export const getNotification = (title, status, param) => ({
  id: nanoid(),
  type: status,
  message: `${title}.${param || status}`,
})

export function* successNotify(title, status) {
  const successNotification = getNotification(title, 'success', status);
  yield put(notification.show(successNotification));
  yield delay(3000);
  yield put(notification.hide(successNotification.id));
}

export function* errorNotify(title, status) {
  const errorNotification = getNotification(title, 'error', status);
  yield put(notification.show(errorNotification));
  yield delay(3000);
  yield put(notification.hide(errorNotification.id));
}
