/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type AccessObjectEnum = "PRIVATE" | "PUBLIC" | "SHARED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ResearchFragment$ref: FragmentReference;
export type ResearchFragment = {|
  +uid: ?string,
  +label: ?string,
  +ruName: ?string,
  +enName: ?string,
  +localName: ?string,
  +year: ?number,
  +permission: ?AccessObjectEnum,
  +type: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +ruName: ?string,
        +enName: ?string,
        +localName: ?string,
        +sort: ?number,
      |}
    |}>
  |},
  +$refType: ResearchFragment$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ruName",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enName",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "localName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ResearchFragment",
  "type": "ResearchNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "uid",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "label",
      "args": null,
      "storageKey": null
    },
    v0,
    v1,
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "year",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permission",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "type",
      "storageKey": null,
      "args": null,
      "concreteType": "ResearchTypeNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ResearchTypeNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ResearchTypeNode",
              "plural": false,
              "selections": [
                v0,
                v1,
                v2,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "sort",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '809f1973116570aa5c906a0205d56ced';
module.exports = node;
