import React from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import { Form, Input, Button, Alert } from 'antd';

const FormItem = Form.Item;

const renderErrors = (error, t) => {
  const keys = Object.keys(error);

  const result = [];
  keys.forEach(item => {
    result.push(
      <Alert key={item} message={`${t(`recovery.${item}.title`)}: ${error[item]}`} type="error" />
    )
  });
  return result;
}

const InnerForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  isError,
  t,
}) => (
  <Form onSubmit={handleSubmit} className="login-form">
    {isError && renderErrors(isError, t)}
    <FormItem
      validateStatus={touched.email && errors.email && 'error'}
      help={touched.email && errors.email && errors.email}
    >
      <Input
        placeholder={t('recovery.email.title')}
        type="email"
        name="email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
      />
    </FormItem>

    <Button
      type="primary"
      htmlType="submit"
      className="login-form-button"
      disabled={isSubmitting}
    >
      {t('recovery.button')}
    </Button>
    <div className="footer_link">
      <Link to="/login">{t('logIn.form.title')}</Link>
    </div>
  </Form>
)

// Wrap our form with the using withFormik HoC
const RecoveryForm = withFormik({
  // Transform outer props into form values
  mapPropsToValues: () => ({ email: '' }),
  // Add a custom validation function (this can be async too!)
  validate: (values, props) => {
    const errors = {};
    const { t } = props;
    if (!values.email) {
      errors.email = t('recovery.email.error');
    }
    return errors;
  },
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    props.handleSubmit(values);
    setTimeout(setSubmitting(false), 1000);
  },
})(InnerForm);

export default RecoveryForm;
