import React, { useEffect } from 'react';
import {
  Spin,
  Tabs,
} from 'antd';
import { graphql } from 'relay-runtime'
import {
  getPropertyByNameString as allocator,
} from 'utility/transformData/constants'
import { addToMap } from 'utility'
import { SUBJECT_TABS } from 'utility/constants'
import ProfileTabSourceTable from './Table'

import './index.scss'

const { TabPane } = Tabs;
const sourceFragment = graphql`
fragment SourceFragment on SourceSearchNode {
  uid
  ruName
  enName
  localName
  year
  author {
    edges {
      node {
        publicName
        uid
      }
    }
  }
  coauthors {
    edges {
      node {
        publicName
        uid
      }
    }
  }
  label
}
`;

export const SOURCES_QUERY = graphql`
query SourceQuery {
  me {
    createdSources(first: 1000) {
      edges {
        node {
          ... SourceFragment @relay(mask: false)
        }
      }
    }
    writtenSources(first: 1000) {
      edges {
        node {
          ... SourceFragment @relay(mask: false)
        }
      }
    }
  }
}
`

const getAuthor = (item, keys = ['author']) => {
  const result = {}
  keys.forEach(key => {
    const authors = allocator(item, `${key}.edges`) || []
    result[key] = authors.map(author => author.node)
  })
  return result;
}

export const sourceMapper = ({ me: data }) => {
  const result = new Map();
  const written = new Map();
  const created = new Map();
  const writtenIds = new Map();

  const writtenItems = allocator(data, 'writtenSources.edges')
  writtenItems.forEach(res => {
    const item = res.node;
    const type = allocator(item, 'label')
    const formatted = {
      ...item,
      ...getAuthor(item, ['author', 'coauthors']),
    }
    addToMap(formatted, written, type)
    writtenIds.set(item.uid)
  })
  const createdItems = allocator(data, 'createdSources.edges')
  createdItems.forEach(res => {
    const item = res.node;
    if (!writtenIds.has(item.uid)) {
      const type = allocator(item, 'label')
      const formatted = {
        ...item,
        ...getAuthor(item, ['author', 'coauthors']),
      }
      addToMap(formatted, created, type)
    }
  })
  result.set('written', written)
  result.set('created', created)
  return result
}

const ProfileTabSource = (props) => {
  const {
    t,
    title,
    sources,
    isLoading,
    loadSources,
  } = props;

  useEffect(() => {
    if (!sources && !isLoading) {
      loadSources()
    }
  }, [sources])

  const renderTabs = () => {
    return (
      <Tabs defaultActiveKey="0">
        {
          SUBJECT_TABS.map((tab, index) => (
            <TabPane tab={t(`profile.source.tabs.${tab.type}`)} key={index}>
              <ProfileTabSourceTable
                t={t}
                type={tab.type}
                data={sources}
                isLoading={isLoading}
                loadSources={loadSources}
              />
            </TabPane>
          ))
        }
      </Tabs>
    )
  }

  return (
    <div>
      <h1>
        {t('profile.tabs.source')}
      </h1>
      { renderTabs() }
      {
        isLoading && <Spin size="large" />
      }
    </div>
  );
}

export default ProfileTabSource;
