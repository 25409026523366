export const getDefaultIcon = subject => {
  if (subject.epoch && subject.epoch.sort) {
    return {
      epoch: subject.epoch.sort,
    }
  }
  const epoch = subject.attributes
    ? subject.attributes.filter(a => a.type === 'Epoch')[0]
    : null
  const img =
    epoch ? epoch.sort :
      (subject.nodeAttributes && subject.nodeAttributes.epoch) ? subject.nodeAttributes.epoch.sort
        : 0
  return {
    epoch: img,
  }
}
