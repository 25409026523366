import React from 'react'
import copy from 'clipboard-copy'
import { Icon, message } from 'antd'
import { translate } from 'react-i18next'
import './style.scss'

const CodeSnippet = props => {
  const { text, t } = props

  const copyText = () => {
    copy(text)
    message.success(t('copy'))
  }

  return (
    <div className="code_snippet">
      <pre className="code_snippet">{text}</pre>
      <Icon className="code_snippet_button" type="copy" onClick={copyText} />
    </div>
  )
}

export default translate()(CodeSnippet)
