import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { Checkbox } from 'antd'

import ResearcherList from 'components/Researcher/List'
import DatePeriod from 'components/Date/Period'
import { createAuthor } from 'redux/api/eauthor'
import {
  postOrganizationResearcherEmail,
  postOrganizationResearcher,
  getOrganizationResearcherList,
  getOrgUid,
} from 'redux/api/organization'
import { getTagList } from 'redux/api/tags'
import UserItem from 'components/User/Item'
import ResearcherCreate from 'components/Researcher/Create'
import { getNotification } from 'utility/notification'
import { actions as notification } from 'redux/modules/notification'
import { actions } from 'redux/modules/user'
import ProfileTabResearcherForm from '../Form'
import ProfileTabResearcherNew from '../New'
import ProfileTabResearcherExist from '../Exist'

import './index.css'

const mapStateToProps = state => ({
  user: state.user,
})

const mapDispatchToProps = {
  getCurrentUser: actions.getCurrentUser,
  show: notification.show,
  hide: notification.hide,
}

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class ProfileTabResearcherContainer extends Component {
  dateForm = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      uid: '',
      isNew: false,
      tags: new Map(),
      userSelected: null,
      userCreated: null,
      selectError: null,
      userSelectValue: [],
      isUserCreating: false,
      isResearcherCreating: false,
      isSubmitting: false,
      addEmailToExist: false,
      date: null,
      researcherList: new Map(),
    }
  }

  componentDidMount() {
    getOrgUid().then(data => {
      getOrganizationResearcherList(data.response.uid).then(
        ({ response, error }) => {
          const newMap = new Map()
          response.results.forEach(item => newMap.set(item.uid, item))
          this.setState({
            researcherList: newMap,
          })
        },
      )
    })
    getTagList().then(({ response, error }) => {
      const tags = new Map()
      console.log(response)
      response.results.forEach(item => {
        tags.set(item.uid, item)
      })
      this.setState({
        tags,
      })
    })
  }

  onCheckboxChange = () => {
    this.setState(prevState => {
      return { addEmailToExist: !prevState.addEmailToExist }
    })
  }




  handleUserSelect = (user, value) => {
    console.log(user)
    console.log(value)
    this.setState({
      userSelectValue: [value],
      userSelected: user,
      selectError: null,
    })
  }

  sendEmail = (uid, email) => {
    postOrganizationResearcherEmail(this.props.orgId, uid, { email }).then(
      ({ response, error }) => {
        this.setState({
          isUserCreating: false,
        })
      },
    )
  }

  handleUserCreate = body => {
    console.log(body)
    this.setState({
      isUserCreating: true,
    })
    createAuthor(body).then(({ response, error }) => {
      if (response.email) {
        this.setState({
          userCreated: response,
        })
        this.sendEmail(response.uid, response.email)
      } else {
        this.setState({
          isUserCreating: false,
          userCreated: response,
        })
      }
      const { isSubmitting, date } = this.state
      if (isSubmitting) {
        this.createResearcher({ date }, response.uid)
      }
    })
  }

  createResearcher = (body, uid) => {
    this.setState({
      isResearcherCreating: true,
    })
    postOrganizationResearcher(this.props.orgId, uid, body.date).then(
      ({ response, error }) => {
        console.log(response)
        console.log(error)
        const newData = new Map()
        newData.set(uid, { uid, ...response })
        this.setState({
          isResearcherCreating: false,
          isSubmitting: false,
          userSelected: null,
          userCreated: null,
          date: null,
          isNew: false,
          researcherList: new Map([...newData, ...this.state.researcherList]),
        })
        this.dateForm.current.resetForm()
        const successNotification = getNotification(
          'researcher.form',
          'success',
          'created',
        )
        this.props.show(successNotification)
        setTimeout(() => {
          this.props.hide(successNotification.id)
        }, 3000)
      },
    )
  }

  handleSubmit = body => {
    const {
 isNew, userSelected, userCreated, addEmailToExist
} = this.state
    const { uid } = this.props.organization.info
    console.log(body)
    console.log(uid)
    if (isNew) {
      if (userCreated) {
        this.createResearcher(body, userCreated.uid)
      } else {
        this.setState({
          isSubmitting: true,
          date: body.date,
        })
        document.getElementById('user_create_btn').click()
      }
    } else if (userSelected) {
      if (userSelected && userSelected.email === null && addEmailToExist) {
        console.log(body)
        postOrganizationResearcherEmail(this.props.orgId, uid, body.email).then(
          ({ response, error }) => {
            console.log(response)
            console.log(error)
          },
        )
      }
      this.createResearcher(body, userSelected.uid)
    } else {
      this.setState({
        selectError: 'researcher.form.error.choose',
      })
    }
  }

  handleUserRemove = () => {
    this.setState({ userSelected: null, userSelectValue: [] })
  }

  handleResearchRemove = uid => {
    const newData = new Map(this.state.researcherList)
    newData.delete(uid)
    this.setState({
      researcherList: newData,
    })
  }

  toggleCreateNew = () => {
    this.setState(prevState => {
      return { isNew: !prevState.isNew }
    })
  }

  render() {
    const {
      isNew,
      tags,
      userSelected,
      userSelectValue,
      userCreated,
      selectError,
      isUserCreating,
      isResearcherCreating,
      researcherList,
      addEmailToExist,
    } = this.state
    const { t, orgId } = this.props
    return (
      <div className="researcher_container">
        {isNew ? (
          <ProfileTabResearcherNew toggleCreateNew={this.toggleCreateNew} t={t}>
            {userCreated ? (
              <UserItem
                key={userCreated.uid}
                user={userCreated}
                handleUserRemove={this.handleUserRemove}
                tags={tags}
              />
            ) : (
              <ResearcherCreate
                t={t}
                handleSubmit={this.handleUserCreate}
                isLoading={isUserCreating}
              />
            )}
          </ProfileTabResearcherNew>
        ) : (
          <div>
            <ProfileTabResearcherExist
              toggleCreateNew={this.toggleCreateNew}
              t={t}
              user={userSelected}
              userSelectValue={userSelectValue}
              tags={tags}
              handleUserSelect={this.handleUserSelect}
              handleUserRemove={this.handleUserRemove}
              isError={selectError}
            />
            {userSelected && userSelected.email === null && (
              <div>
                <Checkbox
                  checked={addEmailToExist}
                  onChange={this.onCheckboxChange}
                >
                  {t('addEmailToExist')}
                </Checkbox>
              </div>
            )}
          </div>
        )}
        <ProfileTabResearcherForm
          t={t}
          handleSubmit={this.handleSubmit}
          isLoading={isResearcherCreating}
          userSelected={userSelected}
          addEmailToExist={addEmailToExist}
        >
          <DatePeriod
            ref={this.dateForm}
            t={t}
            form={{}}
            getFieldDecorator={() => {}}
          />
        </ProfileTabResearcherForm>
        <ResearcherList
          handleRemove={this.handleResearchRemove}
          researcherList={researcherList}
          orgId={orgId}
          tags={tags}
          t={t}
        />
      </div>
    )
  }
}

export default ProfileTabResearcherContainer
