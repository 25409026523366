import * as L from 'leaflet';
import { MapControl } from 'react-leaflet';
import '../../distLibs/PolylineMeasure';

import '../../distLibs/PolylineMeasure/index.css';

export default class PolylineMeasure extends MapControl {
  createLeafletElement = (props) => {
    const options = {
      position: 'bottomright',
      unit: 'metres',
      showBearings: true,
      clearMeasurementsOnStop: false,
      showClearControl: true,
      showUnitControl: true,
      ...props,
    }
    return L.control.polylineMeasure(options);
  }

  componentDidMount() {
    const { map } = this.context;
    this.leafletElement.addTo(map);
  }
}
