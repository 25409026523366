import { createAction, handleActions } from 'redux-actions';

const SIGN_UP = 'user/SIGN_UP';
const SIGN_UP_EXISTING = 'user/SIGN_UP_EXISTING';
const SIGN_UP_CAPTCHA_OK = 'user/SIGN_UP_CAPTCHA_OK';
const SIGN_UP_START = 'user/SIGN_UP_START';
const SIGN_UP_SUCCESS = 'user/SIGN_UP_SUCCESS';
const SIGN_UP_FAILED = 'user/SIGN_UP_FAILED';

export const constants = {
  SIGN_UP,
  SIGN_UP_EXISTING,
  SIGN_UP_CAPTCHA_OK,
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILED,
};

// ------------------------------------
// Actions
// ------------------------------------
export const signUp = createAction(SIGN_UP);
export const signUpExisting = createAction(SIGN_UP_EXISTING);
export const signUpHandleCaptcha = createAction(SIGN_UP_CAPTCHA_OK);
export const signUpStart = createAction(SIGN_UP_START);
export const signUpSuccess = createAction(SIGN_UP_SUCCESS);
export const signUpFailed = createAction(SIGN_UP_FAILED);


export const actions = {
  signUp,
  signUpExisting,
  signUpHandleCaptcha,
  signUpStart,
  signUpSuccess,
  signUpFailed,
};

export const reducers = {
  [SIGN_UP_START]: state =>
    ({
      ...state,
      isLoading: true,
    }),
  [SIGN_UP_FAILED]: (state, { payload }) =>
    ({
      ...state,
      isOk: false,
      isLoading: false,
      error: payload || { default: '' },
    }),
  [SIGN_UP_SUCCESS]: state =>
    ({
      ...state,
      isOk: true,
      isLoading: false,
    }),
  [SIGN_UP_CAPTCHA_OK]: state =>
    ({
      ...state,
      captchaOk: true,
    }),
}

export const initialState = () => {
  const state = {
    captchaOk: false,
  };
  return state;
}

export default handleActions(reducers, initialState());
