import * as L from 'leaflet';
import { MapControl } from 'react-leaflet';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import '../../distLibs/ControlGeocoder/Control.Geocoder'

const geocoder = new L.Control.Geocoder.Nominatim({
  geocodingQueryParams: { limit: 20 },
  htmlTemplate: (params) => params.display_name,
});

export default class ControlGeocoder extends MapControl {
  createLeafletElement = (props) => {
    const options = {
      position: 'topleft',
      geocoder,
      ...props,
    }
    return L.Control.geocoder(options);
  }

  componentDidMount() {
    const { map } = this.context;
    this.leafletElement.addTo(map);
  }
}
