const ICONS = {
  0: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M48.505 31.314C48.7811 30.7214 48.9428 30.0547 48.9899 29.3139H46.1513C46.1042 30.1961 45.8651 30.8359 45.4341 31.2332C45.0031 31.6238 44.4172 31.8191 43.6764 31.8191C42.7942 31.8191 42.1476 31.4723 41.7368 30.7787C41.3328 30.085 41.1307 29.0142 41.1307 27.5663V25.5661C41.1307 24.8927 41.1812 24.2933 41.2823 23.768C41.3833 23.236 41.5382 22.7882 41.7469 22.4245C41.9624 22.0608 42.2318 21.7847 42.5551 21.5962C42.8783 21.4076 43.2588 21.3133 43.6966 21.3133C44.1141 21.3133 44.471 21.3705 44.7674 21.485C45.0704 21.5928 45.3196 21.7578 45.5149 21.98C45.7169 22.1955 45.8685 22.4683 45.9695 22.7983C46.0772 23.1283 46.1446 23.5121 46.1715 23.9499H49C48.9327 23.1821 48.771 22.4918 48.5151 21.879C48.2592 21.2662 47.909 20.7476 47.4645 20.3233C47.0201 19.8991 46.4813 19.5724 45.8482 19.3435C45.2219 19.1145 44.5047 19 43.6966 19C42.8615 19 42.1072 19.1616 41.4338 19.4849C40.7671 19.8014 40.198 20.2493 39.7266 20.8284C39.2552 21.4076 38.8915 22.1012 38.6356 22.9094C38.3797 23.7175 38.2517 24.6098 38.2517 25.5863V27.5663C38.2517 28.5361 38.3729 29.425 38.6154 30.2332C38.8578 31.0413 39.208 31.735 39.666 32.3141C40.1239 32.8866 40.6896 33.3344 41.3631 33.6577C42.0365 33.9742 42.8076 34.1324 43.6764 34.1324C44.4441 34.1324 45.1445 34.0213 45.7775 33.7991C46.4106 33.5701 46.9561 33.2469 47.414 32.8293C47.872 32.4118 48.2356 31.9067 48.505 31.314ZM23.556 33.9203H20.7174V22.6366L17 23.9701V21.5254L23.4045 19.2121H23.556V33.9203ZM34.6555 28.4552H36.3627V30.7281H34.6555V33.9203H31.8472V30.7281H25.7356L25.6245 28.9704L31.8068 19.2121H34.6555V28.4552ZM28.3823 28.4552H31.8472V22.9902L31.5745 23.4751L28.3823 28.4552ZM23.4167 35.833L33.429 53.1747L43.4413 35.833L23.4167 35.833Z" fill="white" />
    </svg>`,
}
ICONS['default'] = ICONS[0]

export default ICONS
