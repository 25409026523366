const ICONS = {
  0: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path d="M33 12L37.7148 26.5106H52.9722L40.6287 35.4787L45.3435 49.9894L33 41.0213L20.6565 49.9894L25.3713 35.4787L13.0278 26.5106H28.2852L33 12Z" fill="white" />
    </svg>
  `,
}
ICONS['default'] = ICONS[0]

export default ICONS
