import * as L from 'leaflet';
import { MapControl } from 'react-leaflet';
import '../../distLibs/MousePosition';

import '../../distLibs/MousePosition/index.css';

const NUM_DIGITS = 5;

export default class MousePosition extends MapControl {
  createLeafletElement = (props) => {
    const options = {
      numDigits: NUM_DIGITS,
      lngFirst: true,
      emptyString: '0º N<br>0º E',
      separator: '<br>',
      lngFormatter: (num) => {
        const direction = (num < 0) ? 'W' : 'E';
        return `${Math.abs(L.Util.formatNum(num, NUM_DIGITS))}º ${direction}`;
      },
      latFormatter: (num) => {
        const direction = (num < 0) ? 'S' : 'N';
        return `${Math.abs(L.Util.formatNum(num, NUM_DIGITS))}º ${direction}`;
      },
      ...props,
    }
    return L.control.mousePosition(options);
  }

  componentDidMount() {
    const { map } = this.context;
    this.leafletElement.addTo(map);
  }
}
