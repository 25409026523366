import { put } from 'redux-saga/effects';

import * as request from '../../utility/request';
import { actions } from '../modules/create';

export const createResearch = async ({
  data,
  researchType = 'archaeology',
}) => {
  const path = `api/${researchType}/research/`;
  const { response, error } = await request.safelyPost(
    path,
    data,
    request.HEADERS_POST_AUTH(),
  )
  return { response, error };
}

export const createAuthor = async (data) => {
  const path = 'api/eauthor/create/';
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const createSource = async (data) => {
  console.log('createSource', data)
  let path = '';
  if (data.source_type === 'bookseries') {
    path = `api/${data.source_type}/`;
  } else {
    path = `api/source/${data.source_type}/`;
  }
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const createPeriodicalIssue = async (data) => {
  console.log('createPeriodicalIssue', data)
  const path = `api/periodicalissue/`;
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const createPeriodical = async (data) => {
  console.log('createPeriodical', data)
  const path = `api/periodical/`;
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const createBookSeries = async (data) => {
  console.log('createBookSeries', data)
  const path = `api/bookseries/`;
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const createPublisher = async (data) => {
  console.log('createPublisher', data)
  const path = `api/publisher/`;
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const uploadFile = async (data, type, label) => {
  console.log('uploadFile alldata', data, type, label)
  const path = `api/file/${type}/${label}/${data.resId}/`;

  const formData = new FormData();
  const { file, fileId, fileType} = data;
  const { headers } = {
    ...request.GET_HEADERS(),
  };
  const action = actions.sourceFilelistLoadbarChanged;

  console.log('uploadFile path', path, file)
  const photoData = (data.allData.photoData) ? data.allData.photoData[data.fileId] : {};

  let fileName = photoData.name || file.name;
  const fileNameParts = fileName.split('.');
  fileName = `${fileNameParts[0].substring(0, 1000)}${(fileNameParts[1]) ? '.' + fileNameParts[1] : ''}`
  formData.append('file', file.originFileObj, fileName);
  formData.append('ru_name', fileName);
  formData.append('en_name', fileName);
  formData.append('local_name', fileName);

  if (label === 'Photo' || label === 'TopographicPlan') {
    if (photoData.year) {
      formData.append('year', photoData.year);
    }
    if (photoData.author) {
      formData.append('author', photoData.author);
    }
  }
  console.log('uploadFile formData', formData)

  await request.safelyPostFormData(path, { fileId, label: fileType }, formData, { headers }, 'PUT', action).then((response) => {
    console.log('uploadFile response', response);
    return { response }
  })
}

export const createAddress = async (data) => {
  const path = `api/encyclopedia/address/`;
  console.log('createAddress', data)
  const { response, error } = await request.safelyPost(path,
    data,
    request.HEADERS_POST_AUTH()
  );
  return { response, error };
}

export const createPopulation = async (data) => {
  const path = `api/encyclopedia/population/`;
  console.log('createPopulation', data)
  const { response, error } = await request.safelyPost(path,
    data,
    request.HEADERS_POST_AUTH()
  );
  return { response, error };
}

export const createSite = async data => {
  const path = `api/v2/subject/${data.label}/`
  console.log('createSite subj', data, data.subj_data)
  const { response, error } = await request.safelyPost(
    path,
    { ...data.subj_data },
    request.HEADERS_POST_AUTH(),
  )
  return { response, error }
}

export const createInterpretation = async (data) => {
  console.log('editInterpretation', data)
  const path = `api/v2/subject/uid/${data.uid}/`;
  const body = data.subj_data

  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH(), 'PATCH');

  return { response, error };
}

// export const createInterpretationSciTopic = async (data) => {
//   const path = `api/connect/sci_topic/`;
//   const body = data
//   const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH(), 'PATCH');
//   return { response, error };
// }

export const createCulture = async (data) => {
  const path = `api/v2/subject/Culture/`;
  const { response, error } = await request.safelyPost(path,
    { ...data.data },
    request.HEADERS_POST_AUTH()
  );
  return { response, error };
}

export const createEnsemble = async (data) => {
  const path = `api/architecturalensemble/`;
  const { response, error } = await request.safelyPost(path,
    { ...data.data },
    request.HEADERS_POST_AUTH()
  );
  return { response, error };
}

export const createSubjRelation = async (data) => {
  const path = `api/research/uid/${data.res_id}/connect/uid/${data.uid1}/uid/${data.uid2}/`;
  const { response, error } = await request.safelyPost(path,
    { ...data },
    request.HEADERS_POST_AUTH()
  );
  return { response, error };
}

export const createSubjRelationV2 = async (data) => {
  const path = `api/v2/subjects/connect/`;
  const { response, error } = await request.safelyPost(path,
    { ...data },
    request.HEADERS_POST_AUTH()
  );
  return { response, error };
}

export const createSpatialReference = async (data) => {
  const { knowledge } = data.sr_data || 'ArchaeologySpatial';
  const path = `api/spatial_reference/${knowledge}/`;
  const { response, error } = await request.safelyPost(path,
    data.sr_data,
    request.HEADERS_POST_AUTH()
  );
  return { response, error };
}
