export const data = {
  locality: {
    path: 'locality',
    icon: 'locality',
    items: [
      {
        title: '5',
        props: {
          type: 5,
        },
      },
      {
        title: '4',
        props: {
          type: 4,
        },
      },
      {
        title: '1',
        props: {
          type: 1,
        },
      },
      {
        title: '2',
        props: {
          type: 2,
        },
      },
      {
        title: '3',
        props: {
          type: 3,
        },
      },
      {
        title: '0',
        props: {
          type: 0,
        },
      },
    ],
  },
  project: {
    path: 'project',
    icon: 'project',
    items: [
      {
        title: '0',
        props: {
          type: 0,
        },
      },
      {
        title: '1',
        props: {
          type: 1,
        },
      },
      {
        title: '2',
        props: {
          type: 2,
        },
      },
      {
        title: '3',
        props: {
          type: 3,
        },
      },
      {
        title: '4',
        props: {
          type: 4,
        },
      },
      {
        title: '5',
        props: {
          type: 5,
        },
      },
    ],
  },
  site: {
    path: 'site',
    icon: 'layer',
    items: [
      {
        title: 'site',
        icon: 'site',
        props: {},
      },
      {
        title: '1',
        props: {
          type: 1,
        },
      },
      {
        title: '2',
        props: {
          type: 2,
        },
      },
      {
        title: '5',
        props: {
          type: 5,
        },
      },
      {
        title: '4',
        props: {
          type: 4,
        },
      },
      {
        title: '7',
        props: {
          type: 7,
        },
      },
      {
        title: '9',
        props: {
          type: 9,
        },
      },
      {
        title: '6',
        props: {
          type: 6,
        },
      },
      {
        title: '8',
        props: {
          type: 8,
        },
      },
      {
        title: '3',
        props: {
          type: 3,
        },
      },
      {
        title: '10',
        props: {
          type: 10,
        },
      },
      {
        title: '11',
        props: {
          type: 11,
        },
      },
    ],
  },
  epoch: {
    path: 'epoch',
    icon: 'epoch',
    items: [
      {
        title: '8',
        props: {
          epoch: 8,
        },
      },
      {
        title: '1',
        props: {
          epoch: 1,
        },
      },
      {
        title: '2',
        props: {
          epoch: 2,
        },
      },
      {
        title: '3',
        props: {
          epoch: 3,
        },
      },
      {
        title: '4',
        props: {
          epoch: 4,
        },
      },
      {
        title: '5',
        props: {
          epoch: 5,
        },
      },
      {
        title: '6',
        props: {
          epoch: 6,
        },
      },
      {
        title: '7',
        props: {
          epoch: 7,
        },
      },
      {
        title: '0',
        props: {
          epoch: 0,
        },
      },
    ],
  },
}

export default data
