/**
 * @flow
 * @relayHash 50502e307eaec91f40967f0abd41c7aa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelfMutationVariables = {|
  uid: any
|};
export type SelfMutationResponse = {|
  +deleteSelection: ?{|
    +status: ?string
  |}
|};
export type SelfMutation = {|
  variables: SelfMutationVariables,
  response: SelfMutationResponse,
|};
*/


/*
mutation SelfMutation(
  $uid: UUID!
) {
  deleteSelection(uid: $uid) {
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "uid",
    "type": "UUID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteSelection",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "uid",
        "variableName": "uid",
        "type": "UUID!"
      }
    ],
    "concreteType": "DeleteMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SelfMutation",
  "id": null,
  "text": "mutation SelfMutation(\n  $uid: UUID!\n) {\n  deleteSelection(uid: $uid) {\n    status\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SelfMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SelfMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b41d3c22a023077c9491d31d6496023c';
module.exports = node;
