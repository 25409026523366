/**
 * @flow
 * @relayHash 1afc05f62ea9b7cd797b705921f46b4d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessObjectEnum = "PRIVATE" | "PUBLIC" | "SHARED" | "%future added value";
export type AccessRoleEnum = "ADMIN" | "AUTHOR" | "CREATOR" | "MODERATOR" | "NOT_ACCESSED" | "PUBLIC_USER" | "SHARED_USER" | "%future added value";
export type AllPublicFetchSelectionsQueryVariables = {|
  first?: ?number,
  last?: ?number,
|};
export type AllPublicFetchSelectionsQueryResponse = {|
  +selections: ?{|
    +total: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +uid: ?string,
        +accessType: ?number,
        +permission: ?AccessObjectEnum,
        +createdAt: ?any,
        +creator: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +uid: ?string,
              +publicName: ?string,
            |}
          |}>
        |},
        +accessRole: ?AccessRoleEnum,
        +description: ?string,
        +model: ?string,
        +name: ?string,
        +resultCount: ?number,
      |}
    |}>,
  |}
|};
export type AllPublicFetchSelectionsQuery = {|
  variables: AllPublicFetchSelectionsQueryVariables,
  response: AllPublicFetchSelectionsQueryResponse,
|};
*/


/*
query AllPublicFetchSelectionsQuery(
  $first: Int
  $last: Int
) {
  selections: publicSelections(first: $first, last: $last, order: "-created_at") {
    total: totalCount
    edges {
      node {
        uid
        accessType
        permission
        createdAt
        creator {
          edges {
            node {
              uid
              publicName
              id
            }
          }
        }
        accessRole
        description
        model
        name
        resultCount
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last",
    "type": "Int"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": "-created_at",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "total",
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accessType",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permission",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accessRole",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "model",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "resultCount",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AllPublicFetchSelectionsQuery",
  "id": null,
  "text": "query AllPublicFetchSelectionsQuery(\n  $first: Int\n  $last: Int\n) {\n  selections: publicSelections(first: $first, last: $last, order: \"-created_at\") {\n    total: totalCount\n    edges {\n      node {\n        uid\n        accessType\n        permission\n        createdAt\n        creator {\n          edges {\n            node {\n              uid\n              publicName\n              id\n            }\n          }\n        }\n        accessRole\n        description\n        model\n        name\n        resultCount\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AllPublicFetchSelectionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "selections",
        "name": "publicSelections",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNodeConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "SelectionNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "SelectionNode",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "creator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorSearchNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AuthorSearchNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AuthorSearchNode",
                            "plural": false,
                            "selections": [
                              v3,
                              v7
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  v8,
                  v9,
                  v10,
                  v11,
                  v12
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllPublicFetchSelectionsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "selections",
        "name": "publicSelections",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNodeConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "SelectionNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "SelectionNode",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "creator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorSearchNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AuthorSearchNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AuthorSearchNode",
                            "plural": false,
                            "selections": [
                              v3,
                              v7,
                              v13
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  v8,
                  v9,
                  v10,
                  v11,
                  v12,
                  v13
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bee95aaa6306108b01c51a943c036608';
module.exports = node;
