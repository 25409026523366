import React from 'react'
import { Drawer } from 'antd';
import { formatDate } from 'utility/time'
import { getLinkByLabel } from 'utility/links'
import CodeSnippet from 'components/CodeSnippet'
import './index.scss'

const SettingPanelDrawer = (props) => {
  const {
    visible,
    toggle,
    pointHistory,
    editDeleteSpatial,
    t,
  } = props

  const historyItem = (data) => {
    const {
      linkedBy,
      uid,
      gradationType,
      pointType,
      points = [],
      createdAt = new Date(),
    } = data

    return (
      <div className="point_history_item" key={uid}>
        <div className="point_history_item_points">
          <CodeSnippet text={points.toString()} />
        </div>
        <div className="point_history_item_info">
          <span className="linked_by">
            {linkedBy ?
              (
                <a href={getLinkByLabel('Author', linkedBy.uid)} target="_blank">
                  {linkedBy.publicName}
                </a>
              ) :
              "-"
            }
          </span>
          {gradationType &&
            (<div className="accuracy">
              {`${t(`common.gradation.${gradationType}`)} / ${t(`common.pointType.${pointType}`)}`}
            </div>)
          }
          <span className="date">{formatDate(createdAt)}</span>
          <span className="delete"><p onClick={() => editDeleteSpatial({uid})}>{t('common.delete')}</p></span>
        </div>
      </div>
    )
  }

  const renderHistory = () => {
    if (!pointHistory) return null
    const result = []
    pointHistory.forEach && pointHistory.forEach(item => {
      result.push(historyItem(item))
    })
    return result
  }

  return (
    <Drawer
      width={600}
      className="setting-panel-drawer"
      title={t('settings.menu.points')}
      placement="right"
      closable={false}
      onClose={toggle}
      visible={visible}
    >
      {renderHistory()}
    </Drawer>
  )
}

export default SettingPanelDrawer
