import React, { Component } from 'react';
import { Input, Form } from 'antd';

const FormItem = Form.Item;

class CommonInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: false,
    };
  }

  handleChange = (value) => {
    console.log(value);
    this.setState({ hide: value.target === '' });
  };


  render() {
    const {
      getFieldDecorator,
      inputName,
      formLayout,
      placeholder,
      title,
      searchType,
      initialValue,
      inputType,
      t,
    } = this.props;
    return (
      <FormItem
        label={t(`search_panel.form.field.${searchType}.${title}`)}
        {...formLayout}
      >
        {getFieldDecorator(`${inputName}`, {
          initialValue,
        })(
          <Input
            onChange={this.handleChange}
            type={inputType}
            name={inputName}
            placeholder={placeholder}
          />)}
      </FormItem>
    );
  }
}

export default CommonInput;
