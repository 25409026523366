import * as request from '../../utility/request';

export const getSourceList = async (type) => {
  const path = `api/source/${type}/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getSource = async (id) => {
  const path = `api/source/uid/${id}/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const deleteSource = async (id) => {
  const path = `api/source/uid/${id}/`;
  const { response, error } = await request.safelyPost(path, {}, request.HEADERS_POST_AUTH(), 'DELETE');
  return { response, error };
}

export const getSourceByLabelBody = async (label, body) => {
  console.log(body);
  const b = {
    ...body,
    __offset__: 0,
    __limit__: 300,
  }
  const path = `api/source/${label}/list/`;
  const { response, error } = await request.safelyPost(path, b, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const getSourceTypeList = async () => {
  const path = 'api/source_type/list/';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getSourceType = async (id) => {
  const path = `api/source_type/uid/${id}/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}
