import { getPropertyByNameString } from 'utility/transformData/constants'

// TODO: omg need to refactor and standartize data from back
export const getLayer = item => {
  if (!item) return {}
  if (
    item.node &&
    item.node.sitetype &&
    item.node.sitetype.edges.length &&
    item.node.epoch &&
    item.node.epoch.edges.length
  ) {
    const siteType = item.node.sitetype.edges[0].node.sort
    const epoch = item.node.epoch.edges[0].node.sort
    return {
      type: siteType,
      epoch,
    }
  }
  // in culture and mb in other show pages
  if (item.sitetype || item.siteType) {
    const type = item.sitetype || item.siteType
    if (!item.epoch) {
      return {
        type: type.sort,
      }
    }
    return {
      type: type.sort,
      epoch: item.epoch.sort,
    }
  }
  // TODO: refactor
  if (item.site_type) {
    // if epoch is undefined use common types
    if (!item.epoch) {
      return {
        type: item.site_type,
      }
    }
    return {
      type: item.site_type,
      epoch: item.epoch,
    }
  }
  // in gsearch
  const SiteType = item.attributes
    ? item.attributes.filter(a => a.type === 'SiteType')[0]
    : 1
  const Epoch = item.attributes
    ? item.attributes.filter(a => a.type === 'Epoch')[0]
    : 0
  const isEpoch = Epoch && Epoch.sort
  const isType = SiteType && SiteType.sort
  const type = isType ? SiteType.sort : 11
  const epoch = isEpoch ? Epoch.sort : 0
  // if epoch is undefined use common types
  if (!isEpoch) {
    return {
      type,
    }
  }
  return {
    type,
    epoch,
  }
}

export const getLayerGraphWrapper = point => {
  return {
    type: getPropertyByNameString(point, 'siteType.sort'),
    epoch: getPropertyByNameString(point, 'epoch.sort'),
  }
}
