import React, { useEffect, useState } from 'react'
import { graphql } from 'relay-runtime'
import { Spin } from 'antd'
import { useDataManualApi } from 'utility/fetchGraphHook'

const RequestAccess = React.lazy(() => import('components/RequestAccess'))

export const QUERY_RESEARCH = graphql`
  query AccessHocResearchQuery($subjectId: ID!) {
    research(id: $subjectId) {
      isRequestSend
      accessRole
    }
  }
`

export const QUERY_SUBJECT = graphql`
  query AccessHocSubjectQuery($subjectId: ID!) {
    subject(id: $subjectId) {
      isRequestSend
      accessRole
    }
  }
`

export const QUERY_SELECTION = graphql`
  query AccessHocSelectionQuery($subjectId: ID!) {
    selection(id: $subjectId) {
      isRequestSend
      accessRole
    }
  }
`

export const QUERY_SOURCE = graphql`
  query AccessHocSourceQuery($subjectId: ID!) {
    source(id: $subjectId) {
      isRequestSend
      accessRole
    }
  }
`

const NOT_ACCESSED = 'NOT_ACCESSED'

const HIDDING_CATEGORIES = ['Issue', 'Periodical']

const CATEGORIES = {
  subjects: {
    query: QUERY_SUBJECT,
    id: 'SubjectNode',
    title: 'subject',
    model: 'SUBJECT',
  },
  research: {
    query: QUERY_RESEARCH,
    id: 'ResearchNode',
    title: 'research',
    model: 'RESEARCH',
  },
  research_arch: {
    query: QUERY_RESEARCH,
    id: 'ResearchNode',
    title: 'research',
    model: 'RESEARCH',
  },
  research_enc: {
    query: QUERY_RESEARCH,
    id: 'ResearchNode',
    title: 'research',
    model: 'RESEARCH',
  },
  // folk_research: {
  //   query: QUERY_RESEARCH,
  //   id: 'ResearchNode',
  //   title: 'research',
  //   model: 'RESEARCH',
  // },
  selection: {
    query: QUERY_SELECTION,
    id: 'SelectionNode',
    title: 'selection',
    model: 'SELECTION',
  },
  source: {
    query: QUERY_SOURCE,
    id: 'SourceNode',
    title: 'source',
    model: 'SOURCE',
  },
  author: {
    title: 'author',
  },
}

export default function AccessHoc(props) {
  const { objCategory, objType, objId } = props.match.params
  const [currentCategory, setCurrentCategory] = useState()
  let variables
  const {
    data,
    isLoading,
    isError,
    doFetch,
  } = useDataManualApi()

  const fetchAccess = () => {
    // selection doesn't have category
    let category = CATEGORIES[objCategory]
    if (!objCategory) {
      const way = props.match.path.split('/')
      const parsedCategory = way ? way[2] : 'author'
      category = CATEGORIES[parsedCategory]
    }
    console.log('AccHOC', objType, HIDDING_CATEGORIES.includes(objType))
    setCurrentCategory(category)
    if (category && category.query && !HIDDING_CATEGORIES.includes(objType)) {
      variables = {
        subjectId: `${category.id}:${objId || objType}`,
      }
      doFetch(variables, category.query)
    }
  }

  useEffect(fetchAccess, [objCategory, objType, objId])

  const renderLoading = () => (
    <div>
      <Spin size="large" />
    </div>
  )

  const renderNotAccess = () => {
    const { isRequestSend } = data ? data[currentCategory.title] || {} : {}
    return (
      <RequestAccess
        model={currentCategory.model}
        uid={objId || objType}
        isRequestSend={isRequestSend}
      />
    )
  }
  const renderCurrentState = () => {
    if (isLoading || !currentCategory) {
      return renderLoading()
    }
    // don't hide subject type
    if (
      currentCategory.title === 'subject' ||
      currentCategory.title === 'author'
    ) {
      return null
    }
    if (
      (data &&
        data[currentCategory.title] &&
        data[currentCategory.title].accessRole !== NOT_ACCESSED) ||
      HIDDING_CATEGORIES.includes(objType)
    ) {
      return null
    }
    return renderNotAccess()
  }

  return renderCurrentState()
}
