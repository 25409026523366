import React, { useEffect, useState } from 'react';
import { graphql } from 'relay-runtime';
import { Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { formatDate } from 'utility/time'
import useDataApi from 'utility/fetchGraphHook'
import {
  getPropertyByNameString as allocator,
} from 'utility/transformData/constants';

import { getLinkByModel, textColorTag } from '../utils';


const QUERY = graphql`
  query MyQuery {
    me {
      myAccessRequestsToObjects {
        uid
        model
        name
        label
        decision
        createdAt
        wasDecision
        author {
          uid
          publicName
        }
      }
    }
  }
`

export const ProfileTabMessagesMy = (props) => {
  const {
    t,
  } = props;

  const { data, isLoading, isError } = useDataApi(QUERY, {});
  const [my, setMy] = useState([])

  useEffect(() => {
    const requestToMe = allocator(data, 'me.myAccessRequestsToObjects') || [];
    setMy(requestToMe);
  }, [data]);

  const COLUMNS = [
    {
      title: t('request_access.table.name'),
      dataIndex: 'name',
      key: 'title',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <Link
          key={record.uid}
          to={`/show/${getLinkByModel(record.model, record.label)}/${record.uid}`}
        >
          {record.name}
        </Link>
      ),
    },
    {
      title: t('request_access.table.type'),
      dataIndex: 'model',
      key: 'model',
      sorter: (a, b) => a.model.localeCompare(b.model),
    },
    {
      title: t('request_access.table.author'),
      dataIndex: 'author',
      render: (text, record) => {
        const authors = record.author || [];
        return authors.map((item) => (
          <Link
            key={item.uid}
            to={`show/author/${item.uid}`}
          >
            {item.publicName}
          </Link>
        ));
      },
      key: 'author',
    },
    {
      title: t('request_access.table.date'),
      dataIndex: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      key: 'created',
      render: created => formatDate(created),
    },
    {
      title: t('request_access.table.decision_mark'),
      key: 'decision',
      dataIndex: 'decision',
      render: (decision, rec) => {
        const tagData = textColorTag(t, rec);
        return (
          <span>
            <Tag color={tagData.color} key={rec.uid}>
              {tagData.text}
            </Tag>
          </span>
        )
      },
    },
  ];

  return (
    <Table
      loading={isLoading}
      columns={COLUMNS}
      dataSource={my}
      rowKey="uid"
    />
  )
}
