import React from 'react'
import PropTypes from 'prop-types'
import { Select, Input } from 'antd'
import './index.scss'

const { Option } = Select

const EQUAL_OPTIONS = ['icontains', 'exact', 'startswith', 'regex']

const INT_EQUAL_OPTIONS = ['lt', 'lte', 'gt', 'gte', 'exact']

const AdvSearchFieldInput = props => {
  const handleChange = selectValue => {
    props.onFieldChange({
      item: {
        selectValue,
      },
    })
  }

  const handleInputChange = inputValue => {
    props.onFieldChange({
      item: {
        inputValue: inputValue.target.value,
      },
    })
  }

  const renderOptions = () => {
    const { type } = props.data
    const keyMap = ['IntegerProperty', 'FloatProperty'].includes(type)
      ? INT_EQUAL_OPTIONS
      : EQUAL_OPTIONS

    return keyMap.map(item => (
      <Option key={item} value={item}>
        {props.t(`advanced.equal.${item}`)}
      </Option>
    ))
  }

  const renderSelectType = selectValue => {
    return (
      <Select
        className="adv-search-field-input select"
        showSearch
        placeholder={props.t('advanced.equal.title')}
        optionFilterProp="children"
        onChange={handleChange}
        value={selectValue}
        filterOption={(input, option) =>
          `${option.props.children}`
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {renderOptions()}
      </Select>
    )
  }

  return (
    <>
      {renderSelectType(props.fields.selectValue)}
      <Input
        className="adv-search-field-input input"
        placeholder={props.t('advanced.input.placeholder')}
        value={props.fields.inputValue}
        onChange={handleInputChange}
      />
    </>
  )
}

AdvSearchFieldInput.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
}

AdvSearchFieldInput.defaultProps = {}

export default AdvSearchFieldInput
