import React from 'react'
import { withFormik } from 'formik'
import { Form, Input, Button, Alert, Spin } from 'antd'
import { formItemLayout } from 'utility/constants'

const FormItem = Form.Item;

const renderErrors = (error, t) => {
  const keys = Object.keys(error)
  const result = []
  keys.forEach(item => {
    result.push(
      <Alert
        key={item}
        message={`${t(`signUp.form.${item}.title`)}: ${error[item]}`}
        type="error"
      />,
    )
  })
  return result
}

const InnerForm = ({
  userSelected,
  addEmailToExist,
  handleSubmit,
  isLoading,
  isError,
  children,
  form,
  t,
}) => {
  const { getFieldDecorator } = form

  const config = {
    email: {
      rules: [
        {
          type: 'email',
          message: t('form.email.error.valid'),
        },
      ],
    },
  }

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { form, getFieldDecorator }));
  return (
    <Form onSubmit={handleSubmit} className="form_container researcher-list">
      {isError && renderErrors(isError, t)}
      <Spin spinning={isLoading}>
        {
          (userSelected && userSelected.email === null && addEmailToExist) &&
          <FormItem
            label={t('form.email.title')}
            {...formItemLayout}
            ref={this.input}
          >
            {getFieldDecorator('email', config.email)(<Input />)}
          </FormItem>
        }
        {childrenWithProps}
        <Button type="primary" htmlType="submit" className="login-form-button">
          {t('researcher.button.add')}
        </Button>
      </Spin>
    </Form>
  )
}

// Wrap our form with the using withFormik HoC
const ProfileTabResearcherForm = Form.create()(
  withFormik({
    // Submission handler
    handleSubmit: (values, { props, setSubmitting }) => {
      values.form.validateFields((err, fieldsValue) => {
        if (err) {
          setSubmitting(false)
          return
        }
        const body = {}

        body.date = {}
        body.date.since = fieldsValue.organization_date_from ? fieldsValue.organization_date_from.format('DD.MM.YYYY') : ''
        body.date.to = fieldsValue.organization_date_to ? fieldsValue.organization_date_to.format('DD.MM.YYYY') : null
        if (props.userSelected && props.userSelected.email === null && props.addEmailToExist) {
          body.email = fieldsValue.email
        }
        console.log(body)
        props.handleSubmit(body)
        setTimeout(setSubmitting(false), 1000)
      })
    },
  })(InnerForm),
)

export default ProfileTabResearcherForm
