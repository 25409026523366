/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import TagCloud from 'react-tag-cloud';

import './index.css';

const colors = [
  '#5e4f49',
  '#e0c592',
  '#aea7a4',
]

const sizes = [
  40,
  36,
  32,
  30,
  30,
  30,
  28,
  28,
  22,
  22,
  22,
  22,
]
@translate()
class TCloud extends Component {
  render() {
    const { words } = this.props;
    return (
      <div className="app-outer">
        <div className="app-inner">
          <TagCloud
            className="tag-cloud"
            style={{
              fontFamily: 'roboto',
            }}
          >
            {words.map((item, i) => (
              <div
                data-subj={item.subj}
                style={{ color: colors[i % 3], fontSize: sizes[i] || 22 }}
                onClick={this.props.handleClickTag}
              >
                {item.word}
              </div>
            ))}
          </TagCloud>
        </div>
      </div>
    );
  }
}

export default TCloud;
