import { useState, useEffect } from 'react'
import arraySort from 'array-sort'

const DEFAULT_SORT = true
const DEFAULT_PATH = 'sort'

const getSortKeys = (item: any): string[] | null => {
  if (!item) return null
  const sortObj = item.sort
  if (!sortObj) return null
  return Object.keys(sortObj)
}

export const useSort = (
  array: any[] = [],
  pathToData: string = DEFAULT_PATH,
  sortType: boolean = DEFAULT_SORT,
) => {
  const [data, setData] = useState(array)
  const [isAsc, setIsAsc] = useState(sortType)
  const [sortField, setSortField] = useState(null)
  const firstElem = array[0]
  const sortKeys: string[] | null = getSortKeys(firstElem)

  useEffect(() => {
    if (sortField) {
      setData(sData => [
        ...arraySort(sData, `${pathToData}.${sortField}`, { reverse: !isAsc }),
      ])
    }
  }, [isAsc, pathToData, sortField])

  const handleSort = ({ field, isAsc: pIsAsc }) => {
    setSortField(field)
    setIsAsc(pIsAsc)
  }

  return { data, handleSort, sortKeys }
}
