import { put, fork, takeLatest, call } from 'redux-saga/effects';

import { getIndexInfo } from '../api';
import { constants as homePageConstants, actions as homePageActions } from '../modules/homePage';

export function* fetchHomeInfo() {
  const { response } = yield call(getIndexInfo);
  if (response) {
    yield put(homePageActions.getHomePageInfoSuccess(response))
  } else {
    yield put(homePageActions.getHomePageInfoFailed())
  }
}

function* watchUserAccountActions() {
  yield takeLatest(homePageConstants.GET_HOME_PAGE_INFO, fetchHomeInfo);
}

export const homePageSaga = [
  fork(watchUserAccountActions),
];
