import i18n from 'i18next'
import {
  getLangName,
  getItem,
  getInterpretation,
} from 'components/GSearchPanel/Queries/constants'

import { page, searchPolygonArg, searchPolygonVar } from './queries'
import {
  getLangTitle,
  getPropertyByNameString,
} from '../../../utility/transformData/constants'

const lang = ['ru','en'].includes(i18n.language) ? i18n.language: 'local'

const encQueries = {
  monumentSearch: searchPolygon => {
    return {
      query: `query postQuery(${searchPolygonArg(
        searchPolygon,
      )} $search: String="", ${page.def}) {
        results:searchMonuments(${searchPolygonVar(
          searchPolygon,
        )} nameIcontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              interpretation {
                ${lang}Name
              }
              locality {
                edges {
                  node {
                    uid
                    ${lang}Name
                  }
                }
              }
              attributes {
                edges {
                  node {
                    ${lang}Name
                    label
                    sort
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'subjects/Monument',
      fields: {
        name: {
          data: getInterpretation,
          sort: `common_${lang}_name`,
        },
        locality: {
          data: item => {
            return getPropertyByNameString(
              item,
              `locality.edges.0.node.${lang}Name`,
            )
          },
          sort: `locality__${lang}_name`,
        },
      },
    }
  },

  personSearch: () => {
    return {
      query: `query postQuery($search: String="", ${page.def}) {
        results:persons(${lang}Name_Icontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      url: 'subjects/Person',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
      },
    }
  },

  localitySelectSearch: () => {
    return {
      query: `
      query postQuery(
          $search: String="",
          $commonSearch: String,
          $district_Has: UUID,
          $first: Int=500, $last: Int=500, $sort: [String]=[]
        ) {
        results:localities(
            ${lang}Name_Icontains: $search,
            commonSearch: $commonSearch,
            district_Has: $district_Has,
            ${page.params}
        ) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
              localityType {
               edges {
                 node {
                   sort
                 }
               }
              }
              district (first: 1) {
                edges {
                  node {
                    ${lang}Name
                    region (first: 1) {
                      edges {
                        node {
                          ${lang}Name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'subjects/Locality',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        district: {
          data: getItem(`district.edges.0.node.${lang}Name`),
          sort: `district__${lang}_name`,
        },
        region: {
          data: getItem(
            `district.edges.0.node.region.edges.0.node.${lang}Name`,
          ),
        },
      },
    }
  },

  localitySearch: searchPolygon => {
    return {
      query: `
      query postQuery(
          ${searchPolygonArg(searchPolygon)}
          $search: String="",
          $district_Has: UUID,
          ${page.def}) {
        results:localities(${searchPolygonVar(
          searchPolygon,
        )} ${lang}Name_Icontains: $search, district_Has: $district_Has, ${
        page.params
      }) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
              localityType {
                edges {
                  node {
                    sort
                  }
                }
              }
              district (first: 1) {
                edges {
                  node {
                    ${lang}Name
                    region {
                      edges {
                        node {
                          ${lang}Name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'subjects/Locality',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        district: {
          data: getItem(`district.edges.0.node.${lang}Name`),
          sort: `district__${lang}_name`,
        },
        region: {
          data: getItem(
            `district.edges.0.node.region.edges.0.node.${lang}Name`,
          ),
        },
      },
    }
  },

  districtSearch: searchPolygon => {
    return {
      query: `query postQuery(${searchPolygonArg(
        searchPolygon,
      )} $search: String="", ${page.def}) {
        results:districts(${searchPolygonVar(
          searchPolygon,
        )} ${lang}Name_Icontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
              region {
                edges {
                  node {
                    ${lang}Name
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'subjects/District',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        region: {
          data: getItem(`region.edges.0.node.${lang}Name`),
          sort: `region__${lang}_name`,
        },
      },
    }
  },

  encOrgsSearch: searchPolygon => {
    return {
      query: `query postQuery(${searchPolygonArg(
        searchPolygon,
      )} $search: String="", ${page.def}) {
        results:encOrganizations(${searchPolygonVar(
          searchPolygon,
        )} ${lang}Name_Icontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              locality {
                edges {
                  node {
                    uid
                    ${lang}Name
                  }
                }
              }
              ${lang}Name
            }
          }
        }
      }`,
      url: 'subjects/EncOrganization',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        locality: {
          data: item => {
            return getPropertyByNameString(
              item,
              `locality.edges.0.node.${lang}Name`,
            )
          },
          sort: `locality__${lang}_name`,
        },
      },
    }
  },
  encOrganizationSearch: searchPolygon => {
    return {
      query: `query postQuery(${searchPolygonArg(
        searchPolygon,
      )} $search: String="", ${page.def}) {
        results:encOrganizations(${searchPolygonVar(
          searchPolygon,
        )} ${lang}Name_Icontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      url: 'subjects/EncOrganization',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
      },
    }
  },
}

export default encQueries
