import React, { useState } from 'react'
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import {
  Button,
  message,
  Icon,
  Tag,
} from 'antd';
import { graphql } from 'relay-runtime'
import { fetchGrapqlMutation } from 'utility/request';

import './index.scss'

const REQUEST_QUERY = graphql`
mutation RequestAccessMutation($data: SendRequestInputType!) {
  sendRequestToAccess(data: $data){
    isRequestSend
  }
}
`

const mapStateToProps = state => ({
  user: state.user,
});

function RequestAccess(props) {
  const {
    t,
    user,
    model,
    uid,
    isRequestSend,
  } = props;
  const [isSend, setIsSend] = useState(false);

  const handleGetAccess = () => {
    const body = {
      model,
      uid,
    };
    const variables = {
      data: body,
    }

    fetchGrapqlMutation(REQUEST_QUERY, variables, (response) => {
      if (response && (response.sendRequestToAccess)) {
        setIsSend(response.sendRequestToAccess.isRequestSend)
        message.success(t('request_access.is_send'));
      }
    })
  }

  const renderButton = () => {
    if (user && user.role && !isRequestSend && !isSend) {
      return (
        <Button
          onClick={handleGetAccess}
        >
          {t('request_access.button')}
        </Button>
      )
    }
    if (user && user.role && isRequestSend) {
      return (
        <div className="request_access_is_send">
          <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
          {t('request_access.is_send')}
        </div>
      )
    }
    return null;
  }

  return (
    <div className="request_access">
      <Tag color="orange">
        <span className="request_access_text">
          <Icon type="exclamation-circle" />
          {t('request_access.title')}
        </span>
        {renderButton()}
      </Tag>
    </div>
  )
}

export default connect(mapStateToProps)(translate()(RequestAccess))
