import React, { Component } from 'react';
import { List, Spin } from 'antd';
import {
  getExistingUserList,
  setExistingUser,
} from 'redux/api/registration'
import './index.css';

class ProfileTabUserConnection extends Component {
  state = {
    userToConfirm: null,
    userConfirmed: null,
    userDeclined: null,
    isLoading: true,
    isEmpty: false,
  }

  componentDidMount() {
    getExistingUserList().then(({ response, error }) => {
      if (response) {
        console.log(response);
        if (response.results.length === 0) {
          this.setState({
            userToConfirm: response.results,
            userConfirmed: response.results,
            userDeclined: response.results,
            isLoading: false,
            isEmpty: true,
          })
        } else {
          const userConfirmed = response.results.filter(user => user.link_params.status === 1)
          const userDeclined = response.results.filter(user => user.link_params.status === 2)
          const userToConfirm = response.results.filter(user => user.link_params.status === 0)
          this.setState({
            userConfirmed,
            userDeclined,
            userToConfirm,
            isLoading: false,
          })
        }
      }
    })
  }

  handleDismiss = (id, index) => {
    const body = { status: 'decline' }
    setExistingUser(id, body).then(({ response, error }) => {
      if (response) {
        const userArr = [...this.state.userToConfirm];
        userArr.splice(index, 1);
        if (userArr.length === 0) {
          this.setState({
            userToConfirm: userArr,
            isEmpty: this.state.userConfirmed.length === 0,
          })
        } else {
          this.setState({
            userToConfirm: userArr,
          })
        }
      }
    })
  }

  handleConfirm = (id, index) => {
    const body = { status: 'accept' }
    setExistingUser(id, body).then(({ response, error }) => {
      if (response) {
        const userArr = [...this.state.userToConfirm];
        userArr.splice(index, 1);
        if (userArr.length === 0) {
          this.setState({
            userToConfirm: userArr,
            isEmpty: this.state.userConfirmed.length === 0,
          })
        } else {
          this.setState({
            userToConfirm: userArr,
          })
        }
      }
    })
  }

  renderUserCard = (user, t) => (
    <List.Item.Meta
      className="existing-user-card"
      title={this.renderUserName(user)}
      description={[
        <div><span>{t('profile.confirmationUsers.fields.connectTo')}:</span><a href={`/show/author/${user.link_to.uid}/`} target="_blank">{user.link_to.public_name}</a></div>,
        <div><span>email:</span>{user.email || '-'}</div>,
        <div><span>{t('profile.confirmationUsers.fields.about')}:</span>{user.link_params.about || '-'}</div>,
        <div><span>{t('profile.confirmationUsers.fields.passport_serial')}:</span>{user.link_params.passport_serial || '-'}</div>,
        <div><span>{t('profile.confirmationUsers.fields.passport_no')}:</span>{user.link_params.passport_no || '-'}</div>,
      ]}
    />
  )

  renderUserName = (user) =>
    `${user.last_name} ${user.first_name} ${user.parent_name} (${user.public_name})`

  renderCards = () => {
    const { t } = this.props;
    const {
      userToConfirm,
      userConfirmed,
      userDeclined,
      isEmpty,
    } = this.state;
    if (isEmpty) {
      return (<div>{t('profile.confirmationUsers.empty')}</div>)
    }
    return (
      <div>
        <h2>{t('profile.confirmationUsers.toConfirm')}</h2>
        <List
          dataSource={userToConfirm}
          className="existing-user-card-list"
          renderItem={(user, index) => (
            <List.Item
              key={user.uid}
              actions={[
                <button
                  className="user-confirm-confirm"
                  onClick={() => this.handleConfirm(user.uid, index)}
                >{t('profile.confirmationUsers.confirm')}</button>,
                <button
                  className="user-confirm-dismiss"
                  onClick={() => this.handleDismiss(user.uid, index)}
                >{t('profile.confirmationUsers.dismiss')}</button>,
              ]}
            >
              {this.renderUserCard(user, t)}
            </List.Item>
          )}
        />
        <h2>{t('profile.confirmationUsers.confirmed')}</h2>
        <List
          dataSource={userConfirmed}
          renderItem={(user, index) => (
            <List.Item
              key={user.uid}
            >
              {this.renderUserCard(user, t)}
            </List.Item>
          )}
        />
        <h2>{t('profile.confirmationUsers.declined')}</h2>
        <List
          dataSource={userDeclined}
          renderItem={(user, index) => (
            <List.Item
              key={user.uid}
            >
              {this.renderUserCard(user, t)}
            </List.Item>
          )}
        />
      </div>
    )
  }

  render() {
    const { t } = this.props;
    const { isLoading } = this.state;
    return (
      <div>
        <div>
          {
            isLoading ?
              <Spin size="large" />
              :
              this.renderCards()
          }
        </div>
      </div>
    );
  }
}

export default ProfileTabUserConnection;
