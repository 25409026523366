import { createAction, handleActions } from 'redux-actions';

const FETCH_MAPS = 'map/FETCH';
const FETCH_MAPS_FAILED = 'map/FETCH_FAILED';
const FETCH_MAPS_SUCCESS = 'map/FETCH_SUCCESS';

export const constants = {
  FETCH_MAPS,
  FETCH_MAPS_FAILED,
  FETCH_MAPS_SUCCESS,
};

// ------------------------------------
// Actions
// ------------------------------------
export const fetchMaps = createAction(FETCH_MAPS);
export const fetchMapsFailed = createAction(FETCH_MAPS_FAILED);
export const fetchMapsSuccess = createAction(FETCH_MAPS_SUCCESS);

export const actions = {
  fetchMaps,
  fetchMapsFailed,
  fetchMapsSuccess,
};

export const reducers = {
  [FETCH_MAPS]: state =>
    ({
      ...state,
      isLoading: true,
      results: [],
      mapTabs: {},
      overlayMapTabs: {},
    }),
  [FETCH_MAPS_FAILED]: (state, { payload }) =>
    ({
      ...state,
      isOk: false,
      isLoading: false,
      results: payload || [],
      mapTabs: {},
      overlayMapTabs: {},
    }),
  [FETCH_MAPS_SUCCESS]: (state, {payload}) =>
    ({
      ...state,
      isOk: true,
      isLoading: false,
      results: payload.maps || [],
      mapTabs: payload.mapTabs || {},
      overlayMapTabs: payload.overlayMapTabs || {},
    }),
}

export const initialState = () => {
  const state = {
    isLoading: true,
    isOk: false,
    mapTabs: {},
    overlayMapTabs: {},
  }
  return state
}

export default handleActions(reducers, initialState());
