import * as request from '../../utility/request'

export const getAdvSearchSchema = () => {
  const path = 'api/advanced/schema/'
  return request.safelyGet(path, request.GET_HEADERS())
}

export const postAdvSearch = data => {
  const path = 'api/advanced/search/'
  return request.safelyPost(path, data, request.HEADERS_POST_AUTH())
}

export const postAdvSearchSpatials = data => {
  const path = 'api/advanced/search/spatial_reference/'
  return request.safelyPost(path, data, request.HEADERS_POST_AUTH())
}

export const downloadAdvSearch = async (data, format) => {
  const path = `api/advanced/download/${format}/`
  if (
    format === 'esri-json' &&
    !data.result_set.includes('spatial_reference')
  ) {
    data.result_set.push('spatial_reference')
  }
  return request.safelyPostBlob(path, data || {}, request.HEADERS_POST_AUTH())
}
