import React, { Component } from 'react'
import { Input, Select, Form, InputNumber, Collapse, Button, Radio } from 'antd'
import archQueries from 'components/GSearchPanel/Queries/archQueries'
import { listQueries } from 'components/CreatePanel/queries/getList'
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters'

import '../../index.scss'
import FileUpload from '../fields/fileUpload'
import AutoInputGQL from '../fields/autoInputGQL'
import SimpleMultiInput from '../fields/simpleMultiInput'
import CoordinatesInput from '../fields/coordinatesInput'
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions'

const FormItem = Form.Item
const { Panel } = Collapse
const { TextArea } = Input

class ArtifactTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
      placeType: {},
    }
  }

  addTab = () => {
    const key = this.newTabIndex++
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state
    newName[key] = data.typed
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangePlaceType = (input, key) => {
    const { placeType } = this.state
    placeType[key] = input.target.value
    this.setState({ placeType })
  }

  handleChangeName = input => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      filelistChanged,
      getFieldDecorator,
      formInputLayout,
      stateTabs,
      subjects,
      createData,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props

    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    const { placeType } = this.state

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel
              header={`${t('create_panel.tab.artifact.artifact')} #${1 + key}`}
              key={title + item.key}
            >
              <AutoInputGQL
                handleSelect={data => {
                  this.handleSelect(data, key)
                }}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.artifact.choose')}
                selectkey={`artifact_select_${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.artifact.add')}
                query={listQueries.artifactSearch()}
                params={[{ key: 'search', val: 'value' }]}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel="Artifact"
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') && (
                <div>
                  {item.status === 'new' && (
                    <div>
                      <FormItem
                        {...formInputLayout}
                        label={t('common.where_is_found')}
                      >
                        {getFieldDecorator(`place_${key}`, {
                          initialValue: 'Assemblage',
                        })(
                          <Radio.Group
                            onChange={value => {
                              this.handleChangePlaceType(value, key)
                            }}
                          >
                            <Radio value="Assemblage">
                              {t('common.subjects.assemblage')}
                            </Radio>
                            <Radio value="Layer">
                              {t('common.subjects.layer')}
                            </Radio>
                            <Radio value="Site">
                              {t('common.subjects.site')}
                            </Radio>
                          </Radio.Group>,
                        )}
                      </FormItem>
                      {(placeType[key] === 'Assemblage' || !placeType[key]) && (
                        <AutoInputGQL
                          handleSelect={commonFunctions.handleAutoInputChange}
                          handleTabAddRelation={this.props.handleTabAddRelation}
                          handleChangeStateTab={this.props.handleChangeStateTab}
                          getFieldDecorator={getFieldDecorator}
                          formLayout={formInputLayout}
                          title={this.props.title}
                          leftLabel="Artifact"
                          rightLabel="Assemblage"
                          connectionField="assemblage"
                          label={t('create_panel.tab.artifact.assemblage')}
                          selectkey={`artifact_layer_${key}`}
                          selectType="assemblages"
                          stateTabs={stateTabs}
                          objkey={key}
                          noAddOption
                          query={archQueries.assemblageSearch(false, true)}
                          params={[{ key: 'search', val: 'value' }]}
                          rules={config.req}
                          t={t}
                        />
                      )}
                      {placeType[key] === 'Layer' && (
                        <AutoInputGQL
                          handleSelect={commonFunctions.handleAutoInputChange}
                          handleTabAddRelation={this.props.handleTabAddRelation}
                          handleChangeStateTab={this.props.handleChangeStateTab}
                          getFieldDecorator={getFieldDecorator}
                          formLayout={formInputLayout}
                          title={this.props.title}
                          leftLabel="Artifact"
                          rightLabel="Layer"
                          connectionField="abstract_layer"
                          label={t('create_panel.tab.artifact.layer')}
                          selectkey={`artifact_layer_${key}`}
                          selectType="layers"
                          stateTabs={stateTabs}
                          objkey={key}
                          noAddOption
                          query={archQueries.layerSearch(false, true)}
                          params={[{ key: 'search', val: 'value' }]}
                          rules={config.req}
                          t={t}
                        />
                      )}
                      {(placeType[key] === 'Site' || !placeType[key]) && (
                        <AutoInputGQL
                          handleSelect={commonFunctions.handleAutoInputChange}
                          handleTabAddRelation={this.props.handleTabAddRelation}
                          handleChangeStateTab={this.props.handleChangeStateTab}
                          getFieldDecorator={getFieldDecorator}
                          formLayout={formInputLayout}
                          title={this.props.title}
                          leftLabel="Artifact"
                          rightLabel="Site"
                          connectionField="site"
                          label={t('create_panel.tab.artifact.site')}
                          selectkey={`artifact_site_${key}`}
                          selectType="sites"
                          stateTabs={stateTabs}
                          objkey={key}
                          noAddOption
                          query={archQueries.siteSearch(false)}
                          params={[{ key: 'search', val: 'value' }]}
                          rules={config.req}
                          t={t}
                        />
                      )}
                    </div>
                  )}
                  {item.status === 'new' && (
                    <FormItem
                      {...formInputLayout}
                      label={t('create_panel.tab.artifact.name')}
                    >
                      {getFieldDecorator(`artifact_name_${key}`, {
                        ...config.req,
                        initialValue: this.state.newName[key],
                      })(
                        <Input
                          onChange={this.handleChangeName}
                          data-objkey={key}
                        />,
                      )}
                    </FormItem>
                  )}
                  <CoordinatesInput
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={title}
                    subjname="artifact"
                    objkey={key}
                    handleAddMarker={handleAddMarker}
                    interactiveMarkers={interactiveMarkers}
                    form={form}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="Artifact"
                    rightLabel="Excavation"
                    connectionField="excavation"
                    label={t('common.subjects.excavation')}
                    selectkey={`artifact_excavation_${key}`}
                    selectType="excavations"
                    stateTabs={stateTabs}
                    objkey={key}
                    noAddOption
                    query={archQueries.excavationSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <FormItem {...formInputLayout} label={t('common.date')}>
                    {getFieldDecorator(
                      `artifact_date_since_${key}`,
                      {},
                    )(
                      <InputNumber
                        placeholder={t('common.date_since')}
                        min={-100000}
                        max={new Date().getFullYear()}
                      />,
                    )}
                    {getFieldDecorator(
                      `artifact_date_to_${key}`,
                      {},
                    )(
                      <InputNumber
                        placeholder={t('common.date_to')}
                        min={-100000}
                        max={new Date().getFullYear()}
                      />,
                    )}
                  </FormItem>
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="Artifact"
                    rightLabel="Culture"
                    dontShowOnMap
                    connectionField="culture"
                    label={t('common.culture')}
                    selectkey={`artifact_culture_${key}`}
                    selectType="cultures"
                    stateTabs={stateTabs}
                    objkey={key}
                    noAddOption
                    query={archQueries.cultureSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.artifact.technique')}
                  >
                    {getFieldDecorator(`artifact_technique_${key}`)(<Input />)}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.artifact.weight')}
                  >
                    {getFieldDecorator(`artifact_weight_${key}`)(
                      <InputNumber
                        placeholder={t('common.hints.artifact.weight_measure')}
                      />,
                    )}
                  </FormItem>
                  <SimpleMultiInput
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    selectType="artifact-category"
                    selectkey={`artifact_category_${key}`}
                    label={t('common.category')}
                    rules={config.req}
                    query={listQueries.artifactCategorySearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    form={form}
                    t={t}
                  />
                  <SimpleMultiInput
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    selectType="artifact-material"
                    selectkey={`artifact_material_${key}`}
                    label={t('common.material')}
                    rules={config.req}
                    query={listQueries.artifactMaterialSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    form={form}
                    t={t}
                  />
                  <FormItem
                    {...formInputLayout}
                    label={t('common.description')}
                  >
                    {getFieldDecorator(
                      `artifact_desc_${key}`,
                      {},
                    )(
                      <TextArea
                        onPaste={e =>
                          textFieldFormatters.onTextAreaPaste(
                            e,
                            form,
                            `artifact_desc_${key}`,
                          )
                        }
                      />,
                    )}
                  </FormItem>
                  <FileUpload
                    getFieldDecorator={getFieldDecorator}
                    formInputLayout={formInputLayout}
                    filelistChanged={filelistChanged}
                    fileLists={createData.sourceFilelist}
                    label={`artifact_files_${key}`}
                    fileType="photo"
                    title={t('common.photo.title')}
                    t={t}
                  />
                  <RelationComponent
                    handleAddExtraRelation={handleAddExtraRelation}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    objkey={key}
                    title="Artifact"
                    tabName="artifacts"
                    stateTabs={stateTabs}
                    form={form}
                    t={t}
                  />
                </div>
              )}
            </Panel>
          ))}
        </Collapse>

        <FormItem wrapperCol={{ span: 8, offset: 8 }}>
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.artifact.add_button')}
          </Button>
        </FormItem>
      </div>
    )
  }
}

export default ArtifactTab
