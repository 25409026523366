import archQueries from 'components/GSearchPanel/Queries/archQueries';
import encQueries from 'components/GSearchPanel/Queries/encQueries';
import { listQueries } from 'components/CreatePanel/queries/getList';
import i18n from 'i18next';

export const searchFields = {
  Artifact: {
    title: i18n.t('common.subjects.artifact'),
    query: listQueries.artifactSearch(),
  },
  Assemblage: {
    title: i18n.t('common.subjects.assemblage'),
    query: archQueries.assemblageSearch(false, true),
  },
  Building: {
    title: i18n.t('common.subjects.monument'),
    query: encQueries.monumentSearch(),
  },
  Culture: {
    title: i18n.t('common.subjects.culture'),
    query: archQueries.cultureSearch(),
  },
  District: {
    title: i18n.t('common.subjects.district'),
    query: encQueries.districtSearch(),
  },
  EncOrganization: {
    title: i18n.t('common.subjects.encorganization'),
    query: encQueries.encOrgsSearch(),
  },
  Excavation: {
    title: i18n.t('common.subjects.excavation'),
    query: archQueries.excavationSearch(),
    key: 'search',
  },
  Layer: {
    title: i18n.t('common.subjects.layer'),
    query: archQueries.layerSearch(),
  },
  Locality: {
    title: i18n.t('common.subjects.locality'),
    query: encQueries.localitySelectSearch(),
    key: 'commonSearch',
  },
  Monument: {
    title: i18n.t('common.subjects.monument'),
    query: encQueries.monumentSearch(),
  },
  Person: {
    title: i18n.t('common.subjects.person'),
    query: encQueries.personSearch(),
  },
  Radiocarbon: {
    title: i18n.t('common.subjects.rdate'),
    query: archQueries.radiocarbonSearch(),
  },
  Site: {
    title: i18n.t('common.subjects.site'),
    query: archQueries.siteSearch(),
  },
}

export const availableRelations = {
  artifacts: {
    relations: [
      'assemblages',
      'cultures',
      'encOrganizations',
      'excavations',
    ],
  },
  assemblages: {
    relations: [
      'artifacts',
      'assemblages',
      'cultures',
      'excavations',
      'layers',
      'monuments',
      'rdates',
    ],
  },
  cultures: {
    relations: [
      'artifacts',
      'assemblages',
      'cultures',
      'layers',
      'monuments',
    ],
  },
  districts: {
    relations: [
      'districts',
      'encOrganizations',
      'localities',
      'monuments',
      'sites',
    ],
  },
  encorganizations: {
    relations: [
      'artifacts',
      'districts',
      'encOrganizations',
      'localities',
      'monuments',
      'persons',
      'rdates',
    ],
  },
  excavations: {
    relations: [
      'artifacts',
      'assemblages',
      'layers',
      'monuments',
      'rdates',
      'sites',
    ],
  },
  layers: {
    relations: [
      'assemblages',
      'cultures',
      'excavations',
      'layers',
      'monuments',
    ],
  },
  localities: {
    relations: [
      'districts',
      'encOrganizations',
      'localities',
      'monuments',
      'persons',
      'sites',
    ],
  },
  monuments: {
    relations: [
      'assemblages',
      'cultures',
      'districts',
      'encOrganizations',
      'excavations',
      'layers',
      'localities',
      'monuments',
      'persons',
      'rdates',
      'sites',
    ],
  },
  persons: {
    relations: [
      'encOrganizations',
      'localities',
      'monuments',
      'persons',
    ],
  },
  rdates: {
    relations: [
      'assemblages',
      'encOrganizations',
      'excavations',
      'monuments',
    ],
  },
  sites: {
    relations: [
      'districts',
      'excavations',
      'layers',
      'localities',
      'monuments',
    ],
  },
}
