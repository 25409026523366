import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';

import { formItemLayout } from '../../utility/constants'

const FormItem = Form.Item;

class EmailForm extends Component {
  state = {
    isError: false,
  }

  input = React.createRef()

  handleClick = (e) => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    console.log(email);
    if (!email) {
      this.setState({
        isError: true,
      })
    } else {
      this.setState({
        isError: false,
      })
      this.props.handleSubmit(e)
    }
  }

  render() {
    const {
      t,
      getFieldDecorator,
    } = this.props;

    const config = {
      email: {
        rules: [
          {
            type: 'email',
            message: t('form.email.error.valid'),
          },
        ],
      },
    }

    const errorMsg = {};

    if (this.state.isError) {
      errorMsg.validateStatus = 'error'
      errorMsg.help = t('form.email.error.required')
    }

    return (
      <div>
        <FormItem
          label={t('form.email.title')}
          {...formItemLayout}
          {...errorMsg}
          ref={this.input}
        >
          {getFieldDecorator('email', config.email)(<Input />)}
        </FormItem>
        <Button
          type="primary"
          htmlType="submit"
          onClick={this.handleClick}
        >
          {t('form.email.button')}
        </Button>
      </div>
    );
  }
}

EmailForm.propTypes = {};

export default EmailForm;
