import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';

import '../../index.scss';
import AutoInput from '../fields/autoInput';
import { textFieldFormatters } from '../../functions/textFieldFormatters';

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class LexemLocality extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;
    this.state = {
      tabs: [],
    };
  }

  addTab = () => {
    const key = this.newTabIndex++;
    this.state.tabs.push({
      key,
    });
    this.forceUpdate()
  }

  handleChange = (type, e) => {
    this.setState({ type });
  }

  render() {
    const {
      objkey,
      getFieldDecorator,
      stateTabs,
      // formItemLayout,
      t,
    } = this.props;

    const {
      key1,
      key2,
      key3,
      tabs,
    } = this.state;

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };

    const config = {
      req: {
        rules: [{
          required: true, message: t('common.default_error'),
        }],
      },
    }

    return (
      <div>
        {tabs.map((item, key) => (
          <AutoInput
            getFieldDecorator={getFieldDecorator}
            formItemLayout={formItemLayout}
            selectkey={`lexem_variant_city_${key1}_${key2}_${key3}`}
            stateTabs={stateTabs}
            objkey={key1}
            label={`${t('create_panel.tab.lexem.city-name')} #${1 + item.key}`}
            selectType="culture"
            title={this.props.title}
            path="api/city/list"
            params={['ru_name']}
            textField="ru_name"
            method="GET"
            noAddOption
            t={t}
          />
        ))}

        <FormItem
          wrapperCol={{ span: 8, offset: 8 }}
        >
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.lexem.city-add')}
          </Button>
        </FormItem>
      </div>
    );
  }
}

export default LexemLocality;
