import { createAction, handleActions } from 'redux-actions';
import get from 'lodash/get'
import set from 'lodash/set'

const INIT = 'advSearch/INIT';
const UPDATE_STATE = 'advSearch/UPDATE_STATE';
const RESET_STATE = 'advSearch/RESET_STATE';
const SELECT_FIELDS = 'advSearch/SELECT_FIELDS';
const REMOVE_FIELD = 'advSearch/REMOVE_FIELD';
const LOAD_STATE = 'advSearch/LOAD_STATE';
const GET_REQUEST = 'advSearch/GET_REQUEST';
const INIT_SAGA = 'advSearch/INIT_SAGA';
const UPDATE_STATE_SAGA = 'advSearch/UPDATE_STATE_SAGA';
const LOAD_STATE_SAGA = 'advSearch/LOAD_STATE_SAGA';
const GET_REQUEST_SAGA = 'advSearch/GET_REQUEST_SAGA';

export const constants = {
  INIT,
  UPDATE_STATE,
  RESET_STATE,
  SELECT_FIELDS,
  REMOVE_FIELD,
  LOAD_STATE,
  GET_REQUEST,
  INIT_SAGA,
  UPDATE_STATE_SAGA,
  LOAD_STATE_SAGA,
  GET_REQUEST_SAGA,
};

// ------------------------------------
// Actions
// ------------------------------------

export const initState = createAction(INIT);
export const updateState = createAction(UPDATE_STATE);
export const loadState = createAction(LOAD_STATE);
export const resetState = createAction(RESET_STATE);
export const selectFields = createAction(SELECT_FIELDS);
export const removeField = createAction(REMOVE_FIELD);
export const getRequest = createAction(GET_REQUEST);
export const initStateSaga = createAction(INIT_SAGA);
export const updateStateSaga = createAction(UPDATE_STATE_SAGA);
export const loadStateSaga = createAction(LOAD_STATE_SAGA);
export const getRequestSaga = createAction(GET_REQUEST_SAGA);

export const actions = {
  initState,
  updateState,
  loadState,
  resetState,
  selectFields,
  removeField,
  getRequest,
  initStateSaga,
  updateStateSaga,
  loadStateSaga,
  getRequestSaga,
};

// ------------------------------------
// Reducers
// ------------------------------------


export const initialState = () => {
  const state = {
    isLoading: true,
    isError: false,
    isSavedCopy: false,
    fields: {
      root: {
        type: null,
        fieldName: null,
        isNot: false,
        selections: null,
      },
    },
    selected: [],
    selectedFields: [],
  };
  return state;
};

const changeById = (state, payload) => {
  const newState = { ...state };
  const item = get(newState, payload.id);
  const newItem = {
    ...item,
    ...payload.item,
  }
  set(newState, payload.id, newItem)
  return newState;
}

const replaceById = (state, payload) => {
  const newState = { ...state };
  const newItem = {
    ...payload.item,
  }
  set(newState, payload.id, newItem)
  return newState;
}

const handleUpdateState = (state, payload) => {
  if (!payload.isRemove) {
    return ({
      ...state,
      fields: changeById(state.fields, payload),
      isSavedCopy: false,
    })
  }
  if (payload.id === 'root') {
    return initialState();
  }
  return ({
    ...state,
    fields: replaceById(state.fields, payload),
    isSavedCopy: false,
  })
}

export const reducers = {
  [INIT]: (state, { payload }) =>
    ({
      ...state,
      ...payload,
      isLoading: false,
    }),
  [SELECT_FIELDS]: (state, { payload }) =>
    ({
      ...state,
      selected: payload.selected,
      selectedFields: payload.selectedFields || state.selectedFields,
    }),
  [RESET_STATE]: () => initialState(),
  [UPDATE_STATE]: (state, { payload }) => handleUpdateState(state, payload),
  [REMOVE_FIELD]: (state, { payload }) =>
    ({
      ...state,
      fields: replaceById(state.fields, payload),
      isSavedCopy: false,
    }),
  [LOAD_STATE]: (state, { payload }) =>
    ({
      ...state,
      fields: {
        ...state.fields,
        ...payload,
      },
      isSavedCopy: false,
    }),
  [GET_REQUEST]: state =>
    ({
      ...state,
    }),
};

export default handleActions(reducers, initialState());
