import React, {Component, useEffect} from 'react'
import {connect, useDispatch} from 'react-redux'
import {translate} from 'react-i18next'

import {actions} from '../../redux/modules/example'
import DataPanel from '../../components/DataPanel'
import MapPanel from '../../components/MapPanel'

const getDataTitle = (data = {}) => {
  if (data[0]) {
    return {
      type: data[0].subjectType,
      name: data[0].title,
    }
  }

  return data
}

const mapStateToProps = state => ({
  tabs: state.example.showPage.data,
  isLoading: state.example.showPage.isLoading,
  title: getDataTitle(state.example.showPage.data),
  mapContent: state.example.points,
})

const mapDispatchToProps = {
  getShowPageData: actions.getShowPageData,
}

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class ShowAuthorView extends Component {
  componentDidMount() {
    const {objType, objId} = this.props.match.params
    const objCategory = 'author'

    this.props.getShowPageData({
      category: objCategory,
      type: objType,
      id: objId,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {objType, objId} = this.props.match.params
    const {objType: objTypeOld, objId: objIdOld} = prevProps.match.params
    const objCategory = 'author'

    if (objType !== objTypeOld || objId !== objIdOld) {
      console.log('UPDATE SHOW')
      this.props.getShowPageData({
        category: objCategory,
        type: objType,
        id: objId,
      })
    }
  }

  render() {
    const {
      t, title, tabs, mapContent, isLoading, match
    } = this.props

    return (
      <div>
        <DataPanel
          title={title}
          tabs={tabs}
          isLoading={isLoading}
          match={match}
        />
        <MapPanel content={mapContent} t={t}/>
      </div>
    )
  }
}

export default ShowAuthorView
