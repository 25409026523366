import React, { useState } from 'react'
import { Button, Select } from 'antd'
import { translate } from 'react-i18next'

const { Option } = Select
const BUTTON_ICON = (isAsc: boolean): string => {
  return isAsc ? 'sort-ascending' : 'sort-descending'
}

interface SortProps {
  handleChange: ({ field: string, isAsc: boolean }) => void
  keys: string[] | undefined
  t: any
}

const Sort = (props: SortProps) => {
  const { handleChange, keys, t } = props
  const [isAsc, setIsAsc] = useState(true)
  const [selectedValue, setSelectedValue] = useState(undefined)
  const handleSortFieldChange = (value: string) => {
    setSelectedValue(value)
    handleChange({
      field: value,
      isAsc,
    })
  }

  const toggleSortType = () => {
    handleChange({
      field: selectedValue,
      isAsc: !isAsc,
    })
    setIsAsc(!isAsc)
  }

  const renderOptions = data => {
    const result: any = []
    if (!data) return result
    data.forEach(item => {
      result.push(
        <Option key={item} value={item}>
          {t(`sort.sortBy.${item}`)}
        </Option>,
      )
    })
    return result
  }

  return (
    <div className="sort-container">
      <Select
        style={{ width: 200 }}
        placeholder={t('sort.title')}
        onChange={handleSortFieldChange}
        value={selectedValue}
      >
        {renderOptions(keys)}
      </Select>
      <Button onClick={toggleSortType} type="link" icon={BUTTON_ICON(isAsc)}>
        {t(`sort.${isAsc ? 'asc' : 'desc'}`)}
      </Button>
    </div>
  )
}

export default translate()(Sort)
