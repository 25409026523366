import L from 'leaflet'

import {createAction, handleActions} from 'redux-actions'
import {
  getMarkers,
  getPolygons,
  getIconFunctions,
  unitePoints,
} from 'utility/points'
import {transformData} from 'utility/transformData'

const GET_SHOW_PAGE_DATA = 'show/GET_SHOW_PAGE_DATA'
const GET_SHOW_PAGE_DATA_SUCCESS = 'show/GET_SHOW_PAGE_DATA_SUCCESS'
const GET_SHOW_PAGE_DATA_GRAPHQL_SUCCESS =
  'show/GET_SHOW_PAGE_DATA_GRAPHQL_SUCCESS'
const GET_SHOW_PAGE_DATA_FAILED = 'show/GET_SHOW_PAGE_DATA_FAILED'
const GET_SHOW_PAGE_POINTS_SUCCESS = 'show/GET_SHOW_PAGE_POINTS_SUCCESS'
const GET_SHOW_PAGE_POINTS_FAILED = 'show/GET_SHOW_PAGE_POINTS_FAILED'
const SIMPLE_SEARCH = 'search/QUERY'
const SIMPLE_SEARCH_GRAPHQL = 'search/QUERY_GRAPHQL'
const SIMPLE_SEARCH_START = 'search/QUERY_START'
const SIMPLE_SEARCH_NEW_SPATIALS = 'search/QUERY_NEW_SPATIALS'
const SIMPLE_SEARCH_SUCCESS = 'search/QUERY_SUCCESS'
const SIMPLE_SEARCH_GRAPHQL_SUCCESS = 'search/QUERY_GRAPHQL_SUCCESS'
const SIMPLE_SEARCH_FAILED = 'search/QUERY_FAILED'
const SIMPLE_SEARCH_SAVE_QUERY = 'search/QUERY_SAVE'
const SIMPLE_SEARCH_CLEAN = 'search/QUERY_CLEAN'
const SHOW_PAGE_ADD_TABS = 'show/SHOW_PAGE_ADD_TABS'
const SHOW_PAGE_UPDATE_TAB = 'show/SHOW_PAGE_UPDATE_TAB'
const SHOW_PAGE_CLEAN = 'show/SHOW_PAGE_CLEAN'
const CHANGE_INTERACTIVE_MARKER = 'create/CHANGE_INTERACTIVE_MARKER'
const CHANGE_MAP_POSITION = 'map/CHANGE_MAP_POSITION'
const MAP_DREW_POLYGON = 'map/MAP_DREW_POLYGON'

const EDIT_TOGGLE = 'edit/EDIT_TOGGLE'
const EDIT_DELETE_INTERPRETATION = 'edit/EDIT_DELETE_INTERPRETATION'
const EDIT_DELETE_INTERPRETATION_SUCCESS = 'edit/EDIT_DELETE_INTERPRETATION_SUCCESS'
const EDIT_DELETE_INTERPRETATION_FAIL = 'edit/EDIT_DELETE_INTERPRETATION_FAIL'
const EDIT_DELETE_SUBJECT_REL = 'edit/EDIT_DELETE_SUBJECT_REL'
const EDIT_DELETE_SUBJECT_REL_SUCCESS = 'edit/EDIT_DELETE_SUBJECT_REL_SUCCESS'
const EDIT_DELETE_SUBJECT_REL_FAIL = 'edit/EDIT_DELETE_SUBJECT_REL_FAIL'
const EDIT_DELETE_SUBJECT = 'edit/EDIT_DELETE_SUBJECT'
const EDIT_DELETE_SUBJECT_SUCCESS = 'edit/EDIT_DELETE_SUBJECT_SUCCESS'
const EDIT_DELETE_SUBJECT_FAIL = 'edit/EDIT_DELETE_SUBJECT_FAIL'
const EDIT_EDIT_INTERPRETATION = 'edit/EDIT_EDIT_INTERPRETATION'
const EDIT_EDIT_INTERPRETATION_SUCCESS = 'edit/EDIT_EDIT_INTERPRETATION_SUCCESS'
const EDIT_EDIT_INTERPRETATION_FAIL = 'edit/EDIT_EDIT_INTERPRETATION_FAIL'
const EDIT_ADD_CONNECTION = 'edit/EDIT_ADD_CONNECTION'
const EDIT_CLEAR_CONNECTIONS = 'edit/EDIT_CLEAR_CONNECTIONS'

const EDIT_ADD_TO_SELECTION = 'edit/EDIT_ADD_TO_SELECTION'

const EDIT_UPDATE_SPATIAL = 'edit/EDIT_UPDATE_SPATIAL'
const EDIT_UPDATE_SPATIAL_SUCCESS = 'edit/EDIT_UPDATE_SPATIAL_SUCCESS'

const EDIT_DELETE_SPATIAL = 'edit/EDIT_DELETE_SPATIAL'
const EDIT_DELETE_SPATIAL_SUCCESS = 'edit/EDIT_DELETE_SPATIAL_SUCCESS'

const {CRS} = L

export const constants = {
  GET_SHOW_PAGE_DATA,
  GET_SHOW_PAGE_DATA_SUCCESS,
  GET_SHOW_PAGE_DATA_GRAPHQL_SUCCESS,
  GET_SHOW_PAGE_DATA_FAILED,
  SIMPLE_SEARCH,
  SIMPLE_SEARCH_GRAPHQL,
  SIMPLE_SEARCH_START,
  SIMPLE_SEARCH_NEW_SPATIALS,
  SIMPLE_SEARCH_SUCCESS,
  SIMPLE_SEARCH_GRAPHQL_SUCCESS,
  SIMPLE_SEARCH_FAILED,
  SIMPLE_SEARCH_CLEAN,
  SIMPLE_SEARCH_SAVE_QUERY,
  SHOW_PAGE_ADD_TABS,
  SHOW_PAGE_UPDATE_TAB,
  SHOW_PAGE_CLEAN,
  CHANGE_INTERACTIVE_MARKER,
  CHANGE_MAP_POSITION,
  MAP_DREW_POLYGON,

  EDIT_TOGGLE,
  EDIT_DELETE_INTERPRETATION,
  EDIT_DELETE_INTERPRETATION_SUCCESS,
  EDIT_DELETE_INTERPRETATION_FAIL,
  EDIT_DELETE_SUBJECT_REL,
  EDIT_DELETE_SUBJECT_REL_SUCCESS,
  EDIT_DELETE_SUBJECT_REL_FAIL,
  EDIT_DELETE_SUBJECT,
  EDIT_DELETE_SUBJECT_SUCCESS,
  EDIT_DELETE_SUBJECT_FAIL,
  EDIT_EDIT_INTERPRETATION,
  EDIT_EDIT_INTERPRETATION_SUCCESS,
  EDIT_EDIT_INTERPRETATION_FAIL,

  EDIT_ADD_CONNECTION,
  EDIT_CLEAR_CONNECTIONS,

  EDIT_ADD_TO_SELECTION,

  EDIT_UPDATE_SPATIAL,
  EDIT_UPDATE_SPATIAL_SUCCESS,

  EDIT_DELETE_SPATIAL,
  EDIT_DELETE_SPATIAL_SUCCESS,
}

// ------------------------------------
// Actions
// ------------------------------------
export const getShowPageData = createAction(GET_SHOW_PAGE_DATA)
export const getShowPageDataSuccess = createAction(GET_SHOW_PAGE_DATA_SUCCESS)
export const getShowPageDataGraphqlSuccess = createAction(
  GET_SHOW_PAGE_DATA_GRAPHQL_SUCCESS
)
export const getShowPageDataFailed = createAction(GET_SHOW_PAGE_DATA_FAILED)
export const getShowPagePointsSuccess = createAction(
  GET_SHOW_PAGE_POINTS_SUCCESS
)
export const getShowPagePointsFailed = createAction(GET_SHOW_PAGE_POINTS_FAILED)
export const simpleSearch = createAction(SIMPLE_SEARCH)
export const simpleSearchGraphql = createAction(SIMPLE_SEARCH_GRAPHQL)
export const simpleSearchStart = createAction(SIMPLE_SEARCH_START)
export const simpleSearchNewSpatials = createAction(SIMPLE_SEARCH_NEW_SPATIALS)
export const simpleSearchSuccess = createAction(SIMPLE_SEARCH_SUCCESS)
export const simpleSearchGraphqlSuccess = createAction(
  SIMPLE_SEARCH_GRAPHQL_SUCCESS
)
export const simpleSearchFailed = createAction(SIMPLE_SEARCH_FAILED)
export const simpleSearchClean = createAction(SIMPLE_SEARCH_CLEAN)
export const simpleSearchSaveQuery = createAction(SIMPLE_SEARCH_SAVE_QUERY)
export const showPageAddTabs = createAction(SHOW_PAGE_ADD_TABS)
export const showPageUpdateTab = createAction(SHOW_PAGE_UPDATE_TAB)
export const showPageClean = createAction(SHOW_PAGE_CLEAN)
export const changeInteractiveMarker = createAction(CHANGE_INTERACTIVE_MARKER)
export const changeMapPosition = createAction(CHANGE_MAP_POSITION)
export const mapDrewPolygon = createAction(MAP_DREW_POLYGON)

export const editToggle = createAction(EDIT_TOGGLE)
export const editDeleteInterpretation = createAction(EDIT_DELETE_INTERPRETATION)
export const editDeleteInterpretationSuccess = createAction(EDIT_DELETE_INTERPRETATION_SUCCESS)
export const editDeleteInterpretationFail = createAction(EDIT_DELETE_INTERPRETATION_FAIL)
export const editDeleteSubjectRel = createAction(EDIT_DELETE_SUBJECT_REL)
export const editDeleteSubjectRelSuccess = createAction(EDIT_DELETE_SUBJECT_REL_SUCCESS)
export const editDeleteSubjectRelFail = createAction(EDIT_DELETE_SUBJECT_REL_FAIL)
export const editDeleteSubject = createAction(EDIT_DELETE_SUBJECT)
export const editDeleteSubjectSuccess = createAction(EDIT_DELETE_SUBJECT_SUCCESS)
export const editDeleteSubjectFail = createAction(EDIT_DELETE_SUBJECT_FAIL)
export const editEditInterpretation = createAction(EDIT_EDIT_INTERPRETATION)
export const editEditInterpretationSuccess = createAction(EDIT_EDIT_INTERPRETATION_SUCCESS)
export const editEditInterpretationFail = createAction(EDIT_EDIT_INTERPRETATION_FAIL)

export const editAddConnection = createAction(EDIT_ADD_CONNECTION)
export const editClearConnections = createAction(EDIT_CLEAR_CONNECTIONS)

export const editAddToSelection = createAction(EDIT_ADD_TO_SELECTION)

export const editUpdateSpatial = createAction(EDIT_UPDATE_SPATIAL)
export const editUpdateSpatialSuccess = createAction(EDIT_UPDATE_SPATIAL_SUCCESS)

export const editDeleteSpatial = createAction(EDIT_DELETE_SPATIAL)
export const editDeleteSpatialSuccess = createAction(EDIT_DELETE_SPATIAL_SUCCESS)
export const actions = {
  getShowPageData,
  getShowPageDataSuccess,
  getShowPageDataGraphqlSuccess,
  getShowPageDataFailed,
  simpleSearch,
  simpleSearchGraphql,
  simpleSearchStart,
  simpleSearchNewSpatials,
  simpleSearchSuccess,
  simpleSearchGraphqlSuccess,
  simpleSearchFailed,
  simpleSearchClean,
  simpleSearchSaveQuery,
  showPageAddTabs,
  showPageUpdateTab,
  getShowPagePointsSuccess,
  getShowPagePointsFailed,
  showPageClean,
  changeInteractiveMarker,
  changeMapPosition,
  mapDrewPolygon,

  editToggle,
  editDeleteInterpretation,
  editDeleteInterpretationSuccess,
  editDeleteInterpretationFail,
  editDeleteSubjectRel,
  editDeleteSubjectRelSuccess,
  editDeleteSubjectRelFail,
  editDeleteSubject,
  editDeleteSubjectSuccess,
  editDeleteSubjectFail,
  editEditInterpretation,
  editEditInterpretationSuccess,
  editEditInterpretationFail,

  editAddConnection,
  editClearConnections,

  editAddToSelection,

  editUpdateSpatial,
  editUpdateSpatialSuccess,

  editDeleteSpatial,
  editDeleteSpatialSuccess,
}

export const reducers = {
  [SHOW_PAGE_CLEAN]: state => ({
    ...state,
    showPage: {
      isLoading: true,
      isError: false,
      data: [],
    },
    points: [],
  }),
  [GET_SHOW_PAGE_DATA_SUCCESS]: (state, {payload}) => ({
    ...state,
    showPage: {
      isLoading: false,
      isError: false,
      data: [...state.showPage.data, transformData(payload)],
    },
  }),
  [GET_SHOW_PAGE_DATA_GRAPHQL_SUCCESS]: (state, {payload}) => ({
    ...state,
    showPage: {
      isLoading: false,
      isError: false,
      data: [...state.showPage.data, payload],
    },
  }),
  [GET_SHOW_PAGE_DATA_FAILED]: state => ({
    ...state,
    showPage: {
      isLoading: false,
      isError: true,
      data: [],
    },
  }),
  [GET_SHOW_PAGE_POINTS_SUCCESS]: (state, {payload}) => ({
    ...state,
    points: unitePoints(state.points, payload),
  }),
  [GET_SHOW_PAGE_POINTS_FAILED]: state => ({
    ...state,
    points: [],
  }),
  [SIMPLE_SEARCH_START]: state => ({
    ...state,
    spatials: {
      isLoading: true,
      data: [],
    },
    searchResults: {
      isLoading: true,
      data: state.searchResults ? state.searchResults.data : [],
    },
  }),
  [SIMPLE_SEARCH_NEW_SPATIALS]: (state, {payload}) => ({
    ...state,
    spatials: {
      isLoading: false,
      data: [
        ...(state.spatials ? state.spatials.data : []),
        {
          name: payload.name,
          markers: getMarkers(payload.points, payload.type, payload.name),
          polygons: getPolygons(payload.points, payload.type, payload.name),
        },
      ],
    },
  }),
  [SIMPLE_SEARCH_SUCCESS]: (state, {payload}) => ({
    ...state,
    searchResults: {
      isLoading: false,
      error: false,
      count: payload.count,
      data: [...payload.results],
      resultsType: payload.type,
    },
    savedQuery: {
      ...state.savedQuery,
      count: state.savedQuery.count || payload.count,
    },
  }),
  [SIMPLE_SEARCH_GRAPHQL_SUCCESS]: (state, {payload}) => {
    let d = []
    const {edges} = payload.data[payload.queryType]
    if (edges) {
      if (edges[0]) {
        d = edges
      } else if (edges.node) {
        d = edges.node
      }
    }

    return {
      ...state,
      searchResults: {
        isLoading: false,
        error: false,
        graphql: true,
        count: payload.data[payload.queryType].totalCount,
        data: d,
        objLink: payload.objLink,
        resultsType: payload.queryType,
      },
      savedQuery: {
        ...state.savedQuery,
        count:
          state.savedQuery.count || payload.data[payload.queryType].totalCount,
      },
    }
  },
  [SIMPLE_SEARCH_CLEAN]: (state, {payload}) => ({
    ...state,
    searchResults: {
      isLoading: false,
      data: [],
    },
    savedQuery: {
      count: 0,
    },
  }),
  [SIMPLE_SEARCH_SAVE_QUERY]: (state, {payload}) => ({
    ...state,
    savedQuery: {
      params: {
        ...payload.params,
      },
      count: payload.count,
      select: payload.select,
    },
  }),
  [SHOW_PAGE_ADD_TABS]: (state, {payload}) => {
    const newTabs = payload.map(obj => {
      return {
        name: obj.label.toLowerCase(),
        contentType: 'collapse',
        subjectType: obj.label.toLowerCase(),
        content: [],
      }
    })

    return {
      ...state,
      showPage: {
        isLoading: false,
        isError: false,
        data: [...state.showPage.data, ...newTabs],
      },
    }
  },
  [SHOW_PAGE_UPDATE_TAB]: (state, {payload}) => {
    console.log('payload', payload)
    const newData = payload.map(o => {
      return transformData(o)
    })
    console.log(newData)
    return {
      ...state,
    }
  },
  [SIMPLE_SEARCH_FAILED]: state => ({
    ...state,
    searchResults: {
      isLoading: false,
      error: true,
      data: [],
    },
  }),
  [CHANGE_INTERACTIVE_MARKER]: (state, {payload}) => ({
    ...state,
    interactiveMarkers: [
      ...state.interactiveMarkers.filter(
        m => m.uid !== `${payload.title}_${payload.key}`
      ),
      {
        coords: {
          lat: payload.lat || state.mapState.lat,
          lng: payload.lng || state.mapState.lng,
        },
        img: getIconFunctions[payload.title],
        title: payload.title,
        key: payload.key,
        uid: `${payload.title}_${payload.key}`,
        draggable: true,
      },
    ],
  }),
  [CHANGE_MAP_POSITION]: (state, {payload}) => ({
    ...state,
    mapState: {
      ...state.mapState,
      lat: payload.lat,
      lng: payload.lng,
      zoom: payload.zoom,
    },
  }),
  [MAP_DREW_POLYGON]: (state, {payload}) => ({
    ...state,
    drawnPolygon: payload,
  }),
  [EDIT_TOGGLE]: (state) => ({
    ...state,
    editMode: !state.editMode,
  }),
  [EDIT_DELETE_INTERPRETATION_SUCCESS]: (state, {payload}) => {
    console.log('EDIT_DELETE_INTERPRETATION_SUCCESS', payload)
    const {id, subjectType, editPath, editPathIds} = payload
    const retState = {
      ...state,
    }

    let {data} = retState.showPage

    if (editPath) {
      for (let i = 0; i < editPath.length; i++) {
        console.log('EDIT_DELETE_INTERPRETATION_SUCCESS2', data)
        if (data.childrenItem) {
          data = data.childrenItem
        } else {
          data = data.find(d => d.subjectType === editPath[i])
        }
        data = data.content

        if (i !== editPath.length - 1) {
          data = data[editPathIds[i]]
        }
      }

      data = data.splice(id, 1)
    } else {
      data.forEach(item => {
        if (item.subjectType === subjectType) {
          item.content.splice(id, 1)
        }
      })
    }
    console.log('EDIT_DELETE_INTERPRETATION_SUCCESS', retState)
    return {
      ...retState,
    }
  },
  [EDIT_EDIT_INTERPRETATION_SUCCESS]: (state, {payload}) => {
    console.log('EDIT_EDIT_INTERPRETATION_SUCCESS', state, payload)
    const {editPath, editPathIds, fieldLabel, newValue, newValueText, subjectType} = payload
    const retState = {
      ...state,
    }

    const nValue = newValueText || newValue;

    let {data} = retState.showPage

    if (editPath) {
      for (let i = 0; i < editPath.length; i++) {
        console.log('EDIT_EDIT_INTERPRETATION_SUCCESS2', data)
        if (data.childrenItem) {
          data = data.childrenItem
        } else {
          console.log('EDIT_EDIT_INTERPRETATION_SUCCESS3', data)
          data = data.find(d => d.subjectType === editPath[i])
        }
        console.log('EDIT_EDIT_INTERPRETATION_SUCCESS4', data)
        data = data.content[editPathIds[i]]
      }
      data = data.content.find(d => d.fieldName === fieldLabel)
      data.text = nValue
    } else {
      data.forEach(item => {
        if (item.subjectType === subjectType) {

          const field = item.content.find(d => d.fieldName === fieldLabel)
          field.text = nValue
        }
      })
    }

    return {
      ...retState,
    }
  },

  [EDIT_ADD_CONNECTION]: (state, data) => {
    const editModeNewConnections = state.editModeNewConnections;
    editModeNewConnections[data.payload.id] = data.payload.relation
    console.log('EDIT_ADD_CONNECTION', editModeNewConnections, data)
    return {
      ...state,
      editModeNewConnections,
    }
  },

  [EDIT_CLEAR_CONNECTIONS]: (state) => ({
    ...state,
    editModeNewConnections: [],
  }),

  [EDIT_DELETE_SPATIAL_SUCCESS]: (state, data) => {
    console.log('EDIT_DELETE_SPATIAL', state, data)

    const retState = {
      ...state,
    }
    const {payload} = data;
    const {points} = retState.showPage.data[0].settings

    points.forEach((point, i) => {
      if (point.uid === payload.uid) {
        points.splice(i, 1)
        return 0
      }
    })

    return {
      ...state,
      showPage: {
        ...retState.showPage,
      }
    }
  },
}

export const initialState = () => {
  const state = {
    showPage: {
      isLoading: true,
      isError: false,
      data: [],
      leftPanelButtons: [],
    },
    editMode: false,
    editModeNewConnections: [],
    points: [],
    interactiveMarkers: [],
    mapState: {
      lat: 53.89,
      lng: 26.87,
      zoom: 5,
      crs: CRS.EPSG3857,
    },
    drawnPolygon: [],
  }
  return state
}

export default handleActions(reducers, initialState())
