export const getCoords = (data, key, id, knowledge = 'ArchaeologySpatial') => {
  if (data[`${key}_coords_type_${id}`] === 'point') {
    return {
      points: [
        data[`${key}_coords_e_${id}`],
        data[`${key}_coords_n_${id}`],
      ],
      gradation_type: data[`${key}_coords_a_${id}`],
      point_type: '0',
      knowledge,
    }
  }

  if (data[`${key}_coords_type_${id}`] === 'polygon') {
    const points = [];
    let counter = 0;

    while (data[`${key}_coords_n_${id}_${counter}`]) {
      points.push([
        data[`${key}_coords_e_${id}_${counter}`],
        data[`${key}_coords_n_${id}_${counter}`]
      ])
      counter++;
    }

    return {
      points,
      gradation_type: data[`${key}_coords_a_${id}`],
      point_type: '1',
      knowledge,
    }
  }

  return {}
}

export const getFiles = (data, createData, label, type) => {
  const files = {};
  console.log('getFiles', data, createData, label, type)
  if (createData.sourceFilelist && createData.sourceFilelist[label]) {
    files.fileList = createData.sourceFilelist[label].fileList;
    files.type = type;
    files.label = label;

    if (type === 'Photo' || type === 'TopographicPlan') {
      files.photoData = [];
      for (let i = 0; i < files.fileList.length; i++) {
        files.photoData.push({
          year: data[`${label}_year_${i}`],
          name: data[`${label}_name_${i}`],
          author: data[`${label}_author_${i}`]
        })
      }
    }

    if (type === 'OfficialDocument') {
      files.photoData = [];
      for (let i = 0; i < files.fileList.length; i++) {
        files.photoData.push({
          name: data[`${label}_name_${i}`],
        })
      }
    }
  }

  return files;
}

export const collectData = {
  sites: (data, createData, i, uid, status) => {
    console.log('collectData', i)
    return {
      status,
      uid,
      files: getFiles(data, createData, `site_files_${i}`, 'TopographicPlan'),
      files2: getFiles(data, createData, `site_files_photo_${i}`, 'Photo'),
      subj_data: {
        research: {
          ru_name: data[`site_name_${i}`],
          local_name: data[`site_name_${i}`],
          en_name: data[`site_name_${i}`],
          ru_desc: data[`site_desc_${i}`],
          en_desc: data[`site_desc_${i}`],
          local_desc: data[`site_desc_${i}`],
        },
        attributes: {},
      },
      sr_data: getCoords(data, 'site', i)
    }
  },
  layers: (data, createData, i, uid, status) => {
    const epoch = data[`layer_epoch_${i}`];
    const type = data[`layer_type_${i}`];

    const ret = {
      status,
      uid,
      subj_data: {
        research: {
          ru_name: data[`layer_name_${i}`],
          local_name: data[`layer_name_${i}`],
          en_name: data[`layer_name_${i}`],
          ru_desc: data[`layer_desc_${i}`],
          en_desc: data[`layer_desc_${i}`],
          local_desc: data[`layer_desc_${i}`],
          date_start: data[`layer_date_since_${i}`],
          date_end: data[`layer_date_to_${i}`],
        },
      },
      sr_data: getCoords(data, 'layer', i),
    }

    if (epoch && type) {
      ret.subj_data.attributes = {
        Epoch: [{ uid: epoch }],
        SiteType: [{ uid: type }],
      }
    }
    console.log('collect layers', i, epoch, type, ret)
    return ret;
  },
  artifacts: (data, createData, i, uid, status) => {
    const category = (data[`artifact_category_${i}`]) ? data[`artifact_category_${i}`].map(c => {
      return { uid: c }
    }) : {};
    const material = (data[`artifact_material_${i}`]) ? data[`artifact_material_${i}`].map(m => {
      return { uid: m }
    }) : {};
    console.log('catmat', category, material)
    return {
      status,
      uid,
      files: getFiles(data, createData, `artifact_files_${i}`, 'Photo'),
      subj_data: {
        research: {
          ru_desc: data[`artifact_desc_${i}`],
          en_desc: data[`artifact_desc_${i}`],
          local_desc: data[`artifact_desc_${i}`],
          date_start: data[`artifact_date_since_${i}`],
          date_end: data[`artifact_date_to_${i}`],
          technique: data[`artifact_technique_${i}`],
        },
        node_data:{
          ru_name: data[`artifact_name_${i}`],
          local_name: data[`artifact_name_${i}`],
          en_name: data[`artifact_name_${i}`],
          ru_desc: data[`artifact_desc_${i}`],
          en_desc: data[`artifact_desc_${i}`],
          local_desc: data[`artifact_desc_${i}`],
          weight: data[`artifact_weight_${i}`],
        },
        attributes: {
          ArtifactCategory: category,
          ArtifactMaterial: material,
        },
      },
      sr_data: getCoords(data, 'artifact', i),
    }
  },
  excavations: (data, createData, i, uid, status) => {
    return {
      status,
      uid,
      files: getFiles(data, createData, `excavation_files_${i}`, 'TopographicPlan'),
      subj_data: {
        research: {
          ru_desc: data[`excavation_desc_${i}`],
          en_desc: data[`excavation_desc_${i}`],
          local_desc: data[`excavation_desc_${i}`],
        },
        node_data: {
          year: data[`excavation_year_${i}`],
          ru_name: data[`excavation_name_${i}`],
          local_name: data[`excavation_name_${i}`],
          en_name: data[`excavation_name_${i}`],
          area: data[`excavation_area_${i}`],
        },
        attributes: {},
      },
      sr_data: getCoords(data, 'excavation', i),
    }
  },
  assemblages: (data, createData, i, uid, status) => {
    const type = data[`assemblage_type_${i}`];

    const ret = {
      status,
      uid,
      files: getFiles(data, createData, `assemblage_files_${i}`, 'Photo'),
      subj_data: {
        research: {
          ru_desc: data[`assemblage_desc_${i}`],
          en_desc: data[`assemblage_desc_${i}`],
          local_desc: data[`assemblage_desc_${i}`],
          date_start: data[`assemblage_date_since_${i}`],
          date_end: data[`assemblage_date_to_${i}`],
        },
        node_data: {
          ru_name: data[`assemblage_name_${i}`],
          local_name: data[`assemblage_name_${i}`],
          en_name: data[`assemblage_name_${i}`],
          ru_desc: data[`assemblage_desc_${i}`],
          en_desc: data[`assemblage_desc_${i}`],
          local_desc: data[`assemblage_desc_${i}`],
        },
      },
      sr_data: getCoords(data, 'assemblage', i),
    }

    if (type) {
      ret.subj_data.attributes = {
        AssemblageType: [{ uid: type }],
      }
    }

    return ret;
  },
  cultures: (data, createData, i, uid, status) => {
    console.log('collectData', i)
    const epoch = data[`culture_epoch_${i}`];

    const ret = {
      status,
      uid,
      subj_data: {
        research: {
          ru_name: data[`culture_name_${i}`],
          local_name: data[`culture_name_${i}`],
          en_name: data[`culture_name_${i}`],
          ru_desc: data[`culture_desc_${i}`],
          en_desc: data[`culture_desc_${i}`],
          local_desc: data[`culture_desc_${i}`],
          date_start: data[`culture_date_since_${i}`],
          date_end: data[`culture_date_to_${i}`],
        },
      },
    }

    if (epoch) {
      ret.subj_data.attributes = {
        Epoch: [{ uid: epoch }],
      }
    }

    return ret;
  },
  rdates: (data, createData, i, uid, status) => {
    return {
      status,
      uid,
      subj_data: {
        research: {
          ru_name: data[`rdate_name_${i}`],
          local_name: data[`rdate_name_${i}`],
          en_name: data[`rdate_name_${i}`],
          context_type: 'culture',
          context_desc: 'no',
        },
        node_data: {
          ru_name: data[`rdate_name_${i}`],
          local_name: data[`rdate_name_${i}`],
          en_name: data[`rdate_name_${i}`],
          r_date: data[`rdate_data_bp_${i}`],
          dispersion: data[`rdate_s_${i}`],
          dating_method: data[`rdate_date_type_${i}`],
          curve_type: data[`rdate_curve_type_${i}`],
        },
        attributes: {
          CarbonMaterial: [{ uid: data[`rdate_material_${i}`] }],
          SludgeGenesis: [{ uid: data[`rdate_genesis_${i}`] }],
        },
      },
      sr_data: getCoords(data, 'rdate', i),
    }
  },
  heritages: (data, createData, i, uid, status) => {
    const offDocUid = data[`heritage_official_doc_uid`]
    const official_document = []
    const link_official_document = []

    if (offDocUid === 'new') {
      official_document.push({
        name: data[`heritage_files_name_0`],
        ru_name: data[`heritage_files_name_0`],
        en_name: data[`heritage_files_name_0`],
        local_name: data[`heritage_files_name_0`],
        type: data[`heritage_files_type_0`],
        number: data[`heritage_files_number_0`],
        date_of_issue: data[`heritage_date_string`],
      })
    } else {
      link_official_document.push({
        uid: offDocUid,
        date_start: data[`heritage_date_string`],
      })
    }

    return {
      status,
      uid,
      files: getFiles(data, createData, `heritage_files`, 'SourceFile'),
      files2: getFiles(data, createData, `heritage_topo`, 'TopographicPlan'),
      subj_data: {
        research: {},
        node_data: {
          ru_desc: data[`heritage_desc`],
          en_desc: data[`heritage_desc`],
          local_desc: data[`heritage_desc`],
          ru_name: data[`heritage_name`],
          local_name: data[`heritage_name`],
          en_name: data[`heritage_name`],
          date: data[`heritage_date_string`],
          preservation_boundaries_description: data[`preservation_boundaries_description`],
          subject_of_preservation: data[`subject_of_preservation`],
          usability_regime: data[`usability_regime`],
          address: data[`heritage_address`],
          site: data[`heritage_site`],
          building: data[`heritage_building`],
          object_status: data[`heritage_status`],
          link_official_document,
          official_document,
        },
      },
      sr_data: getCoords(data, 'heritage', i, 'Heritage'),
    }
  },
  persons: (data, createData, i, uid, status) => {
    return {
      status,
      uid,
      files: getFiles(data, createData, `person_files_${i}`, 'Photo'),
      subj_data: {
        research: {
          ru_desc: data[`person_bio_${i}`],
          en_desc: data[`person_bio_${i}`],
          local_desc: data[`person_bio_${i}`],
        },
        node_data: {
          ru_name: data[`person_name_${i}`],
          local_name: data[`person_name_${i}`],
          en_name: data[`person_name_${i}`],
          date_of_birth: data[`person_birth_year_${i}`],
          date_of_death: data[`person_death_year_${i}`],
          role: data[`person_role_${i}`],
          info: data[`person_info_${i}`],
        },
        attributes: {},
      },
    }
  },
  monuments: (data, createData, i, uid, status) => {
    return {
      status,
      uid,
      files: getFiles(data, createData, `building_files_${i}`, 'Photo'),
      subj_data: {
        research: {
          ru_desc: data[`building_desc_${i}`],
          en_desc: data[`building_desc_${i}`],
          local_desc: data[`building_desc_${i}`],
          ru_name: data[`building_name_${i}`],
          local_name: data[`building_name_${i}`],
          en_name: data[`building_name_${i}`],
          date_start: parseInt(data[`building_year_${i}`], 10),
        },
        node_data: {
          architect: data[`building_architect_${i}`],
          style: data[`building_style_${i}`],
        },
        address: {
          ru_name: data[`building_address_ru_${i}`],
          en_name: data[`building_address_en_${i}`],
          date_start: data[`building_address_since_${i}`],
          date_end: data[`building_address_to_${i}`],
        },
        attributes: {},
      },
      sr_data: getCoords(data, 'building', i),
    }
  },
  localities: (data, createData, i, uid, status) => {
    const ret = {
      status,
      uid,
      files: getFiles(data, createData, `locality_files_${i}`, 'Photo'),
      subj_data: {
        research: {
          ru_desc: data[`locality_desc_${i}`],
          en_desc: data[`locality_desc_${i}`],
          local_desc: data[`locality_desc_${i}`],
        },
        node_data: {
          ru_name: data[`locality_name_${i}`],
          local_name: data[`locality_name_${i}`],
          en_name: data[`locality_name_${i}`],
        },
        attributes: {
          LocalityType: [{ uid: data[`locality_type_${i}`] }],
        },
      },
      sr_data: getCoords(data, 'locality', i, 'EncyclopediaSpatial'),
    }

    const population = [];
    let counter = 0;

    while (data[`locality_year_${i}_${counter}`] && data[`locality_population_${i}_${counter}`]) {
      population.push({
        date: data[`locality_year_${i}_${counter}`],
        population: data[`locality_population_${i}_${counter}`]
      })
      counter++;
    }

    ret.population = population;

    console.log('collectData localities', ret)

    return ret;
  },
  districts: (data, createData, i, uid, status) => {
    console.log('collectData', i)

    const ret = {
      status,
      uid,
      subj_data: {
        research: {
          ru_name: data[`district_name_${i}`],
          local_name: data[`district_name_${i}`],
          en_name: data[`district_name_${i}`],
          ru_desc: data[`district_desc_${i}`],
          en_desc: data[`district_desc_${i}`],
          local_desc: data[`district_desc_${i}`],
          date_start: data[`district_date_since_${i}`],
          date_end: data[`district_date_to_${i}`],
        },
        node_data: {
          ru_name: data[`district_name_${i}`],
          local_name: data[`district_name_${i}`],
          en_name: data[`district_name_${i}`],
        },
      },
      sr_data: getCoords(data, 'district', i),
    }

    return ret;
  },
  encorganizations: (data, createData, i, uid, status) => {
    return {
      status,
      uid,
      files: getFiles(data, createData, `encorganization_files_${i}`, 'Photo'),
      subj_data: {
        research: {
          ru_desc: data[`encorganization_desc_${i}`],
          en_desc: data[`encorganization_desc_${i}`],
          local_desc: data[`encorganization_desc_${i}`],
        },
        node_data: {
          ru_name: data[`encorganization_name_${i}`],
          local_name: data[`encorganization_name_${i}`],
          en_name: data[`encorganization_name_${i}`],
          date_start: data[`encorganization_year_${i}`],
          url: data[`encorganization_url_${i}`],
        },
        attributes: {
          EncOrganizationType: [{ uid: data[`encorganization_type_${i}`] }],
        },
      },
      sr_data: getCoords(data, 'encorganization', i, 'EncyclopediaSpatial'),
    }
  },
}

export default collectData
