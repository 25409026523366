export const getVariant = subject => {
  const lexemeIndex = subject.lexemeIndex % 20 || 0
  const groupIndex = (subject.groupIndex - 1) % 5 || 0
  const variantIndex = subject.variantIndex % 12 || 0
  return {
    epoch: groupIndex,
    type: lexemeIndex,
    subType: variantIndex,
  }
}
