export const getAssemblages = subject => {
  const type =
    (subject.attributes
      ? subject.attributes.filter(
          a => a.label === 'AssemblageType' || a.type === 'AssemblageType',
        )[0]
      : {}) || {}

  if (subject.epoch && subject.epoch.sort) {
    return {
      epoch: subject.epoch.sort,
      type: type.sort,
    }
  }
  const epoch = subject.attributes
    ? subject.attributes.filter(a => a.type === 'Epoch')[0]
    : null

  const img = epoch
    ? epoch.sort
    : subject.nodeAttributes && subject.nodeAttributes.epoch
    ? subject.nodeAttributes.epoch.sort
    : 0

  return {
    epoch: img,
    type: type.sort,
  }
}
