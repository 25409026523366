import { useState, useEffect } from 'react'
import { fetchGrapqlRequest, HEADERS_POST_AUTH } from 'utility/request'
import { url } from 'utility/constants'

const PARAMS = [{ key: 'search', val: 'value' }]
const searchUrl = `${url}/api/graphql/`

export const useDataApi = (
  query,
  variables,
  initialData = [],
  deps = [],
  mapper = elem => elem,
) => {
  const [data, setData] = useState(initialData)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)
      try {
        const response = await fetchGrapqlRequest(query, variables)
        setData(mapper(response))
      } catch (error) {
        console.error(error)
        setIsError(true)
      }

      setIsLoading(false)
    }

    fetchData()
  }, [])

  return { data, isLoading, isError }
}

export const useDataManualApi = (query, initialData, mapper = elem => elem) => {
  const [data, setData] = useState(initialData)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  console.log('ProfileResearcher useDataManualApi')
  const doFetch = async (variables, customQuery) => {
    setIsError(false)
    setIsLoading(true)

    try {
      const response = await fetchGrapqlRequest(customQuery || query, variables)
      setData(mapper(response))
    } catch (error) {
      console.error(error)
      setIsError(true)
    }

    setIsLoading(false)
  }

  const reset = () => {
    setIsError(false)
    setIsLoading(false)
    setData(mapper(initialData))
  }

  const updateData = newData => {
    setIsError(false)
    setIsLoading(false)
    setData(mapper(newData))
  }

  return {
    data,
    isLoading,
    isError,
    doFetch,
    reset,
    updateData,
  }
}

export const useDataDynamic = (query, initialData, mapper = elem => elem) => {
  const [data, setData] = useState(initialData)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const variables = {}

  const doFetch = async (value, customQuery) => {
    setIsError(false)
    setIsLoading(true)

    PARAMS.forEach(par => {
      if (par.val === 'value') {
        variables[par.key] = value
      } else {
        variables[par.key] = par.val
      }
    })

    const headers = {
      ...HEADERS_POST_AUTH(),
      method: 'POST',
      body: JSON.stringify({
        query: customQuery || query,
        variables,
      }),
    }

    try {
      const response = await fetch(searchUrl, headers)
        .then(res => res.json())
        .catch(error => {
          console.log(`request failed ${error}`)
          setIsError(true)
        })
      setData(mapper(response))
    } catch (error) {
      console.error(error)
      setIsError(true)
    }

    setIsLoading(false)
  }

  const reset = () => {
    setIsError(false)
    setIsLoading(false)
    setData(mapper(initialData))
  }

  const updateData = newData => {
    setIsError(false)
    setIsLoading(false)
    setData(mapper(newData))
  }

  return {
    data,
    isLoading,
    isError,
    doFetch,
    reset,
    updateData,
  }
}

export default useDataApi
