import React, { Children } from 'react'
import { MapControl } from 'react-leaflet'
import { render } from 'react-dom'
import Dumb from './Control.Dumb'

export default class LeafletSnapshots extends MapControl {
  createLeafletElement = props => {
    const element = new Dumb(props)

    return element
  }

  componentDidMount() {
    super.componentDidMount()
    this.renderContent()
  }

  handleClick = () => {
    const { handleClick, toggleSnapshot } = this.props
    toggleSnapshot()
    handleClick()
  }

  renderElement() {
    /* eslint-disable */
    return (
      <span>
        <a
          className="leaflet-control-easyPrint-button-export"
          onClick={this.handleClick}
        />
      </span>
    )
    /* eslint-enable */
  }

  renderContent() {
    const container = this.leafletElement.getContainer()
    render(Children.only(this.renderElement()), container)
  }
  render() {
    return null
  }
}
