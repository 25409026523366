import React from 'react'
import { getExcavation } from 'distLibs/images/excTypes'
import { getResearch } from 'distLibs/images/resTypes'
import { getLayer } from 'distLibs/images/monTypes'
import { getAssemblages } from 'distLibs/images/assemblage'
import { getLocality } from 'distLibs/images/locality'
import { getBuilding } from 'distLibs/images/building'
import { getVariant } from 'distLibs/images/variant'
import { getCulture } from 'distLibs/images/culture'
import { getDefaultIcon } from 'distLibs/images/defaultIcon'
import { getMapIconSrc } from 'components/MapIcon'
import { getTypeByLabel } from 'utility/links'
import i18n from 'i18n'

const LANG = ['ru','en'].includes(i18n.language) ? i18n.language: 'local'

export const EPOCH_COLOR = {
  0: '#000000',
  1: '#ea00b6',
  2: '#00d9ff',
  3: '#ff3d17',
  4: '#7a32bc',
  5: '#2400fa',
  6: '#ff7e00',
  7: '#97e400',
  8: '#fcc90c',
}

// ['key', [children's key]]
const FILTER_PARENTS = new Map([['layer', ['site']], ['layergraph', ['site']]])

const FILTER_CHILDREN = new Map([['site', ['layer', 'layergraph']]])

export const getIconLabel = {
  excavation: 'excavation',
  excavationgraph: 'excavation',
  excavations: 'excavation',
  culture: 'culture',
  research: 'project',
  archaeologyresearch: 'project',
  encyclopediaresearch: 'project',
  researchgraph: 'project',
  heritage: 'heritage',
  site: 'site',
  sites: 'site',
  layer: 'layer',
  layers: 'layer',
  layergraph: 'layer',
  monumentgraph: 'monument',
  monument: 'monument',
  monuments: 'monument',
  rdate: 'radiocarbon',
  rdates: 'radiocarbon',
  radiocarbon: 'radiocarbon',
  artifact: 'artifact',
  artifacts: 'artifact',
  assemblage: 'assemblage',
  assemblages: 'assemblage',
  locality: 'locality',
  district: 'district',
  districtgraph: 'district',
  variant: 'lexeme',
  lexeme: 'lexeme',
  // folkVariant: 'variant',
}

export const getIconFunctions = {
  layer: getLayer,
  assemblage: getAssemblages,
  lexeme: getVariant,
  locality: getLocality,
  monument: getBuilding,
  project: getResearch,
  culture: getCulture,
  excavation: getExcavation,
}

const getPointId = item => `${item.uid}-${item.latitude}-${item.longitude}`

export const getUniqPoints = array => {
  const result = new Map()
  array.forEach(item => {
    const id = getPointId(item)
    if (result.has(id)) {
      return
    }
    result.set(id, {
      ...item,
      title:
        item.interpretation[`${LANG}_name`] !== null
          ? item.interpretation[`${LANG}_name`]
          : item.interpretation[`sub_${LANG}_name`],
      subject_id: item.uid,
    })
  })
  return [...result.values()]
}

export const createMarker = item => {
  if (!item.points || typeof item.points[0] === 'object') {
    return null
  }
  return {
    tooltip: item.title,
    link: item.label
      ? `show/${getTypeByLabel(item.label)}/${item.subject_id}`
      : `show/${item.type}/${item.subject_id}`,
    uid: item.uid,
    icon: item.icon,
    coords: {
      lat: item.points[1],
      lng: item.points[0],
    },
  }
}
export const createPolygon = item => {
  if (!item.points || typeof item.points[0] !== 'object') {
    return null
  }
  return {
    tooltip: item.title,
    link: item.label
      ? `show/${getTypeByLabel(item.label)}/${item.subject_id}`
      : `show/${item.type}/${item.subject_id}`,
    uid: item.uid,
    icon: item.icon,
    coords: item.points,
  }
}

export const getMarkers = (points, type, name) => {
  console.log('getMarkers', points, type, name)
  if (points) {
    const nameLC = getIconLabel[name.toLowerCase()] || name.toLowerCase()
    console.log('getMarkersLC', nameLC, points, type)
    const pointSet = new Set()
    const isIconFunc = nameLC in getIconFunctions
    points.forEach(item => {
      if (item.points && typeof item.points[0] !== 'object') {
        pointSet.add({
          tooltip: item.title,
          subject: item.subject_id,
          img: getMapIconSrc({
            icon: nameLC,
            ...(isIconFunc
              ? getIconFunctions[nameLC](item)
              : getDefaultIcon(item)),
          }),
          link: item.label
            ? `show/${getTypeByLabel(item.label)}/${item.subject_id}`
            : `show/${type}/${item.subject_id}`,
          epoch:
            item.epoch ||
            (item.attributes
              ? item.attributes.filter(a => a.type === 'Epoch')[0]
              : 0),
          uid: item.uid,
          coords: {
            lat: item.points[1],
            lng: item.points[0],
          },
        })
      }

      const uids = []
      if (
        item.points &&
        typeof item.points[0] === 'object' &&
        !uids.includes(item.uid) &&
        item.inherited &&
        item.point[0] &&
        item.point[1]
      ) {
        uids.push(item.uid)
        console.log('inherited polygon', item)
        pointSet.add({
          tooltip: item.title,
          subject: item.subject_id,
          img: getMapIconSrc({
            icon: nameLC,
            ...(isIconFunc
              ? getIconFunctions[nameLC](item)
              : getDefaultIcon(item)),
          }),
          link: item.label
            ? `show/${getTypeByLabel(item.label)}/${item.subject_id}`
            : `show/${type}/${item.subject_id}`,
          epoch:
            item.epoch ||
            (item.attributes
              ? item.attributes.filter(a => a.type === 'Epoch')[0]
              : 0),
          uid: item.uid,
          coords: {
            lat: item.point[0],
            lng: item.point[1],
          },
        })
      }
    })
    return [...pointSet]
  }
  return []
}

export const getPolygons = (points, type, name) => {
  if (points) {
    const pointSet = new Set()
    const uids = []
    const nameLC = getIconLabel[name.toLowerCase()] || name.toLowerCase()
    const isIconFunc = nameLC in getIconFunctions
    points.forEach(item => {
      const epoch =
        item.epoch ||
        (item.attributes
          ? item.attributes.filter(a => a.type === 'Epoch')[0]
          : 0)
      // console.log('EPOCH', epoch)
      if (
        item.points &&
        typeof item.points[0] === 'object' &&
        !uids.includes(item.uid) &&
        !item.inherited
      ) {
        uids.push(item.uid)
        pointSet.add({
          tooltip: item.title,
          subject: item.subject_id,
          uid: item.uid,
          color: EPOCH_COLOR[epoch && epoch.sort],
          img: getMapIconSrc({
            icon: nameLC,
            ...(isIconFunc
              ? getIconFunctions[nameLC](item)
              : getDefaultIcon(item)),
          }),
          epoch,
          link: item.label
            ? `show/${getTypeByLabel(item.label)}/${item.subject_id}`
            : `show/${type}/${item.subject_id}`,
          coords: item.points,
          type,
        })
      }
    })
    return [...pointSet]
  }
  return []
}

const findElementWithName = (array, name) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].name === name) return i
  }
  return -1
}

const filterByParents = (statePoints, payload) => {
  const children = FILTER_PARENTS.get(payload.name)
  const newStatePoints = [...statePoints]
  children.forEach(item => {
    const itemIndex = findElementWithName(statePoints, item)
    if (itemIndex !== -1) {
      newStatePoints[itemIndex].isHidden = true
    }
  })
  return [
    ...newStatePoints,
    {
      isHidden: payload.isHidden,
      name: payload.name,
      markers: getMarkers(payload.points, payload.type, payload.name),
      polygons: getPolygons(payload.points, payload.type, payload.name),
    },
  ]
}

const filterByChildren = (statePoints, payload) => {
  const parents = FILTER_CHILDREN.get(payload.name)
  // use for-loop to return data after first match
  for (let i = 0; i < parents.length; i++) {
    const itemIndex = findElementWithName(statePoints, parents[i])
    if (itemIndex !== -1) {
      return [
        ...statePoints,
        {
          isHidden: true,
          name: payload.name,
          markers: getMarkers(payload.points, payload.type, payload.name),
          polygons: getPolygons(payload.points, payload.type, payload.name),
        },
      ]
    }
  }
  return [
    ...statePoints,
    {
      isHidden: payload.isHidden,
      name: payload.name,
      markers: getMarkers(payload.points, payload.type, payload.name),
      polygons: getPolygons(payload.points, payload.type, payload.name),
    },
  ]
}

export const unitePoints = (statePoints, payload) => {
  if (FILTER_PARENTS.has(payload.name)) {
    return filterByParents(statePoints, payload)
  }
  if (FILTER_CHILDREN.has(payload.name)) {
    return filterByChildren(statePoints, payload)
  }
  return [
    ...statePoints,
    {
      isHidden: payload.isHidden,
      name: payload.name,
      markers: getMarkers(payload.points, payload.type, payload.name),
      polygons: getPolygons(payload.points, payload.type, payload.name),
    },
  ]
}

export const revertPolygon = points => {
  if (!points || !points.map || points.length === 0) return null
  return [
    points.map(ll => {
      console.log(ll)
      return {
        lat: ll[1],
        lng: ll[0],
      }
    }),
  ]
}
