import * as request from '../../utility/request';

export const getLocale = async () => {
  const path = 'api/locale/';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const setLocale = async (data) => {
  const path = 'api/locale/';
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}
