import React from 'react'
import i18n from 'i18next'
import {
  siteTypeFragment,
  epochFragment,
} from 'components/GSearchPanel/Queries/archQueries'
import { getPropertyByNameString } from 'utility/transformData/constants'
import { getLayer } from 'distLibs/images/monTypes/index'
import MapIcon from 'components/MapIcon'

const page = {
  def: 'first: 25, last: 25',
}

const pageBig = {
  def: 'first: 100, last: 100',
}
const lang = ['ru','en'].includes(i18n.language) ? i18n.language: 'local'

const setVolNumEd = data => {
  console.log('setVolNumEd', data)
  const volNumEd = []
  const { volume, number, issue, edition, date } = data

  if (date) {
    volNumEd.push(`${date}`)
  }
  if (number) {
    if (issue) {
      volNumEd.push(`${i18n.t('common.number')}: ${issue}(${number})`)
    } else {
      volNumEd.push(`${i18n.t('common.number')}: ${number}`)
    }
  }
  if (volume) {
    volNumEd.push(`${i18n.t('common.volume')}: ${volume}`)
  }
  if (edition) {
    volNumEd.push(`${i18n.t('common.edition')}: ${edition}`)
  }

  if (volNumEd.length) {
    return `(${volNumEd.join(', ')})`
  }

  return ''
}

export const listQueries = {
  artifactSearch: () => {
    return {
      query: `query postQuery($search: String="") {
        results: artifacts(commonSearch: $search, ${page.def}) {
          edges {
            node {
              uid
              ${lang}Name
              assemblage {
                edges {
                  node {
                    uid
                    ${lang}Name
                    layer {
                      edges {
                        node {
                          uid
                          commonName {
                            ${lang}Name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`,
      render: item => {
        let extraText = ''
        const assemblage = getPropertyByNameString(
          item.node,
          'assemblage.edges.0.node',
        )
        if (assemblage) {
          extraText += `(Комплекс: ${getPropertyByNameString(
            assemblage,
            `${lang}Name`,
          )})`
          const layer = getPropertyByNameString(
            assemblage,
            'layer.edges.0.node',
          )
          if (layer) {
            extraText += ` (Слой: ${getPropertyByNameString(
              layer,
              `commonName.${lang}Name`,
            )})`
          }
        }
        return `${item.node[`${lang}Name`]} ${extraText}`
      },
    }
  },

  artifactCategorySearch: () => {
    return {
      query: `query postQuery($search: String="") {
        results: artifactcategories(${lang}Name_Icontains: $search, ${pageBig.def}, sort:["block__sort", "sort"]) {
          edges {
            node {
              uid
              sort
              ${lang}Name
            }
          }
        }
      }`,
      render: item => {
        return `${item.node[`${lang}Name`]}`
      },
    }
  },

  artifactMaterialSearch: () => {
    return {
      query: `query postQuery($search: String="") {
        results: artifactmaterials(${lang}Name_Icontains: $search, ${pageBig.def}, sort:["block__sort", "sort"]) {
          edges {
            node {
              uid
              sort
              ${lang}Name
              block {
                edges {
                  node {
                    sort
                  }
                }
              }
            }
          }
        }
      }`,
      render: item => {
        return `${item.node[`${lang}Name`]}`
      },
    }
  },

  researchSearch: () => {
    return {
      query: `query searchResearchCommon($search: String="") {
        results: researches (commonSearch: $search ,${page.def}) {
          edges {
            node {
              uid
              label
              year
              ${lang}Name
              author {
                edges {
                  node {
                    publicName
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'research',
    }
  },

  seriesSearch: () => {
    return {
      query: `query searchResearchCommon($search: String="") {
      results: searchSeries (${lang}Name_Icontains: $search, ${page.def}) {
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      render: item => {
        return `${item.node[`${lang}Name`]}`
      },
    }
  },

  bookSearch: () => {
    return {
      query: `query searchBooks($search: String="") {
        results: searchBooks (${lang}Name_Icontains: $search, ${page.def}) {
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      render: item => {
        return `${item.node[`${lang}Name`]}`
      },
    }
  },

  organizationSearch: () => {
    return {
      query: `query searchOrganization($search: String="") {
        results: searchOrganization (${lang}Name_Icontains: $search, ${page.def}) {
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      render: item => {
        return `${item.node[`${lang}Name`]}`
      },
    }
  },

  publisherSearch: () => {
    return {
      query: `query searchOrganization($search: String="") {
        results: searchPublishers (${lang}Name_Icontains: $search, ${page.def}) {
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      render: item => {
        return `${item.node[`${lang}Name`]}`
      },
    }
  },

  citySearch: () => {
    return {
      query: `query localitiesSearch($search: String="") {
        results: cities (${lang}Name_Icontains: $search, ${page.def}) {
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      render: item => {
        return `${item.node[`${lang}Name`]}`
      },
    }
  },

  ensembleSearch: () => {
    return {
      query: `query searchArchitecturalEnsembles($search: String="") {
        results: searchArchitecturalEnsembles (${lang}Name_Icontains: $search, ${page.def}) {
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      render: item => {
        return `${item.node[`${lang}Name`]}`
      },
    }
  },

  collectionSearch: () => {
    return {
      query: `query searchCollections($search: String="") {
        results: searchCollections (${lang}Name_Icontains: $search, ${page.def}) {
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      render: item => {
        return `${item.node[`${lang}Name`]}`
      },
    }
  },

  issueSearch: () => {
    return {
      query: `query issueSearch($search: String="") {
        results: searchIssue (periodicalNameIcontains: $search, ${page.def}) {
          edges {
            node {
              uid
              date
              ${lang}Name
              volume
              number
              issue
              periodical {
                edges {
                  node {
                    ${lang}Name
                  }
                }
              }
            }
          }
        }
      }`,
      render: item => {
        const perTitle =
          item.node.periodical.edges[0].node[`${lang}Name`]
        return `${perTitle} ${setVolNumEd(item.node)}`
      },
    }
  },

  periodicalSearch: () => {
    return {
      query: `query periodicalSearch($search: String="") {
        results: searchPeriodical (${lang}Name_Icontains: $search, ${page.def}) {
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      render: item => {
        return `${item.node[`${lang}Name`]}`
      },
    }
  },

  officialDocSearch: () => {
    return {
      query: `query officialDocuments($search: String="") {
        results: officialDocuments (commonSearch: $search, ${page.def}) {
          edges {
            node {
              uid
              number
              dateOfIssue
              ${lang}Name
            }
          }
        }
      }`,
      render: item => {
        const number = item.node.number ? ` (${item.node.number})` : ''
        return `${item.node[`${lang}Name`]} ${number}`
      },
    }
  },
}
