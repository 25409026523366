import React from 'react'
import * as Sentry from '@sentry/browser'
import ReactDOM from 'react-dom'
import { createBrowserHistory as createHistory } from 'history'
import { I18nextProvider } from 'react-i18next'

import Root from './Root'
import configureStore from './redux/configureStore'
import i18n from './i18n' // initialized i18next instance
const DSN = process.env.REACT_APP_SENTRY_DSN || null

Sentry.init({ dsn: DSN })

const initialState = {}

export const hashHistory = createHistory()

export const store = configureStore(initialState, hashHistory)

// Render the React application to the DOM
// Root component is to bootstrap Provider, Router and DevTools
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Root history={hashHistory} store={store} />
  </I18nextProvider>,
  document.getElementById('root'),
)
