import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Alert, Spin } from 'antd'

import UserItem from '../../../../User/Item'
import UserList from '../../../../User/List'

const url = process.env.REACT_APP_API_URL || 'http://0.0.0.0:8000'

const ProfileTabResearcherExist = props => {
  const renderError = (error, t) => {
    return <Alert message={t(error)} type="error" />;
  }

  const {
    toggleCreateNew,
    t,
    user,
    handleUserRemove,
    tags,
    handleUserSelect,
    userSelectValue,
    isError,
  } = props;

  const getSearchUrl = (path, value) =>
    `${url}/${path}/?search=first_name%3D${value}%7Clast_name%3D${value}%7Cparent_name%3D${value}%7Cemail%3D${value}&page_size=100`

  return (
    <div className="form_container">
      {isError && renderError(isError, t)}
      <Button
        key="create-new-organization"
        type="primary"
        onClick={toggleCreateNew}
      >
        {t('researcher.button.create')}
      </Button>
      {
        user ?
          <UserItem
            key={user.uid}
            user={user}
            handleUserRemove={handleUserRemove}
            tags={tags}
          />
          :
          <UserList
            inputName="user"
            title="Поиск пользователя"
            path="api/eauthor/list"
            getSearchUrl={getSearchUrl}
            tags={tags}
            style={{ width: '100%' }}
            handleSelect={handleUserSelect}
            value={userSelectValue}
          />
      }
    </div>
  );
};

ProfileTabResearcherExist.propTypes = {

};

export default ProfileTabResearcherExist;
