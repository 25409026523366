import { CRS } from 'leaflet'
import { MAPS_V1_URL, MAPS_V2_URL } from 'utility/constants'

export const TileLayerList = [
  [
    {
      checked: true,
      name: 'Open Street Map',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors',
      subdomains: ['a', 'b', 'c'],
      maxZoom: 18,
    },
    {
      name: 'Yandex',
      url:
        'http://vec{s}.maps.yandex.net/tiles?l=map&v=18.04.22-2&z={z}&x={x}&y={y}&scale=1&lang=ru_RU',
      subdomains: ['01', '02', '03', '04'],
      crs: CRS.EPSG3395,
      maxZoom: 19,
    },
    {
      name: 'Yandex Satellite',
      url:
        'http://sat{s}.maps.yandex.net/tiles?l=sat&v=3.383&z={z}&x={x}&y={y}&scale=2&lang=ru_RU',
      subdomains: ['01', '02', '03', '04'],
      crs: CRS.EPSG3395,
      maxZoom: 19,
      minZoom: 1,
    },
    {
      name: 'Google',
      url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    },
    {
      name: 'Google Satellite',
      url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    },
  ],
  [
    {
      checked: true,
      name: 'Topo Routes',
      url: 'http://maps.marshruty.ru/ml.ashx?al=1&x={x}&y={y}&z={z}',
      subdomains: [],
      minZoom: 8,
      maxZoom: 15,
    },
  ],
  [
    {
      checked: true,
      name: {
        ru: 'Европейская часть России (1882)',
        en: 'European part of Russia (1882)',
      },
      url: `${MAPS_V1_URL}/strelb/z{z}/{xx}/x{x}/{yy}/y{y}.jpg`,
      minZoom: 0,
      maxZoom: 12,
      zoomOffset: 1,
      subdomains: [],
      options: {
        xx: c => parseInt(c.x / 1000, 10),
        yy: c => parseInt(c.y / 1000, 10),
      },
    },
    {
      name: {
        ru: 'Приуралье (1911)',
        en: 'Ural region (1911)',
      },
      url: `${MAPS_V2_URL}/Priural_1911/{z}/{x}/{y}.png`,
      center: [54.5256787342, 55.8918203093],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 16,
    },
    {
      name: {
        ru: 'Среднее Поволжье (1946)',
        en: 'Middle Volga Region (1946)',
      },
      url: `${MAPS_V1_URL}/middle_volga_1946/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 15,
      center: [55.17, 49.38],
      subdomains: [],
      tms: true,
    },
    {
      name: {
        ru: 'Казанская губерния (1800)',
        en: 'Kazan Governorate (1800)',
      },
      url: `${MAPS_V1_URL}/Kazan_1800/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 16,
      center: [55.5722761399, 48.8405195268],
      subdomains: [],
      tms: true,
      opacity: 0.7,
    },
    {
      name: {
        ru: 'Казанская губерния (1822)',
        en: 'Kazan Governorate (1822)',
      },
      url: `${MAPS_V1_URL}/Kazan_1822/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 16,
      center: [55.8406191412, 48.781731631],
      subdomains: [],
      tms: true,
      opacity: 0.7,
    },
    {
      name: {
        ru: 'Пермская губерния (1876)',
        en: "Perm' Governorate (1876)",
      },
      url: `${MAPS_V2_URL}/Permskaya_guberniya_1876/{z}/{x}/{y}.png`,
      center: [58.8224774461, 58.7759773084],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 16,
    },
    {
      name: {
        ru: 'Вятская губ., Сарапульский уезд (1891)',
        en: 'Vyatka Gov., Sarapul Uyezd (1891)',
      },
      url: `${MAPS_V2_URL}/Sarapulskiy_uezd_1891/{z}/{x}/{y}.png`,
      center: [56.8563122707, 53.358133231],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 16,
    },
    {
      name: {
        ru: 'Казанская губ., г. Казань (1767)',
        en: 'Kazan Gov., Kazan (1767)',
      },
      url: `${MAPS_V1_URL}/Kazan_1767/{z}/{x}/{y}.png`,
      minZoom: 10,
      maxZoom: 20,
      center: [55.7762130747, 49.1098095463],
      subdomains: [],
      tms: true,
      opacity: 0.7,
    },
    {
      name: {
        ru: 'Казанская губ., г. Казань (1776)',
        en: 'Kazan Gov., Kazan (1776)',
      },
      url: `${MAPS_V1_URL}/Kazan_1776/{z}/{x}/{y}.png`,
      minZoom: 10,
      maxZoom: 20,
      center: [55.7830195107, 49.1220966256],
      subdomains: [],
      tms: true,
      opacity: 0.7,
    },
    {
      name: {
        ru: 'Казанская губ., Елабужский уезд (1891)',
        en: 'Kazan Gov., Elabuga Uyezd (1891)',
      },
      url: `${MAPS_V1_URL}/Elabuzhskiy_uezd_1891/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 16,
      center: [56.1211119063, 52.3747887012],
      subdomains: [],
      tms: true,
      opacity: 0.7,
    },
    {
      name: {
        ru: 'Казанская губ., Казанский уезд (1745)',
        en: 'Kazan Gov., Kazan Uyezd (1745)',
      },
      url: `${MAPS_V2_URL}/RT_1745/{z}/{x}/{y}.png`,
      center: [56.1228089313, 49.5322997974],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 10,
    },
    {
      name: {
        ru: 'Казанская губ., Казанский уезд (1824)',
        en: 'Kazan Gov., Kazan Uyezd (1824)',
      },
      url: `${MAPS_V2_URL}/RT_1824/{z}/{x}/{y}.png`,
      center: [55.305307506, 43.5136931221],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 10,
    },
    {
      name: {
        ru: 'Казанская губ., Казанский уезд (1876)',
        en: 'Kazan Gov., Kazan Uyezd (1876)',
      },
      url: `${MAPS_V2_URL}/RT_1876/{z}/{x}/{y}.png`,
      center: [55.5173308269, 48.7507746166],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 10,
    },
    {
      name: {
        ru: 'Казанская губ., Казанский уезд (1910)',
        en: 'Kazan Gov., Kazan Uyezd (1910)',
      },
      url: `${MAPS_V1_URL}/kazan/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 15,
      center: [55.83, 49.06],
      subdomains: [],
      tms: true,
    },
    {
      name: {
        ru: 'Казанская губ., Свияжский уезд (ПГМ)',
        en: 'Kazan Gov., Sviyazhsk Uyezd (Gen.Cad.Map)',
      },
      url: `${MAPS_V1_URL}/sviyazhsk_pgm/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 15,
      center: [55.77, 48.66],
      subdomains: [],
      tms: true,
    },
    {
      name: {
        ru: 'Казанская губ., Спасский уезд (?)',
        en: 'Kazan Gov., Spassk Uyezd (?)',
      },
      url: `${MAPS_V2_URL}/Spasskiy_Verstovka/{z}/{x}/{y}.png`,
      center: [54.8882900054, 49.5755962254],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 16,
    },
    {
      name: {
        ru: 'Казанская губ., Спасский уезд (?)',
        en: 'Kazan Gov., Spassk Uyezd (?)',
      },
      url: `${MAPS_V2_URL}/Spasskiy_PGM/{z}/{x}/{y}.png`,
      center: [54.8883647162, 49.5756416169],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 16,
    },
    {
      name: {
        ru: 'Казанская губ., Спасский уезд (1895)',
        en: 'Kazan Gov., Spassk Uyezd (1895)',
      },
      url: `${MAPS_V2_URL}/Spasskiy_uezd_1895/{z}/{x}/{y}.png`,
      center: [54.8651590523, 49.6526966938],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 16,
    },
    {
      name: {
        ru: 'Казанская губ., Спасский уезд (1910)',
        en: 'Kazan Gov., Spassk Uyezd (1910)',
      },
      url: `${MAPS_V1_URL}/spasskiy_verstovka/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 15,
      center: [55.025, 49.34],
      subdomains: [],
      tms: true,
    },
    {
      name: {
        ru: 'Казанская губ., Спасский уезд (ПГМ)',
        en: 'Kazan Gov., Spassk Uyezd (Gen.Cad.Map)',
      },
      url: `${MAPS_V1_URL}/spasskiy_pgm/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 15,
      center: [55.025, 49.34],
      subdomains: [],
      tms: true,
    },
    {
      name: {
        ru: 'Казанская губ., Царевококшайский уезд (1913)',
        en: "Kazan Gov., Tsar'ovokokshaysk Uyezd (1913)",
      },
      url: `${MAPS_V1_URL}/Carevokokshayskiy_uezd_1913/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 16,
      center: [56.4494891393, 48.401726701],
      subdomains: [],
      tms: true,
      opacity: 0.7,
    },
    {
      name: {
        ru: 'Казанская губ., Цивильский уезд (1910)',
        en: "Kazan Gov., Tsivil'sk Uyezd (1910)",
      },
      url: `${MAPS_V1_URL}/Civilskiy_uezd_1910/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 16,
      center: [55.5447791436, 47.6290301131],
      subdomains: [],
      tms: true,
      opacity: 0.7,
    },
    {
      name: {
        ru: 'Казанская губ., Чебоксарский уезд (1912)',
        en: 'Kazan Gov., Cheboksary Uyezd (1912)',
      },
      url: `${MAPS_V1_URL}/Cheboksarskiy_uezd_1912/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 16,
      center: [56.120168604, 47.7149681307],
      subdomains: [],
      tms: true,
      opacity: 0.7,
    },
    {
      name: {
        ru: 'Казанская губ., Чистопольский уезд (1895)',
        en: "Kazan Gov., Chistopol' Uyezd (1895)",
      },
      url: `${MAPS_V1_URL}/Chistopolsky_uezd_1895/{z}/{x}/{y}.png`,
      minZoom: 1,
      maxZoom: 16,
      center: [54.890281433, 50.9179633844],
      subdomains: [],
      tms: true,
      opacity: 0.7,
    },
    {
      name: {
        ru: 'Казанская губ., Свияжский уезд, г. Свияжск (1829)',
        en: 'Kazan Gov., Sviyazhsk Uyezd, Sviyazhsk (1829)',
      },
      url: `${MAPS_V2_URL}/Sviyazhsk_1829s/{z}/{x}/{y}.png`,
      center: [55.7723806698, 48.6594249094],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 10,
      maxZoom: 20,
    },
    {
      name: {
        ru: 'Казанская губ., Свияжский уезд, г. Свияжск (1847)',
        en: 'Kazan Gov., Sviyazhsk Uyezd, Sviyazhsk (1847)',
      },
      url: `${MAPS_V2_URL}/Sviyazhsk_1847/{z}/{x}/{y}.png`,
      center: [55.7729476218, 48.6558310279],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 10,
      maxZoom: 20,
    },
    {
      name: {
        ru: 'Казанская губ., Свияжский уезд, г. Свияжск (1869)',
        en: 'Kazan Gov., Sviyazhsk Uyezd, Sviyazhsk (1869)',
      },
      url: `${MAPS_V2_URL}/Sviyazhsk_1869/{z}/{x}/{y}.png`,
      center: [55.7732262466, 48.6584349686],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 10,
      maxZoom: 20,
    },
    {
      name: {
        ru: 'Казанская губ., Спасский уезд, с. Болгары (?)',
        en: 'Kazan Gov., Spassk Uyezd, Bolgary (?)',
      },
      url: `${MAPS_V2_URL}/Plan_sela_Bolgar/{z}/{x}/{y}.png`,
      center: [54.9826009783, 49.0565818499],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 10,
      maxZoom: 20,
    },
  ],
  [
    {
      checked: true,
      name: 'Relief',
      url: `${MAPS_V1_URL}/relief/z{z}/0/x{x}/0/y{y}.jpg`,
      subdomains: [],
      maxZoom: 12,
      tms: false,
      zoomOffset: 1,
      minZoom: 1,
    },
  ],
  [
    {
      name: 'Карта глобального землепользования',
      url: `${MAPS_V2_URL}/Global_vegetation_2_new/{z}/{x}/{y}.png`,
      center: [58.8224774461, 58.7759773084],
      tms: true,
      checked: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 11,
    },
  ],
  [
    {
      checked: true,
      isGrey: true,
      name: 'Open Street Map',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors',
      subdomains: ['a', 'b', 'c'],
      maxZoom: 19,
    },
  ],
  [
    {
      checked: true,
      name: '3d',
      url: '',
      subdomains: [],
      minZoom: 8,
      maxZoom: 15,
    },
  ],
]

export const OverlayTileLayerList = [
  [],
  [],
  [
    {
      name: {
        ru: 'Yandex Overlay',
        en: 'Yandex Overlay',
      },
      isGrey: true,
      url:
        'http://vec{s}.maps.yandex.net/tiles?l=map&v=18.04.22-2&z={z}&x={x}&y={y}&scale=1&lang=ru_RU',
      subdomains: ['01', '02', '03', '04'],
      crs: CRS.EPSG3395,
      checked: true,
      opacity: 0.5,
      maxZoom: 19,
    },
  ],
  [
    {
      checked: true,
      name: 'water',
      url: 'https://maps-for-free.com/layer/water/z{z}/row{y}/{z}_{x}-{y}.gif',
      subdomains: [],
      maxZoom: 11,
      minZoom: 1,
    },
  ],
  [],
  [
    {
      name: {
        ru: 'Yandex Overlay',
        en: 'Yandex Overlay',
      },
      isGrey: true,
      url:
        'http://vec{s}.maps.yandex.net/tiles?l=map&v=18.04.22-2&z={z}&x={x}&y={y}&scale=1&lang=ru_RU',
      subdomains: ['01', '02', '03', '04'],
      crs: CRS.EPSG3395,
      maxZoom: 19,
      checked: true,
      opacity: 0.5,
    },
    {
      name: 'sviyazhsk_2011',
      url: `${MAPS_V1_URL}/Sviyazhsk_2011/{z}/{x}/{y}.png`,
      center: [55.8406191412, 48.781731631],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 10,
      maxZoom: 18,
    },
    {
      name: 'bolgar_2011',
      url: `${MAPS_V2_URL}/aerieal_photo/Bolgar_Ortho_2011_WGS/{z}/{x}/{y}.png`,
      center: [54.9780129121, 49.056588771],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 18,
    },
    {
      name: 'bolgar_2007',
      url: `${MAPS_V2_URL}/aerieal_photo/Bolgar_Ortho_2007/{z}/{x}/{y}.png`,
      center: [54.9665446308, 49.0425301321],
      tms: true,
      opacity: 0.7,
      subdomains: [],
      minZoom: 1,
      maxZoom: 18,
    },
  ],
  [],
]

export default TileLayerList
