/**
 * @flow
 * @relayHash 9aae4de92e9cb96cab85970415a420b2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SourceQueryVariables = {||};
export type SourceQueryResponse = {|
  +me: ?{|
    +createdSources: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +uid: ?string,
          +ruName: ?string,
          +enName: ?string,
          +localName: ?string,
          +year: ?number,
          +author: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +publicName: ?string,
                +uid: ?string,
              |}
            |}>
          |},
          +coauthors: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +publicName: ?string,
                +uid: ?string,
              |}
            |}>
          |},
          +label: ?string,
        |}
      |}>
    |},
    +writtenSources: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +uid: ?string,
          +ruName: ?string,
          +enName: ?string,
          +localName: ?string,
          +year: ?number,
          +author: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +publicName: ?string,
                +uid: ?string,
              |}
            |}>
          |},
          +coauthors: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +publicName: ?string,
                +uid: ?string,
              |}
            |}>
          |},
          +label: ?string,
        |}
      |}>
    |},
  |}
|};
export type SourceQuery = {|
  variables: SourceQueryVariables,
  response: SourceQueryResponse,
|};
*/


/*
query SourceQuery {
  me {
    createdSources(first: 1000) {
      edges {
        node {
          uid
          ruName
          enName
          localName
          year
          author {
            edges {
              node {
                publicName
                uid
                id
              }
            }
          }
          coauthors {
            edges {
              node {
                publicName
                uid
                id
              }
            }
          }
          label
          id
        }
      }
    }
    writtenSources(first: 1000) {
      edges {
        node {
          uid
          ruName
          enName
          localName
          year
          author {
            edges {
              node {
                publicName
                uid
                id
              }
            }
          }
          coauthors {
            edges {
              node {
                publicName
                uid
                id
              }
            }
          }
          label
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000,
    "type": "Int"
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ruName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "localName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "year",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicName",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "AuthorSearchNodeEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorSearchNode",
        "plural": false,
        "selections": [
          v6,
          v1
        ]
      }
    ]
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v9 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "SourceSearchNodeEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "SourceSearchNode",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "author",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorSearchNodeConnection",
            "plural": false,
            "selections": v7
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "coauthors",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorSearchNodeConnection",
            "plural": false,
            "selections": v7
          },
          v8
        ]
      }
    ]
  }
],
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v11 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "AuthorSearchNodeEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorSearchNode",
        "plural": false,
        "selections": [
          v6,
          v1,
          v10
        ]
      }
    ]
  }
],
v12 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "SourceSearchNodeEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "SourceSearchNode",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "author",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorSearchNodeConnection",
            "plural": false,
            "selections": v11
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "coauthors",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorSearchNodeConnection",
            "plural": false,
            "selections": v11
          },
          v8,
          v10
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SourceQuery",
  "id": null,
  "text": "query SourceQuery {\n  me {\n    createdSources(first: 1000) {\n      edges {\n        node {\n          uid\n          ruName\n          enName\n          localName\n          year\n          author {\n            edges {\n              node {\n                publicName\n                uid\n                id\n              }\n            }\n          }\n          coauthors {\n            edges {\n              node {\n                publicName\n                uid\n                id\n              }\n            }\n          }\n          label\n          id\n        }\n      }\n    }\n    writtenSources(first: 1000) {\n      edges {\n        node {\n          uid\n          ruName\n          enName\n          localName\n          year\n          author {\n            edges {\n              node {\n                publicName\n                uid\n                id\n              }\n            }\n          }\n          coauthors {\n            edges {\n              node {\n                publicName\n                uid\n                id\n              }\n            }\n          }\n          label\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SourceQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "createdSources",
            "storageKey": "createdSources(first:1000)",
            "args": v0,
            "concreteType": "SourceSearchNodeConnection",
            "plural": false,
            "selections": v9
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "writtenSources",
            "storageKey": "writtenSources(first:1000)",
            "args": v0,
            "concreteType": "SourceSearchNodeConnection",
            "plural": false,
            "selections": v9
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SourceQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "createdSources",
            "storageKey": "createdSources(first:1000)",
            "args": v0,
            "concreteType": "SourceSearchNodeConnection",
            "plural": false,
            "selections": v12
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "writtenSources",
            "storageKey": "writtenSources(first:1000)",
            "args": v0,
            "concreteType": "SourceSearchNodeConnection",
            "plural": false,
            "selections": v12
          },
          v10
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eeac46a6b52b4751f8c060e4808aaf66';
module.exports = node;
