import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'
import { translate } from 'react-i18next'

import ProfileTabName from 'components/Profile/Tab/Name'
import ProfileTabResearcherContainer from 'components/Profile/Tab/Researcher/Container/index'
import ProfileTabUserConfirmation from 'components/Profile/Tab/UserConfirmation'
import ProfileTabUserConnection from 'components/Profile/Tab/UserConnection'
import { actions } from 'redux/modules/organization'
import { store } from '../../../index'

const { TabPane } = Tabs

const mapStateToProps = state => ({
  organization: state.organization,
  orgUid: state.organization.orgUid,
})

const mapDispatchToProps = {
  getOrgUid: actions.getOrgUid,
  getOrganization: actions.getOrganization,
  editOrganization: actions.editOrganization,
  addResearcher: actions.addResearcher,
}

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class ProfileOrganization extends Component {
  state = {}

  componentDidMount() {
    this.props.getOrgUid()
    this.props.getOrganization()
  }

  handleChangeTab = key => {
    const { history } = this.props
    if (history.location.pathname.includes('profile/')) {
      history.push(key)
    } else {
      history.push(`profile/${key}`)
    }
  }

  render() {
    const {
      isMobile,
      t,
      organization,
      editOrganization,
      addResearcher,
      section,
    } = this.props
    console.log("tralala", organization)
    return (
      <Tabs
        defaultActiveKey={section || 'general'}
        tabPosition={isMobile ? 'top' : 'left'}
        onChange={this.handleChangeTab}
      >
        <TabPane tab={t('profile.tabs.general')} key="general">
          <ProfileTabName
            t={t}
            organization={organization}
            handleSubmit={editOrganization}
          />
        </TabPane>
        <TabPane tab={t('profile.tabs.researcher')} key="researcher">
          <ProfileTabResearcherContainer
            t={t}
            organization={organization}
            handleSubmit={addResearcher}
            orgId={organization}
          />
        </TabPane>
        <TabPane tab={t('profile.tabs.confirmationUsers')} key="confirmation">
          <ProfileTabUserConfirmation t={t} />
        </TabPane>
        <TabPane tab={t('profile.tabs.connectionUsers')} key="connection">
          <ProfileTabUserConnection t={t} />
        </TabPane>
        {/* <TabPane tab={t('profile.tabs.about')} key="3">
          <ProfileTabPartner
            t={t}
            userInfo={info}
            isError={error}
            organizations={organizations}
            tags={tags}
            organizationsNum={organizationsNum}
            handleSubmit={this.handleSubmit}
            handleOrganizationCountChange={handleOrganizationCountChange}
          />
        </TabPane> */}
      </Tabs>
    )
  }
}

export default ProfileOrganization
