import React from 'react'
import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getAttribute,
  getLangTitle,
  sortArray,
} from 'utility/transformData/constants';
import { I18n } from 'react-i18next';

export default function getTermFields(d, tabType) {
  console.log('locality fields', d)
  const r = d;
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const docLink = (r.docs[0] && r.docs[0].link) ? r.docs[0].link : ''
      const content = [
        createCommonField('ru_name', r.ru_name),
        createCommonField('en_name', r.en_name),
        createCommonField('description', r.interpretations[0].description),
      ]
      if (r.docs[0] && r.docs[0].link) {
        const text = (<I18n>
          {(t) => (<span>{`${t('dataPanel.term.doc_udmurt')}`}</span>)}
        </I18n>)
        content.push(createLinkField('document', r.docs[0].link, '', text, false))
      } else {
        const text = (<I18n>
          {(t) => (<span>{`${t('common.no_data')}`}</span>)}
        </I18n>)
        content.push(createCommonField('document', text))
      }
      return content;
    }
    case TAB_TYPE.COLLAPSE: {
      const ret = d.results.map((item, index) => {
        const itemObj = {}
        const interpretation = (item.interpretations) ? item.interpretations[0] : item.research;
        itemObj.content = [
          createLinkField('ru_name', item.uid, `subjects/${item.label || d.label}`, item.ru_name),
        ]
        itemObj.name = item.ru_name;
        return itemObj;
      })

      return sortArray(ret)
    }
    default:
      return [];
  }
}
