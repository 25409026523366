import { createAction, handleActions } from 'redux-actions'

import { getCookie, deleteCookie } from 'utility/cookie'

const LOG_IN = 'user/LOG_IN'
const LOG_IN_SUCCESS = 'user/LOG_IN_SUCCESS'
const LOG_IN_FAILED = 'user/LOG_IN_FAILED'
const LOG_OUT = 'user/LOG_OUT'
const GET_CURRENT_USER = 'user/GET_CURRENT_USER'
const GET_CURRENT_USER_SUCCESS = 'user/GET_CURRENT_USER_SUCCESS'
const GET_CURRENT_USER_FAILED = 'user/GET_CURRENT_USER_FAILED'

export const constants = {
  LOG_IN,
  LOG_OUT,
  LOG_IN_SUCCESS,
  LOG_IN_FAILED,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILED,
}

// ------------------------------------
// Actions
// ------------------------------------
export const logOut = createAction(LOG_OUT)
export const logIn = createAction(LOG_IN)
export const logInSuccess = createAction(LOG_IN_SUCCESS)
export const logInFailed = createAction(LOG_IN_FAILED)
export const getCurrentUser = createAction(GET_CURRENT_USER)
export const getCurrentUserSuccess = createAction(GET_CURRENT_USER_SUCCESS)
export const getCurrentUserFailed = createAction(GET_CURRENT_USER_FAILED)

export const actions = {
  logOut,
  logIn,
  logInSuccess,
  logInFailed,
  getCurrentUser,
  getCurrentUserSuccess,
  getCurrentUserFailed,
}

export const reducers = {
  [LOG_OUT]: state => {
    deleteCookie('token')
    return {
      ...state,
      isAuthenticated: false,
    }
  },
  [LOG_IN_FAILED]: (state, { payload }) => ({
    ...state,
    isAuthenticated: false,
    error: payload || 'Ошибка авторизации',
  }),
  [LOG_IN_SUCCESS]: state => ({
    ...state,
    isAuthenticated: true,
  }),
  [GET_CURRENT_USER_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
    isAuthenticated: true,
  }),
  [GET_CURRENT_USER_FAILED]: state => ({
    ...state,
    role: null,
    isAuthenticated: false,
  }),
}

export const initialState = () => {
  const state = {
    role: null,
    isAuthenticated: false,
  }
  if (getCookie('token')) {
    // TODO(timurmardanov97@gmail.com): valid token check
    state.isAuthenticated = true
  }
  return state
}

export default handleActions(reducers, initialState())
