import React from 'react'
import i18n from 'i18next'
import {
  getLangName,
  getItem,
  getInterpretation,
} from 'components/GSearchPanel/Queries/constants'

const lang = ['ru','en'].includes(i18n.language) ? i18n.language: 'local'

const catQuery = [
  () => {
    return {
      query: `query postQuery($location: SpecificLocationEnum!) {
        results:locationSites(location: $location, first: 1000) {
          totalCount
          edges {
            node {
              uid
              interpretation {
                ${lang}Name
              }
            }
          }
        }
      }`,
      queryType: 'locationSites',
      url: 'subjects/Site',
      fields: {
        name: getInterpretation,
      },
    }
  },
  () => {
    return {
      query: `query postQuery($location: SpecificLocationEnum!) {
        results:locationLayers (location: $location, first: 1000) {
          totalCount
          edges {
            node {
              uid
              label
              commonCultures {
                count
                culture {
                  ${lang}Name
                }
              }
              interpretation {
                ${lang}Name
              }
              epoch {
                edges {
                  node {
                    ${lang}Name
                    sort
                  }
                }
              }
              sitetype {
                edges {
                  node {
                    ${lang}Name
                    sort
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'subjects/Layer',
      fields: {
        name: {
          data: getInterpretation,
          sort: `common_${lang}_name`,
        },
        sitetype: {
          data: getItem(`sitetype.edges.0.node.${lang}Name`),
          sort: `sitetype__${lang}_name`,
        },
        epoch: {
          data: getItem(`epoch.edges.0.node.${lang}Name`),
          sort: `epoch__${lang}_name`,
        },
      },
    }
  },
  () => {
    return {
      query: `query postQuery($location: SpecificLocationEnum!) {
        results:locationMonuments(location: $location, first: 1000) {
          totalCount
          edges {
            node {
              uid
              interpretation {
                ${lang}Name
              }
              attributes {
                edges {
                  node {
                    ${lang}Name
                    label
                    sort
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'subjects/Monument',
      fields: {
        name: {
          data: getInterpretation,
          sort: `common_${lang}_name`,
        },
      },
    }
  },
  () => {
    return {
      query: `query postQuery($location: SpecificLocationEnum!) {
        results:locationResearches(location: $location, first: 1000) {
          totalCount
          edges {
            node {
              uid
              label
              ruName
              enName
              year
              type {
                edges {
                  node {
                    ${lang}Name
                    sort
                  }
                }
              }
              author {
                edges {
                  node {
                    publicName
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'research',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        label: {
          data: getItem('label'),
          sort: 'label',
        },
        year: {
          data: getItem('year'),
          sort: 'year',
        },
        type: {
          data: getItem(`type.edges.0.node.${lang}Name`),
          sort: `type__${lang}_name`,
        },
        author: {
          data: getItem('author.edges.0.node.publicName'),
          sort: 'author__public_name',
        },
      },
    }
  },
  () => {
    return {
      query: `query postQuery($location: SpecificLocationEnum!) {
        results:locationExcavations(location: $location, first: 1000) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
              year
              supervisor {
                edges {
                  node {
                    publicName
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'subjects/Excavation',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        year: {
          data: getItem('year'),
          sort: 'year',
        },
        supervisor: {
          data: data => {
            let supervisors = []
            if (data.supervisor) {
              supervisors = data.supervisor.edges.map(v => {
                if (v.node.publicName) {
                  return `${v.node.publicName}`
                }
                return ''
              })
            }
            const supervisorsNames = supervisors.length
              ? `(${supervisors.join(', ')})`
              : ''
            return supervisorsNames
          },
          sort: 'supervisor__public_name',
        },
      },
    }
  },
  // () => {
  //   return {
  //     query: `query postQuery($location: SpecificLocationEnum!) {
  //       results:locationArtifacts(location: $location, first: 1000) {
  //         totalCount
  //         edges {
  //           node {
  //             uid
  //             ${lang}Name
  //           }
  //         }
  //       }
  //     }`,
  //     queryType: 'locationArtifacts',
  //     url: 'subjects/Artifact',
  //   }
  // },
  () => {
    return {
      query: `query postQuery($location: SpecificLocationEnum!) {
        results:locationPublication(location: $location, first: 1000) {
          totalCount
          edges {
            node {
              uid
              year
              label
              ${lang}Name
              author {
                edges {
                  node {
                    publicName
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'source/Publication',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        year: {
          data: getItem('year'),
          sort: 'year',
        },
        label: {
          data: getItem('label'),
          sort: 'label',
        },
        author: {
          data: getItem('author.edges.0.node.publicName'),
          sort: 'author__public_name',
        },
      },
    }
  },
  () => {
    return {
      query: `query postQuery($location: SpecificLocationEnum!) {
        results:locationArchieveItem(location: $location, first: 1000) {
          totalCount
          edges {
            node {
              uid
              year
              ${lang}Name
              author {
                edges {
                  node {
                    publicName
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'source/Report',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        year: {
          data: getItem('year'),
          sort: 'year',
        },
        author: {
          data: getItem('author.edges.0.node.publicName'),
          sort: 'author__public_name',
        },
      },
    }
  },
  () => {
    return {
      query: `query postQuery($location: SpecificLocationEnum!) {
        results:locationScientificDocumentation(location: $location, first: 1000) {
          totalCount
          edges {
            node {
              uid
              year
              ${lang}Name
              author {
                edges {
                  node {
                    publicName
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'source/ScientificProjectDocumentation',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        year: {
          data: getItem('year'),
          sort: 'year',
        },
        author: {
          data: getItem('author.edges.0.node.publicName'),
          sort: 'author__public_name',
        },
      },
    }
  },
]

export default catQuery
