import { graphql } from 'relay-runtime'

export const addToSelection = graphql`
  mutation editAddToSelectionMutation($selectionUid: UUID!, $subjectUid: [UUID]) {
    updateSelection (uid: $selectionUid, addStickedUids: $subjectUid) {
      uid
      stickedUids
    }
  }
`
