import React, { Component } from 'react'
import { Marker, Tooltip, FeatureGroup } from 'react-leaflet'
import 'react-leaflet-markercluster/dist/styles.min.css'
import { handleMakerClick } from 'utility/markers'

class LayerAddMarkerComponent extends Component {
  handleDragend = e => {
    const title = e.target.options['data-title']
    const key = e.target.options['data-key']
    this.props.handleChangeInteractiveMarker({
      title,
      key,
      lat: e.target._latlng.lat,
      lng: e.target._latlng.lng,
    })
  }

  renderMarkers(markers) {
    return markers.map(item => (
      <Marker
        draggable={item.draggable}
        onDragend={this.handleDragend}
        position={[item.coords.lat, item.coords.lng]}
        className="marker-class"
        key={item.uid}
        onClick={() => handleMakerClick(item.link)}
        data-title={item.title}
        data-key={item.key}
      >
        <Tooltip sticky>
          <span>{item.tooltip}</span>
        </Tooltip>
      </Marker>
    ))
  }

  render() {
    const { markers } = this.props
    const markerArr = this.renderMarkers(markers)
    return <FeatureGroup>{markerArr}</FeatureGroup>
  }
}

export default LayerAddMarkerComponent
