import React from 'react'
import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  sortArray,
} from 'utility/transformData/constants';
import getLexemeFields from 'utility/transformData/fields/lexeme';

function sortLexemes(lexemes) {
  const ret = {}
  lexemes.forEach((lexeme, id) => {
    const lit = (lexeme.node.name[0] !== ' ') ? lexeme.node.name[0] : lexeme.node.name[1]
    if (!ret[lit]) {
      ret[lit] = [];
    }
    ret[lit].push({ lexeme, lexemeIndex: id });
  })

  console.log('lexemes', lexemes, ret)
  return ret;
}

export default function getLexemeSortFields(d, tabType) {
  console.log(tabType);
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      console.log(item);
      const result = [
        createCommonField('name', item.name),
      ];
      const term = item.subjects.filter(a => a.label === 'Term')[0]
      if (term) {
        result.push(
          createLinkField('term', term.uid, 'subjects/Term', term.name),
        )
      }
      const language = item.attributes.filter(a => a.label === 'Language')[0]
      if (language) {
        result.push(
          createCommonField('language', getLangTitle(language)),
        )
      }
      return result
    }
    case TAB_TYPE.COLLAPSE: {
      console.log('getLexemeSortFields', d);
      const result = [];
      const groups = sortLexemes(d.results)
      Object.keys(groups).forEach((key) => {
        const itemObj = {}
        console.log(key);
        itemObj.content = []
        itemObj.childrenItem = {
          subjectType: 'lexeme',
          content: getLexemeFields({ results: groups[key] }, TAB_TYPE.COLLAPSE, key),
        }
        itemObj.name = key.toUpperCase();
        result.push(itemObj);
      })
      return sortArray(result)
    }
    default:
      return [];
  }
}
