const ICONS = {
  0: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18 16C17.4477 16 17 16.4477 17 17V20.7143C17 21.2666 17.4477 21.7143 18 21.7143H21.5714V43.4286L18 43.4286C17.4477 43.4286 17 43.8763 17 44.4286V48.1429C17 48.6951 17.4477 49.1429 18 49.1429H48C48.5523 49.1429 49 48.6951 49 48.1429V44.4286C49 43.8763 48.5523 43.4286 48 43.4286L44.4286 43.4286V21.7143H48C48.5523 21.7143 49 21.2666 49 20.7143V17C49 16.4477 48.5523 16 48 16H18ZM42.1429 21.7143H37.5714V43.4286H42.1429V21.7143ZM35.2857 21.7143V43.4286H30.7143V21.7143H35.2857ZM28.4286 21.7143V43.4286H23.8571V21.7143H28.4286Z" fill="white" />
    </svg>
  `,
  1: (color: string, size: number) => `
    <svg width="${size}" height="${size}" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 18C17 17.4477 17.4477 17 18 17H48C48.5523 17 49 17.4477 49 18V48C49 48.5523 48.5523 49 48 49H18C17.4477 49 17 48.5523 17 48V18ZM20.3103 20.3103H45.6897V45.6897H20.3103V20.3103ZM40.7241 25.2759H25.2759V40.7241H40.7241V25.2759Z" fill="white" />
    </svg>
  `,
}
ICONS['default'] = ICONS[0]

export default ICONS
