import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Tag, Icon } from 'antd';
import i18n from 'i18next';

import './index.css';

const { Meta } = Card

const getFullName = (obj) => (obj.public_name || `${obj.first_name || ''} ${obj.second_name || ''} ${obj.parent_name || ''}`);

const getLang = () => {
  const langs = ['ru', 'tat', 'en'];
  if (langs.includes(i18n.language)) {
    return `${['ru','en'].includes(i18n.language) ? i18n.language: 'local'}_name`
  }
  return 'ru_name';
}

const UserInfo = ({
  user, handleUserRemove, tags, children, controls = {},
}) => (
  <Card
    className="user_info"
    actions={[...controls, <Icon type="close" onClick={() => handleUserRemove(user.uid)} />]}
  >
    <Meta
      title={getFullName(user)}
      description={<span><Icon type="calendar" /> {user.birthday}</span>}
    />
    <div>
      { user.scientific_interests && user.scientific_interests.map(item => (
        <Tag key={item}>{tags.get(item)[getLang()]}</Tag>
      ))}
    </div>
    { children }
  </Card>
)

export default UserInfo;
