import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { QueryRenderer } from 'react-relay'
import { isUdmurt } from 'utility/constants'

import relay from './graphqlEnv'
import RoutesGis from './Routes'
import RoutesLing from './RoutesLing'
import ScrollToTop from './utility/ScrollToTop'

export default class Root extends Component {
  state = {
    query: null,
    variables: {},
  }

  get content() {
    const Routes = isUdmurt() ? RoutesLing : RoutesGis

    return (
      <Router history={this.props.history}>
        <ScrollToTop>
          <Routes user={this.props.user} />
        </ScrollToTop>
      </Router>
    )
  }

  renderReadyState = ({ error, props, retry }) => (
    <Provider store={this.props.store}>{this.content}</Provider>
  )

  render() {
    return (
      <QueryRenderer
        environment={relay}
        query={this.state.query}
        variables={this.state.variables}
        render={this.renderReadyState}
      />
    )
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
}
