import React, { Component } from 'react'
import { Input, Form, InputNumber, Collapse, Button } from 'antd'
import encQueries from 'components/GSearchPanel/Queries/encQueries'
// eslint-disable-next-line import/no-cycle
import CommonSelect from 'components/GSearchPanel/components/commonSelect'
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries'
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters'

import '../../index.scss'
// eslint-disable-next-line import/no-cycle
import FileUpload from '../fields/fileUpload'
// eslint-disable-next-line import/no-cycle
import AutoInputGQL from '../fields/autoInputGQL'
import CoordinatesInput from '../fields/coordinatesInput'
// eslint-disable-next-line import/no-cycle
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions'

const FormItem = Form.Item
const { Panel } = Collapse
const { TextArea } = Input

class LocalityTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
      population: [['0']],
    }
  }

  addTab = () => {
    const key = this.newTabIndex++
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  getPopulationCounter(key) {
    if (!this.state.population[key]) {
      this.state.population[key] = ['0']
    }
    return this.state.population[key]
  }

  handleAddPopulation = key => {
    this.state.population[key].push('0')
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state
    newName[key] = data.typed
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = input => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      getFieldDecorator,
      filelistChanged,
      formInputLayout,
      stateTabs,
      createData,
      subjects,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props
    const formButton = {
      wrapperCol: { offset: 8, span: 10 },
    }

    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    // Add search Argument in locality search
    const searchLocalityArgs = [{ key: 'commonSearch', val: 'value' }]

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel
              header={`${t('create_panel.tab.locality.locality')} #${1 + key}`}
              key={title + item.key}
            >
              <AutoInputGQL
                handleSelect={data => {
                  this.handleSelect(data, key)
                }}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.locality.choose')}
                selectkey={`locality_select_${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.locality.add')}
                query={encQueries.localitySelectSearch()}
                params={searchLocalityArgs}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel="Locality"
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') && (
                <div>
                  {item.status === 'new' && (
                    <FormItem
                      {...formInputLayout}
                      label={t('create_panel.tab.locality.name')}
                    >
                      {getFieldDecorator(`locality_name_${key}`, {
                        ...config.req,
                        initialValue: this.state.newName[key],
                      })(
                        <Input
                          onChange={this.handleChangeName}
                          data-objkey={key}
                        />,
                      )}
                    </FormItem>
                  )}
                  <CoordinatesInput
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={title}
                    rules={item.status === 'new' ? config.req : {}}
                    subjname="locality"
                    objkey={key}
                    handleAddMarker={handleAddMarker}
                    interactiveMarkers={interactiveMarkers}
                    form={form}
                    t={t}
                  />
                  <CommonSelect
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    inputName={`locality_type_${key}`}
                    label={t('create_panel.tab.locality.type')}
                    selectQuery={attributeQueries.defaultSearch(
                      'localityTypes',
                      false,
                    )}
                    dontNeedEmptyOption="true"
                    t={t}
                  />
                  {item.status === 'new' && (
                    <AutoInputGQL
                      handleSelect={commonFunctions.handleAutoInputChange}
                      handleTabAddRelation={this.props.handleTabAddRelation}
                      handleChangeStateTab={this.props.handleChangeStateTab}
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      title={this.props.title}
                      leftLabel="Locality"
                      rightLabel="District"
                      connectionField="district"
                      label={t('common.subjects.district')}
                      selectkey={`locality_district_${key}`}
                      selectType="districts"
                      stateTabs={stateTabs}
                      objkey={key}
                      noAddOption
                      query={encQueries.districtSearch()}
                      params={[{ key: 'search', val: 'value' }]}
                      t={t}
                    />
                  )}
                  {this.getPopulationCounter(key).map((item, i) => (
                    <FormItem
                      {...formInputLayout}
                      label={`${t('create_panel.tab.locality.population')} ${i +
                        1}`}
                    >
                      {getFieldDecorator(`locality_year_${key}_${i}`)(
                        <InputNumber placeholder={t('common.year')} />,
                      )}
                      {getFieldDecorator(`locality_population_${key}_${i}`)(
                        <InputNumber placeholder={t('common.population')} />,
                      )}
                    </FormItem>
                  ))}
                  <FormItem {...formButton}>
                    {getFieldDecorator('add_population', {})(
                      <Button
                        type="primary"
                        onClick={() => this.handleAddPopulation(key)}
                      >
                        {t('create_panel.tab.locality.add_population')}
                      </Button>,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.locality.desc')}
                  >
                    {getFieldDecorator(`locality_desc_${key}`, {})(
                      <TextArea
                        onPaste={e =>
                          textFieldFormatters.onTextAreaPaste(
                            e,
                            form,
                            `locality_desc_${key}`,
                          )
                        }
                      />,
                    )}
                  </FormItem>
                  <FileUpload
                    getFieldDecorator={getFieldDecorator}
                    formInputLayout={formInputLayout}
                    filelistChanged={filelistChanged}
                    fileLists={createData.sourceFilelist}
                    label={`locality_files_${key}`}
                    fileType="photo"
                    title={t('common.photo.title')}
                    t={t}
                  />
                  <RelationComponent
                    handleAddExtraRelation={handleAddExtraRelation}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    objkey={key}
                    title="Locality"
                    tabName="localities"
                    stateTabs={stateTabs}
                    form={form}
                    t={t}
                  />
                </div>
              )}
            </Panel>
          ))}
        </Collapse>

        <FormItem wrapperCol={{ span: 8, offset: 8 }}>
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.locality.add_button')}
          </Button>
        </FormItem>
      </div>
    )
  }
}

export default LocalityTab
