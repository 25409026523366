import { all } from 'redux-saga/effects'
import { authSaga } from './auth';
import { utilsSaga } from './utils';
import { homePageSaga } from './homePage';
import { createSaga } from './create';
import { showSaga } from './show';
import { editSaga } from './editMode';
import { searchSaga } from './simpleSearch';
import { profileSaga } from './profile';
import { organizationSaga } from './organization';
import { heatmapSaga } from './heatmap';
import { advSearchSaga } from './advancedSearch';
import { searchSaga as quickSearchSaga } from './quickSearch';
import { toolsSaga } from './tools';
import { mapSaga } from './map';


export default function* sagas() {
  yield all([
    ...authSaga,
    ...utilsSaga,
    ...homePageSaga,
    ...createSaga,
    ...showSaga,
    ...editSaga,
    ...searchSaga,
    ...profileSaga,
    ...organizationSaga,
    ...heatmapSaga,
    ...quickSearchSaga,
    ...advSearchSaga,
    ...toolsSaga,
    ...mapSaga,
  ]);
}
