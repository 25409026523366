/**
 * @flow
 * @relayHash 30a14fddfa97a0775fd10cbb0cfcc3e3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ModelPermissionEnum = "RESEARCH" | "SELECTION" | "SOURCE" | "SUBJECT" | "%future added value";
export type ToMeQueryVariables = {||};
export type ToMeQueryResponse = {|
  +me: ?{|
    +accessRequestObjects: ?$ReadOnlyArray<?{|
      +uid: ?string,
      +model: ?ModelPermissionEnum,
      +name: ?string,
      +label: ?string,
      +requests: ?$ReadOnlyArray<?{|
        +createdAt: ?string,
        +author: ?{|
          +uid: ?string,
          +publicName: ?string,
        |},
      |}>,
    |}>
  |}
|};
export type ToMeQuery = {|
  variables: ToMeQueryVariables,
  response: ToMeQueryResponse,
|};
*/


/*
query ToMeQuery {
  me {
    accessRequestObjects {
      uid
      model
      name
      label
      requests {
        createdAt
        author {
          uid
          publicName
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "model",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ToMeQuery",
  "id": null,
  "text": "query ToMeQuery {\n  me {\n    accessRequestObjects {\n      uid\n      model\n      name\n      label\n      requests {\n        createdAt\n        author {\n          uid\n          publicName\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ToMeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "accessRequestObjects",
            "storageKey": null,
            "args": null,
            "concreteType": "AccessAbstractNode",
            "plural": true,
            "selections": [
              v0,
              v1,
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "requests",
                "storageKey": null,
                "args": null,
                "concreteType": "UnionAuthorDataType",
                "plural": true,
                "selections": [
                  v4,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "author",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorNode",
                    "plural": false,
                    "selections": [
                      v0,
                      v5
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ToMeQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "accessRequestObjects",
            "storageKey": null,
            "args": null,
            "concreteType": "AccessAbstractNode",
            "plural": true,
            "selections": [
              v0,
              v1,
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "requests",
                "storageKey": null,
                "args": null,
                "concreteType": "UnionAuthorDataType",
                "plural": true,
                "selections": [
                  v4,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "author",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorNode",
                    "plural": false,
                    "selections": [
                      v0,
                      v5,
                      v6
                    ]
                  }
                ]
              }
            ]
          },
          v6
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '08856e5ec3393538a04691410069e01e';
module.exports = node;
