import * as L from 'leaflet'
import React, { Component } from 'react';
import {
  FeatureGroup,
  Polygon,
} from 'react-leaflet';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { EditControl } from 'react-leaflet-draw'
import { connect } from 'react-redux';
import i18n from 'i18next'

import { actions } from '../../redux/modules/example';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  onPolygonDrew: actions.mapDrewPolygon,
};

@connect(mapStateToProps, mapDispatchToProps)
class LeafletDraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastPolygon: null,
      initialPolygon: null,
      showInitialPolygon: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.polygon && nextProps.polygon !== prevState.initialPolygon) {
      nextProps.map.current.leafletElement.fitBounds(nextProps.polygon)
      nextProps.onPolygonDrew(nextProps.polygon);
      return {
        initialPolygon: nextProps.polygon,
        showInitialPolygon: true,
      }
    }
    return null;
  }

  onCreated = (par) => {
    const { lastPolygon } = this.state
    if (lastPolygon) {
      this.props.map.current.leafletElement.removeLayer(lastPolygon)
    }
    console.log('created');
    this.setState({ lastPolygon: par.layer, showInitialPolygon: false })
    this.props.onPolygonDrew(par.layer._latlngs);
  }

  onEdited = (par) => {
    let notChanged = true;
    const thisOnPolygonDrew = this.props.onPolygonDrew;
    const layers = par.sourceTarget._targets;
    Object.keys(layers).forEach(key => {
      if (notChanged && layers[key]._latlngs) {
        console.log('edited')
        thisOnPolygonDrew(layers[key]._latlngs);
        notChanged = false;
      }
    })
  }

  render() {
    const {
      initialPolygon,
      showInitialPolygon,
    } = this.state;
    return (
      <FeatureGroup>
        <EditControl
          position="topleft"
          onCreated={this.onCreated}
          onEditStop={this.onEdited}
          draw={{
            polygon: {
              allowIntersection: false,
              drawError: {
                color: '#e1e100',
                message: i18n.t('map_panel.errors.intersection'),
              },
              shapeOptions: {
                color: '#97009c',
              },
            },
            polyline: false,
            circle: false,
            circlemarker: false,
            rectangle: false,
            marker: false,
          }}
          edit={{
            remove: true,
          }}
        />
        {
          (showInitialPolygon && initialPolygon) && <Polygon positions={initialPolygon} />
        }
      </FeatureGroup>
    )
  }
}

export default LeafletDraw;
