import { put, fork, takeLatest, call } from 'redux-saga/effects';

import * as queries from '../api/heatmap';
import { constants as heatmapConstants, actions as heatmapActions } from '../modules/heatmap';

export function* fetchMainChart() {
  const { response } = yield call(queries.getWhenMain);
  if (response) {
    yield put(heatmapActions.whenGetMainChartSuccess(response))
  } else {
    yield put(heatmapActions.whenGetMainChartFailed())
  }
}

export function* saveBbox(action) {
  console.log('saveBbox', action)
  yield put(heatmapActions.saveBbox(action.payload.bbox))
  action.payload.history.push('/home_result')
}

export function* saveDate(action) {
  console.log('saveDate', action)
  yield put(heatmapActions.saveDate(action.payload.date))
  action.payload.history.push('/home_result')
}

export function* saveSubj(action) {
  console.log('saveSubj', action)
  yield put(heatmapActions.saveSubj(action.payload.subject))
  action.payload.history.push('/home_result')
}

export function* fetchHeatmap(action) {
  const { response } = yield call(queries.postBboxHeatmap, action.payload.bbox);
  if (response) {
    yield put(heatmapActions.whereGetHeatmapSuccess(response))
  } else {
    yield put(heatmapActions.whereGetHeatmapFailed())
  }
}

export function* fetchChart(action) {
  const { response } = yield call(queries.postBboxChart, action.payload.bbox);
  if (response) {
    yield put(heatmapActions.whereGetChartSuccess(response))
  } else {
    yield put(heatmapActions.whereGetChartFailed())
  }
}

export function* fetchData(action) {
  console.log('WHERE_GET_DATA', action)
  yield put(heatmapActions.eraseListData())
  const params = {
    ...action.payload.bbox,
    limit: action.payload.limit,
    offset: action.payload.offset,
  }
  const count = yield call(queries.postBboxDataCount, params)
  yield put(heatmapActions.whereGetDataSetCount(count.response))
  console.log('count', count)
  const { response } = yield call(queries.postBboxData, params);
  if (response) {
    yield put(heatmapActions.whereGetDataSuccess(response))
    console.log('WHERE_GET_DATA', response)
  } else {
    yield put(heatmapActions.whereGetDataFailed())
  }
}

export function* fetchWhenHeatmap(action) {
  const { response } = yield call(queries.postWhenHeatmap, {
    ...action.payload.date,
    ...action.payload.bbox,
  });
  if (response) {
    yield put(heatmapActions.whereGetHeatmapSuccess(response))
  } else {
    yield put(heatmapActions.whereGetHeatmapFailed())
  }
}

export function* fetchWhenChart(action) {
  const { response } = yield call(queries.postWhenChart, action.payload.date);
  if (response) {
    yield put(heatmapActions.whereGetChartSuccess(response))
  } else {
    yield put(heatmapActions.whereGetChartFailed())
  }
}

export function* fetchWhenData(action) {
  console.log('WHEN_GET_DATA', action)
  yield put(heatmapActions.eraseListData())
  const params = {
    ...action.payload.date,
    limit: action.payload.limit,
    offset: action.payload.offset,
  }
  const count = yield call(queries.postWhenDataCount, params)
  yield put(heatmapActions.whereGetDataSetCount({ count: count.response }))
  console.log('count', count)
  const { response } = yield call(queries.postWhenData, params);
  if (response) {
    yield put(heatmapActions.whereGetDataSuccess({ result: response }))
    console.log('WHEN_GET_DATA', response)
  } else {
    yield put(heatmapActions.whereGetDataFailed())
  }
}


export function* fetchWhatHeatmap(action) {
  const { response } = yield call(queries.postWhatHeatmap, action.payload.subject);
  if (response) {
    yield put(heatmapActions.whereGetHeatmapSuccess(response))
  } else {
    yield put(heatmapActions.whereGetHeatmapFailed())
  }
}

export function* fetchWhatChart(action) {
  const { response } = yield call(queries.postWhatChart, action.payload);
  if (response) {
    yield put(heatmapActions.whereGetChartSuccess(response))
  } else {
    yield put(heatmapActions.whereGetChartFailed())
  }
}

export function* fetchWhatData(action) {
  console.log('WHAT_GET_DATA', action)
  yield put(heatmapActions.eraseListData())
  const params = {
    subject: action.payload.subject,
    limit: action.payload.limit,
    offset: action.payload.offset,
  }
  const count = yield call(queries.postWhatDataCount, params)
  yield put(heatmapActions.whereGetDataSetCount(count.response))
  console.log('count', count)
  const { response } = yield call(queries.postWhatData, params);
  if (response) {
    yield put(heatmapActions.whereGetDataSuccess(response))
    console.log('WHAT_GET_DATA', response)
  } else {
    yield put(heatmapActions.whereGetDataFailed())
  }
}

function* watchUserAccountActions() {
  yield takeLatest(heatmapConstants.WHEN_GET_MAIN_CHART, fetchMainChart);

  yield takeLatest(heatmapConstants.WHERE_SUBMIT, saveBbox);
  yield takeLatest(heatmapConstants.WHERE_GET_HEATMAP, fetchHeatmap);
  yield takeLatest(heatmapConstants.WHERE_GET_CHART, fetchChart);
  yield takeLatest(heatmapConstants.WHERE_GET_DATA, fetchData);

  yield takeLatest(heatmapConstants.WHEN_SUBMIT, saveDate);
  yield takeLatest(heatmapConstants.WHEN_GET_HEATMAP, fetchWhenHeatmap);
  yield takeLatest(heatmapConstants.WHEN_GET_CHART, fetchWhenChart);
  yield takeLatest(heatmapConstants.WHEN_GET_DATA, fetchWhenData);

  yield takeLatest(heatmapConstants.WHAT_SUBMIT, saveSubj);
  yield takeLatest(heatmapConstants.WHAT_GET_HEATMAP, fetchWhatHeatmap);
  yield takeLatest(heatmapConstants.WHAT_GET_CHART, fetchWhatChart);
  yield takeLatest(heatmapConstants.WHAT_GET_DATA, fetchWhatData);
}

export const heatmapSaga = [
  fork(watchUserAccountActions),
];
