import React from 'react'

import st from './index.module.scss'

const ResultsNum = props => {
  const { t, counter } = props

  if (counter < 0) return null

  return (
    <div className={st.num}>
      {t('search_panel.results.found')}
      <span>{counter}</span>
    </div>
  )
}

export default ResultsNum
