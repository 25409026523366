import { graphql } from 'relay-runtime'
import * as request from '../../utility/request'

const URLS = {
  recovery: 'api/auth/recovery/',
  getCurrentUser: 'api/auth/whoami/',
  auth: 'api/auth/',
}

const currentUserQuery = graphql`
  query authCurrentUserQuery {
    me {
      uid
      email
      role{
        edges{
          node{
            slug
          }
        }
      }
      firstName
      lastName
      accountConfirmed
      emailConfirmed
    }
  }
`

export const auth = data => request.safelyPost(URLS.auth, data)

export const recovery = data => request.safelyPost(URLS.recovery, data)

export const getCurrentUser = () => request.safelyGet(URLS.getCurrentUser)

export const getCurrentUserGraph = () => request.fetchGrapqlRequest(currentUserQuery)
