import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as request from 'utility/request';
import * as editFunctions from 'redux/api/editMode';
import * as createFunctions from 'redux/api/create';
import { constants as exampleConstants, actions as exampleActions } from 'redux/modules/example';
import { graphqlPostEnv, QUERY_TYPE } from 'redux/api/graphql'
import { fetchGrapqlMutation } from 'utility/request'
import { deleteMerge } from 'redux/api/merge'
import { message } from 'antd';
import i18n from 'i18next'

import { addToSelection } from '../queries/edit';

function getBodyForDeleteRel(action) {
  console.log('fetchDeleteSubjectRel', action.payload)
  const { payload } = action;
  return {
    connection_field: payload.connection_field,
    left: {
      label: payload.labelLeft,
      uid: payload.subjectUid
    },
    right: {
      label: payload.labelRight,
      uid: payload.subject2Uid
    }
  }
}

function getBodyForCreateSubjRel(action) {
  console.log('fetchDeleteSubjectRel', action.payload)
  const { payload } = action;
  return {
    connection_field: payload.connection_field,
    left: {
      label: payload.labelLeft,
      uid: payload.subjectUid
    },
    right: {
      label: payload.labelRight,
      uid: payload.newValue
    },
    base: {},
    research: payload.researchUid,
  }
}

export function* fetchDeleteInterpretation(action) {
  console.log('fetchDeleteInterpretation', action.payload)
  yield put(exampleActions.editDeleteInterpretationSuccess(action.payload));
  const { response, error } = yield call(editFunctions.deleteInterpretation, action.payload);
}

export function* fetchDeleteSubjectRel(action) {
  console.log('fetchDeleteSubjectRel', action.payload)
  const body = getBodyForDeleteRel(action)
  yield put(exampleActions.editDeleteInterpretationSuccess(action.payload));
  const { response, error } = yield call(editFunctions.deleteSubjectConnection, body);
}

export function* fetchEditInterpretation(action) {
  const { payload } = action;
  console.log('fetchEditInterpretation', action.payload)
  yield put(exampleActions.editEditInterpretationSuccess(action.payload));

  let editFunction;
  if (payload.fieldType === 'autoSelectKnownResearch') {
    if (payload.subject2Uid) {
      const bodyForDelete = getBodyForDeleteRel(action)
      console.log('autoSelectKnownResearch1', bodyForDelete)
      yield call(editFunctions.deleteSubjectConnection, bodyForDelete);
    }

    const bodyForCreate = getBodyForCreateSubjRel(action)
    console.log('autoSelectKnownResearch2', bodyForCreate)
    yield call(createFunctions.createSubjRelationV2, bodyForCreate)

  } else if (payload.editType === 'interpretation') {
    editFunction = editFunctions.editInterpretation
  } else if (payload.editType === 'researchNode') {
    editFunction = editFunctions.editResearchNode
  } else if (payload.editType === 'authorNode') {
    editFunction = editFunctions.editAuthorNode
  } else if (payload.editType === 'sourceNode') {
    editFunction = editFunctions.editSourceNode
  } else {
    editFunction = editFunctions.editSubjectNode;
  }
  const { response, error } = yield call(editFunction, action.payload);
}

export function* fetchAddToSelection(action) {
  const vars = { selectionUid: action.payload.selectionUid, subjectUid: action.payload.subjectUid }
  console.log('fetchAddToSelection', action.payload, addToSelection, vars)
  const response = yield call(graphqlPostEnv, { query: addToSelection, vars, queryType: QUERY_TYPE.MUTATION });
}

export function* fetchDeleteSpatial(action) {
  console.log('fetchDeleteSpatial', action.payload)
  const { payload } = action;

  const { response, error } = yield call(editFunctions.deleteSpatial, { uid: payload.uid });
  console.log('fetchDeleteSpatial2', response)

  if (typeof response !== "undefined") {
    yield put(exampleActions.editDeleteSpatialSuccess(payload));
  }
}

export function* fetchDeleteSubject(action) {
  const { payload } = action;
  const body = {
    label: payload.label,
    uids: [payload.uid],
  }

  console.log('fetchDeleteSubject', payload, body)

  const { response, error } = yield call(deleteMerge, body);
  console.log('fetchDeleteSubject2', response)

  if (response === false) {
    yield put(exampleActions.editDeleteSuccess(payload));
  }
}

export function* fetchUpdateSpatial(action) {
  const { payload } = action;
  const body = {
    label: payload.label,
    uid: payload.uid,
  }

  console.log('fetchUpdateSpatial', payload, body)

  const { response, error } = yield call(editFunctions.updateSpatial, body);
  console.log('fetchUpdateSpatial2', response)

  if (response && response.status === 'ok') {
    message.success(i18n.t('dataPanel.edit_messages.update_coordinates.success'));
  } else {
    message.error(i18n.t('dataPanel.edit_messages.update_coordinates.error'));
  }
}

function* watchEditModeActions() {
  yield takeLatest(exampleConstants.EDIT_DELETE_INTERPRETATION, fetchDeleteInterpretation);
  yield takeLatest(exampleConstants.EDIT_DELETE_SUBJECT_REL, fetchDeleteSubjectRel);
  yield takeLatest(exampleConstants.EDIT_EDIT_INTERPRETATION, fetchEditInterpretation);

  yield takeLatest(exampleConstants.EDIT_ADD_TO_SELECTION, fetchAddToSelection);

  yield takeLatest(exampleConstants.EDIT_UPDATE_SPATIAL, fetchUpdateSpatial);
  yield takeLatest(exampleConstants.EDIT_DELETE_SUBJECT, fetchDeleteSubject);

  yield takeLatest(exampleConstants.EDIT_DELETE_SPATIAL, fetchDeleteSpatial);
}

export const editSaga = [
  fork(watchEditModeActions),
];
