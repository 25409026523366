import React from 'react';
import { Modal, Button, Form, Input, InputNumber, Upload, Icon, message, Select } from 'antd';
import { withFormik } from 'formik';
import { listQueries } from 'components/CreatePanel/queries/getList';

import config from './config';
import AutoInputGQL from '../fields/autoInputGQL';

const FormItem = Form.Item;
const { OptGroup, Option } = Select;

const InnerForm = ({
                     values,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     handleEdit,
                     isSubmitting,
                     isError,
                     form,
                     newPeriodical,
                     newPublisher,
                     newPublisherName,
                     handleSelectPublisher,
                     visible,
                     loading,
                     hide,
                     t,
                   }) => {
  const { getFieldDecorator } = form;

  if (newPeriodical) {
    hide();
  }

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  const handlePublisherChange = (data) => {
    form.setFieldsValue({ publisher: data.uid });
  }

  const handleOrgChange = (data) => {
    form.setFieldsValue({ organization: data.uid });
  }

  const handleCityChange = (data) => {
    console.log('handleCityChange', data)
    form.setFieldsValue({ city: data.uid });
  }

  return (
    <Modal
      visible={visible}
      className="create-panel-modal"
      onCancel={hide}
      width={900}
      footer={null}
    >
      <div className="create-panel-modal-header">
        <h2>
          {t('create_panel.modal.addPeriodical.title')}
        </h2>
      </div>

      <Form onSubmit={handleSubmit} className="search-form">
        <FormItem
          label={t('create_panel.modal.addNewReport.ru_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('ru_name', config.ru_name
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.en_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('en_name', config.en_name
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.doi.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('doi'
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.issn.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('issn', config.issn
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.eissn.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('e_issn', config.eissn
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.url.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('url'
          )(<Input />)}
        </FormItem>
        <AutoInputGQL
          newObjChanged={handleCityChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="city_select"
          label={t('create_panel.modal.addNewReport.city.title')}
          noAddOption
          rules={config.req}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.citySearch()}
          t={t}
        />
        <AutoInputGQL
          handleSelect={handleSelectPublisher}
          newObjChanged={handlePublisherChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="publisher_select"
          label={t('create_panel.modal.addNewReport.publisher.title')}
          addOptionText={t('create_panel.modal.addNewReport.publisher.new')}
          rules={config.publisher}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.publisherSearch()}
          t={t}
        />
        {newPublisher === true &&
          <div>
            <FormItem
              label={t('create_panel.modal.addNewReport.publisher.ru_name')}
              {...formItemLayout}
            >
              {getFieldDecorator('publisher_ru_name', { ...config.ru_name, initialValue: newPublisherName }
              )(<Input />)}
            </FormItem>
            <FormItem
              label={t('create_panel.modal.addNewReport.publisher.en_name')}
              {...formItemLayout}
            >
              {getFieldDecorator('publisher_en_name', { ...config.en_name, initialValue: newPublisherName }
              )(<Input />)}
            </FormItem>
            <AutoInputGQL
              newObjChanged={handleOrgChange}
              getFieldDecorator={getFieldDecorator}
              formLayout={formItemLayout}
              selectkey="publisher-organization"
              label={t('create_panel.modal.addNewReport.publisher.organization')}
              noAddOption
              params={[{ key: 'search', val: 'value' }]}
              query={listQueries.organizationSearch()}
              t={t}
            />
          </div>
        }
        <FormItem>
          {getFieldDecorator('city', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('publisher', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('organization', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem
          wrapperCol={{ offset: 10, span: 6 }}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            className="create-form-button"
            disabled={loading}
          >
            {t('common.add')}
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
}

const AddPeriodicalModal = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      console.log('new periodical', fieldsValue)
      if (err) {
        setSubmitting(false);
        return;
      }

      const params = {};

      Object.keys(fieldsValue).forEach(key => {
        if (fieldsValue[key]) {
          params[key] = fieldsValue[key]
        }
      });

      params.local_name = params.ru_name;
      console.log('new periodical', params, props)
      props.handleSubmit(params);
      setTimeout(setSubmitting(false), 1000);
    })
  },
})(InnerForm));

export default AddPeriodicalModal;
