import React from 'react'
import { Table } from 'antd';
import { Link } from 'react-router-dom'

export default function ProfileTabSelectionShared(props) {
  const {
    t,
    data,
    isLoading,
  } = props;

  const COLUMNS = [
    {
      title: t('selection.table.creator'),
      dataIndex: 'creator',
      key: 'creator',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: creator => creator.publicName,
    },
    {
      title: t('selection.table.title'),
      dataIndex: 'name',
      key: 'title',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <Link key={record.uid} to={`/show/selection/${record.uid}`}>{record.name}</Link>
      ),
    },
    {
      title: t('selection.table.type'),
      dataIndex: 'model',
      sorter: (a, b) => a.name.localeCompare(b.name),
      key: 'type',
    },
    {
      title: t('selection.table.num'),
      dataIndex: 'resultCount',
      sorter: (a, b) => a.resultCount - b.resultCount,
      key: 'num',
    },
    {
      title: t('selection.table.access'),
      dataIndex: 'accessRole',
      sorter: (a, b) => a.name.localeCompare(b.name),
      key: 'access',
      render: accessRole => t(`selection.access.${accessRole}`),
    },
  ];

  return (
    <Table
      columns={COLUMNS}
      dataSource={data}
      rowKey="uid"
      loading={isLoading}
    />
  )
}
