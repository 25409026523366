import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';

import '../../index.scss';
import AutoInput from '../fields/autoInput';
import { textFieldFormatters } from '../../functions/textFieldFormatters';
import LexemLocality from './lexemLocality';

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class LexemVariant extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;
    this.state = {
      tabs: [],
    };
  }

  addTab = () => {
    const key = this.newTabIndex++;
    this.state.tabs.push({
      key,
    });
    this.forceUpdate()
  }

  handleChange = (type, e) => {
    this.setState({ type });
  }

  render() {
    const {
      objkey,
      getFieldDecorator,
      stateTabs,
      // formItemLayout,
      t,
    } = this.props;

    const {
      key1,
      key2,
      tabs,
    } = this.state;

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };

    const config = {
      req: {
        rules: [{
          required: true, message: t('common.default_error'),
        }],
      },
    }

    return (
      <div>
        <Collapse marginTop={50}>
          {tabs.map((item, key) => (
            <Panel header={`${t('create_panel.tab.lexem.variant')} #${1 + item.key}`} key={item.key}>
              <FormItem
                {...formItemLayout}
                label={t('create_panel.tab.lexem.variant-name')}
              >
                {getFieldDecorator(`lexem_variant_name_${key1}_${key2}`, config.req)(
                  <Input />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={t('create_panel.tab.lexem.variant-lit')}
              >
                {getFieldDecorator(`lexem_variant_lit_${key1}_${key2}`, config.req)(
                  <Input />)}
              </FormItem>
              <AutoInput
                getFieldDecorator={getFieldDecorator}
                formItemLayout={formItemLayout}
                selectkey={`lexem_variant_vern_${key1}_${key2}`}
                stateTabs={stateTabs}
                objkey={key1}
                rules={config.req}
                label={t('create_panel.tab.lexem.variant-vern')}
                addoptiontext={t('create_panel.tab.lexem.variant-vern-add')}
                selectType="culture"
                title={this.props.title}
                path="api/linguistic/vernacular/list"
                params={['ru_name']}
                textField="ru_name"
                method="POST"
                t={t}
              />
              <AutoInput
                getFieldDecorator={getFieldDecorator}
                formItemLayout={formItemLayout}
                selectkey={`lexem_variant_res_${key1}_${key2}`}
                stateTabs={stateTabs}
                objkey={key1}
                label={t('create_panel.tab.lexem.variant-res')}
                selectType="culture"
                title={this.props.title}
                path="api/research/list"
                params={['ru_name']}
                textField="ru_name"
                method="POST"
                noAddOption
                t={t}
              />
              <LexemLocality
                getFieldDecorator={getFieldDecorator}
                t={t}
                key1={this.props.key1}
                key2={this.props.key2}
                key3={key}
                stateTabs={stateTabs}
                formItemLayout={formItemLayout}
              />
            </Panel>
          ))}
        </Collapse>

        <FormItem
          wrapperCol={{ span: 8, offset: 8 }}
        >
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.lexem.add-variant')}
          </Button>
        </FormItem>
      </div>
    );
  }
}

export default LexemVariant;
