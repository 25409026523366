import * as request from '../../utility/request';

export const getUserList = async () => {
  const path = 'api/author/list/';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getAuthor = async (id) => {
  const path = `api/author/uid/${id}/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getProfile = async () => {
  const path = 'api/author/profile/';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const updateProfile = async (data) => {
  const path = 'api/author/profile/';
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH(), 'PATCH');
  return { response, error };
}

export const removeProfile = async () => {
  const path = 'api/author/profile/';
  const { response, error } = await request.safelyGet(path, request.HEADERS_POST_AUTH(), 'DELETE');
  return { response, error };
}

export const changePassword = async (data) => {
  const path = 'api/author/profile/change_password/';
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const uploadPhoto = async (data) => {
  const path = 'api/author/profile/photo/';
  const { response, error } =
    await request.safelyPostFormData(path, data, request.HEADERS_POST_AUTH(), 'PUT');
  return { response, error };
}

export const removePhoto = async () => {
  const path = 'api/author/profile/photo/';
  const { response, error } = await request.safelyNewGet(path, request.HEADERS_POST_AUTH(), 'DELETE');
  return { response, error };
}

export const getAuthorResearches = async (id) => {
  const path = `api/author/uid/${id}/researches/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getAuthorSpatialReference = async (id) => {
  const path = `api/author/uid/${id}/spatial_reference/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getAuthorSites = async (id) => {
  const path = `api/author/uid/${id}/sites/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getAuthorApprovers = async () => {
  const path = 'api/author/profile/my_approvers/list/';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getAuthorSources = async (id, type) => {
  const path = `api/author/uid/${id}/sources/${type}/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getConfirmationUsers = async () => {
  const path = 'api/author/profile/confirmation_users/';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const dismissUserConfirm = async (id) => {
  const path = `api/author/profile/confirmation_users/dismiss/uid/${id}/`;
  const { response, error } = await request.safelyPost(path, {}, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const confirmUserConfirm = async (url) => {
  const path = url;
  const { response, error } = await request.safelyPost(path, {}, request.HEADERS_POST_AUTH());
  return { response, error };
}
