const SUB_ICONS = {
  default: () => null,
  0: (color: string) => `
    <rect x="23" y="23" width="20" height="20" fill="${color}" />
  `,
  1: (color: string) => `
    <rect
      x="33"
      y="21"
      width="16.9705"
      height="16.9705"
      transform="rotate(45 33 21)"
      fill="${color}"
    />
  `,
  2: (color: string) => `
    <rect x="22" y="31" width="22" height="4" fill="${color}" />
  `,
  3: (color: string) => `
    <rect
      x="31"
      y="44"
      width="22"
      height="4"
      transform="rotate(-90 31 44)"
      fill="${color}"
    />
  `,
  4: (color: string) => `
    <>
      <rect x="22" y="31" width="22" height="4" fill="${color}" />
      <rect
        x="31"
        y="44"
        width="22"
        height="4"
        transform="rotate(-90 31 44)"
        fill="${color}"
      />
    </>
  `,
  5: (color: string) => `
    <>
      <rect
        x="23.364"
        y="39.364"
        width="22"
        height="4"
        transform="rotate(-45 23.364 39.364)"
        fill="${color}"
      />
      <rect
        x="38.9203"
        y="42.1924"
        width="22"
        height="4"
        transform="rotate(-135 38.9203 42.1924)"
        fill="${color}"
      />
    </>
  `,
  6: (color: string) => `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.3015 24.989L26.3809 20.9934L26.3809 28.9846L19.4603 32.9802L26.3809 36.9758L26.3809 44.9671L33.3015 40.9715L40.2221 44.9671L40.2221 36.9758L47.1427 32.9802L40.2221 28.9846L40.2221 20.9934L33.3015 24.989Z"
      fill="${color}"
    />
  `,
  7: (color: string) => `
    <>
      <rect x="27.6666" y="23" width="11.1111" height="20" fill="${color}" />
      <path d="M21 33L27.6667 23L27.6667 43L21 33Z" fill="${color}" />
      <path d="M45.4445 33L38.7778 43L38.7778 23L45.4445 33Z" fill="${color}" />
    </>
  `,
  8: (color: string) => `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45 29.2143L33 21L21 29.2143L25.2857 42.3571H40.7143L45 29.2143Z"
      fill="${color}"
    />
  `,
  9: (color: string) => `
    <path d="M33 19L45.1244 40H20.8756L33 19Z" fill="${color}" />
  `,
  10: (color: string) => `<circle cx="33" cy="33" r="10" fill="${color}" />`,
  11: (color: string) => `
    <path d="M33 47L20.8756 26L45.1244 26L33 47Z" fill="${color}" />
  `,
}

const getSubIcon = (subType: number | string, color: string) => {
  const icon = SUB_ICONS[subType]
  if (!icon) return null
  return icon(color)
}

const ICONS = {
  0: (color: string, size: number, subType: number | string) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <circle cx="33" cy="33" r="21" fill="white" />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  1: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.2308 27.0824L33.1154 12L12 27.0824L19.5412 51.2143H46.6896L54.2308 27.0824Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  2: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <rect
        x="53"
        y="22.0494"
        width="22.4691"
        height="40.4444"
        transform="rotate(90 53 22.0494)"
        fill="white"
      />
      <path
        d="M32.7777 8.56799L53 22.0495L12.5556 22.0495L32.7777 8.56799Z"
        fill="white"
      />
      <path
        d="M32.7778 58L12.5556 44.5185L53 44.5185L32.7778 58Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  3: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <rect
        x="33"
        y="7.99997"
        width="35.3554"
        height="35.3554"
        transform="rotate(45 33 7.99997)"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  4: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.9871 19.1325L32.9807 5.26498L24.9742 19.1325L8.96143 19.1325L16.9678 33L8.96143 46.8675H24.9743L32.9807 60.735L40.9871 46.8675H56.9999L48.9935 33L56.9999 19.1325L40.9871 19.1325Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  5: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <rect x="18" y="13" width="30" height="40" fill="white" />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  6: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <rect
        x="12"
        y="48"
        width="30"
        height="40"
        transform="rotate(-90 12 48)"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  7: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.9855 27.4583C49.9951 27.2234 50 26.9873 50 26.75C50 17.3612 42.3888 9.75 33 9.75C23.6112 9.75 16 17.3612 16 26.75C16 26.9873 16.0049 27.2234 16.0145 27.4583H16V52.25L33 48.7083L50 52.25V27.4583H49.9855Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  8: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4143 39.5C16.4048 39.7321 16.4 39.9655 16.4 40.2C16.4 49.4784 23.9216 57 33.2 57C42.4784 57 50 49.4784 50 40.2C50 39.9655 49.9952 39.7321 49.9857 39.5L50 39.5L50 15L33.2 18.5L16.4 15L16.4 39.5L16.4143 39.5Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  9: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        d="M13 13L33 16.5714L53 13L49.4286 33L53 53L33 49.4286L13 53L16.5714 33L13 13Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  10: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45 9V11.4L35.4 11.4V13.1425C44.7924 14.2658 52.1848 21.8984 52.9369 31.4H54.6V21.8H57V31.4V34.6V44.2H54.6V34.6H52.9369C52.1848 44.1016 44.7924 51.7342 35.4 52.8575V54.6H45V57H35.4H32.2H22.6V54.6H32.2V52.9843C22.0586 52.5854 13.8574 44.635 13.0631 34.6H11.4V44.2H9V34.6V31.4V21.8H11.4V31.4H13.0631C13.8574 21.365 22.0586 13.4146 32.2 13.0157V11.4L22.6 11.4V9H32.2H35.4H45Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  11: (color: string, size: number, subType: number) => `
    <svg
      width="74"
      height="74"
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5 18.5L18.5 18.5L23.5455 13.4546L28.5909 18.5L45.4091 18.5L50.4546 13.4545L60.5455 23.5455L55.5 28.591V45.4091L60.5455 50.4546L50.4546 60.5455L45.4091 55.5L28.5909 55.5L23.5455 60.5455L18.5 55.5L18.5 55.5L18.5 55.5L13.4546 50.4545L18.5 45.4091L18.5 28.5909L13.4546 23.5455L18.5 18.5L18.5 18.5Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  12: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.3636 6H33H25.6364V13.3636L13.3636 25.6364H6V40.3636H13.3637L25.6364 52.6363V60H40.3636V52.6363L52.6363 40.3636H60V25.6364H52.6364L40.3636 13.3636V6Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  13: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path d="M18.0968 13H47.9032L55 49H11L18.0968 13Z" fill="white" />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  14: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path d="M47.9032 53L18.0968 53L11 17L55 17L47.9032 53Z" fill="white" />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  15: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M47.5203 19.6632L53 25.1429L53 13L40.8571 13L45.5 17.6429L44.1178 19.0251C41.0673 16.5951 37.2032 15.1429 33 15.1429C28.6144 15.1429 24.5981 16.7238 21.4898 19.3469L20.1429 18L25.1429 13L13 13L13 25.1429L18.1226 20.0203L19.4597 21.3574C16.7692 24.4836 15.1429 28.5519 15.1429 33C15.1429 37.2032 16.5951 41.0673 19.0251 44.1177L17.6429 45.5L13 40.8571L13 53L25.1429 53L19.6632 47.5203L20.9786 46.2048C24.1515 49.095 28.37 50.8571 33 50.8571C37.4481 50.8571 41.5164 49.2308 44.6426 46.5403L45.9797 47.8774L40.8571 53L53 53V40.8571L48 45.8571L46.6531 44.5102C49.2762 41.4019 50.8572 37.3856 50.8572 33C50.8572 28.37 49.0951 24.1515 46.2049 20.9786L47.5203 19.6632Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  16: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        d="M11 49L14.6667 33L11 17L55 17L51.3333 33L55 49L11 49Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  17: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        d="M17 11L33 14.6667L49 11L49 55L33 51.3333L17 55L17 11Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
  18: (color: string, size: number, subType: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="${color}" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33 11L42.4828 20.4828L46.2759 16.6897L41.3448 11.7586L54.2414 11.7586L54.2414 24.6552L48.4216 18.8354L44.6285 22.6285L55 33L45.1379 42.8621L48.931 46.6552L54.2414 41.3449L54.2414 54.2414L41.3448 54.2414L46.7853 48.8009L42.9922 45.0078L33 55L22.6285 44.6285L18.8354 48.4216L24.6552 54.2414L11.7586 54.2414L11.7587 41.3449L16.6897 46.2759L20.4828 42.4828L11 33L20.9922 23.0078L17.1991 19.2147L11.7586 24.6552L11.7586 11.7586L24.6552 11.7587L19.3448 17.069L23.1379 20.8621L33 11Z"
        fill="white"
      />
      ${getSubIcon(subType, color)}
    </svg>
  `,
}
ICONS['default'] = ICONS[0]

export default ICONS
