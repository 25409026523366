import {
  TAB_TYPE,
  createCommonField,
  createImageField,
  getLangTitle,
  createLinkField,
} from 'utility/transformData/constants';

export default function getArtifactFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const content = []
      const artName = getLangTitle(d)
      if (d.photo[0]) {
        content.push(
          createImageField('photo', d.photo[0].link)
        )
      }

      const material = d.attributes.filter(item => item.label === 'ArtifactMaterial')[0]
      const category = d.attributes.filter(item => item.label === 'ArtifactCategory')[0]
      if (category) {
        content.push(
          createCommonField('ArtifactCategory', getLangTitle(category)),
        )
      }
      if (material) {
        content.push(
          createCommonField('ArtifactMaterial', getLangTitle(material)),
        )
      }
      content.unshift(
        createCommonField('name', artName),
      )
      return content
    }
    case TAB_TYPE.COLLAPSE: {
      return d.results.map((item) => {
        // console.log('ARTIFACT', item);
        const itemObj = {}
        const artName = getLangTitle(item);
        const attrs = [];
        itemObj.content = [
          createLinkField('title', item.uid, 'subjects/Artifact',
            'Перейти к артефакту'),
        ]
        if (item.photo[0]) {
          itemObj.content.push(
            createImageField('photo', item.photo[0].link)
          )
        }
        if (item.research) {
          if (item.research.date_end || item.research.date_start) {
            itemObj.content.push(
              createCommonField('date', `${item.research.date_start || '?'} - ${item.research.date_end || '?'}`),
            )
          }
          if (item.research.ru_desc) {
            itemObj.content.push(
              createCommonField('description', getLangTitle(item.research)),
            )
          }
          if (item.research.technique) {
            itemObj.content.push(
              createCommonField('technique', item.research.technique),
            )
          }
        }
        if (item.weight) {
          itemObj.content.push(
            createCommonField('weight', item.weight),
          )
        }
        if (item.attributes.ArtifactCategory && item.attributes.ArtifactCategory[0]) {
          itemObj.content.push(
            createCommonField('ArtifactCategory', getLangTitle(item.attributes.ArtifactCategory[0])),
          )
          attrs.push(getLangTitle(item.attributes.ArtifactCategory[0]))
        }
        if (item.attributes.ArtifactMaterial && item.attributes.ArtifactMaterial[0]) {
          itemObj.content.push(
            createCommonField('ArtifactMaterial', getLangTitle(item.attributes.ArtifactMaterial[0])),
          )
          attrs.push(getLangTitle(item.attributes.ArtifactMaterial[0]))
        }
        itemObj.name = `${getLangTitle(item)} ${attrs ? `(${attrs.join(',')})` : ''}`
        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
