import i18n from 'i18next'

const config = {
  book: {
    rules: [{
      required: true, message: i18n.t('create_panel.modal.addNewReport.book.error'),
    }],
  },
  edition: {
    rules: [{
      message: i18n.t('create_panel.modal.addNewReport.edition.error'),
    }],
  },
  en_name: {
    rules: [{
      required: true, message: i18n.t('create_panel.modal.addNewReport.en_name.error'),
    }],
  },
  isbn: {
    rules: [{
      required: true, message: i18n.t('create_panel.modal.addNewReport.isbn.error'),
    }],
  },
  issn: {
    rules: [{
      message: i18n.t('create_panel.modal.addNewReport.issn.error'), min: 8, max: 9,
    }],
  },
  eissn: {
    rules: [{
      message: i18n.t('create_panel.modal.addNewReport.eissn.error'), min: 8, max: 9,
    }],
  },
  periodical: {
    rules: [{
      required: true, message: i18n.t('create_panel.modal.addNewReport.periodical.error'),
    }],
  },
  issue: {
    rules: [{
      required: true, message: i18n.t('create_panel.modal.addNewReport.issue.error'),
    }],
  },
  number: {
    rules: [{
      message: i18n.t('create_panel.modal.addNewReport.number.error'),
    }],
  },
  reportType: {
    rules: [{
      required: true, message: i18n.t('create_panel.modal.report_type.ru_name.error'),
    }],
  },
  req: {
    rules: [{
      required: true, message: i18n.t('common.default_error'),
    }],
  },
  ru_name: {
    rules: [{
      required: true, message: i18n.t('create_panel.modal.addNewReport.ru_name.error'),
    }],
  },

  pages_book: {
    rules: [{
      required: true, message: i18n.t('create_panel.modal.addNewReport.pages_book.error'),
    }],
  },
  pages_issue: {
    rules: [{
      required: true, message: i18n.t('create_panel.modal.addNewReport.pages_issue.error'),
    }],
  },
  publisher: {
    rules: [{
      required: true, message: i18n.t('create_panel.modal.addNewReport.publisher.error'),
    }],
  },
  year: {
    rules: [{
      required: true, message: i18n.t('create_panel.modal.addNewReport.year.error'),
    }],
  },
  volume: {
    rules: [{
      message: i18n.t('create_panel.modal.addNewReport.volume.error'),
    }],
  },
}

export default config
