/**
 * @flow
 * @relayHash dc8993156168abef1961b3e60cbc9608
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessObjectEnum = "PRIVATE" | "PUBLIC" | "SHARED" | "%future added value";
export type ModelPermissionEnum = "RESEARCH" | "SELECTION" | "SOURCE" | "SUBJECT" | "%future added value";
export type ChangeAccessInputType = {
  model: ModelPermissionEnum,
  uid: any,
  permission: AccessObjectEnum,
  sharedUsers?: ?$ReadOnlyArray<?string>,
};
export type showSelectionAccessMutationVariables = {|
  data?: ?ChangeAccessInputType
|};
export type showSelectionAccessMutationResponse = {|
  +accessChange: ?{|
    +permission: ?AccessObjectEnum,
    +sharedUsers: ?$ReadOnlyArray<?{|
      +uid: ?string,
      +publicName: ?string,
      +publicNameEn: ?string,
    |}>,
  |}
|};
export type showSelectionAccessMutation = {|
  variables: showSelectionAccessMutationVariables,
  response: showSelectionAccessMutationResponse,
|};
*/


/*
mutation showSelectionAccessMutation(
  $data: ChangeAccessInputType
) {
  accessChange(data: $data) {
    permission
    sharedUsers {
      uid
      publicName
      publicNameEn
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "ChangeAccessInputType",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "data",
    "type": "ChangeAccessInputType"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permission",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicNameEn",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "showSelectionAccessMutation",
  "id": null,
  "text": "mutation showSelectionAccessMutation(\n  $data: ChangeAccessInputType\n) {\n  accessChange(data: $data) {\n    permission\n    sharedUsers {\n      uid\n      publicName\n      publicNameEn\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "showSelectionAccessMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "accessChange",
        "storageKey": null,
        "args": v1,
        "concreteType": "ChangeAccessMutation",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "sharedUsers",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorNode",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "showSelectionAccessMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "accessChange",
        "storageKey": null,
        "args": v1,
        "concreteType": "ChangeAccessMutation",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "sharedUsers",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorNode",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e928b38dde6ab190123f027011d12cb';
module.exports = node;
