import React, { Component } from 'react'
import { translate } from 'react-i18next'
import AccessHoc from 'components/AccessHoc'
import LoadingSubject from 'components/Loading/Subject'
import SettingPanel from 'components/SettingPanel'
import DataLeftPanel from 'components/DataLeftPanel'

import DataPanelContent from '../DataPanelContent'
import DataPanelAuthor from '../DataPanelAuthor'
import './index.scss'

@translate()
class DataPanel extends Component {
  render() {
    const {
      t,
      tabs,
      isLoading,
      isError,
      source,
      author,
      match,
      settings,
      leftPanelButtons,
    } = this.props
    const { type, name } = this.props.title
    return (
      <div className={`data-panel${source ? ' source' : ''}`}>
        <LoadingSubject />
        <h1 className="data-panel-header">
          {isLoading && <span>{t('homePage.loading')}</span>}
          {isError && <span>{t('homePage.wrongIdError')}</span>}
          {!isLoading && !isError && (
            <div>
              <span>{name}</span>
              <div className="under-header">{t(`dataPanel.title.${type}`)}</div>
            </div>
          )}
        </h1>
        <DataLeftPanel buttons={leftPanelButtons} />
        <AccessHoc match={match} />
        {author ? <DataPanelAuthor tabs={tabs} author={author} /> : null}
        {!source && !author ? <DataPanelContent tabs={tabs} /> : null}
      </div>
    )
  }
}

export default DataPanel
