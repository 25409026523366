import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as request from 'utility/request';

const en = require('./en.json');
const ru = require('./ru.json');
const be = require('./be.json');
const cs = require('./cs.json');
const de = require('./de.json');
const hr = require('./hr.json');
const hu = require('./hu.json');
const lt = require('./lt.json');
const lv = require('./lv.json');
const pl = require('./pl.json');
const sr = require('./sr.json');
const tr = require('./tr.json');
const tt = require('./tt.json');
const uk = require('./uk.json');

const lngDetector = new LanguageDetector()
lngDetector.addDetector({
  name: 'customDetector',
  lookup(options) {
    let lng = localStorage.getItem('i18nextLng');
    console.log('LOCALE customDetector lng1', lng)
    if (lng === null) {
      lng = navigator.language
      // if (navigator.languages.includes('ru')) {
      //   lng = 'ru';
      // } else {
      //   lng = 'en';
      // }
      request.safelyPost('api/locale/', {locale: lng});
    }
    console.log('LOCALE customDetector lng2', lng, navigator.languages)
    return lng;
  },

  cacheUserLanguage(lng, options) {
    console.log('LOCALE cacheUserLanguage', lng)
  },
})

i18n
  .use(lngDetector)
  .init({
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
    detection: {
      order: ['customDetector', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    },
    resources: {
      en: {translation: en,},
      ru: {translation: ru,},
      be: {translation: be,},
      cs: {translation: cs,},
      de: {translation: de,},
      hr: {translation: hr,},
      hu: {translation: hu,},
      lt: {translation: lt,},
      lv: {translation: lv,},
      pl: {translation: pl,},
      sr: {translation: sr,},
      tr: {translation: tr,},
      tt: {translation: tt,},
      uk: {translation: uk,},
    },
  });

export default i18n;
