import React from 'react'
import { Pagination as PG } from 'antd'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import '../index.scss'
import st from './index.module.scss'

const PAGE_SIZE_OPTIONS = ['25', '50', '100', '500']
const PAGE_SIZE = '25'

const Pagination = props => {
  const { pageData = {}, savedQuery, handleSubmit } = props
  const [pageSize, setPageSize] = React.useState(pageData.pageSize || PAGE_SIZE)
  const [page, setPage] = React.useState(pageData.page || 1)
  const { count = 0 } = savedQuery

  React.useEffect(() => {
    if (pageData.pageSize !== pageSize) {
      setPageSize(pageData.pageSize)
    }
    if (pageData.page !== page) {
      setPage(pageData.page)
    }
  }, [pageData.pageSize, pageData.page, pageSize, page])

  const handlePage = (newPage, newSize = PAGE_SIZE) => {
    handleSubmit(newPage, newSize)
  }

  const handleShowNum = e => {
    setPageSize(e.target.dataset.num)
    handlePage(page, e.target.dataset.num)
  }

  const renderShowNum = () => {
    const result = []
    PAGE_SIZE_OPTIONS.forEach(item => {
      if (item < count) {
        result.push(
          <li
            key={item}
            data-num={item}
            className={item === pageSize && st.active}
          >
            {item}
          </li>,
        )
      }
    })
    result.push(
      <li
        key={count}
        data-num={count}
        className={pageSize === count && st.active}
      >
        {i18n.t('search_panel.showall')}
      </li>,
    )
    return result
  }

  if (count < 1) return null

  return (
    <div className={st.pagination}>
      <div className={st.show_num}>
        <span>{i18n.t('search_panel.showcount')}</span>
        <ul className={st.show_num_list} onClick={handleShowNum}>
          {renderShowNum()}
        </ul>
      </div>
      <PG
        size="small"
        hideOnSinglePage={count === 0}
        className={st.search_page_pagination}
        total={count}
        onChange={handlePage}
        pageSize={pageSize}
        current={page}
      />
    </div>
  )
}

Pagination.propTypes = {
  pageData: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  savedQuery: PropTypes.object,
}

export default Pagination
