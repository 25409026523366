import { createAction, handleActions } from 'redux-actions';

const GET_USER_INFO = 'user/GET_USER_INFO';
const USER_INFO = 'user/USER_INFO';
const CHANGE_USER_INFO = 'user/CHANGE_USER_INFO';
const USER_INFO_FAILED = 'user/USER_INFO_FAILED';
const LOAD_RESEARCHES = 'user/LOAD_RESEARCHES';
const RESEARCHES = 'user/RESEARCHES';
const LOAD_SOURCES = 'user/LOAD_SOURCES';
const SOURCES = 'user/SOURCES';
// const REMOVE_PHOTO = 'user/REMOVE_PHOTO';
const ORGANIZATION_COUNT_CHANGE = 'user/ORGANIZATION_COUNT_CHANGE';

export const constants = {
  GET_USER_INFO,
  USER_INFO,
  CHANGE_USER_INFO,
  USER_INFO_FAILED,
  LOAD_RESEARCHES,
  RESEARCHES,
  LOAD_SOURCES,
  SOURCES,
  ORGANIZATION_COUNT_CHANGE,
  // REMOVE_PHOTO,
};

// ------------------------------------
// Actions
// ------------------------------------
export const getUserInfo = createAction(GET_USER_INFO);
export const changeUserInfo = createAction(CHANGE_USER_INFO);
export const userInfo = createAction(USER_INFO);
export const userInfoFailed = createAction(USER_INFO_FAILED);
export const loadResearches = createAction(LOAD_RESEARCHES);
export const researches = createAction(RESEARCHES);
export const loadSources = createAction(LOAD_SOURCES);
export const sources = createAction(SOURCES);
// export const removePhoto = createAction(REMOVE_PHOTO);
export const handleOrganizationCountChange = createAction(ORGANIZATION_COUNT_CHANGE);

export const actions = {
  getUserInfo,
  changeUserInfo,
  userInfo,
  userInfoFailed,
  loadResearches,
  researches,
  loadSources,
  sources,
  handleOrganizationCountChange,
  // removePhoto,
};

const getMapFromArray = (array) => {
  const map = new Map();
  console.log(array);
  array.forEach(item => {
    const { response } = item;
    map.set(response.uid, response);
  });
  console.log(map);
  return map;
}

const getSourceMap = (state, payload) => {
  if (state.sources.result) {
    return new Map([
      ...state.sources.result,
      ...payload,
    ])
  }
  return new Map([
    ...payload,
  ])
}

export const reducers = {
  [USER_INFO]: (state, { payload }) =>
    ({
      ...state,
      info: payload.result,
      organizationsNum: payload.result.organizations.length || null,
      tags: payload.tags,
      organizations: getMapFromArray(payload.organizations),
      error: {},
      isSaved: true,
    }),
  [ORGANIZATION_COUNT_CHANGE]: (state, { payload }) =>
    ({
      ...state,
      organizationsNum: payload,
    }),
  [RESEARCHES]: (state, { payload }) =>
    ({
      ...state,
      researches: payload,
      error: {},
    }),
  [SOURCES]: (state, { payload }) =>
    ({
      ...state,
      sources: {
        result: getSourceMap(state, payload),
      },
      error: {},
    }),
  [USER_INFO_FAILED]: (state, { payload }) =>
    ({
      ...state,
      error: payload,
    }),
};


export const initialState = () => {
  const state = {
    organizationsNum: null,
    sources: {},
  };
  return state;
}

export default handleActions(reducers, initialState());
