import React, { useState, useEffect, useContext } from 'react'
import LeafletSnapshots from 'utility/leaflet-snapshots'
import { drawScale } from 'utility/canvasDraw'
import randomColor from 'randomcolor'
import L from 'leaflet'

enum OVERLAY_TYPE {
  MARKER,
  POLYGON,
  TILE,
}
const X_PADDING = 30
const FILE_NAME = 'arch-map.png'
const LEGEND_NAME = 'legend.csv'
const LEGEND_FORMAT = 'data:text/csv;charset=utf-8,\uFEFF'
const LEGEND_TITLE = '# Title \n'
const DPI_MULTI = 2

// dummy canvas image when loadTile get 404 error
// and layer don't have errorTileUrl
const dummycanvas = document.createElement('canvas')
dummycanvas.width = 1
dummycanvas.height = 1
const dummyctx = dummycanvas.getContext('2d')
dummyctx.fillStyle = 'rgba(0,0,0,0)'
dummyctx.fillRect(0, 0, 1, 1)

const getLegendString = item => `${item.index} ${item.title}`
const sortLegend = (a, b) => a.index - b.index
const reduceLegend = (summ, current) =>
  (summ += `${getLegendString(current)}\n`)

const isDataURL = url => {
  const dataURLRegex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i
  return !!url.match(dataURLRegex)
}

const cacheBusterDate = +new Date()

const addCacheString = url => {
  // If it's a data URL we don't want to touch this.
  if (isDataURL(url) || url.indexOf('mapbox.com/styles/v1') !== -1) {
    return url
  }
  return `${url + (url.match(/\?/) ? '&' : '?')}cache=${cacheBusterDate}`
}

// get color='rgb(21, 27, 198)'; alpha=0.2
// return 'rgba(21, 27, 198, 0.2)'
const _getRgbaFromRgb = (color: string, alpha: number = 1): string => {
  const { length } = color
  const nums = color.substr(4, length - 5)
  return `rgba(${nums}, ${alpha})`
}

const saveFile = (name: string, href: string): void => {
  const element = document.createElement('a')
  element.setAttribute('href', href)
  element.setAttribute('download', name)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export default function Snapshots(props) {
  const { map: mapRef, toggleSnapshot } = props
  let count = 1
  let legendCount = 0

  const promices = []
  const legendText = []
  let legendY
  const [mainCtx, setMainCtx] = useState(null)
  const [mainCanvas, setMainCanvas] = useState(null)
  const [map, setMap] = useState(null)

  const getCount = (isIncrement: boolean = true): number => {
    const curCount = count
    if (isIncrement) count++
    return curCount
  }

  const isShowOnMap = (marker): boolean => {
    // get pixelBound to check if point is show in map
    // and project it position to canvas
    const pixelBounds = map.getPixelBounds()
    const pixelPoint = map.project(marker.getLatLng())
    const result =
      pixelPoint.x < pixelBounds.max.x &&
      pixelPoint.x > pixelBounds.min.x &&
      (pixelPoint.y < pixelBounds.max.y && pixelPoint.y > pixelBounds.min.y)
    return result
  }

  useEffect(() => {
    const { current } = mapRef
    if (current) {
      const curMap = current.leafletElement
      const curDimensions = curMap.getSize()
      const canvas = document.createElement('canvas')
      canvas.width = curDimensions.x
      canvas.height = curDimensions.y
      const ctx = canvas.getContext('2d')
      setMainCtx(ctx)
      setMainCanvas(canvas)
      setMap(curMap)
    }
  }, [mapRef])

  const getDimension = () => map.getSize()

  const reset = (): void => {
    const dimensions = getDimension()
    mainCanvas.width = dimensions.x
    mainCanvas.height = dimensions.y
  }

  const saveLegend = (): void => {
    const text = legendText.sort(sortLegend).reduce(reduceLegend, LEGEND_TITLE)
    saveFile(LEGEND_NAME, LEGEND_FORMAT + encodeURIComponent(text))
  }

  const saveCanvas = (): void => {
    const image = mainCanvas
      .toDataURL('image/png')
      .replace(/^data:image\/[^;]/, 'data:application/octet-stream')
    saveFile(FILE_NAME, image)
    reset()
    toggleSnapshot()
  }

  const done = () => {
    const dimensions = getDimension()
    drawScale(map, mainCtx, dimensions)
    saveCanvas()
    saveLegend()
  }

  const layersDone = tiles => {
    reset()
    mainCanvas.height += 20 * legendCount
    Promise.all(promices).then(values => {
      const layers = [...tiles, ...values]
      layers.forEach(layer => {
        if (layer && layer.canvas) {
          try {
            mainCtx.drawImage(layer.canvas, 0, 0)
          } catch (e) {
            console.error(e)
          }
        }
      })
      done()
    })
  }

  const createCanvasAndContext = (): {
    canvas: HTMLCanvasElement
    ctx: CanvasRenderingContext2D
  } => {
    const canvas: HTMLCanvasElement = document.createElement('canvas')
    const dimensions = getDimension()
    canvas.width = dimensions.x
    canvas.height = dimensions.y
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d')
    return {
      canvas,
      ctx,
    }
  }

  const handleMarkerLayer = (
    marker,
    resolve,
    showNumber: boolean = true,
    currentCount?: number,
  ): void => {
    const { canvas, ctx } = createCanvasAndContext()
    const pixelBounds = map.getPixelBounds()
    const minPoint = new L.Point(pixelBounds.min.x, pixelBounds.min.y)
    const pixelPoint = map.project(marker.getLatLng())
    const iconSrc = marker.options.icon.options.iconUrl
    const isBase64 = /^data\:/.test(iconSrc)
    const url = isBase64 ? iconSrc : addCacheString(iconSrc)
    const img = new Image()
    const options = marker.options.icon.options
    let size = options.iconSize
    const pos = pixelPoint.subtract(minPoint)

    if (size instanceof L.Point) size = [size.x, size.y]
    const x =
      pos.x -
      size[0] +
      (typeof options.iconAnchor === 'object'
        ? options.iconAnchor[0]
        : size[0] / 2)
    const y =
      pos.y -
      (typeof options.iconAnchor === 'object'
        ? options.iconAnchor[1]
        : size[1] / 2)
    img.crossOrigin = ''
    img.onload = function () {
      const box = marker._tooltip || marker._popup
      const title = box && box._content || ''
      legendText.push({ title, index: currentCount })
      try {
        ctx.drawImage(img, x, y, size[0] / 2, size[1] / 2)
      } catch (e) {
        console.error(e)
      }
      // draw icon in legend
      if (showNumber) {
        ctx.shadowOffsetX = 1
        ctx.shadowOffsetY = 1
        ctx.shadowColor = 'white'
        ctx.shadowBlur = 1
        ctx.font = '16px Arial'
        ctx.fillText(`${currentCount}`, x + size[0] / 2, y + size[1] / 2)
      }
      resolve({
        canvas,
      })
    }
    img.src = url
    // if (isBase64) img.onload(null)
  }

  const handlePolygon = (
    polygon,
    resolve,
    color?: string,
    currentCount?: number,
  ): void => {
    const isNotSearchPolygon = polygon._tooltip
    const bounds = map.getPixelBounds()
    const { canvas, ctx } = createCanvasAndContext()
    const minPoint = new L.Point(bounds.min.x, bounds.min.y)

    if (isNotSearchPolygon) {
      const dimensions = getDimension()
      if (!legendY) {
        legendY = dimensions.y
      }
      canvas.height = legendY + 20
      const { _latlngs } = polygon
      const { length } = _latlngs[0]
      const isMultiPoly = Array.isArray(_latlngs[0][0])
      try {
        const title = polygon._tooltip._content || ''
        legendText.push({ title, index: currentCount })
        ctx.fillStyle = _getRgbaFromRgb(color, 0.2)
        ctx.lineWidth = 3
        ctx.strokeStyle = color
        ctx.beginPath()
        let pixelPoint
        if (isMultiPoly) {
          pixelPoint = map.project(_latlngs[0][0][0])
        } else {
          pixelPoint = map.project(_latlngs[0][0])
        }
        let pos = pixelPoint.subtract(minPoint)
        ctx.moveTo(pos.x, pos.y)
        if (isMultiPoly) {
          for (let i = 1; i < length; i++) {
            const innerLength = _latlngs[0][i].length
            for (let j = 1; j < innerLength; j++) {
              pixelPoint = map.project(_latlngs[0][i][j])
              pos = pixelPoint.subtract(minPoint)
              ctx.lineTo(pos.x, pos.y)
            }
          }
        } else {
          for (let i = 1; i < length; i++) {
            pixelPoint = map.project(_latlngs[0][i])
            pos = pixelPoint.subtract(minPoint)
            ctx.lineTo(pos.x, pos.y)
          }
        }
        ctx.closePath()
        ctx.fill()
        ctx.stroke()
        // draw in legend
        ctx.fillStyle = 'rgba(0,0,0,1)'
        ctx.font = '16px Arial'
        ctx.fillText(`${currentCount}`, X_PADDING, legendY + 16)

        ctx.beginPath()
        ctx.arc(X_PADDING + 40, legendY + 10, 8, 0, 2 * Math.PI)
        ctx.fillStyle = color
        ctx.fill()
        ctx.fillStyle = 'rgba(0,0,0,1)'
        ctx.font = '16px Arial'
        ctx.fillText(title, X_PADDING + 60, legendY + 16)
        legendY += 20
        legendCount++
        resolve({
          canvas,
        })
      } catch (e) {
        console.error('Element could not be drawn on canvas', e)
      }
    } else {
      resolve({
        canvas,
      })
    }
  }

  const handleTileLayer = (layer, callback) => {
    const { canvas, ctx } = createCanvasAndContext()
    const bounds = map.getPixelBounds()
    const tileSize = layer.options.tileSize

    const tileBounds = L.bounds(
      bounds.min.divideBy(tileSize)._floor(),
      bounds.max.divideBy(tileSize)._floor(),
    )
    const tiles = []
    const tilePromices = []

    for (let j = tileBounds.min.y; j <= tileBounds.max.y; j++) {
      for (let i = tileBounds.min.x; i <= tileBounds.max.x; i++) {
        tiles.push(new L.Point(i, j))
      }
    }

    const tileQueueFinish = data => {
      data.forEach(drawTile)
      callback({ canvas })
    }

    const drawTile = d => {
      ctx.drawImage(
        d.img,
        Math.floor(d.pos.x),
        Math.floor(d.pos.y),
        d.size,
        d.size,
      )
    }

    const loadTile = (url, tilePos, tileSize, callback) => {
      const im = new Image()
      im.crossOrigin = ''
      im.onload = function () {
        callback({
          img: this,
          pos: tilePos,
          size: tileSize,
        })
      }
      im.onerror = function (e: Event) {
        // use canvas instead of errorTileUrl if errorTileUrl get 404
        // @ts-ignore
        if (layer.options.errorTileUrl != '' && e.target.errorCheck === undefined) {
          // @ts-ignore
          e.target.errorCheck = true
          // @ts-ignore
          e.target.src = layer.options.errorTileUrl
        } else {
          callback({
            img: dummycanvas,
            pos: tilePos,
            size: tileSize,
          })
        }
      }
      im.src = url
    }

    tiles.forEach(tilePoint => {
      const originalTilePoint = tilePoint.clone()

      if (layer._adjustTilePoint) {
        layer._adjustTilePoint(tilePoint)
      }

      const tilePos = originalTilePoint
        .scaleBy(new L.Point(tileSize, tileSize))
        .subtract(bounds.min)

      if (tilePoint.y >= 0) {
        const url = addCacheString(layer.getTileUrl(tilePoint))
        tilePromices.push(
          new Promise(resolve => {
            loadTile(url, tilePos, tileSize, resolve)
          }),
        )
      }
    })
    Promise.all(tilePromices).then(tileQueueFinish)
  }

  const addToPromiceArray = (item, type, ...args) => {
    promices.push(
      new Promise((resolve, reject) => {
        switch (type) {
          case OVERLAY_TYPE.MARKER:
          default:
            handleMarkerLayer(item, resolve, ...args)
            break
          case OVERLAY_TYPE.POLYGON:
            handlePolygon(item, resolve, ...args)
            break
          case OVERLAY_TYPE.TILE:
            handleTileLayer(item, resolve)
            break
        }
      }),
    )
  }

  const drawOverlays = (l): true => {
    if (l instanceof L.Marker && l.options.icon instanceof L.Icon) {
      if (isShowOnMap(l)) {
        const curCount = getCount()
        addToPromiceArray(l, OVERLAY_TYPE.MARKER, true, curCount)
      }
      return true
    }
    if (l instanceof L.Marker && l._childCount) {
      let curCount = null
      const childMarkers = l.getAllChildMarkers()
      const arrLength = childMarkers.length
      childMarkers.forEach((item, index) => {
        if (isShowOnMap(item)) {
          if (!curCount) curCount = getCount()
          addToPromiceArray(
            item,
            OVERLAY_TYPE.MARKER,
            index === arrLength - 1,
            curCount,
          )
        }
      })
      return true
    }
    if (l instanceof L.Polygon) {
      const color = randomColor({
        luminosity: 'bright',
        hue: 'random',
        format: 'rgb',
      })
      const curCount = getCount()
      addToPromiceArray(l, OVERLAY_TYPE.POLYGON, color, curCount)
      return true
    }
    if (l instanceof L.TileLayer) {
      addToPromiceArray(l, OVERLAY_TYPE.TILE)
    }
    return true
  }

  const getMarkers = () => {
    map.eachLayer(drawOverlays)
  }

  const handleClick = () => {
    const dimensions = getDimension()
    const canvas = document.createElement('canvas')
    canvas.width = dimensions.x
    canvas.height = dimensions.y
    getMarkers()
    layersDone([{ canvas }])
  }

  return (
    <span>
      <LeafletSnapshots
        handleClick={handleClick}
        toggleSnapshot={toggleSnapshot}
      />
    </span>
  )
}
