import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Menu, Select, Icon } from 'antd'
import { translate } from 'react-i18next'
import i18n from 'i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { actions as utilsActions } from 'redux/modules/utils'
import { logoItem, signOutItem } from './menuItems'
import { actions } from '../../redux/modules/user'

import './index.scss'

const { Option } = Select

const mapStateToProps = state => ({
  user: state.user,
})

const mapDispatchToProps = {
  handleExit: actions.logOut,
  changeLanguage: utilsActions.changeLanguage,
}

@translate()
@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class Header extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    handleExit: PropTypes.func.isRequired,
    changeLanguage: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location !== state.location) {
      return {
        isOpen: false,
        location: props.location,
      }
    }
    return {}
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      // check location to detect page change and close mobile menu
      location: props ? props.location : {},
    }
  }

  handleLangChange = value => {
    i18n.changeLanguage(value)
    this.props.changeLanguage({
      locale: value,
    })
  }

  handleExit = e => {
    e.preventDefault()
    this.props.handleExit()
  }

  handleToggleMenu = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }))
  }

  generateMenuItem = ({ link, label, target = null }) => {
    let linkKwargs = {};
    if (target !== null) {
      linkKwargs.target = target
    }
    return (
      <Menu.Item className="left-top-menu-item" key={`/${link}`}>
        <Link to={`/${link}`} {...linkKwargs}>{this.props.t(label)}</Link>
      </Menu.Item>
    )
  }

  // todo: move links to const
  renderProfileLink = isAuthenticated => {
    const array = []
    if (isAuthenticated) {
      array.push(
        this.generateMenuItem({ link: 'profile', label: 'nav.profile' }),
      )
      array.push(signOutItem(this.handleExit, this.props))
    } else {
      array.push(this.generateMenuItem({ link: 'login', label: 'nav.login' }))
      array.push(this.generateMenuItem({ link: 'signup', label: 'nav.signUp' }))
    }
    array.push(this.renderLanguageSelect())
    return array
  }
  // todo: move links to const
  renderMainLinks = (isAuthenticated, role, isConfirmed) => {
    const array = []
    array.push(logoItem)
    // array.push(
    //   this.generateMenuItem({ link: 'bolgar-sviyazhsk', label: 'nav.bolgar' }),
    // )
    array.push(
      this.generateMenuItem({ link: 'quick-search', label: 'nav.search' }),
    )
    if (isAuthenticated && isConfirmed) {
      array.push(
        this.generateMenuItem({
          link: 'advanced-search',
          label: 'nav.advanced-search',
        }),
      )
      array.push(this.generateMenuItem({ link: 'tools', label: 'nav.tools' }))
      if (role === 'ad') {
        array.push(
          this.generateMenuItem({
            link: 'organization/create',
            label: 'nav.createOrg',
          }),
        )
        array.push(
          this.generateMenuItem({
            link: 'fadmin/',
            label: 'Admin',
            target: '_blank',
          }),
        )
      }
      array.push(
        this.generateMenuItem({ link: 'data-entry', label: 'nav.importData' }),
      )
      array.push(this.generateMenuItem({ link: 'merge', label: 'nav.merge' }))
    }
    return array
  }

  renderLanguageSelect = () => (
    <Select
      key="lang"
      defaultValue={i18n.language}
      onChange={this.handleLangChange}
    >
      <Option value="ru" key="ru">
        Рус
      </Option>
      <Option value="en" key="en">
        Eng
      </Option>
      <Option value="tt" key="tt">
        Тат
      </Option>
      <Option value="be" key="be">
        Бел
      </Option>
      <Option value="cs" key="cs">
        Ces
      </Option>
      <Option value="de" key="de">
        Deu
      </Option>
      <Option value="hr" key="hr">
        Hrv
      </Option>
      <Option value="hu" key="hu">
        Hun
      </Option>
      <Option value="lt" key="lt">
        Lit
      </Option>
      <Option value="lv" key="lv">
        Lav
      </Option>
      <Option value="pl" key="pl">
        Pol
      </Option>
      <Option value="sr" key="sr">
        Scc
      </Option>
      <Option value="tr" key="tr">
        Tur
      </Option>
      <Option value="uk" key="uk">
        Ukr
      </Option>
    </Select>
  )

  render() {
    const {
      t,
      user: { isAuthenticated, role, accountConfirmed, emailConfirmed },
      location: { pathname },
    } = this.props
    const isConfirmed = accountConfirmed && emailConfirmed
    const { isOpen } = this.state
    const isMain = pathname === '/'
    const mainLinks = this.renderMainLinks(isAuthenticated, role, t)
    const profileLinks = this.renderProfileLink(
      isAuthenticated,
      role,
      isConfirmed,
    )
    return (
      <div
        className={`header-container dark${isOpen ? ' opened' : ''}${
          isMain ? ' is-main' : ''
        }`}
      >
        <div className="header-main-link">
          <Menu mode="horizontal">{logoItem}</Menu>
        </div>
        <Icon
          type={isOpen ? 'menu-unfold' : 'menu-fold'}
          onClick={this.handleToggleMenu}
        />

        <Menu
          className="center-submenu"
          selectedKeys={[pathname]}
          mode={isOpen ? 'vertical' : 'horizontal'}
        >
          {mainLinks}
        </Menu>
        <Menu
          className="right-submenu"
          selectedKeys={[pathname]}
          mode={isOpen ? 'vertical' : 'horizontal'}
        >
          {profileLinks}
        </Menu>
      </div>
    )
  }
}

export default Header
