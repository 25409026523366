import React from 'react';

import { LayerGroup } from 'react-leaflet';
import L from 'leaflet'
import 'leaflet.markercluster';
import LayerGroupComponent from 'components/Map/layerGroupComponent';
import { defineClusterIcon } from 'utility/leaflet-sub-group/createPieIcon';
import { lingLayers } from 'utility/constants';

import './index.css';

export default class MarkerClusterGroup extends LayerGroup {
  mcg = null;

  componentDidMount() {
    if (!this.props.needCluster) {
      return;
    }
    if (!this.mcg) {
      this.mcg = L.markerClusterGroup({
        iconCreateFunction: defineClusterIcon,
        maxClusterRadius: 25,
      });
    }
    const { map } = this.context;
    this.mcg.addTo(map);
  }

  render() {
    const {
      data,
      zoom,
      t,
      layersControl,
      needCluster,
    } = this.props;
    return (
      <div>
        {data.map((item) => (
          <LayerGroupComponent
            checked={false}
            layersControl={layersControl}
            layerName={t(`map_panel.overlay.${item.name}`)}
            layerNameValue={item.name}
            mcg={this.mcg}
            key={item.name}
            name={item.name}
            zoom={zoom}
            isHidden={item.isHidden}
            markers={item.markers}
            polygons={item.polygons}
            needCluster={(!lingLayers.includes(item.name)) && needCluster}
          />
        ))
        }
      </div>
    )
  }
}
