import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Select, Button, Input } from 'antd'
import i18n from 'i18next'

import { getOrganizationTypeList } from '../../../../redux/api/organization'
import OrganizationNameList from '../../NameList'
import { formItemLayout } from '../../../../utility/constants'

const FormItem = Form.Item
const { TextArea } = Input;
const { Option } = Select

class OrganizationCreateNew extends Component {
  static propTypes = {
    getFieldDecorator: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    initialValue: PropTypes.shape({
      type: PropTypes.string,
      local_name: PropTypes.string,
      ru_name: PropTypes.string,
      en_name: PropTypes.string,
      local_desc: PropTypes.string,
      ru_desc: PropTypes.string,
      en_desc: PropTypes.string,
      inn: PropTypes.number,
    }),
  }

  static defaultProps = {
    initialValue: {
      type: '',
      local_name: '',
      ru_name: '',
      en_name: '',
      local_desc: '',
      ru_desc: '',
      en_desc: '',
      inn: '',
    },
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.initialValue &&
      props.initialValue.ru_name &&
      props.initialValue.ru_name !== state.ru_name
    ) {
      console.log('changed')
      console.log(props.initialValue)
      return {
        type: props.initialValue.type == null ? '' : props.initialValue.type,
        local_name: props.initialValue.local_name || '',
        ru_name: props.initialValue.ru_name || '',
        en_name: props.initialValue.en_name || '',
        local_desc: props.initialValue.local_desc || '',
        ru_desc: props.initialValue.ru_desc || '',
        en_desc: props.initialValue.en_desc || '',
        inn: props.initialValue.inn || '',
      }
    }
    return {}
  }

  state = {
    organizationTypes: [],
    type: this.props.initialValue.type == null ? '' : this.props.initialValue.type,
    local_name: this.props.initialValue.local_name || '',
    ru_name: this.props.initialValue.ru_name || '',
    en_name: this.props.initialValue.en_name || '',
    local_desc: this.props.initialValue.local_desc || '',
    ru_desc: this.props.initialValue.ru_desc || '',
    en_desc: this.props.initialValue.en_desc || '',
    inn: this.props.initialValue.inn || '',
  }

  componentDidMount() {
    getOrganizationTypeList().then(({ response, error }) => {
      console.log(response)
      console.log(error)
      if (response) {
        this.setState({
          organizationTypes: response.results,
        })
      }
    })
  }

  getNameMap = () => this.nameListRef.current.getNameMap()

  nameListRef = React.createRef()

  handleChange = type => {
    this.setState({ type })
  }

  renderOrganizations = items => {
    return items.map(item => (
      <Option value={item.uid} key={item.uid}>
        {item[`${['ru','en'].includes(i18n.language) ? i18n.language: 'local'}_name`] || item.ru_name}
      </Option>
    ))
  }

  render() {
    const { t, getFieldDecorator } = this.props

    const {
      organizationTypes,
      local_name,
      ru_name,
      en_name,
      local_desc,
      ru_desc,
      en_desc,
      inn,
      type,
    } = this.state

    const config = {
      organization_type: {
        rules: [],
        initialValue: type,
      },
      type: {
        rules: [],
        initialValue: type,
      },
      inn: {
        rules: [],
        initialValue: inn,
      },
      local_name: {
        rules: [
          {
            required: true,
            message: t('organizationAdd.form.local_name.error'),
          },
        ],
        initialValue: local_name,
      },
      ru_name: {
        rules: [
          {
            required: true,
            message: t('organizationAdd.form.ru_name.error'),
          },
        ],
        initialValue: ru_name,
      },
      en_name: {
        rules: [
          {
            required: true,
            message: t('organizationAdd.form.en_name.error'),
          },
        ],
        initialValue: en_name,
      },
      ru_desc: {
        rules: [
          {
            required: true,
            message: t('organizationAdd.form.ru_desc.error'),
          },
        ],
        initialValue: ru_desc,
      },
      en_desc: {
        rules: [
          {
            required: true,
            message: t('organizationAdd.form.en_desc.error'),
          },
        ],
        initialValue: en_desc,
      },
      local_desc: {
        rules: [
          {
            required: true,
            message: t('organizationAdd.form.local_desc.error'),
          },
        ],
        initialValue: local_desc,
      },
    }

    return (
      <React.Fragment>
        <FormItem
          {...formItemLayout}
          label={t('organizationAdd.form.ru_name.title')}
        >
          <div
            title={ru_name}
          >
            {getFieldDecorator('ru_name', config.ru_name)(<Input />)}
          </div>
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('organizationAdd.form.en_name.title')}
        >
          <div
            title={en_name}
          >
            {getFieldDecorator('en_name', config.en_name)(<Input />)}
          </div>
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('organizationAdd.form.local_name.title')}
        >
          <div
            title={local_name}
          >
            {getFieldDecorator('local_name', config.local_name)(<Input />)}
          </div>
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('organizationAdd.form.ru_desc.title')}
        >
          {getFieldDecorator('ru_desc', config.ru_desc)(<TextArea rows={4} />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('organizationAdd.form.en_desc.title')}
        >
          {getFieldDecorator('en_desc', config.en_desc)(<TextArea rows={4} />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('organizationAdd.form.local_desc.title')}
        >
          {getFieldDecorator('local_desc', config.local_desc)(<TextArea rows={4} />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('organizationAdd.form.inn.title')}
        >
          {getFieldDecorator('inn', config.inn)(<Input />)}
        </FormItem>
        <OrganizationNameList {...this.props} ref={this.nameListRef} />
        <FormItem
          {...formItemLayout}
          label={t('organizationAdd.form.type.title')}
        >
          {getFieldDecorator('organization_type', config.organization_type)(
            <Select onChange={this.handleChange}>
              {this.renderOrganizations(organizationTypes)}
            </Select>
          )}
          {getFieldDecorator('type', config.type)(
            <input type="text" name="type" hidden />
          )}
        </FormItem>
      </React.Fragment>
    )
  }
}

export default OrganizationCreateNew
