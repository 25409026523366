import React, { useState } from 'react'
import { Button, Select, Spin } from 'antd'
import { downloadAdvSearch } from 'redux/api/advSearch'

const { Option } = Select

const EXTENSION = {
  csv: 'csv',
  json: 'json',
  geojson: 'json',
  'esri-json': 'json',
}

export const AdvancedSearchDownload = props => {
  const { t, children, parsed, esriAllowed } = props
  console.log('adv download', children, props)
  const [downloadType, setDownloadType] = useState('json')
  const [isLoading, setIsLoading] = useState(false)
  const download = () => {
    setIsLoading(true)
    downloadAdvSearch(parsed, downloadType).then(response => {
      const contentDisp = response.request.getResponseHeader(
        'Content-Disposition',
      )
      let filename

      if (contentDisp) {
        filename = contentDisp
          .split(';')
          .find(n => n.includes('filename='))
          .replace('filename=', '')
          .trim()
          .slice(1, -1)
      }
      // console.log('response', response, filename)
      if (response && response.data) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))

        if (filename) {
          link.download = filename
        } else {
          link.download = `advanced-search.${EXTENSION[downloadType]}`
        }

        document.body.appendChild(link)

        link.click()
        setIsLoading(false)

        document.body.removeChild(link)
      }
    })
  }

  return (
    <Spin spinning={isLoading}>
      <div className="adv-search-download">
        <Select
          placeholder={t('advanced.button.download_type')}
          onChange={setDownloadType}
          defaultValue="json"
        >
          {/* <Option value="xml">xml</Option> */}
          <Option value="csv">csv</Option>
          <Option value="json">json</Option>
          <Option value="geojson">geojson</Option>
          {esriAllowed && <Option value="esri-json">esri-json</Option>}
        </Select>
        <Button icon="download" type="primary" onClick={download}>
          {t('advanced.button.download')}
        </Button>
        {children}
      </div>
    </Spin>
  )
}

export default AdvancedSearchDownload
