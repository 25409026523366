import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Spin, Tag, Tooltip, Icon } from 'antd';
import fetch from 'isomorphic-fetch';
import debounce from 'lodash/debounce';
import i18n from 'i18next';
import { url } from 'utility/constants/index';
import { createTag } from 'redux/api/tags'

const { Option } = Select;
const FormItem = Form.Item;

const getLang = () => {
  const langs = ['ru', 'tat', 'en'];
  if (langs.includes(i18n.language)) {
    return `${['ru','en'].includes(i18n.language) ? i18n.language: 'local'}_name`
  }
  return 'ru_name';
}

const normalizeData = (data = []) =>
  data.map(({ response }) => ({
    label: response[getLang()],
    key: response.uid.toString(),
  }))

const getTagList = (data = []) =>
  data.map(({ response }) => (response.uid.toString()))

class ScienceInterestTagInput extends Component {
  static propTypes = {
    getFieldDecorator: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    inputName: PropTypes.string.isRequired,
    formItemLayout: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.array,
  };

  static defaultProps = {
    value: [],
  }

  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.saveInputRef = React.createRef();
    this.fetchTags = debounce(this.fetchTags, 400);
    this.state = {
      data: [],
      inputVisible: false,
      isLoading: false,
      fetching: false,
      selectedValue: normalizeData(props.value),
      tagsId: getTagList(props.value),
    };
  }

  handleClose = (key) => {
    const selectedValue = this.state.selectedValue.filter(tag => tag.key !== key);
    const tagsId = this.state.tagsId.filter(tag => tag !== key);
    this.setState({
      selectedValue,
      tagsId,
    });
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.saveInputRef.current.focus());
  }

  hideInput = () => {
    this.setState({ inputVisible: false })
  }

  fetchTags = (value) => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    fetch(`${url}/api/tags/list/?search=ru_name%3D${value}%7Cen_name%3D${value}%7Clocal_name%3D${value}`)
      .then(response => response.json())
      .then((d) => {
        if (fetchId !== this.lastFetchId) { // for fetch callback order
          return;
        }
        if (d.results) {
          const data = d.results
            .filter(tag => !this.state.tagsId.includes(tag.uid))
            .map(tag => ({
              label: tag[getLang()],
              value: tag.uid,
              tag,
            }));
          this.setState({ data, fetching: false });
        }
      });
  }

  postNewTag = (value) => {
    const body = {
      type: 0,
      [getLang()]: value,
    }
    createTag(body).then(({ response }) => {
      if (!response) {
        this.setState({
          isLoading: false,
          inputVisible: false,
        })
      } else {
        const { selectedValue, tagsId } = this.state;
        this.setState({
          selectedValue: [...selectedValue, {
            key: response.uid,
            label: response[getLang()],
          }],
          tagsId: [...tagsId, response.uid],
          isLoading: false,
          inputVisible: false,
        })
      }
    })
  }

  handleChange = (value) => {
    const filtered = [
      ...this.state.selectedValue,
      ...value.filter(item => item.key !== item.label),
    ]

    this.setState({
      data: [],
      fetching: false,
      inputVisible: value[0].key === value[0].label,
      tagsId: filtered.map(item => item.key),
      selectedValue: filtered,
    });
  }

  handleSelect = (value) => {
    if (value.key === value.label) {
      this.setState({
        isLoading: true,
      })
      this.postNewTag(value.key)
    }
  }

  renderOptions = (d, index) => (
    <Option key={d.value} index={index}>
      {d.label}
    </Option>
  )

  renderTags = (selectedValue) => {
    const result = [];
    selectedValue.forEach((tag) => {
      const { key, label } = tag;
      if (!label) return;
      const isLongTag = label && label.length > 20;
      const tagElem = (
        <Tag key={key} closable afterClose={() => this.handleClose(key)}>
          {isLongTag ? `${label.slice(0, 20)}...` : label}
        </Tag>
      );
      result.push(
        isLongTag ? <Tooltip title={label} key={key}>{tagElem}</Tooltip> : tagElem
      )
    })
    return result;
  }

  render() {
    const {
      getFieldDecorator,
      inputName,
      formItemLayout,
      title,
      t,
    } = this.props;
    const {
      selectedValue,
      inputVisible,
      data,
    } = this.state;
    const options = data.map(this.renderOptions);
    const tags = this.renderTags(selectedValue);

    return (

      <FormItem
        {...formItemLayout}
        label={title}
      >
        {tags}
        {inputVisible && (
          <Spin spinning={this.state.isLoading}>
            <Select
              ref={this.saveInputRef}
              onBlur={this.hideInput}
              labelInValue
              allowClear
              mode="tags"
              size="small"
              placeholder={this.props.placeholder}
              style={this.props.style}
              defaultActiveFirstOption={false}
              filterOption={false}
              value={[]}
              notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
              onSearch={this.fetchTags}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
            >
              {options}
            </Select>
          </Spin>
        )}
        {!inputVisible && (
          <Tag
            onClick={this.showInput}
            style={{ background: '#fff', borderStyle: 'dashed' }}
          >
            <Icon type="plus" /> {t('profile.form.tags.add')}
          </Tag>
        )}
        {getFieldDecorator(inputName, { rules: [], initialValue: this.state.tagsId })(<input
          type="text"
          name={inputName}
          hidden
        />)}
      </FormItem>
    );
  }
}

export default ScienceInterestTagInput;
