/**
 * @flow
 * @relayHash 0b9dcb56dcbcedf044443e97f41a32d8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessRoleEnum = "ADMIN" | "AUTHOR" | "CREATOR" | "MODERATOR" | "NOT_ACCESSED" | "PUBLIC_USER" | "SHARED_USER" | "%future added value";
export type AccessHocSelectionQueryVariables = {|
  subjectId: string
|};
export type AccessHocSelectionQueryResponse = {|
  +selection: ?{|
    +isRequestSend: ?boolean,
    +accessRole: ?AccessRoleEnum,
  |}
|};
export type AccessHocSelectionQuery = {|
  variables: AccessHocSelectionQueryVariables,
  response: AccessHocSelectionQueryResponse,
|};
*/


/*
query AccessHocSelectionQuery(
  $subjectId: ID!
) {
  selection(id: $subjectId) {
    isRequestSend
    accessRole
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "subjectId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subjectId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isRequestSend",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accessRole",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AccessHocSelectionQuery",
  "id": null,
  "text": "query AccessHocSelectionQuery(\n  $subjectId: ID!\n) {\n  selection(id: $subjectId) {\n    isRequestSend\n    accessRole\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AccessHocSelectionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "selection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccessHocSelectionQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "selection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '63af816828132becb722dc49782d069a';
module.exports = node;
