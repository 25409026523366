import { createAction, handleActions } from 'redux-actions';

const FIND_SELECTIONS = 'tools/FIND_SELECTIONS';
const SET_SELECTIONS = 'tools/SET_SELECTIONS';
const GET_UIDS = 'tools/GET_UIDS';
const SAVE_UIDS = 'tools/SAVE_UIDS';
const SEND_SELECTION = 'tools/SEND_SELECTION';
const SAVE_IMAGE = 'tools/SAVE_IMAGE';
const SAVE_REPORT = 'tools/SAVE_REPORT';
const GET_PARENTS = 'tools/GET_PARENTS';
const SAVE_PARENTS = 'tools/SAVE_PARENTS';
const GET_CHILDREN = 'tools/GET_CHILDREN';
const SAVE_CHILDREN = 'tools/SAVE_CHILDREN';
const GET_METHODS = 'tools/GET_METHODS';
const SAVE_METHODS = 'tools/SAVE_METHODS';
const CLEAR_STATE = 'tools/CLEAR_STATE';

export const constants = {
  FIND_SELECTIONS,
  SET_SELECTIONS,
  GET_UIDS,
  SAVE_UIDS,
  SEND_SELECTION,
  SAVE_IMAGE,
  SAVE_REPORT,
  GET_PARENTS,
  SAVE_PARENTS,
  GET_CHILDREN,
  SAVE_CHILDREN,
  GET_METHODS,
  SAVE_METHODS,
  CLEAR_STATE,
};

// ------------------------------------
// Actions
// ------------------------------------
export const findSelections = createAction(FIND_SELECTIONS);
export const setSelections = createAction(SET_SELECTIONS);
export const getUids = createAction(GET_UIDS);
export const saveUids = createAction(SAVE_UIDS);
export const sendSelection = createAction(SEND_SELECTION);
export const saveImage = createAction(SAVE_IMAGE);
export const saveReport = createAction(SAVE_REPORT);
export const getParents = createAction(GET_PARENTS);
export const saveParents = createAction(SAVE_PARENTS);
export const getChildren = createAction(GET_CHILDREN);
export const saveChildren = createAction(SAVE_CHILDREN);
export const getMethods = createAction(GET_METHODS);
export const saveMethods = createAction(SAVE_METHODS);
export const clearState = createAction(CLEAR_STATE);

export const actions = {
  findSelections,
  setSelections,
  getUids,
  saveUids,
  sendSelection,
  saveImage,
  saveReport,
  getParents,
  saveParents,
  getChildren,
  saveChildren,
  getMethods,
  saveMethods,
  clearState,
};

export const initialState = () => {
  return {
    parameters: {
      parents: [],
      children: [],
      methods: [],
    },
    selections: {
      count: 0,
      data: [],
    },
    spatials: {
      isLoading: true,
      data: [],
    },
    subjects: {
      isLoading: false,
      error: false,
      count: 0,
      data: [],
      model: null,
      uid: null,
      returnedFields: [],
    },
    doneImage: null,
    report: null,
  };
};

export const reducers = {
  [SET_SELECTIONS]: (state, { payload }) =>
    ({
      ...state,
      selections: {
        count: payload.length,
        data: payload,
      },
      parameters: {
        ...state.parameters,
        children: [],
        methods: [],
      },
    }),
  [SAVE_UIDS]: (state, { payload }) =>
    ({
      ...state,
      spatials: {
        isLoading: false,
        data: [payload.spatials],
      },
      subjects: {
        isLoading: false,
        data: payload.subjects.response,
        model: payload.model,
        uid: payload.uid,
        count: payload.count,
        returnedFields: payload.returnedFields,
      },
      doneImage: null,
      report: null,
    }),
  [SAVE_IMAGE]: (state, { payload }) =>
    ({
      ...state,
      doneImage: payload,
    }),
  [SAVE_REPORT]: (state, { payload }) =>
    ({
      ...state,
      report: payload,
    }),
  [SAVE_PARENTS]: (state, { payload }) =>
    ({
      ...state,
      parameters: {
        ...state.parameters,
        parents: payload,
      },
    }),
  [SAVE_CHILDREN]: (state, { payload }) =>
    ({
      ...state,
      parameters: {
        ...state.parameters,
        children: payload,
      },
    }),
  [SAVE_METHODS]: (state, { payload }) =>
    ({
      ...state,
      parameters: {
        ...state.parameters,
        methods: payload,
      },
    }),
  [CLEAR_STATE]: (state, { payload }) =>
    ({
      ...initialState(),
    }),
};

export default handleActions(reducers, initialState());
