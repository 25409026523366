import React, { Component } from 'react';
import { Spin } from 'antd';
import { getSiteLayers } from 'redux/api/site';
import { transformData } from 'utility/transformData'

import TabList from '../TabContent/ListTab';
import CollapseTab from '../TabContent/CollapseTab';

const tabs = {
  list: TabList,
  collapse: CollapseTab,
};

function TabComponent(props) {
  console.log('TabComponent props', props);
  const AuthorComp = tabs[props.type];

  return <AuthorComp content={props.content} subjectType={props.subjectType} />
}

class AuthorTabComponent extends Component {
  state = {
    isLoading: true,
    data: [],
  }
  componentDidMount() {
    console.log('author tab component mount');
    const { tab, author } = this.props;
    console.log(tab);
    console.log(author);
    if (!tab.content) {
      if (tab.loader) {
        if (tab.variable) {
          tab.loader(author.uid, tab.variable).then(this.handleResponse)
        } else {
          tab.loader(author.uid).then(this.handleResponse)
        }
      } else {
        const tabItem =
          { ...author, mainTabType: 'author', label: 'author' };
        this.setState({
          isLoading: false,
          data: transformData(tabItem),
        })
      }
    }
  }

  handleResponse = ({ response, error }) => {
    const { tab, author } = this.props;
    if (response) {
      const tabItem =
        { ...response, mainTabType: 'author', label: this.props.tab.name };
      if (tab.name === 'site') {
        tab.loader(author.uid, 'Layer').then(({ response: layerResp, error: layerErr }) => {
          if (layerResp) {
            console.log(response);
            const layers = response.data.map(site => (
              getSiteLayers(site.uid)
            ))
            Promise.all(layers).then(results => {
              console.log(results);
              tabItem.layers = results
              this.setState({
                isLoading: false,
                data: transformData(tabItem),
              })
            });
          }
        })
      } else {
        this.setState({
          isLoading: false,
          data: transformData(tabItem),
        })
      }
    }
  }

  render() {
    const { data, isLoading } = this.state;
    return (
      <div>
        {
          isLoading ?
            <Spin size="large" />
            :
            <TabComponent
              content={data.content}
              type={data.contentType}
              subjectType={data.subjectType}
            />
        }
      </div>
    );
  }
}

AuthorTabComponent.propTypes = {};

export default AuthorTabComponent;
