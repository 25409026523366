/**
 * @flow
 * @relayHash bb6d969146a99def1cad8a0a17d7b1c0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessObjectEnum = "PRIVATE" | "PUBLIC" | "SHARED" | "%future added value";
export type ModalPermissionQueryVariables = {|
  researchId: string
|};
export type ModalPermissionQueryResponse = {|
  +archaeologyResearch: ?{|
    +uid: ?string,
    +permission: ?AccessObjectEnum,
    +sharedUsers: ?$ReadOnlyArray<?{|
      +uid: ?string,
      +publicName: ?string,
    |}>,
  |}
|};
export type ModalPermissionQuery = {|
  variables: ModalPermissionQueryVariables,
  response: ModalPermissionQueryResponse,
|};
*/


/*
query ModalPermissionQuery(
  $researchId: ID!
) {
  archaeologyResearch(id: $researchId) {
    uid
    permission
    sharedUsers {
      uid
      publicName
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "researchId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "researchId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permission",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ModalPermissionQuery",
  "id": null,
  "text": "query ModalPermissionQuery(\n  $researchId: ID!\n) {\n  archaeologyResearch(id: $researchId) {\n    uid\n    permission\n    sharedUsers {\n      uid\n      publicName\n      id\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ModalPermissionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "archaeologyResearch",
        "storageKey": null,
        "args": v1,
        "concreteType": "ArchaeologyResearchNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "sharedUsers",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorNode",
            "plural": true,
            "selections": [
              v2,
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ModalPermissionQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "archaeologyResearch",
        "storageKey": null,
        "args": v1,
        "concreteType": "ArchaeologyResearchNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "sharedUsers",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorNode",
            "plural": true,
            "selections": [
              v2,
              v4,
              v5
            ]
          },
          v5
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '745627037d4fe76d020f349cfb1dfc4b';
module.exports = node;
