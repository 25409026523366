import React, { Component } from 'react'
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd'
import { listQueries } from 'components/CreatePanel/queries/getList'
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries'
import { connect } from 'react-redux'
import { actions } from 'redux/modules/create'

import i18n from 'i18next'
import config from '../config'
import AutoInputGQL from '../../fields/autoInputGQL'
import AddBookModal from '../newBookModal'

const FormItem = Form.Item

const mapStateToProps = state => ({
  createData: state.create,
})

const mapDispatchToProps = {
  createBook: actions.createBook,
  createBookSeries: actions.createBookSeries,
  newBookSelected: actions.newBookSelected,
  newBookSeriesSelected: actions.newBookSeriesSelected,
}

@connect(mapStateToProps, mapDispatchToProps)
class CreateBookSectionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newBookModal: false,
      newBookSeriesModal: false,
      newPublisher: false,
      newPublisherName: '',
      addRuName: false,
      addLocName: false,
    }
  }

  handleChangeBookSeriesSelector = data => {
    console.log('handleChangeBookSeriesSelector', data)
    if (data.value === 'new') {
      this.setState({ newBookSeriesModal: true })
    } else {
      this.setState({ newBookSeriesModal: false })
    }
  }

  hideNewBookSeriesModal = e => {
    this.setState({ newBookSeriesModal: false })
  }

  handleChangeBookSelector = data => {
    console.log('handleChangeBookSelector', data)
    if (data.value === 'new') {
      this.setState({ newBookModal: true })
    } else {
      this.setState({ newBookModal: false })
    }
  }

  hideNewBookModal = e => {
    this.setState({ newBookModal: false })
    if (this.props.form.getFieldValue('book_select') === 'new') {
      this.props.form.setFieldsValue({
        book_select: null,
      })
    }
  }

  handleBookChange = data => {
    this.props.form.setFieldsValue({ book: data.uid })
  }

  handleSelectPublisher = data => {
    if (data.value === 'new') {
      this.setState({ newPublisher: true, newPublisherName: data.typed })
    } else {
      this.setState({ newPublisher: false })
    }
  }

  render() {
    const {
      getFieldDecorator,
      formItemLayout,
      createBook,
      createBookSeries,
      newBookSelected,
      newBookSeriesSelected,
      createData,
      newName,
      t,
    } = this.props

    const formButton = {
      wrapperCol: { offset: 8, span: 10 },
    }

    if (createData.newBook) {
      this.props.form.setFieldsValue({
        book_select:
          createData.data.book[
            `${
              ['ru', 'en'].includes(i18n.language) ? i18n.language : 'local'
            }_name`
          ],
        book: createData.data.book.uid,
      })
      newBookSelected()
    }

    const authorId = createData.data.author ? createData.data.author.uid : null

    return (
      <div>
        <FormItem
          label={t('create_panel.modal.addNewReport.en_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('en_name', {
            ...config.en_name,
            initialValue: newName,
          })(<Input />)}
        </FormItem>
        {!this.state.addRuName && (
          <FormItem {...formButton}>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ addRuName: true })
              }}
            >
              {t('create_panel.tab.research.add_ru_name')}
            </Button>
          </FormItem>
        )}
        {this.state.addRuName && (
          <FormItem
            label={t('create_panel.modal.addNewReport.ru_name.title')}
            {...formItemLayout}
          >
            {getFieldDecorator('ru_name')(<Input />)}
          </FormItem>
        )}
        {!this.state.addLocName && (
          <FormItem {...formButton}>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ addLocName: true })
              }}
            >
              {t('create_panel.tab.research.add_loc_name')}
            </Button>
          </FormItem>
        )}
        {this.state.addLocName && (
          <FormItem
            label={t('create_panel.modal.addNewReport.loc_name.title')}
            {...formItemLayout}
          >
            {getFieldDecorator('local_name')(<Input />)}
          </FormItem>
        )}
        <FormItem
          label={t('create_panel.modal.addNewReport.doi.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('doi')(<Input />)}
        </FormItem>
        <AutoInputGQL
          handleSelect={this.handleChangeBookSelector}
          newObjChanged={this.handleBookChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="book_select"
          label={t('create_panel.modal.addNewReport.book.title')}
          addOptionText={t('create_panel.modal.addNewReport.create.book')}
          rules={config.book}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.bookSearch()}
          t={t}
        />
        <FormItem
          {...formItemLayout}
          label={t('create_panel.modal.addNewReport.pages_book.title')}
        >
          {getFieldDecorator(
            'page_start',
            {},
          )(
            <InputNumber
              placeholder={t('common.date_since')}
              min={1}
              max={10000}
            />,
          )}
          {getFieldDecorator(
            'page_end',
            {},
          )(
            <InputNumber
              placeholder={t('common.date_to')}
              min={1}
              max={10000}
            />,
          )}
        </FormItem>
        <FormItem>{getFieldDecorator('book', {})(<Input hidden />)}</FormItem>
        <AddBookModal
          visible={this.state.newBookModal}
          hide={this.hideNewBookModal}
          handleSubmit={createBook}
          seriesVisible={this.state.newBookSeriesModal}
          seriesHide={this.hideNewBookSeriesModal}
          seriesHandleSubmit={createBookSeries}
          seriesHandleChangeSelector={this.handleChangeBookSeriesSelector}
          handleSelectPublisher={this.handleSelectPublisher}
          newPublisher={this.state.newPublisher}
          newPublisherName={this.state.newPublisherName}
          newSeriesSelected={newBookSeriesSelected}
          loading={createData.isLoading}
          createData={createData}
          authorId={authorId}
          t={t}
        />
      </div>
    )
  }
}

export default CreateBookSectionForm
