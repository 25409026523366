const researchQueries = {
  Lexeme: {
    q: `query postQuery($uid: String) {
      linguisticResearches (uid_Exact: $uid) {
        edges {
          node {
            lexeme {
              edges {
                node {
                  name
                  uid
                  attributes {
                    edges {
                      node {
                        label
                        ru_name: ruName
                      }
                    }
                  }
                  research {
                    edges {
                      node {
                        edgeSubject {
                          etimDescription
                        }
                      }
                    }
                  }
                  variant {
                    edges {
                      node {
                        names
                        uid
                        sense
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,
    dataPath: [
      'linguisticResearches',
      'lexeme',
    ],
  },
};

export default researchQueries
