import React from 'react'
import { EPOCH_COLOR } from 'utility/points'
import { ICON_SIZE } from './constants'
import { default as LayerIcon } from './Layer'
import { default as LocalityIcon } from './Locality'
import { default as MonumentIcon } from './Monument'
import { default as ProjectIcon } from './Project'
import { default as SiteIcon } from './Site'
import { default as EpochIcon } from './Epoch'
import { default as RadiocarbonIcon } from './Radiocarbon'
import { default as HeritageIcon } from './Heritage'
import { default as ExcavationIcon } from './Excavation'
import { default as DistrictIcon } from './District'
import { default as CultureIcon } from './Culture'
import { default as AssemblageIcon } from './Assemblage'
import { default as ArtifactIcon } from './Artifact'
import { default as GeophysicIcon } from './Geophysic'
import { default as PersonIcon } from './Person'
import { default as LexemeIcon } from './Lexeme'

interface MapIconProps {
  icon?: string
  color?: string
  epoch?: string
  size?: number
  type?: number
  subType?: number
}

const ICON_TYPE = {
  layer: LayerIcon,
  person: PersonIcon,
  lexeme: LexemeIcon,
  locality: LocalityIcon,
  monument: MonumentIcon,
  project: ProjectIcon,
  site: SiteIcon,
  epoch: EpochIcon,
  radiocarbon: RadiocarbonIcon,
  heritage: HeritageIcon,
  excavation: ExcavationIcon,
  district: DistrictIcon,
  culture: CultureIcon,
  assemblage: AssemblageIcon,
  artifact: ArtifactIcon,
  geophysic: GeophysicIcon,
}
const DEFAULT_ICON = 'epoch'
const DEFAULT_TYPE = 'default'

const MapIcon = (props: MapIconProps) => {
  const {
    icon,
    color: pColor,
    epoch,
    type,
    subType,
    size = ICON_SIZE.small,
  } = props
  // return icon with defined color or epoch.color
  const color = pColor || EPOCH_COLOR[epoch] || EPOCH_COLOR[0]
  const normalizedIcon = icon ? icon.toLowerCase() : ''
  const iconType = ICON_TYPE[normalizedIcon] || ICON_TYPE[DEFAULT_ICON]
  const resultIcon = iconType[type] || iconType[DEFAULT_TYPE]
  const iconStr = resultIcon(color, size, subType)
  const src = `data:image/svg+xml,${encodeURIComponent(iconStr)}`
  return <img src={src} alt="icon" />
}

export const getMapIconSrc = (props: MapIconProps) => {
  const {
    icon,
    color: pColor,
    epoch,
    type,
    subType,
    size = ICON_SIZE.small,
  } = props
  // return icon with defined color or epoch.color
  const color = pColor || EPOCH_COLOR[epoch] || EPOCH_COLOR[0]
  const normalizedIcon = icon ? icon.toLowerCase() : ''
  const iconType = ICON_TYPE[normalizedIcon] || ICON_TYPE[DEFAULT_ICON]
  const resultIcon = iconType[type] || iconType[DEFAULT_TYPE]
  const iconStr = resultIcon(color, size, subType)
  const src = `data:image/svg+xml,${encodeURIComponent(iconStr)}`
  return src
}

export default MapIcon
