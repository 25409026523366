import React, { Component } from 'react'
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd'
import encQueries from 'components/GSearchPanel/Queries/encQueries'
import CommonSelect from 'components/GSearchPanel/components/commonSelect'
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries'
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters'

import '../../index.scss'
import AutoInputGQL from '../fields/autoInputGQL'
import FileUpload from '../fields/fileUpload'
import CoordinatesInput from '../fields/coordinatesInput'
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions'

const FormItem = Form.Item
const { Panel } = Collapse
const { Option } = Select
const { TextArea } = Input

class EncorganizationTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
    }
  }

  addTab = () => {
    const key = this.newTabIndex++
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state
    newName[key] = data.typed
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = input => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      filelistChanged,
      getFieldDecorator,
      formInputLayout,
      stateTabs,
      subjects,
      createData,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props

    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel
              header={`${t(
                'create_panel.tab.encorganization.encorganization',
              )} #${1 + key}`}
              key={title + item.key}
            >
              <AutoInputGQL
                handleSelect={data => {
                  this.handleSelect(data, key)
                }}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.encorganization.choose')}
                selectkey={`encorganization_select_${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.encorganization.add')}
                query={encQueries.encOrgsSearch()}
                params={[{ key: 'search', val: 'value' }]}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel="EncOrganization"
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') && (
                <div>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.encorganization.name')}
                  >
                    {getFieldDecorator(`encorganization_name_${key}`, {
                      ...config.req,
                      initialValue: this.state.newName[key],
                    })(
                      <Input
                        onChange={this.handleChangeName}
                        data-objkey={key}
                      />,
                    )}
                  </FormItem>
                  <CoordinatesInput
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={title}
                    subjname="encorganization"
                    objkey={key}
                    handleAddMarker={handleAddMarker}
                    interactiveMarkers={interactiveMarkers}
                    form={form}
                    t={t}
                  />
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.encorganization.year')}
                  >
                    {getFieldDecorator(
                      `encorganization_year_${key}`,
                      {},
                    )(
                      <div>
                        <InputNumber
                          min={1000}
                          max={new Date().getFullYear()}
                        />
                      </div>,
                    )}
                  </FormItem>
                  <CommonSelect
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    inputName={`encorganization_type_${key}`}
                    label={t('create_panel.tab.encorganization.type')}
                    selectQuery={attributeQueries.defaultSearch(
                      'encorganizationtypes',
                      false,
                    )}
                    dontNeedEmptyOption="true"
                    t={t}
                  />
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.encorganization.url')}
                  >
                    {getFieldDecorator(
                      `encorganization_url_${key}`,
                      {},
                    )(<Input />)}
                  </FormItem>
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="EncOrganization"
                    rightLabel="Locality"
                    connectionField="locality"
                    label={t('common.subjects.locality')}
                    selectkey={`encOrganization_locality_${key}`}
                    selectType="localities"
                    stateTabs={stateTabs}
                    objkey={key}
                    noAddOption
                    query={encQueries.localitySelectSearch()}
                    params={[{ key: 'commonSearch', val: 'value' }]}
                    t={t}
                  />
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.encorganization.desc')}
                  >
                    {getFieldDecorator(
                      `encorganization_desc_${key}`,
                      {},
                    )(
                      <TextArea
                        onPaste={e =>
                          textFieldFormatters.onTextAreaPaste(
                            e,
                            form,
                            `encorganization_desc_${key}`,
                          )
                        }
                      />,
                    )}
                  </FormItem>
                  <FileUpload
                    getFieldDecorator={getFieldDecorator}
                    formInputLayout={formInputLayout}
                    filelistChanged={filelistChanged}
                    fileLists={createData.sourceFilelist}
                    label={`encorganization_files_${key}`}
                    fileType="photo"
                    title={t('common.photo.title')}
                    t={t}
                  />
                  <RelationComponent
                    handleAddExtraRelation={handleAddExtraRelation}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    objkey={key}
                    title="EncOrganization"
                    tabName="encorganizations"
                    stateTabs={stateTabs}
                    form={form}
                    t={t}
                  />
                </div>
              )}
            </Panel>
          ))}
        </Collapse>

        <FormItem wrapperCol={{ span: 8, offset: 8 }}>
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.encorganization.add_button')}
          </Button>
        </FormItem>
      </div>
    )
  }
}

export default EncorganizationTab
