import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  getResearches,
  addProperty,
} from 'utility/transformData/constants';

export default function getBookSectionFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const result = [
        createCommonField('research', getResearches(d.research)),
      ];
      if (d.book) {
        result.push(
          createLinkField('book', d.book.uid, 'source/Book',
            getLangTitle(d.book))
        )
      }
      addProperty('pages', d, result);
      addProperty('doi', d, result);
      return result;
    }
    case TAB_TYPE.COLLAPSE: {
      return d.results.map((item) => {
        const itemObj = {}
        itemObj.content = [
          createLinkField('name', item.uid, `source/${item.label}`,
            getLangTitle(item)),
          createCommonField('pages', item.pages),
        ]
        addProperty('doi', item, itemObj.content);
        itemObj.name = getLangTitle(item)
        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
