import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
} from 'utility/transformData/constants';

export default function getAssemblageFields(d, tabType) {
  console.info('assemblage transf', d);
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      const content = [
        createCommonField('name', getLangTitle(item)),
      ]
      return content
    }
    case TAB_TYPE.COLLAPSE: {
      return d.results.map((item, index) => {
        const itemObj = {}
        const artName = getLangTitle(item)
        itemObj.content = [
          createLinkField('name', item.uid, 'subjects/Assemblage',
            getLangTitle(item)),
        ]
        itemObj.name = artName || '-'
        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
