import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Form, Col, DatePicker, Button, Icon, Checkbox } from 'antd';
import moment from 'moment';
import i18n from 'i18next';
import { formItemLayout } from 'utility/constants'

import FetchInputSelect from '../../FetchInputSelect';

const { MonthPicker } = DatePicker;
const dateFormat = 'DD.MM.YYYY';
const monthFormat = 'YYYY/MM';
const FormItem = Form.Item;

const disabledDate = (current) => {
  // Can not select days after today
  return current && current > moment();
}

class OrganizationList extends Component {
  static propTypes = {
    getFieldDecorator: PropTypes.func.isRequired,
    initialValue: PropTypes.array,
    organizations: PropTypes.object,
    handleOrganizationCountChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialValue: [],
    organizations: new Map(),
  }

  static getDerivedStateFromProps(props, state) {
    if (props.initialValue.length !== state.data.length &&
      props.initialValue.length > 0 && state.data.length === 1) {
      console.log('changed');
      console.log(props.initialValue);
      return {
        data: props.initialValue,
      }
    }
    return {}
  }

  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      data: props.initialValue.length !== 0 ? props.initialValue : [{}],
      checkboxList: props.initialValue.length !== 0 ?
        this.getCheckboxArray(props.initialValue) : [],
    };
  }

  onCheckboxChange = (e, index) => {
    const { checkboxList } = this.state;
    checkboxList[index] = e.target.checked;
    this.setState({ checkboxList });
  }

  getLang = () => {
    const langs = ['ru', 'tat', 'en'];
    if (langs.includes(i18n.language)) {
      return `${['ru','en'].includes(i18n.language) ? i18n.language: 'local'}_name`
    }
    return 'ru_name';
  }

  getCheckboxArray = (organizations) => organizations.map(item => !item.to);

  handleAddButton = () => {
    const { data } = this.state;
    data.push({});
    this.setState({
      data,
    });
    this.props.handleOrganizationCountChange(data.length);
  };

  handleRemoveItem = (index) => {
    const { data } = this.state;
    data.splice(index, 1);
    this.setState({
      data,
    });
    this.props.handleOrganizationCountChange(data.length);
  };

  render() {
    const { data, checkboxList } = this.state;
    const { getFieldDecorator, t, organizations } = this.props;
    console.log(organizations);
    return (
      <React.Fragment>
        <List
          dataSource={data}
          itemLayout="vertical"
          locale={{ emptyText: t('profile.form.organizations.noData') }}
          renderItem={(item, index) => (
            <List.Item className="organization-list-item">
              <FetchInputSelect
                title={t('signUp.form.organizations.title')}
                formItemLayout={formItemLayout}
                inputName={`organization_input-${index}`}
                path="api/organization/list"
                style={{ width: '100%' }}
                getFieldDecorator={getFieldDecorator}
                value={{
                  text: organizations.get(item.uid) ? organizations.get(item.uid)[this.getLang()] : '',
                  key: item.uid,
                }}
              />
              <FormItem
                label={t('signUp.form.organizations.workDate')}
                {...formItemLayout}
              >
                <Col span={11}>
                  <FormItem>
                    {getFieldDecorator(`organization_date_from-${index}`, {
                      rules: [],
                      initialValue: item.since ?
                        moment(moment(item.since, dateFormat).format(monthFormat), monthFormat)
                        : null,
                    })(<MonthPicker
                      disabledDate={disabledDate}
                      format={monthFormat}
                    />)}
                  </FormItem>
                </Col>

                {
                  !checkboxList[index] && [
                    <Col span={2} key="divider">
                      <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
                        -
                        </span>
                    </Col>,
                    <Col span={11} key="to_date">
                      <FormItem>
                        {getFieldDecorator(`organization_date_to-${index}`, {
                          rules: [],
                          initialValue: item.to ?
                            moment(moment(item.to, dateFormat).format(monthFormat), monthFormat)
                            : null,
                        })(<MonthPicker
                          disabledDate={disabledDate}
                          format={monthFormat}
                        />)}
                      </FormItem>
                    </Col>,
                  ]
                }
              </FormItem>
              <FormItem>
                <Checkbox
                  checked={checkboxList[index]}
                  onChange={(e) => this.onCheckboxChange(e, index)}
                >
                  {t('signUp.form.organizations.tillNow')}
                </Checkbox>
              </FormItem>
              <Icon type="close" onClick={() => this.handleRemoveItem(index)} />
            </List.Item>
          )}
        />
        <Button
          type="default"
          className="organization-add-button"
          onClick={this.handleAddButton}
        >
          {t('signUp.form.organizations.addButton')}
        </Button>
      </React.Fragment>
    );
  }
}

export default OrganizationList;
