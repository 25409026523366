/* eslint-disable no-unneeded-ternary */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

import { formItemLayout as formItemLayoutConst } from '../../utility/constants'

const FormItem = Form.Item;

class Initials extends Component {
  static propTypes = {
    formItemLayout: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    last_name: '',
    first_name: '',
    parent_name: '',
    public_name: '',
  };

  handleChange = (e, name) => {
    if (name === 'public_name') {
      this.setState({ [name]: e.target.value });
    } else {
      const currentState = this.state;
      currentState[name] = e.target.value;
      const publicName = `${currentState.last_name} ${currentState.first_name.substring(0, 1)}.${currentState.parent_name.substring(0, 1)}.`;
      console.log(publicName);
      this.setState({
        [name]: e.target.value,
        public_name: publicName,
      })
      this.props.form.setFields({
        public_name: {
          value: publicName,
        },
      });
    }
  };

  render() {
    const {
      getFieldDecorator,
      disablePublicName,
      labels,
      t,
    } = this.props;

    const firstNameRequired = (this.props.dontNeedFirstName) ? false : true;

    const formItemLayout = this.props.formItemLayout || formItemLayoutConst;

    const config = {
      first_name: {
        rules: [{
          required: firstNameRequired, message: labels ? labels.first_name.error : t('signUp.form.first_name.error'),
        }],
        initialValue: this.state.first_name,
      },
      last_name: {
        rules: [{
          required: true, message: labels ? labels.last_name.error : t('signUp.form.last_name.error'),
        }],
        initialValue: this.state.last_name,
      },
      public_name: {
        rules: [{
          required: !disablePublicName, message: labels ? labels.public_name.error : t('signUp.form.public_name.error'),
        }],
        initialValue: this.state.public_name,
      },
      parent_name: {
        rules: [],
        initialValue: this.state.parent_name,
      },
    };

    return (
      <Fragment>
        <FormItem
          label={t('signUp.form.last_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('last_name', config.last_name
          )(<Input onChange={(e) => this.handleChange(e, 'last_name')} />)}
        </FormItem>
        <FormItem
          label={t('signUp.form.first_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('first_name', config.first_name
          )(<Input onChange={(e) => this.handleChange(e, 'first_name')} />)}
        </FormItem>
        <FormItem
          label={t('signUp.form.parent_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('parent_name', config.parent_name
          )(<Input onChange={(e) => this.handleChange(e, 'parent_name')} />)}
        </FormItem>
        <FormItem
          label={t('signUp.form.public_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('public_en_name', config.public_name,
          )(<Input
            placeholder={t('signUp.form.public_name.placeholder')}
            onChange={(e) => this.handleChange(e, 'public_name')}
            disabled={disablePublicName}
          />)}
        </FormItem>
        <FormItem
          label={t('signUp.form.public_name_en.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('public_name', config.public_name,
          )(<Input
            placeholder={t('signUp.form.public_name.placeholder')}
            onChange={(e) => this.handleChange(e, 'public_name')}
            disabled={disablePublicName}
          />)}
        </FormItem>
      </Fragment>
    );
  }
}

export default Initials;
