export const PERMISSION = {
  public: 'PUBLIC',
  private: 'PRIVATE',
  shared: 'SHARED',
};

export const PERMISSION_COLORS = {
  0: 'green',
  1: 'red',
  2: 'gold',
  PUBLIC: 'green',
  PRIVATE: 'red',
  SHARED: 'gold',
}
