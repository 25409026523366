import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';

import sagas from './sagas';
import rootReducer from './rootReducers';
import thunk from "redux-thunk"
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

export default function configureStore(initialState, history) {
  const sagaMiddleware = createSagaMiddleware();

  const middleware = applyMiddleware(sagaMiddleware, routerMiddleware(history));

  const composedStoreEnhancer = composeEnhancers(
    middleware,
    applyMiddleware(thunk),
  )

  const store = createStore(rootReducer, initialState, composedStoreEnhancer)

  sagaMiddleware.run(sagas);

  return store;
}
