import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Spin } from 'antd';

import './index.css';

const Line = React.lazy(() => import('distLibs/Chart'))

@translate()
class WhenChart extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.data = (canvas) => {
      const ctx = canvas.getContext('2d')
      const { t } = props
      const gradient = ctx.createLinearGradient(500, 0, 500, 300);
      gradient.addColorStop(0, '#e0c592');
      gradient.addColorStop(1, '#ffffff');
      const chartData = this.getChartData(this.props.chartData)
      return {
        labels: chartData.labels,
        datasets: [
          {
            label: t('when.objects'),
            backgroundColor: gradient,
            borderColor: '#e0c592',
            borderWidth: 4,
            data: chartData.datasets,
          },
        ],
      }
    };
    this.options = () => {
      return {
      }
    }
  }

  getChartData = (data) => {
    const ret = { labels: [], datasets: [] }
    const tmp = [];
    Object.keys(data).forEach(key => {
      tmp.push([parseInt(key.split(':')[0], 10), data[key]])
    })
    tmp.sort((a, b) => a[0] - b[0])
    tmp.forEach(d => {
      ret.labels.push(d[0])
      ret.datasets.push(d[1])
    })
    return ret
  }

  render() {
    const { t } = this.props;
    return (
      <div className="when-chart">
        <Suspense fallback={<Spin />}>
          <Line data={this.data} options={this.options()} />
        </Suspense>
      </div>
    )
  }
}

export default WhenChart;
