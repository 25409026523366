import React, { useState } from 'react'
import { Radio } from 'antd'
import { translate } from 'react-i18next'

import './group.scss'

const RadioButton = Radio.Button
const RadioGroup = Radio.Group

interface GroupProps {
  handleChange: (value: string) => void
  keys: string[] | undefined
  t: any
}

const iT = 'dataPanel.groups'

const Group = (props: GroupProps) => {
  const { handleChange, keys, t } = props
  const [key, setKey] = useState('default')

  const handleKeyChange = (value: string): void => {
    setKey(value)
    handleChange(value)
  }

  return (
    <div className="show-group-by">
      <label className="show-group-by">{t('dataPanel.groups.label')}</label>
      <RadioGroup
        onChange={e => handleKeyChange(e.target.value)}
        defaultValue={key}
        value={key}
      >
        {keys.map(i => {
          return <RadioButton value={i}>{t(`${iT}.${i}`)}</RadioButton>
        })}
      </RadioGroup>
    </div>
  )
}

export default translate()(Group)
