import React, { useState } from 'react'
import { Menu, Dropdown, Icon } from 'antd'
import { translate } from 'react-i18next'
import SettingPanelDrawer from 'components/SettingPanel/Points'
import { connect } from 'react-redux'
import { actions } from 'redux/modules/user'
import { actions as actionsE } from 'redux/modules/example';

import './index.scss'

const mapStateToProps = state => ({
  user: state.user,
  userEditMode: state.example.editMode,
  showPage: state.example.showPage
})

const mapDispatchToProps = {
  editModeToggle: actionsE.editToggle,
  editDeleteSpatial: actionsE.editDeleteSpatial,
};

const SettingPanel = (props) => {
  const { t, settings, user, editModeToggle, userEditMode, editDeleteSpatial } = props
  console.log('setting panel', settings, editModeToggle)
  const { isAuthenticated } = user
  const [isPoints, setIsPoints] = useState(false)
  const togglePoints = () => setIsPoints(!isPoints)
  const points = settings && settings.points && settings.points.length > 0
  const menu = (
    <Menu>
      {points &&
        <Menu.Item key="0">
          <span onClick={togglePoints}>
            {t('settings.menu.points')}
          </span>
        </Menu.Item>
      }
      <Menu.Item key="1">
        <span onClick={editModeToggle}>
          {t('settings.menu.edit')}: {t(`common.${userEditMode ? 'on' : 'off'}`)}
        </span>
      </Menu.Item>
    </Menu>
  )
  // ToDo: when there are will more menu elements, remove points check
  // if (!settings) return null
  if (!isAuthenticated) return null

  const pointHistory = settings ? settings.points : null

  return (
    <div className="setting-panel">
      <Dropdown overlay={menu} trigger={['click']}>
        <Icon
          type="ellipsis"
        />
      </Dropdown>
      <SettingPanelDrawer
        t={t}
        visible={isPoints}
        toggle={togglePoints}
        pointHistory={pointHistory}
        editDeleteSpatial={editDeleteSpatial}
      />
    </div>
  )
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(SettingPanel))
