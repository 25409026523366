import 'leaflet'
import { MapLayer } from 'react-leaflet'
import 'leaflet.markercluster'
import 'leaflet.featuregroup.subgroup'
import 'utility/leaflet-sub-group/deflate'

export default class SubGroup extends MapLayer {
  state = {
    mcg: null,
    subGroup: null,
  }

  createLeafletElement = () => {}

  bindEvents = (item, data) => {
    item.on('click', () => {
      data.onClick()
    })
    item.bindTooltip(`${data.tooltip}`)
  }

  createMarkers = (markers, subGroup) => {
    const { layerName, layerNameValue } = this.props
    markers.forEach(marker => {
      const newMarker = L.marker(marker.position, {
        icon: marker.icon,
        epoch: marker.epoch,
        layerNameValue,
        subjectType: layerName,
      }).addTo(subGroup)
      this.bindEvents(newMarker, marker)
    })
  }

  createPolygons = (polygons, deflateFeatures) => {
    const { layerName, layerNameValue } = this.props

    polygons.forEach(polygon => {
      const newPolygon = L.polygon(polygon.position, {
        icon: polygon.icon,
        color: polygon.color || '#696868',
        subjectType: layerName,
        layerNameValue,
        epoch: polygon.epoch,
        onClick: polygon.onClick,
        tooltip: polygon.tooltip,
      })
      deflateFeatures.addLayer(newPolygon)
      this.bindEvents(newPolygon, polygon)
    })
  }

  initSubGroup = () => {
    const {
      mcg,
      markers,
      polygons,
      layersControl,
      layerName,
      isHidden,
    } = this.props
    const { map } = this.context
    const subGroup = L.featureGroup.subGroup(mcg)

    this.createMarkers(markers, subGroup)

    const deflateFeatures = L.deflate({
      minSize: 20,
      subGroup,
    })

    deflateFeatures.addTo(map)
    this.createPolygons(polygons, deflateFeatures)

    if (layersControl && layersControl.current) {
      layersControl.current.leafletElement.addOverlay(subGroup, layerName)
    }
    if (!isHidden) {
      subGroup.addTo(map)
    }
    this.setState({ subGroup })
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.mcg) {
      if (props.mcg) {
        return {
          mcg: props.mcg,
        }
      }
    }
    return null
  }

  componentDidMount() {
    // console.log('ClusterGroupDidMount')
    const { mcg, needCluster } = this.props
    if (!needCluster) {
      this.initSubGroup()
      return
    }
    if (mcg) {
      this.initSubGroup()
      this.setState({ mcg })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('ClusterGroupDidUpdate', prevProps, prevState)
    if (!this.props.needCluster) {
      return
    }
    if (!prevState.mcg && this.state.mcg) {
      this.initSubGroup()
    }
    if (prevProps.isHidden !== this.props.isHidden && this.props.isHidden) {
      const { map } = this.context
      map.removeLayer(this.state.subGroup)
    }
  }

  componentWillUnmount() {
    // console.log('ClusterGroupUnmount', this.context.map, this.state.subGroup, this.props.layersControl)
    const { subGroup } = this.state
    if (subGroup !== null && this.props && this.props.layersControl && this.props.layersControl.current &&
      this.props.layersControl.current.leafletElement) {
      try {
        this.props.layersControl.current.leafletElement.removeLayer(subGroup)
        this.context.map.removeLayer(this.state.subGroup)
      } catch (e) {
        console.error("Can't remove layer on unmount group", e)
      }
    }
  }
}
