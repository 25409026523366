import { createAction, handleActions } from 'redux-actions';
import { getChartData } from 'utility/getChartData';

const WHERE_SUBMIT = 'www/WHERE_SUBMIT';
const WHAT_SUBMIT = 'www/WHAT_SUBMIT';
const WHEN_SUBMIT = 'www/WHEN_SUBMIT';
const SAVE_BBOX = 'heatmap/SAVE_BBOX';
const SAVE_DATE = 'heatmap/SAVE_DATE';
const SAVE_SUBJ = 'heatmap/SAVE_SUBJ';
const ERASE_LIST_DATA = 'heatmap/ERASE_LIST_DATA';
const WHEN_GET_MAIN_CHART = 'heatmap/WHEN_GET_MAIN_CHART';
const WHEN_GET_MAIN_CHART_SUCCESS = 'heatmap/WHEN_GET_MAIN_CHART_SUCCESS';
const WHEN_GET_MAIN_CHART_FAILED = 'heatmap/WHEN_GET_MAIN_CHART_FAILED';
const WHERE_GET_HEATMAP = 'heatmap/WHERE_GET_HEATMAP';
const WHEN_GET_HEATMAP = 'heatmap/WHEN_GET_HEATMAP';
const WHAT_GET_HEATMAP = 'heatmap/WHAT_GET_HEATMAP';
const WHERE_GET_HEATMAP_SUCCESS = 'heatmap/WHERE_GET_HEATMAP_SUCCESS';
const WHERE_GET_HEATMAP_FAILED = 'heatmap/WHERE_GET_HEATMAP_FAILED';
const WHERE_GET_CHART = 'heatmap/WHERE_GET_CHART';
const WHEN_GET_CHART = 'heatmap/WHEN_GET_CHART';
const WHAT_GET_CHART = 'heatmap/WHAT_GET_CHART';
const WHERE_GET_CHART_SUCCESS = 'heatmap/WHERE_GET_CHART_SUCCESS';
const WHERE_GET_CHART_FAILED = 'heatmap/WHERE_GET_CHART_FAILED';
const WHERE_GET_DATA = 'heatmap/WHERE_GET_DATA';
const WHEN_GET_DATA = 'heatmap/WHEN_GET_DATA';
const WHAT_GET_DATA = 'heatmap/WHAT_GET_DATA';
const WHERE_GET_DATA_SET_COUNT = 'heatmap/WHERE_GET_DATA_SET_COUNT';
const WHERE_GET_DATA_SUCCESS = 'heatmap/WHERE_GET_DATA_SUCCESS';
const WHERE_GET_DATA_FAILED = 'heatmap/WHERE_GET_DATA_FAILED';

export const constants = {
  WHERE_SUBMIT,
  WHAT_SUBMIT,
  WHEN_SUBMIT,
  SAVE_BBOX,
  SAVE_DATE,
  SAVE_SUBJ,
  ERASE_LIST_DATA,
  WHEN_GET_MAIN_CHART,
  WHEN_GET_MAIN_CHART_SUCCESS,
  WHEN_GET_MAIN_CHART_FAILED,
  WHERE_GET_HEATMAP,
  WHEN_GET_HEATMAP,
  WHAT_GET_HEATMAP,
  WHERE_GET_HEATMAP_SUCCESS,
  WHERE_GET_HEATMAP_FAILED,
  WHERE_GET_CHART,
  WHEN_GET_CHART,
  WHAT_GET_CHART,
  WHERE_GET_CHART_SUCCESS,
  WHERE_GET_CHART_FAILED,
  WHERE_GET_DATA,
  WHEN_GET_DATA,
  WHAT_GET_DATA,
  WHERE_GET_DATA_SET_COUNT,
  WHERE_GET_DATA_SUCCESS,
  WHERE_GET_DATA_FAILED,
};

// ------------------------------------
// Actions
// ------------------------------------
export const whereSubmit = createAction(WHERE_SUBMIT);
export const whatSubmit = createAction(WHAT_SUBMIT);
export const whenSubmit = createAction(WHEN_SUBMIT);
export const saveBbox = createAction(SAVE_BBOX);
export const saveDate = createAction(SAVE_DATE);
export const saveSubj = createAction(SAVE_SUBJ);
export const eraseListData = createAction(ERASE_LIST_DATA);
export const whenGetMainChart = createAction(WHEN_GET_MAIN_CHART);
export const whenGetMainChartSuccess = createAction(WHEN_GET_MAIN_CHART_SUCCESS);
export const whenGetMainChartFailed = createAction(WHEN_GET_MAIN_CHART_FAILED);
export const whereGetHeatmap = createAction(WHERE_GET_HEATMAP);
export const whenGetHeatmap = createAction(WHEN_GET_HEATMAP);
export const whatGetHeatmap = createAction(WHAT_GET_HEATMAP);
export const whereGetHeatmapSuccess = createAction(WHERE_GET_HEATMAP_SUCCESS);
export const whereGetHeatmapFailed = createAction(WHERE_GET_HEATMAP_FAILED);
export const whereGetChart = createAction(WHERE_GET_CHART);
export const whenGetChart = createAction(WHEN_GET_CHART);
export const whatGetChart = createAction(WHAT_GET_CHART);
export const whereGetChartSuccess = createAction(WHERE_GET_CHART_SUCCESS);
export const whereGetChartFailed = createAction(WHERE_GET_CHART_FAILED);
export const whereGetData = createAction(WHERE_GET_DATA);
export const whenGetData = createAction(WHEN_GET_DATA);
export const whatGetData = createAction(WHAT_GET_DATA);
export const whereGetDataSetCount = createAction(WHERE_GET_DATA_SET_COUNT);
export const whereGetDataSuccess = createAction(WHERE_GET_DATA_SUCCESS);
export const whereGetDataFailed = createAction(WHERE_GET_DATA_FAILED);

export const actions = {
  whereSubmit,
  whatSubmit,
  whenSubmit,
  saveBbox,
  saveDate,
  saveSubj,
  eraseListData,
  whenGetMainChart,
  whenGetMainChartSuccess,
  whenGetMainChartFailed,
  whereGetHeatmap,
  whenGetHeatmap,
  whatGetHeatmap,
  whereGetHeatmapSuccess,
  whereGetHeatmapFailed,
  whereGetChart,
  whenGetChart,
  whatGetChart,
  whereGetChartSuccess,
  whereGetChartFailed,
  whereGetData,
  whenGetData,
  whatGetData,
  whereGetDataSetCount,
  whereGetDataSuccess,
  whereGetDataFailed,
};

export const reducers = {
  [SAVE_BBOX]: (state, { payload }) =>
    ({
      ...state,
      where: true,
      what: false,
      when: false,
      bbox: payload,
      heatmap: [],
      chartData: [],
      listData: [],
    }),
  [SAVE_DATE]: (state, { payload }) =>
    ({
      ...state,
      where: false,
      what: false,
      when: true,
      date: payload,
      heatmap: [],
      chartData: [],
      listData: [],
    }),
  [SAVE_SUBJ]: (state, { payload }) =>
    ({
      ...state,
      where: false,
      what: true,
      when: false,
      subject: payload,
      heatmap: [],
      chartData: [],
      listData: [],
    }),
  [ERASE_LIST_DATA]: (state, { payload }) =>
    ({
      ...state,
      listData: [],
    }),
  [WHEN_GET_MAIN_CHART_SUCCESS]: (state, { payload }) =>
    ({
      ...state,
      mainChart: getChartData(payload),
    }),
  [WHEN_GET_MAIN_CHART_FAILED]: (state, { payload }) =>
    ({
      ...state,
      mainChart: {},
    }),
  [WHERE_GET_HEATMAP_SUCCESS]: (state, { payload }) =>
    ({
      ...state,
      heatmap: payload,
    }),
  [WHERE_GET_HEATMAP_FAILED]: state =>
    ({
      ...state,
      heatmap: [],
    }),
  [WHERE_GET_CHART_SUCCESS]: (state, { payload }) =>
    ({
      ...state,
      chartData: payload,
    }),
  [WHERE_GET_CHART_FAILED]: state =>
    ({
      ...state,
      chartData: [],
    }),
  [WHERE_GET_DATA_SUCCESS]: (state, { payload }) =>
    ({
      ...state,
      listData: payload.result,
    }),
  [WHERE_GET_DATA_FAILED]: state =>
    ({
      ...state,
      listData: [],
    }),
  [WHERE_GET_DATA_SET_COUNT]: (state, { payload }) =>
    ({
      ...state,
      listDataCount: payload.count,
    }),
}

export const initialState = () => {
  const state = {
    mainChart: {},
    heatmap: [],
    chartData: [],
    listData: [],
    where: false,
    what: false,
    when: false,
  };
  return state;
}

export default handleActions(reducers, initialState());
