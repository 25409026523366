import { put, fork, takeLatest, call } from 'redux-saga/effects'
import {
  getPropertyByNameString,
} from 'utility/transformData/constants'
import {
  getNotification,
  successNotify,
  errorNotify,
} from '../../utility/notification'
import {
  actions as notification,
  constants as notificationConstants,
} from '../modules/notification'
import {
  constants as userConstants,
  actions as userActions,
} from '../modules/user'
import {
  constants as signupConstants,
  actions as signupActions,
} from '../modules/signup'
import {
  constants as recoveryConstants,
  actions as recoveryActions,
} from '../modules/recovery'
import { safelyPost } from '../../utility/request'
import {
  auth,
  recovery,
  getCurrentUser,
  getCurrentUserGraph,
} from '../api/auth'
import { deleteCookie } from '../../utility/cookie'

export function* fetchLogIn(action) {
  const loadNotification = getNotification('logIn', 'loading', 'saving')
  yield put(notification.show(loadNotification))

  const { response, error } = yield call(auth, action.payload)
  if (response) {
    const expire = new Date(response.expire)
    document.cookie = `token=${
      response.token
    }; path=/; expires=${expire.toUTCString()}`
    yield put(userActions.logInSuccess())
    yield put(notification.hide(loadNotification.id))
    yield call(successNotify, 'logIn', 'saved')
  } else {
    yield put(notification.hide(loadNotification.id))
    yield put(userActions.logInFailed(error))
  }
}

export function* fetchSignUp(action) {
  const loadNotification = getNotification('signUp', 'loading', 'saving')
  yield put(notification.show(loadNotification))
  yield put(signupActions.signUpStart())
  const { response, error } = yield call(
    safelyPost,
    'api/registration/',
    action.payload,
  )
  if (response) {
    yield put(signupActions.signUpSuccess())
    yield put(notification.hide(loadNotification.id))
    yield call(successNotify, 'signUp', 'saved')
  } else {
    yield put(notification.hide(loadNotification.id))
    console.log(error)
    yield put(signupActions.signUpFailed(error))
  }
}

export function* fetchSignUpExisting(action) {
  const loadNotification = getNotification('signUp', 'loading', 'saving')
  yield put(notification.show(loadNotification))
  yield put(signupActions.signUpStart())
  const { response, error } = yield call(
    safelyPost,
    'api/registration/existing-user/',
    action.payload,
  )
  if (response) {
    yield put(signupActions.signUpSuccess())
    yield put(notification.hide(loadNotification.id))
    yield call(successNotify, 'signUp', 'saved')
  } else {
    yield put(notification.hide(loadNotification.id))
    console.log(error)
    yield put(signupActions.signUpFailed(error))
  }
}

export function* fetchRecovery(action) {
  const loadNotification = getNotification('recovery.sending', 'loading')
  yield put(notification.show(loadNotification))

  const { response, error } = yield call(recovery, action.payload)
  if (response) {
    yield put(recoveryActions.recoverySuccess())
    yield put(notification.hide(loadNotification.id))
  } else {
    yield put(notification.hide(loadNotification.id))
    yield put(recoveryActions.recoveryFailed(error))
  }
}

export function* fetchUser() {
  const { me } = yield call(getCurrentUserGraph)
  if (me) {
    const user = {
      ...me,
      role: getPropertyByNameString(me, 'role.edges.0.node.slug'),
      first_name: me.firstName,
      last_name: me.lastName,
    }
    yield put(userActions.getCurrentUserSuccess(user))
  } else {
    deleteCookie('token')
    yield put(userActions.getCurrentUserFailed())
  }
}

export function* showNotificationWithTimeout(action) {
  console.log('SAGA')
  const { payload } = action
  yield call(errorNotify, payload)
}

function* watchUserAccountActions() {
  yield takeLatest(userConstants.LOG_IN, fetchLogIn)
  yield takeLatest(signupConstants.SIGN_UP, fetchSignUp)
  yield takeLatest(signupConstants.SIGN_UP_EXISTING, fetchSignUpExisting)
  yield takeLatest(recoveryConstants.RECOVERY, fetchRecovery)
  yield takeLatest(userConstants.GET_CURRENT_USER, fetchUser)
  yield takeLatest(
    notificationConstants.SHOW_NOTIFICATION_WITH_TIMEOUT,
    showNotificationWithTimeout,
  )
}

export const authSaga = [fork(watchUserAccountActions)]
