import React, { PureComponent } from 'react'
import { translate } from 'react-i18next'
import { url } from 'utility/constants'

import './index.css'
import '../CommonField/index.css'
import ListContent from '../ListContent'

@translate()
class GalleryField extends PureComponent {
  render() {
    console.log('gallery field', this.props)
    const { src, subjectType, fields } = this.props
    if (src) {
      return (
        <div className="gallery-slide">
          <img src={`${url}/${src}`} alt="topo" />
          <ListContent subjectType={subjectType} content={fields} />
        </div>
      )
    }

    return <span>{'Нет Данных'}</span>
  }
}

export default GalleryField
