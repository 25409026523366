import i18n from 'i18next'
import { getLangName, getItem } from 'components/GSearchPanel/Queries/constants'
import { page } from './queries'

const lang = ['ru','en'].includes(i18n.language) ? i18n.language : 'local'

const archQueries = {
  bookSearch: () => {
    return {
      query: `
      query postQuery($search: String="", $author: String="", $year: Int, ${page.def}) {
        results:searchBooks(${lang}Name_Icontains: $search,
                            year_Exact: $year,
                            authorNameIcontains: $author,
                            ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
              year
              author {
                edges {
                  node {
                    publicName
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'source/Book',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        year: {
          data: getItem('year'),
          sort: 'year',
        },
        author: {
          data: getItem('author.edges.0.node.publicName'),
          sort: 'author__public_name',
        },
      },
    }
  },

  reportSearch: () => {
    return {
      query: `
      query postQuery($search: String="",
                      $author:String="",
                      $year: Int,
                      ${page.def}) {
        results:searchReports(${lang}Name_Icontains: $search,
                              year_Exact: $year,
                              authorNameIcontains: $author,
                              ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
              year
              author {
                edges {
                  node {
                    publicName
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'source/Report',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        year: {
          data: getItem('year'),
          sort: 'year',
        },
        author: {
          data: getItem('author.edges.0.node.publicName'),
          sort: 'author__public_name',
        },
      },
    }
  },

  publicationSearch: () => {
    return {
      query: `
      query postQuery($search: String="", $author: String="", $year: Int, ${page.def}) {
        results:searchPublications(${lang}Name_Icontains: $search,
                            year_Exact: $year,
                            authorNameIcontains: $author,
                              ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
              year
              label
              author {
                edges {
                  node {
                    publicName
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'source/Publication',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        year: {
          data: getItem('year'),
          sort: 'year',
        },
        label: {
          data: getItem('label'),
          sort: 'label',
        },
        author: {
          data: getItem('author.edges.0.node.publicName'),
          sort: 'author__public_name',
        },
      },
    }
  },

  articleSearch: () => {
    return {
      query: `
      query postQuery($search: String="", $year: Int, ${page.def}) {
        results:articlesPeriodical(${lang}Name_Icontains: $search,
                            year_Exact: $year,
                              ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
              year
              label
              author {
                edges {
                  node {
                    publicName
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'source/ArticlePeriodical',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
        year: {
          data: getItem('year'),
          sort: 'year',
        },
        label: {
          data: getItem('label'),
          sort: 'label',
        },
        author: {
          data: getItem('author.edges.0.node.publicName'),
          sort: 'author__public_name',
        },
      },
    }
  },

  periodicalSearch: () => {
    return {
      query: `
      query postQuery($search: String="", ${page.def}) {
        results:searchPeriodical(${lang}Name_Icontains: $search,
                              ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      url: 'source/Periodical',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
      },
    }
  },
}

export default archQueries
