import React, { Component, Fragment } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button, Icon } from 'antd';
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries';
import CommonSelect from 'components/GSearchPanel/components/commonSelect';
import archQueries from 'components/GSearchPanel/Queries/archQueries';
import { listQueries } from 'components/CreatePanel/queries/getList';
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters';

import '../../index.scss';
import AutoInputGQL from '../fields/autoInputGQL';
import CoordinatesInput from '../fields/coordinatesInput';
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions';

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class LayerTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
    }
  }

  addTab = () => {
    const key = `${this.newTabIndex++}`;
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state;
    newName[key] = data.typed;
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = (input) => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      getFieldDecorator,
      formInputLayout,
      stateTabs,
      createData,
      subjects,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props;

    const config = {
      req: {
        rules: [{
          required: true, message: t('common.default_error'),
        }],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel header={`${t('create_panel.tab.layer.layer')} #${1 + key}`} key={title + item.key}>
              <AutoInputGQL
                handleSelect={(data) => {this.handleSelect(data, key)}}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.layer.choose')}
                selectkey={`layer-select-${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.layer.add')}
                query={archQueries.layerSearch()}
                params={[{ key: 'search', val: 'value' }]}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel={'Layer'}
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') &&
              <div>
                {item.status === 'new' &&
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel={'Layer'}
                    rightLabel={'Site'}
                    connectionField={'site'}
                    label={t('create_panel.tab.layer.site')}
                    selectkey={`layer_site_${key}`}
                    selectType="sites"
                    stateTabs={stateTabs}
                    objkey={key}
                    noAddOption
                    query={archQueries.siteSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    rules={config.req}
                    t={t}
                  />
                }
                <FormItem
                  {...formInputLayout}
                  label={t('create_panel.tab.layer.name')}
                >
                  {getFieldDecorator(`layer_name_${key}`, {
                    ...config.req,
                    initialValue: this.state.newName[key],
                  })(
                    <Input onChange={this.handleChangeName} data-objkey={key} />)}
                </FormItem>
                <CoordinatesInput
                  getFieldDecorator={getFieldDecorator}
                  formLayout={formInputLayout}
                  title={title}
                  subjname="layer"
                  objkey={key}
                  handleAddMarker={handleAddMarker}
                  interactiveMarkers={interactiveMarkers}
                  form={form}
                  t={t}
                />
                <FormItem
                  {...formInputLayout}
                  label={t('common.date')}
                >
                  {getFieldDecorator(`layer_date_since_${key}`, {})(
                    <InputNumber placeholder={t('common.date_since')} min={-100000} max={new Date().getFullYear()} />)}
                  {getFieldDecorator(`layer_date_to_${key}`, {})(
                    <InputNumber placeholder={t('common.date_to')} min={-100000} max={new Date().getFullYear()} />)}
                </FormItem>
                <AutoInputGQL
                  handleSelect={commonFunctions.handleAutoInputChange}
                  handleTabAddRelation={this.props.handleTabAddRelation}
                  handleChangeStateTab={this.props.handleChangeStateTab}
                  getFieldDecorator={getFieldDecorator}
                  formLayout={formInputLayout}
                  title={this.props.title}
                  leftLabel={'Layer'}
                  rightLabel={'Culture'}
                  dontShowOnMap
                  connectionField={'culture'}
                  label={t('common.culture')}
                  selectkey={`layer_culture_${key}`}
                  selectType="cultures"
                  stateTabs={stateTabs}
                  objkey={key}
                  noAddOption
                  query={archQueries.cultureSearch()}
                  params={[{ key: 'search', val: 'value' }]}
                  t={t}
                />
                {item.status === 'new' &&
                  <Fragment>
                    <CommonSelect
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      inputName={`layer_epoch_${key}`}
                      label={t('common.epoch')}
                      selectQuery={attributeQueries.defaultSearch('epoches')}
                      dontNeedEmptyOption="true"
                      rules={config.req}
                      t={t}
                    />
                    <CommonSelect
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      inputName={`layer_type_${key}`}
                      label={t('common.layer_type')}
                      selectQuery={attributeQueries.defaultSearch('sitetypes')}
                      dontNeedEmptyOption="true"
                      rules={config.req}
                      t={t}
                    />
                  </Fragment>
                }
                <FormItem
                  {...formInputLayout}
                  label={t('common.description')}
                >
                  {getFieldDecorator(`layer_desc_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `layer_desc_${key}`)} />)}
                </FormItem>
                <RelationComponent
                  handleAddExtraRelation={handleAddExtraRelation}
                  getFieldDecorator={getFieldDecorator}
                  formLayout={formInputLayout}
                  objkey={key}
                  title="Layer"
                  tabName="layers"
                  stateTabs={stateTabs}
                  form={form}
                  t={t}
                />
              </div>
              }
            </Panel>
          ))}
        </Collapse>

        <FormItem
          wrapperCol={{ span: 8, offset: 8 }}
        >
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.layer.add_button')}
          </Button>
        </FormItem>
      </div>
    );
  }
}

export default LayerTab;
