import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  fullNameToShort,
} from 'utility/transformData/constants';
import getResearchFields from 'utility/transformData/fields/research';
import React from 'react';
import { Badge } from 'antd';

export default function getResearchTypeFields(d, tabType) {
  console.info('RESEARCH TYPE', d);
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const { creator, author } = d;
      return [
        createLinkField('author', creator[0] ? creator[0].uid : author.uid, 'author',
          fullNameToShort(creator[0] ? creator[0] : author)),
        createCommonField('name', d.ru_name),
        createCommonField('year', d.year),
        createCommonField('type', (d.type[0]) ? d.type[0].ru_name : ''),
        createCommonField('description', d.description),
      ];
    }
    case TAB_TYPE.COLLAPSE: {
      const result = []
      d.data.forEach((item, key) => {
        const itemObj = {}
        itemObj.childrenItem = {
          subjectType: 'research',
          content: getResearchFields({ results: item }, TAB_TYPE.COLLAPSE,
            key),
        }
        itemObj.name = (<span>
          {key}
          <Badge
            count={item.length}
            overflowCount={999}
            style={{
              backgroundColor: '#fff',
              color: '#999',
              boxShadow: '0 0 0 1px #d9d9d9 inset',
              marginLeft: '10px',
            }}
          />
        </span>)
        result.push(itemObj);
      })
      return result;
    }
    default:
      return [];
  }
}
