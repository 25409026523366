import React from 'react'
import i18n from 'i18next'
import { getPropertyByNameString } from 'utility/transformData/constants'

export const getLangName = data =>
  getPropertyByNameString(
    data,
    `${['en', 'ru'].includes(i18n.language) ? i18n.language : 'local'}Name`,
  )
export const getVersionId = data => getPropertyByNameString(data, 'versionId')
export const getMasterId = data => getPropertyByNameString(data, 'masterId')

export const getItem = label => data => getPropertyByNameString(data, label)
export const getAuthorName = data => {
  const name = i18n.language !== 'ru' ? data.publicNameEn : data.publicName
  return name || data.publicName || 'NA'
}
export const getInterpretation = data => {
  const names = getPropertyByNameString(data, 'interpretation')
  const mainName = Array.isArray(names)
    ? names.length > 0
      ? names[0][
          `${
            ['en','ru'].includes(i18n.language) ? i18n.language : 'local'
          }Name`
        ]
      : 'NA'
    : 'NA'
  const otherNames =
    Array.isArray(names) && names.length > 1
      ? names
          .slice(1)
          .map(
            i =>
              i[
                `${
                  ['en', 'ru'].includes(i18n.language) ? i18n.language : 'local'
                }Name`
              ],
          )
          .join(', ')
      : null
  return otherNames ? `${mainName} (${otherNames})` : `${mainName}`
}

export const getLayerCulture = (layerNode, lang) => {
  const culture =
    getPropertyByNameString(
      layerNode,
      `node.commonCultures.0.culture.${lang}Name`,
    ) || i18n.t('common.culture_unknown')
  const cultureCount = getPropertyByNameString(
    layerNode,
    'node.commonCultures.0.count',
  )
  const otherText = ` ${i18n.t('common.and_other')}`
  return `${culture}${cultureCount > 1 ? otherText : ''}`
}
