import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd';
import { graphql } from 'relay-runtime'
import useDataApi from 'utility/fetchGraphHook'
import {
  getPropertyByNameString as allocator,
} from 'utility/transformData/constants';
import ProfileTabSelectionSelf from './Self/index'
import ProfileTabSelectionShared from './Shared'
import ProfileTabAllSelections from './AllPublic';

const { TabPane } = Tabs;

const QUERY = graphql`
query SelectionQuery {
  me {
    mySel: selections {
      edges {
        node {
          ... fragmentsSelectionNode @relay(mask: false)
        }
      }
    }
    sharedSel : sharedSelections {
      edges {
        node {
          ... fragmentsSelectionNode @relay(mask: false)
        }
      }
    }
  }
}
`

export default function ProfileTabSelection(props) {
  const { data, isLoading, isError } = useDataApi(QUERY);
  const [selfSelections, setSelfSelections] = useState([]);
  const [sharedSelections, setSharedSelections] = useState([]);
  const { t, user } = props;

  useEffect(() => {
    const mySelections = allocator(data, 'me.mySel.edges') || [];
    const mySharedSelections = allocator(data, 'me.sharedSel.edges') || [];

    setSelfSelections(mySelections.map(({ node }) => node));
    setSharedSelections(mySharedSelections.map(({ node }) => node));
  }, [data])

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab={t('selection.tabs.self')} key="1">
        <ProfileTabSelectionSelf
          t={t}
          data={selfSelections}
          isLoading={isLoading}
        />
      </TabPane>
      <TabPane tab={t('selection.tabs.shared')} key="2">
        <ProfileTabSelectionShared
          t={t}
          data={sharedSelections}
          isLoading={isLoading}
        />
      </TabPane>
      <TabPane tab={t('selection.tabs.allPublic')} key="3">
        <ProfileTabAllSelections
          t={t}
          data={[]}
          isLoading={isLoading}
        />
      </TabPane>
    </Tabs>
  )
}
