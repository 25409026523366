import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getAttribute,
  getLangTitle,
  sortArray,
} from 'utility/transformData/constants';

export default function getLocalityFields(d, tabType) {
  console.log('locality fields', d)
  const r = d;
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      console.log(item);
      const result = [
        createCommonField('name', getLangTitle(item, 'name')),
        createLinkField('district', item.district.uid, 'subjects/District',
          getLangTitle(item.district)),
      ];
      const oldNames = (item.names[0]) ? item.names : null;
      if (oldNames) {
        const names = oldNames.map(t => `${getLangTitle(t)}`).join(', ')
        result.push(
          createCommonField('old_names', names),
        )
      }
      const type = (item.attributes.LocalityType) ? item.attributes.LocalityType[0] : null;
      if (type) {
        const types = item.attributes.LocalityType.map(t => `${getLangTitle(t)} (${t.date_start})`).join(', ')
        result.push(
          createCommonField('type', types),
        )
      }
      const date = (r.interpretations && r.interpretations[0]) ? r.interpretations[0].date_start : '-';
      result.push(
        createCommonField('date', date),
      )
      return result;
    }
    case TAB_TYPE.COLLAPSE: {
      const ret = d.results.map((item, index) => {
        const itemObj = {}
        const interpretation = (item.interpretations) ? item.interpretations[0] : item.research;
        itemObj.content = [
          createLinkField('name', item.uid, `subjects/${item.label || d.label}`,
            getLangTitle(item)),
          createCommonField('population', getAttribute(item.attributes, 'Population', 'description')),
          createCommonField('type', getAttribute(item.attributes, 'LocalityType', 'ru_name')),
          createCommonField('desc', getLangTitle(interpretation, 'desc')),
        ]
        itemObj.name = getLangTitle(item)
        return itemObj;
      })
      return sortArray(ret)
    }
    default:
      return [];
  }
}
