import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Button } from 'antd';
import { connect } from 'react-redux';
import getUrl from 'utility/images'
import { actions } from '../../redux/modules/example';

import './index.css';
import '../newIndex/index.scss';

const indexBg = getUrl('bolgar_index/bg.jpg')
const indexBgW = getUrl('bolgar_index/bg.webp')
const arrow = getUrl('bolgar_index/arrow.svg')


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  handleChangeMapPosition: actions.changeMapPosition,
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class BolgarIndexView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  goToPage = (e) => {
    e.preventDefault();
    this.props.handleChangeMapPosition({
      lat: e.target.dataset.lat,
      lng: e.target.dataset.lng,
      zoom: e.target.dataset.zoom,
    })
    const { location } = e.target.dataset;
    const pageUrl = `/bolgar-categories/${location}`;
    this.props.history.push(pageUrl)
  }

  render() {
    const { t } = this.props;

    return (
      <div className="container index">
        <div className="index-bg bolgar-index-bg">
          <picture>
            <source srcSet={indexBgW} type="image/webp" />
            <source srcSet={indexBg} type="image/jpeg" />
            <img className="index-bg-img bolgar-index-bg-img" src={indexBg} alt="culturetat" />
          </picture>
        </div>
        <main className="bolgar-index-main">
          <header>
            <h1 style={{ whiteSpace: 'pre-line' }}>
              {t('bolgar.title')}
            </h1>
          </header>
          <Button
            className="bolgar-button bolgar-index-left-button"
            href="/quick-search"
            data-lat={54.967}
            data-lng={49.034}
            data-zoom={12}
            data-location="BOLGAR"
            onClick={this.goToPage}
          >
            {t('bolgar.bolgar')}
            <img
              className="svg"
              src={arrow}
              alt="arrow"
              data-lat={54.967}
              data-lng={49.034}
              data-zoom={12}
              data-location="BOLGAR"
            />
          </Button>
          <Button
            className="bolgar-button bolgar-index-right-button"
            href="/quick-search"
            data-lat={55.771}
            data-lng={48.658}
            data-zoom={15}
            data-location="SVIYAZHSK"
            onClick={this.goToPage}
          >
            {t('bolgar.sviyazhsk')}
            <img
              className="svg"
              src={arrow}
              alt="arrow"
              data-lat={55.771}
              data-lng={48.658}
              data-zoom={15}
              data-location="SVIYAZHSK"
            />
          </Button>
        </main>
      </div>
    )
  }
}

export default BolgarIndexView;
