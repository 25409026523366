import React, { Component } from 'react'
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd'
import archQueries from 'components/GSearchPanel/Queries/archQueries'
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters'
import CommonSelect from 'components/GSearchPanel/components/commonSelect'
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries'

import '../../index.scss'
import AutoInputGQL from '../fields/autoInputGQL'

import { commonFunctions } from '../../functions/commonFunctions'

const FormItem = Form.Item
const { Panel } = Collapse
const { Option } = Select
const { TextArea } = Input

class CultureTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
    }
  }

  addTab = () => {
    const key = this.newTabIndex++
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state
    newName[key] = data.typed
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = input => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      getFieldDecorator,
      filelistChanged,
      createData,
      formInputLayout,
      stateTabs,
      subjects,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props

    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel
              header={`${t('create_panel.tab.culture.culture')} #${1 + key}`}
              key={title + item.key}
            >
              <AutoInputGQL
                handleSelect={data => {
                  this.handleSelect(data, key)
                }}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.culture.choose')}
                selectkey={`culture-select-${key}`}
                addOptionText={t('create_panel.tab.culture.add')}
                objkey={item.key}
                params={[{ key: 'search', val: 'value' }]}
                query={archQueries.cultureSearch()}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel="Culture"
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') && (
                <div>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.culture.name')}
                  >
                    {getFieldDecorator(`culture_name_${key}`, {
                      ...config.req,
                      initialValue: this.state.newName[key],
                    })(
                      <Input
                        onChange={this.handleChangeName}
                        data-objkey={item.key}
                      />,
                    )}
                  </FormItem>
                  <FormItem {...formInputLayout} label={t('common.date')}>
                    {getFieldDecorator(`culture_date_since_${key}`, {})(
                      <InputNumber
                        placeholder={t('common.date_since')}
                        min={-100000}
                        max={new Date().getFullYear()}
                      />,
                    )}
                    {getFieldDecorator(`culture_date_to_${key}`, {})(
                      <InputNumber
                        placeholder={t('common.date_to')}
                        min={-100000}
                        max={new Date().getFullYear()}
                      />,
                    )}
                  </FormItem>
                  {item.status === 'new' ? (
                    <CommonSelect
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      inputName={`culture_epoch_${key}`}
                      label={t('common.epoch')}
                      selectQuery={attributeQueries.defaultSearch('epoches')}
                      dontNeedEmptyOption="true"
                      rules={config.req}
                      t={t}
                    />
                  ) : (
                    ''
                  )}
                  <FormItem
                    {...formInputLayout}
                    label={t('common.description')}
                  >
                    {getFieldDecorator(`culture_desc_${key}`, {})(
                      <TextArea
                        onPaste={e =>
                          textFieldFormatters.onTextAreaPaste(
                            e,
                            form,
                            `culture_desc_${key}`,
                          )
                        }
                      />,
                    )}
                  </FormItem>
                </div>
              )}
            </Panel>
          ))}
        </Collapse>

        <FormItem wrapperCol={{ span: 8, offset: 8 }}>
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.culture.add_button')}
          </Button>
        </FormItem>
      </div>
    )
  }
}

export default CultureTab
