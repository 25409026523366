import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Modal, Input, message } from 'antd'
import { graphql } from 'relay-runtime'
import { fetchGrapqlMutation } from 'utility/request'

const QUERY = graphql`
  mutation SaveModalMutation($data: createSelectionIT!) {
    saveSelection(data: $data) {
      uid
      description
      model
      name
    }
  }
`

const UPDATE_QUERY = graphql`
  mutation SaveModalUpdateMutation($data: updateSelectionIT!, $uid: UUID!) {
    updateSelection(data: $data, uid: $uid) {
      uid
      description
      model
      name
    }
  }
`

export const AdvancedSearchSaveModal = props => {
  const {
    t,
    toggleSaveModal,
    parseObject,
    pagination,
    root,
    polygonBounds,
    isEdit,
    initTitle,
    initDescr,
    uid,
  } = props

  const [saveModalLoading, setSaveModalLoading] = useState(false)
  const saveTitle = useRef(null)
  const saveDesc = useRef(null)

  const save = () => {
    if (root && root.model) {
      setSaveModalLoading(true)
      const name = saveTitle.current.input.value
      const description = saveDesc.current.input.value
      const query = JSON.stringify(parseObject(root))
      console.log("QUERY", query)
      const body = {
        name,
        description,
        resultCount: pagination.total,
        query,
        polygonBounds,
      }
      const variables = {
        data: body,
        uid: uid,
      }

      if (isEdit) {
        variables.uid = uid
      }

      fetchGrapqlMutation(
        isEdit ? UPDATE_QUERY : QUERY,
        variables,
        response => {
          if (
            response &&
            (response.saveSelection || response.updateSelection)
          ) {
            toggleSaveModal()
            message.success(t('advanced.save.success'))
          }
        },
      )
    }
  }

  return (
    <Modal
      title={t('advanced.save.title')}
      visible
      okText={t('advanced.button.save')}
      cancelText={t('advanced.button.cancel')}
      onOk={save}
      confirmLoading={saveModalLoading}
      onCancel={toggleSaveModal}
    >
      <form className="adv-search-save-modal">
        <Input
          placeholder={t('advanced.save.fields.title')}
          defaultValue={initTitle}
          ref={saveTitle}
        />
        <Input
          placeholder={t('advanced.save.fields.desc')}
          defaultValue={initDescr}
          ref={saveDesc}
        />
      </form>
    </Modal>
  )
}

AdvancedSearchSaveModal.propTypes = {
  t: PropTypes.func.isRequired,
  toggleSaveModal: PropTypes.func.isRequired,
  parseObject: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  root: PropTypes.object.isRequired,
}

export default AdvancedSearchSaveModal
