import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Alert, Spin } from 'antd'

const ProfileTabResearcherNew = props => {
  const {
    toggleCreateNew,
    t,
    children,
  } = props;


  return (
    <div className="form_container">
      <Button key="back" onClick={toggleCreateNew}>
        {t('researcher.button.back')}
      </Button>
      {children}
    </div>
  );
};

ProfileTabResearcherNew.propTypes = {

};

export default ProfileTabResearcherNew;
