import { useState, useEffect } from 'react'
import i18n from 'i18next'
import groupBy from 'lodash/groupBy'
import cloneDeep from 'lodash/cloneDeep'
import { getPropertyByNameString } from '../transformData/constants'

const DEFAULT_PATH = ''

const generateGroups = (
  data: any[],
  key: string,
  subjectType: string,
): any[] => {
  if (key !== 'default') {
    const groupedData = groupBy(data, item => item.group[key].name)
    const initialGroups = cloneDeep(data && data.length ? data[0].group : {})
    return Object.keys(groupedData).map(groupKey => {
      // removing from groupedData previous groups
      const groupData = cloneDeep(groupedData[groupKey])
      groupData.forEach(i => {
        delete i.group[key]
      })
      let tabGroupName = i18n.t('dataPanel.groups.unnamed')
      if (groupKey !== 'null' && groupKey !== 'undefined') {
        tabGroupName = groupKey
      }
      return {
        childrenItem: {
          content: groupData,
          subjectType,
          key,
        },
        content: [],
        name: tabGroupName,
        uid: groupKey,
        group: initialGroups,
      }
    })
  }
  return data
}

const getGroupKeys = (data: any[]): string[] | null => {
  try {
    return Object.keys(data[0].group)
  } catch (err) {
    return null
  }
}

const GroupHook = (array: any[] = [], subjectType: string) => {
  const [key, setKey] = useState('default')
  const [data, setData] = useState(array)
  const groupKeys = ['default'].concat(getGroupKeys(data) || [])

  useEffect(() => {
    setData(generateGroups(array, key, subjectType))
  }, [array, key, subjectType])

  const handleGroup = (item: string) => {
    setKey(item)
  }
  return { data, handleGroup, groupKeys }
}

export default GroupHook
