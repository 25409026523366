import React from 'react'
import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  sortArray,
} from 'utility/transformData/constants';
import { getVariant } from 'distLibs/images/variant/index';
import getLexemeGroupFields from 'utility/transformData/fields/lexemeGroup';

export default function getLexemeFields(d, tabType) {
  console.log(tabType);
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      console.log(item);
      const result = [
        createCommonField('name', item.name),
      ];
      const term = item.subjects.filter(a => a.label === 'Term')[0]
      if (term) {
        result.push(
          createLinkField('term', term.uid, 'subjects/Term', term.name),
        )
      }
      const language = item.attributes.filter(a => a.label === 'Language')[0]
      if (language) {
        result.push(
          createCommonField('language', getLangTitle(language)),
        )
      }
      return result
    }
    case TAB_TYPE.COLLAPSE: {
      console.log('results', d);
      const result = [];
      d.results.forEach((item, index) => {
        console.log(item);
        const { lexeme } = item
        const itemObj = {}
        const {
          attributes: { edges: attrs },
          variant: { edges: variants },
        } = lexeme.node
        const description = lexeme.node.research.edges[0].node.edgeSubject[0].etimDescription
        itemObj.content = [
          createLinkField('name', lexeme.node.uid, 'subjects/Lexeme', lexeme.node.name),
        ]
        const language = attrs.filter(a => a.node.label === 'Language')[0]
        if (language) {
          itemObj.content.push(
            createCommonField('language', getLangTitle(language.node)),
          )
        }
        if (variants) {
          itemObj.childrenItem = {
            subjectType: 'lexemeGroup',
            content: getLexemeGroupFields({ results: variants, lexemeIndex: item.lexemeIndex },
              TAB_TYPE.COLLAPSE, index),
          }
        }
        const image = getVariant({ index: item.lexemeIndex });
        itemObj.name = (<span>
          {image && <img src={image} alt="layer" />}
          {lexeme.node.name}
        </span>);
        itemObj.sortName = lexeme.node.name;
        result.push(itemObj);
      })
      return sortArray(result, 'sortName')
    }
    default:
      return [];
  }
}
