import * as request from '../../utility/request';

export const spatialType = {
  Site: 'ArchaeologySpatial',
  'subjects/Site': 'ArchaeologySpatial',
  Layer: 'ArchaeologySpatial',
  'subjects/Layer': 'ArchaeologySpatial',
  Artifact: 'ArchaeologySpatial',
  'subjects/Artifact': 'ArchaeologySpatial',
  Radiocarbon: 'ArchaeologySpatial',
  'subjects/Radiocarbon': 'ArchaeologySpatial',
  Excavation: 'ArchaeologySpatial',
  'subjects/Excavation': 'ArchaeologySpatial',
  Culture: 'ArchaeologySpatial',
  'subjects/Culture': 'ArchaeologySpatial',
  Assemblage: 'ArchaeologySpatial',
  'subjects/Assemblage': 'ArchaeologySpatial',
  lexeme: 'ArchaeologySpatial',
  Lexeme: 'ArchaeologySpatial',
  'subjects/lexeme': 'ArchaeologySpatial',
  Term: 'ArchaeologySpatial',
  'subjects/Term': 'ArchaeologySpatial',
  variant: 'ArchaeologySpatial',
  Variant: 'ArchaeologySpatial',
  'subjects/variant': 'ArchaeologySpatial',
  vernacular: 'ArchaeologySpatial',
  Vernacular: 'ArchaeologySpatial',
  'subjects/vernacular': 'ArchaeologySpatial',
  District: 'EncyclopediaSpatial',
  'subjects/District': 'EncyclopediaSpatial',
  Locality: 'EncyclopediaSpatial',
  'subjects/Locality': 'EncyclopediaSpatial',
  Building: 'EncyclopediaSpatial',
  'subjects/Building': 'EncyclopediaSpatial',
  Person: 'EncyclopediaSpatial',
  'subjects/Person': 'EncyclopediaSpatial',
  EncOrganization: 'EncyclopediaSpatial',
  'subjects/EncOrganization': 'EncyclopediaSpatial',
}

export const spatialPath = (type, id) => {
  switch (type) {
    case 'Variant':
      return `api/linguistic/variant/uid/${id}/spatial_reference/list/`;
    case 'linguistic/variant/search':
      return `api/linguistic/variant/uid/${id}/spatial_reference/list/`;
    case 'Lexeme':
      return `api/linguistic/lexeme/uid/${id}/spatial_reference/list/`;
    case 'subjects/Lexeme':
      return `api/linguistic/lexeme/uid/${id}/spatial_reference/list/`;
    default:
      return `api/subject/uid/${id}/spatial_reference/list/`;
  }
}

export const subjectType = {
  vernacular: 'linguistic',
  lexeme: 'linguistic',
}

const getSubjectPath = (label, body) => {
  console.log('getSubjectPath', label, body)
  if (label === 'Locality' && body.mainSubjectLabel === 'Lexeme') {
    return {
      path: `api/linguistic/lexeme/uid/${body.uid}/spatial_reference/list/`,
      method: 'GET',
    }
  }
  if (label === 'Lexeme' && body.mainSubjectLabel === 'Locality') {
    return {
      path: `api/linguistic/locality/uid/${body.uid}/lexemes-variants/list/`,
      method: 'GET',
    }
  }
  const subjType = (subjectType[label]) ? subjectType[label] : 'subjects';
  return {
    path: `api/${subjType}/${label}/list/`,
    method: 'POST',
  }
}

export const getSubjectById = async (id) => {
  const path = `api/subject/uid/${id}/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getSubjectByLabelId = async (label, id) => {
  const subjType = (subjectType[label]) ? subjectType[label] : 'subjects';
  const path = `api/${subjType}/${label}/list/`;
  const body = {
    uid: id,
    __limit__: 1000,
    __offset__: 0,
  }
  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const getSubjectByLabelBody = async (label, body) => {
  console.log(body);
  const b = {
    ...body,
    __offset__: 0,
    __limit__: 1000,
  }
  const { path, method } = getSubjectPath(label, body)
  switch (method) {
    case 'GET': {
      const { response, error } = await request.safelyGet(path, request.GET_HEADERS());
      return { response, error };
    }
    default: {
      const { response, error } = await request.safelyPost(path, b, request.HEADERS_POST_AUTH());
      return { response, error };
    }
  }
}

export const getSubjectSpatials = async (id, type) => {
  console.log('getSubjectSpatials', type)
  // const subjType = (subjectType[label]) ? subjectType[label] : 'subjects';
  const path = spatialPath(type, id)

  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getSubjectTabs = async (id) => {
  // const subjType = (subjectType[label]) ? subjectType[label] : 'subjects';
  const path = `api/subjects/uid/${id}/tabs/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getExcavationLayers = async (id) => {
  const path = `api/subject/uid/${id}/type/excavation_layers/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getSubjectPulbications = async (id) => {
  const path = `api/subject/uid/${id}/type/publications/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getSubjectArchive = async (id) => {
  const path = `api/subject/uid/${id}/type/archive/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getSubjectTopo = async (id) => {
  const path = `api/subject/uid/${id}/topo/list/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const getRadiocarbonSummary = async (id) => {
  const path = `api/subject/uid/${id}/summary-radiocarbon/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const makeRadiocarbonSummary = async (id, type) => {
  const body = {
    curve_type: type,
  }
  console.log(body);
  const path = `api/subject/uid/${id}/summary-radiocarbon/`;
  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH());
  return { response, error };
}
