import {
  TAB_TYPE,
  createCommonField,
  createGalleryField,
} from 'utility/transformData/constants';

export default function getTopoFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const result = [
        createCommonField('author', d.author),
        createCommonField('year', d.year),
      ];
      return result;
    }
    case TAB_TYPE.GALLERY: {
      const result = [];
      d.results.forEach((item) => {
        result.push(
          createGalleryField(item.file, [
            {
              title: 'author',
              value: item.author,
            },
            {
              title: 'year',
              value: item.year,
            },
          ]),
        )
      });
      return result;
    }
    default:
      return [];
  }
}
