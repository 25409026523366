import { createAction } from 'redux-actions';

const PAGE_LOCATION_CHANGE = 'utils/PAGE_LOCATION_CHANGE';
const CHANGE_LANG = 'utils/CHANGE_LANG';

export const constants = {
  PAGE_LOCATION_CHANGE,
  CHANGE_LANG,
};

// ------------------------------------
// Actions
// ------------------------------------
export const pageChange = createAction(PAGE_LOCATION_CHANGE);
export const changeLanguage = createAction(CHANGE_LANG);

export const actions = {
  pageChange,
  changeLanguage,
};
