import React, { Component } from 'react';
import { List, Spin } from 'antd';
import {
  getConfirmationUsers,
  dismissUserConfirm,
  confirmUserConfirm,
} from 'redux/api/author'
import './index.css';

class ProfileTabUserConfirmation extends Component {
  state = {
    userToConfirm: null,
    userConfirmed: null,
    isLoading: true,
    isEmpty: false,
  }

  componentDidMount() {
    getConfirmationUsers().then(({ response, error }) => {
      if (response) {
        console.log(response);
        if (response.length === 0) {
          this.setState({
            userToConfirm: response,
            userConfirmed: response,
            isLoading: false,
            isEmpty: true,
          })
        } else {
          const userConfirmed = response.filter(user => user.status)
          const userToConfirm = response.filter(user => !user.status)
          this.setState({
            userConfirmed,
            userToConfirm,
            isLoading: false,
          })
        }
      }
    })
  }

  handleDismiss = (id, index) => {
    dismissUserConfirm(id).then(({ response, error }) => {
      if (response) {
        const userArr = [...this.state.userToConfirm];
        userArr.splice(index, 1);
        if (userArr.length === 0) {
          this.setState({
            userToConfirm: userArr,
            isEmpty: this.state.userConfirmed.length === 0,
          })
        } else {
          this.setState({
            userToConfirm: userArr,
          })
        }
      }
    })
  }

  handleConfirm = (path, index) => {
    confirmUserConfirm(path).then(({ response, error }) => {
      if (response) {
        const userArr = [...this.state.userToConfirm];
        userArr.splice(index, 1);
        if (userArr.length === 0) {
          this.setState({
            userToConfirm: userArr,
            isEmpty: this.state.userConfirmed.length === 0,
          })
        } else {
          this.setState({
            userToConfirm: userArr,
          })
        }
      }
    })
  }

  renderUserName = (user) =>
    `${user.last_name} ${user.first_name} ${user.parent_name} (${user.public_name})`

  renderCards = () => {
    const { t } = this.props;
    const { userToConfirm, userConfirmed, isEmpty } = this.state;
    if (isEmpty) {
      return (<div>{t('profile.confirmationUsers.empty')}</div>)
    }
    return (
      <div>
        <h2>{t('profile.confirmationUsers.toConfirm')}</h2>
        <List
          dataSource={userToConfirm}
          renderItem={(user, index) => (
            <List.Item
              key={user.uid}
              actions={[
                <button
                  className="user-confirm-confirm"
                  onClick={() => this.handleConfirm(user.url)}
                >
                  {t('profile.confirmationUsers.confirm')}
                </button>,
                <button
                  className="user-confirm-dismiss"
                  onClick={() => this.handleDismiss(user.uid, index)}
                >
                  {t('profile.confirmationUsers.dismiss')}
                </button>,
              ]}
            >
              <List.Item.Meta
                title={this.renderUserName(user)}
                description={`email: ${user.email || '-'}`}
              />
            </List.Item>
          )}
        />
        <h2>{t('profile.confirmationUsers.confirmed')}</h2>
        <List
          dataSource={userConfirmed}
          renderItem={(user, index) => (
            <List.Item
              key={user.uid}
            >
              <List.Item.Meta
                title={this.renderUserName(user)}
                description={`email: ${user.email || '-'}`}
              />
            </List.Item>
          )}
        />
      </div>
    )
  }

  render() {
    const { t } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="user-confirmation-block">
        {
          isLoading ?
            <Spin size="large" />
            :
            this.renderCards()
        }
      </div>
    );
  }
}

ProfileTabUserConfirmation.propTypes = {};

export default ProfileTabUserConfirmation;
