import React from 'react';
import { withFormik } from 'formik';
import { Form, Button, Alert, Input, Spin, Select } from 'antd';

import OrganizationList from '../../../Organization/List';
import ScienceInterestTagInput from '../../../ScienceInterest/TagInput/index';
import { formItemLayout } from '../../../../utility/constants'

const { Option } = Select;
const FormItem = Form.Item;
const { TextArea } = Input;

const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const renderErrors = (error, t, title = '') => {
  const keys = Object.keys(error);
  const result = [];
  keys.forEach(item => {
    console.log(error[item]);
    console.log(typeof (error[item]));
    if (typeof (error[item]) === 'object') {
      result.push(...renderErrors(error[item], t, title + item))
    } else {
      const newTitle = title.split('0').join('.');
      result.push(
        <Alert key={item} message={`${t(`profile.form.${newTitle}.title`)}: ${error[item]}`} type="error" />
      )
    }
  });
  console.log(result);
  return result;
}

const InnerForm = ({
  handleSubmit,
  handleOrganizationCountChange,
  userInfo = {},
  organizations,
  tags,
  isLoading,
  isError,
  form,
  t,
}) => {
  const { getFieldDecorator } = form;
  const config = {
    biography: {
      rules: [],
      initialValue: userInfo.biography,
    },
  };
  return (
    <Form onSubmit={handleSubmit} className="sign_up_form">
      {isError && renderErrors(isError, t)}
      <ScienceInterestTagInput
        getFieldDecorator={getFieldDecorator}
        inputName="scientific_interests"
        value={tags}
        t={t}
        formItemLayout={formItemLayout}
        title={t('profile.form.scientific_interests.title')}
      />
      <FormItem
        label={t('profile.form.biography.title')}
        {...formItemLayout}
      >
        {getFieldDecorator('biography', config.biography
        )(<TextArea />)}
      </FormItem>
      <OrganizationList
        getFieldDecorator={getFieldDecorator}
        t={t}
        initialValue={userInfo.organizations}
        organizations={organizations}
        handleOrganizationCountChange={handleOrganizationCountChange}
      />
      {isLoading ?
        <Spin />
        :
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          {t('profile.form.button')}
        </Button>
      }
    </Form>
  )
};

// Wrap our form with the using withFormik HoC
const ProfileTabAbout = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      if (err) {
        setSubmitting(false);
        return;
      }
      const body = {
        organizations: [],
      };
      const interestsField = fieldsValue.scientific_interests;
      body.biography = fieldsValue.biography;
      if (interestsField) {
        body.scientific_interests = interestsField;
      }
      console.log(fieldsValue.scientific_interests);
      // To read form if there no organization
      const formCount = props.organizationsNum === null ? 1 : props.organizationsNum;
      for (let i = 0; i < formCount; i++) {
        const organization = {};
        organization.uid = fieldsValue[`organization_input-${i}`];
        organization.since = fieldsValue[`organization_date_from-${i}`] ?
          fieldsValue[`organization_date_from-${i}`].format('DD.MM.YYYY') : null;
        // date to can be blank
        organization.to = fieldsValue[`organization_date_to-${i}`] ?
          fieldsValue[`organization_date_to-${i}`].format('DD.MM.YYYY') : null;
        console.log(organization);
        if (organization.uid) {
          body.organizations.push(organization);
        }
      }
      console.log(body);
      props.handleSubmit(body);
      setTimeout(setSubmitting(false), 1000);
    });
  },
})(InnerForm));

export default ProfileTabAbout;
