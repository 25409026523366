/**
 * @flow
 * @relayHash b014c076e06cbf74ca842a83809cbd88
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ModelPermissionEnum = "RESEARCH" | "SELECTION" | "SOURCE" | "SUBJECT" | "%future added value";
export type SendRequestInputType = {
  model: ModelPermissionEnum,
  uid: any,
};
export type RequestAccessMutationVariables = {|
  data: SendRequestInputType
|};
export type RequestAccessMutationResponse = {|
  +sendRequestToAccess: ?{|
    +isRequestSend: ?boolean
  |}
|};
export type RequestAccessMutation = {|
  variables: RequestAccessMutationVariables,
  response: RequestAccessMutationResponse,
|};
*/


/*
mutation RequestAccessMutation(
  $data: SendRequestInputType!
) {
  sendRequestToAccess(data: $data) {
    isRequestSend
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "SendRequestInputType!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "sendRequestToAccess",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data",
        "type": "SendRequestInputType"
      }
    ],
    "concreteType": "SendRequestMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isRequestSend",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "RequestAccessMutation",
  "id": null,
  "text": "mutation RequestAccessMutation(\n  $data: SendRequestInputType!\n) {\n  sendRequestToAccess(data: $data) {\n    isRequestSend\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RequestAccessMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "RequestAccessMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ad88da465f94587ea8e102363b1aebf';
module.exports = node;
