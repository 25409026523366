import * as request from '../../utility/request'

export const postMerge = async body => {
  const path = 'api/merge/subjects/'
  const { response, error } = await request.safelyPost(
    path,
    body,
    request.HEADERS_POST_AUTH()
  )
  return { response, error }
}

export const optionsMerge = async body => {
  const path = 'api/merge/subjects/'
  const { response, error } = await request.safelyPost(
    path,
    body,
    request.HEADERS_POST_AUTH(),
    'OPTIONS'
  )
  return { response, error }
}

export const deleteMerge = async body => {
  const path = 'api/merge/subjects/'
  const { response, error } = await request.safelyPost(
    path,
    body,
    request.HEADERS_POST_AUTH(),
    'DELETE'
  )
  return { response, error }
}
