import React, { PureComponent } from 'react'
import { Select, Form } from 'antd'
import { getPropertyByNameString } from 'utility/transformData/constants'
import { url } from 'utility/constants'
import * as request from 'utility/request'
import { getRenderTitle } from 'components/GSearchPanel/Results/renderTitle'
import i18n from 'i18next'
import { actions } from 'redux/modules/quickSearch'
import { connect } from 'react-redux'
import { GET_HEADERS_SMALL } from 'utility/request'
import { getCookie } from '../../../../utility/cookie'

const FormItem = Form.Item
const { Option } = Select

let timeout

export function fetchData(bind, value, props, callback) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
  bind.setState({ loading: true })

  async function fake() {
    bind.setState({ loading: true })
    const { query, params } = props
    const searchUrl = `${url}/api/graphql/`
    const variables = {}

    params.forEach(par => {
      if (par.val === 'value') {
        variables[par.key] = value
      } else {
        variables[par.key] = par.val
      }
    })
    const retData = []
    const headers = {
      ...request.HEADERS_POST_AUTH(),
      method: 'POST',
      body: JSON.stringify({
        query: query.query,
        variables,
      }),
    }
    let d = ''
    if (query === 'SciTopic') {
      d = await fetch('https://www.anthropology-gis.com/api/get_sci_topics/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${getCookie('token')}`,
        },
      }).then(response => response.json())
    } else {
      d = await fetch(searchUrl, headers).then(response => response.json())
    }
    if (d) {
      console.log('zaebalo', d.result)
      const results =
        getPropertyByNameString(d, 'data.results.edges') || d.result
      const renderFunc = query.render ? query.render : getRenderTitle(query.url)
      const uniqIds = []
      if (query === 'SciTopic') {
        results.forEach(i => {
          retData.push({
            value: i.uid,
            text: i.ru_name,
            allData: results,
          })
        })
      } else {
        results.forEach(r => {
          // uid data
          const uid = r.uid || r.node.uid
          // node item data
          const nodeData = r.node || r

          if (!uniqIds.includes(uid)) {
            retData.push({
              value: uid,
              text: renderFunc(r),
              allData: nodeData,
            })
          }
          uniqIds.push(uid)
        })
      }
      bind.setState({ loading: false })
      callback(retData)
    } else {
      bind.setState({ loading: false })
      console.log(`request failed ${d}`)
    }
  }

  timeout = setTimeout(fake, 750)
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  searchSpatial: actions.simpleSearchSpatial,
  deleteSpatial: actions.simpleSearchSpatialAutoInputDelete,
}

@connect(mapStateToProps, mapDispatchToProps)
class AutoInput extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      typed: '',
      value: '',
      selectedValue: '',
      loading: false,
    }
  }

  componentWillUnmount() {
    this.props.deleteSpatial(this.props)
  }

  handleSearch = value => {
    this.setState({ data: [] })
    if (value.length > 0) {
      this.setState({ typed: value })
      fetchData(this, value, this.props, data => this.setState({ data }))
    }
  }

  handleChange = (value, option) => {
    this.setState({ data: [] })
    const { selectType, selectTypeKey } = this.props
    const select = selectTypeKey ? `${selectType}_${selectTypeKey}` : selectType
    if (this.props.handleSelect) {
      this.props.handleSelect({
        value,
        option,
        typed: this.state.typed,
        selectType: select,
        title: this.props.title,
        leftLabel: this.props.leftLabel,
        rightLabel: this.props.rightLabel,
        connectionField: this.props.connectionField,
        handleTabAddRelation: this.props.handleTabAddRelation,
        handleChangeStateTab: this.props.handleChangeStateTab,
        newKeyForNew: this.props.newKeyForNew,
      })
    }
    const showLabel = this.props.rightLabel || this.props.leftLabel
    if (!this.props.dontShowOnMap) {
      console.log('searchSpatial search', showLabel, option.props.selectkey)
      this.props.searchSpatial({
        spatials: [
          {
            node: {
              interpretation: [{ [`${['ru','en'].includes(i18n.language) ? i18n.language: 'local'}Name`]: option.props.text }],
              uid: value,
            },
          },
        ],
        select: `subject/${showLabel}`,
        key: option.props.selectkey,
      })
    }

    if (this.props.newObjChanged) {
      this.props.newObjChanged({
        uid: option.key,
        allData: option.props.allData,
      })
    }
  }

  render() {
    const {
      getFieldDecorator,
      objkey,
      selectkey,
      label,
      formLayout,
      placeholder,
      addOptionText,
      saveTypedText,
      noAddOption,
      rules,
      selectType,
      stateTabs,
      researchConnectedSubjects,
      t,
    } = this.props

    const typedText = this.state.typed

    const options = this.state.data.map(d => {
      if (
        researchConnectedSubjects &&
        researchConnectedSubjects.includes(d.value)
      ) {
        const text = `(${i18n.t('create_panel.connected_subjects.research')})`
        return (
          <Option
            disabled
            key={d.value}
            selectkey={selectkey}
            objkey={`${objkey}`}
          >
            {`${d.text} ${text}`}
          </Option>
        )
      }
      console.log('researchConnectedSubjects', d)
      return (
        <Option
          key={d.value}
          selectkey={selectkey}
          text={d.text}
          objkey={`${objkey}`}
          allData={d.allData}
        >
          {d.text}
        </Option>
      )
    })

    if (selectType && stateTabs) {
      const stateTab = stateTabs.filter(tab => tab.title === selectType)
      if (stateTab[0] && stateTab[0].subj) {
        const addOptions = stateTab[0].subj
          .filter(sub => sub.status === 'new')
          .map(d => (
            <Option
              key={d.key}
              status="otherTab"
              objkey={`${d.key}`}
              parentkey={`${objkey}`}
              objtype={selectType}
              value={d.value}
            >
              {d.value} ({t('common.new')})
            </Option>
          ))
        options.push(...addOptions)
      }
    }

    const newSubjName = saveTypedText
      ? `(${t('common.add_new')}) ${typedText}`
      : addOptionText
    const valueFormatter = (e, q) => {
      if (e === 'new') {
        return e
      }

      if (q.props) {
        return q.props.children
      }
      return []
    }

    return (
      <FormItem {...formLayout} label={label}>
        {getFieldDecorator(selectkey, {
          getValueFromEvent: valueFormatter,
          ...rules,
        })(
          <Select
            showSearch
            placeholder={placeholder}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            loading={this.state.loading}
            notFoundContent={null}
          >
            {!noAddOption && typedText.length > 2 && (
              <Option
                key="new"
                value="new"
                objkey={`${objkey}`}
                className="auto-input-option-new"
              >
                {newSubjName}
              </Option>
            )}
            {options}
          </Select>,
        )}
      </FormItem>
    )
  }
}

export default AutoInput
