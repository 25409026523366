import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { actions } from '../../redux/modules/recovery';
import RecoveryForm from '../../components/Recovery/Form';
import RecoverySuccess from '../../components/Recovery/Success';
import './index.css';

const mapStateToProps = state => ({
  recovery: state.recovery,
});

const mapDispatchToProps = {
  handleSubmit: actions.recovery,
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class RecoveryView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    recovery: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, recovery, t } = this.props;
    return (
      <div className="container recovery">
        <h1>{t('recovery.title')}</h1>
        {
          recovery.isSuccess ?
            <RecoverySuccess
              t={t}
            />
            :
            <RecoveryForm
              handleSubmit={handleSubmit}
              isError={recovery.error}
              t={t}
            />
        }
      </div>
    )
  }
}

export default RecoveryView;
