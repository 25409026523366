import React, { Component } from 'react'
import { Input, Select, Form, InputNumber, Collapse, Button, Radio } from 'antd'
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries'
import CommonSelect from 'components/GSearchPanel/components/commonSelect'
import archQueries from 'components/GSearchPanel/Queries/archQueries'
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters'

import '../../index.scss'
import AutoInputGQL from '../fields/autoInputGQL'
import CoordinatesInput from '../fields/coordinatesInput'
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions'

const FormItem = Form.Item
const { Panel } = Collapse
const { Option } = Select
const { TextArea } = Input

class RdateTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
      placeType: {},
    }
  }

  addTab = () => {
    const key = this.newTabIndex++
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleChangePlaceType = (input, key) => {
    const { placeType } = this.state
    placeType[key] = input.target.value
    this.setState({ placeType })
  }

  handleSelect = (data, key) => {
    const { newName } = this.state
    newName[key] = data.typed
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = input => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      getFieldDecorator,
      formInputLayout,
      stateTabs,
      createData,
      subjects,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props

    const { placeType } = this.state

    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel
              header={`${t('create_panel.tab.rdate.excavation')} #${1 + key}`}
              key={title + item.key}
            >
              <AutoInputGQL
                handleSelect={data => {
                  this.handleSelect(data, key)
                }}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                selectkey={`rdate_select_${item.key}`}
                label={t('create_panel.tab.rdate.choose')}
                objkey={item.key}
                addOptionText={t('create_panel.tab.rdate.add')}
                query={archQueries.radiocarbonSearch()}
                params={[{ key: 'search', val: 'value' }]}
                rules={config.req}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel="Radiocarbon"
                t={t}
              />
              {item.status === 'new' && (
                <div>
                  <FormItem
                    {...formInputLayout}
                    label={t('common.where_is_found')}
                  >
                    {getFieldDecorator(`place_${key}`, {
                      initialValue: 'Assemblage',
                    })(
                      <Radio.Group
                        onChange={value => {
                          this.handleChangePlaceType(value, key)
                        }}
                      >
                        <Radio value="Assemblage">
                          {t('common.subjects.assemblage')}
                        </Radio>
                        <Radio value="Layer">
                          {t('common.subjects.layer')}
                        </Radio>
                      </Radio.Group>,
                    )}
                  </FormItem>

                  {(placeType[key] === 'Assemblage' || !placeType[key]) && (
                    <AutoInputGQL
                      handleSelect={commonFunctions.handleAutoInputChange}
                      handleTabAddRelation={this.props.handleTabAddRelation}
                      handleChangeStateTab={this.props.handleChangeStateTab}
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      title={this.props.title}
                      leftLabel="Radiocarbon"
                      rightLabel="Assemblage"
                      connectionField="assemblage"
                      label={t('create_panel.tab.rdate.assemblage')}
                      selectkey={`rdate_assemblage_${key}`}
                      selectType="assemblages"
                      stateTabs={stateTabs}
                      objkey={key}
                      noAddOption
                      query={archQueries.assemblageSearch(false, true)}
                      params={[{ key: 'search', val: 'value' }]}
                      rules={config.req}
                      t={t}
                    />
                  )}
                  {placeType[key] === 'Layer' && (
                    <AutoInputGQL
                      handleSelect={commonFunctions.handleAutoInputChange}
                      handleTabAddRelation={this.props.handleTabAddRelation}
                      handleChangeStateTab={this.props.handleChangeStateTab}
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      title={this.props.title}
                      leftLabel="Radiocarbon"
                      rightLabel="Layer"
                      connectionField="abstract_layer"
                      label={t('create_panel.tab.rdate.layer')}
                      selectkey={`rdate_layer_${key}`}
                      selectType="layers"
                      stateTabs={stateTabs}
                      objkey={key}
                      noAddOption
                      query={archQueries.layerSearch(false, true)}
                      params={[{ key: 'search', val: 'value' }]}
                      rules={config.req}
                      t={t}
                    />
                  )}
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.rdate.name')}
                  >
                    {getFieldDecorator(`rdate_name_${key}`, {
                      ...config.req,
                      initialValue: this.state.newName[key],
                    })(
                      <Input
                        onChange={this.handleChangeName}
                        data-objkey={key}
                      />,
                    )}
                  </FormItem>
                  <CoordinatesInput
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={title}
                    subjname="rdate"
                    objkey={key}
                    handleAddMarker={handleAddMarker}
                    interactiveMarkers={interactiveMarkers}
                    form={form}
                    t={t}
                  />
                  <CommonSelect
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    inputName={`rdate_material_${key}`}
                    label={t('create_panel.tab.rdate.material')}
                    selectQuery={attributeQueries.defaultSearch(
                      'carbonMaterials',
                    )}
                    dontNeedEmptyOption
                    rules={config.req}
                    t={t}
                  />
                  <CommonSelect
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    inputName={`rdate_genesis_${key}`}
                    label={t('create_panel.tab.rdate.genesis')}
                    selectQuery={attributeQueries.defaultSearch(
                      'sludgeGenesises',
                    )}
                    dontNeedEmptyOption
                    rules={config.req}
                    t={t}
                  />
                  <CommonSelect
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    inputName={`rdate_date_type_${key}`}
                    label={t('create_panel.tab.rdate.date_type_t')}
                    selectQuery={attributeQueries.radioDatingMethods()}
                    dontNeedEmptyOption
                    rules={config.req}
                    t={t}
                  />
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.rdate.curve_type')}
                  >
                    {getFieldDecorator(`rdate_curve_type_${key}`, {
                      initialValue: 'intcal13',
                    })(
                      <Select>
                        <Option value="intcal13">IntCal13</Option>
                        <Option value="marine13">Marine13</Option>
                        <Option value="shcal13">ShCal13</Option>
                        <Option value="intcal09">IntCal09</Option>
                        <Option value="marine09">Marine09</Option>
                        <Option value="intcal04">IntCal04</Option>
                        <Option value="marine04">Marine04</Option>
                        <Option value="shcal04">ShCal04</Option>
                        <Option value="intcal98">IntCal98</Option>
                        <Option value="marine98">Marine98</Option>
                      </Select>,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.rdate.date_bp')}
                  >
                    {getFieldDecorator(
                      `rdate_data_bp_${key}`,
                      config.req,
                    )(<InputNumber />)}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.rdate.s')}
                  >
                    {getFieldDecorator(
                      `rdate_s_${key}`,
                      config.req,
                    )(<InputNumber />)}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('common.description')}
                  >
                    {getFieldDecorator(
                      `rdate_desc_${key}`,
                      {},
                    )(
                      <TextArea
                        onPaste={e =>
                          textFieldFormatters.onTextAreaPaste(
                            e,
                            form,
                            `rdate_desc_${key}`,
                          )
                        }
                      />,
                    )}
                  </FormItem>
                  <RelationComponent
                    handleAddExtraRelation={handleAddExtraRelation}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    objkey={key}
                    title="Radiocarbon"
                    tabName="rdates"
                    stateTabs={stateTabs}
                    form={form}
                    t={t}
                  />
                </div>
              )}
            </Panel>
          ))}
        </Collapse>

        <FormItem wrapperCol={{ span: 8, offset: 8 }}>
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.rdate.add_button')}
          </Button>
        </FormItem>
      </div>
    )
  }
}

export default RdateTab
