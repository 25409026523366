const ICONS = {
  0: (color: string, size: number) => `
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.7715 12.1035L33.8209 12H36.4448L36.5275 12.218L40.6485 23.0825L41.7556 25.875H55.75V28.875H40.7367H39.7178L39.3423 27.9278L37.8556 24.1778L37.8515 24.1674L37.8475 24.157L34.9666 16.562L24.4785 38.5215L24.25 39H22.0204L21.7955 38.5696L16.7307 28.875H12.25V25.875H17.6394H18.5481L18.9689 26.6804L23.0648 34.5205L33.7715 12.1035Z"
        fill="#FC3F27"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.7715 27.1035L33.8209 27H36.4448L36.5275 27.218L40.6485 38.0825L41.7556 40.875H55.75V43.875H40.7367H39.7178L39.3423 42.9278L37.8556 39.1778L37.8515 39.1674L37.8475 39.157L34.9666 31.562L24.4785 53.5215L24.25 54H22.0204L21.7955 53.5696L16.7307 43.875H12.25V40.875H17.6394H18.5481L18.9689 41.6804L23.0648 49.5205L33.7715 27.1035Z"
        fill="#2570E1"
        fill-opacity="0.8"
      />
    </svg>
  `,
}
ICONS['default'] = ICONS[0]

export default ICONS
