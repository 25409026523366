import React, { Component } from 'react';
import { Button, Modal, notification } from 'antd';
import { url } from 'utility/constants';

import { linkExistingUser } from 'redux/api/registration';
import ExistingUsersUser from './User';
import ExistingUsersForm from './Form';

import './index.css'

class ExistingUsers extends Component {
  state = {
    userList: [],
    status: null,
    currentUser: null,
    isModal: false,
  }

  componentDidMount() {
    const { user } = this.props;
    if (!user.link_info || user.link_info.status !== 1) {
      this.getExistingUsers(user.last_name, user.first_name);
    }
  }

  getExistingUsers = (lastName, firstName) => {
    const firstNameChar = firstName[0];
    fetch(`${url}/api/author/non-active/list/?last_name=${lastName}&first_name=${firstNameChar}`, {})
      .then(response => response.json())
      .then((d) => {
        this.setState({
          userList: d.results,
        })
        if (d.results.length > 0) {
          this.openNotification();
        }
      })
      .catch((error) => {
        console.error(`request failed ${error}`);
      });
  }

  handleOk = (body) => {
    const data = {
      ...body,
      existing_author: this.state.userList[this.state.currentUser].uid,
    }
    linkExistingUser(data)
      .then(() => {
        this.setState({
          isModal: false,
        });
      })
      .catch((error) => {
        console.error(`request failed ${error}`);
      });
  }

  handleCancel = (e) => {
    this.setState({
      isModal: false,
    });
  }

  handleYes = (id) => {
    notification.close('is_you')
    this.setState({
      isModal: true,
      currentUser: id,
    });
  }

  handleNo = (id) => {
    const users = [...this.state.userList];
    users.splice(id, 1);
    this.setState({
      userList: users,
    })
    if (users.length === 0) {
      notification.close('is_you')
    }
  }

  openNotification = () => {
    const { t, user: { link_info } } = this.props;
    const { userList } = this.state;
    let args = null;
    let existingUser = null;
    if (link_info && link_info.status === 0) {
      userList.forEach((user, index) => {
        if (user.uid === link_info.author) {
          existingUser = user;
          existingUser.index = index;
        }
      })
      args = {
        message: t('profile.maybe_you.checking'),
        key: 'is_you',
        description: <div>
          <ExistingUsersUser
            key={existingUser.uid}
            t={t}
            id={existingUser.index}
            existingUser={link_info.author}
            statusCode={link_info.status}
            user={existingUser}
            handleYes={this.handleYes}
            handleNo={this.handleNo}
          />
        </div>,
        duration: 0,
      };
    } else {
      args = {
        message: t('profile.maybe_you.title'),
        key: 'is_you',
        description: <div>{
          userList.map((user, id) =>
            (<ExistingUsersUser
              key={user.uid}
              t={t}
              id={id}
              existingUser={link_info ? link_info.author : null}
              statusCode={link_info ? link_info.status : null}
              user={user}
              handleYes={this.handleYes}
              handleNo={this.handleNo}
            />)
          )
        }</div>,
        duration: 0,
      };
    }
    notification.open(args);
  };

  render() {
    const { t } = this.props;
    const { isModal } = this.state
    return (
      <ExistingUsersForm
        t={t}
        isModal={isModal}
        handleCancel={this.handleCancel}
        handleSubmit={this.handleOk}
      />
    );
  }
}

ExistingUsers.propTypes = {};

export default ExistingUsers;
