import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  fullNameToShort,
} from 'utility/transformData/constants'

export default function getPublicationFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const {creator, author} = d;
      return [
        createLinkField('author', creator[0] ? creator[0].uid : author.uid, 'author',
          fullNameToShort(creator[0] ? creator[0] : author)),
        createCommonField('name', d.ru_name),
        createCommonField('year', d.year),
        createCommonField('type', (d.type[0]) ? d.type[0].ru_name : ''),
        createCommonField('description', d.description),
      ];
    }
    case TAB_TYPE.COLLAPSE: {
      return d.results.map(item => {
        const itemObj = {}
        itemObj.content = [
          createLinkField(
            'source',
            item.uid,
            `source/${item.label}`,
            'Перейти к источнику',
          ),
          createCommonField('label', item.label),
        ]
        itemObj.name = ''
        if (item.author.public_name) {
          itemObj.name += `${item.author.public_name}, `
        }
        if (item.year) {
          itemObj.name += `${item.year}, `
        }
        itemObj.name += getLangTitle(item)
        return itemObj
      })
    }
    default:
      return []
  }
}
