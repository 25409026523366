import React from 'react';
import { Modal, Button, Form, Input, InputNumber, Upload, Icon, message, Select } from 'antd';
import { withFormik } from 'formik';

import CreateReportForm from './sourceTemplates/report'
import CreateResearchReportForm from './sourceTemplates/researchReport'
import CreateBookForm from './sourceTemplates/book'
import CreateBookSectionForm from './sourceTemplates/bookSection'
import CreateArticleForm from './sourceTemplates/articlePeriodical'
import CreatePReportForm from './sourceTemplates/pReport'
import CreateArchMapForm from './sourceTemplates/archMap'
import CreateDissertationForm from './sourceTemplates/dissertation'
import CreateThesisAbstractForm from './sourceTemplates/thesisAbstract'
import CreateFieldReportForm from './sourceTemplates/fieldReport'
import '../../index.scss';

const FormItem = Form.Item;
const { OptGroup, Option } = Select;

const InnerForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  handleEdit,
  isSubmitting,
  isError,
  form,
  visible,
  loading,
  hide,
  sourceCreated,
  fileLists,
  handleFileListChange,
  newReportName,
  authorName,
  t,
}) => {
  function beforeUpload(file) {
    const isJPGorPDF = file.type === 'application/pdf';
    if (!isJPGorPDF) {
      message.error(t('create_panel.modal.addNewReport.file.error_type'));
    }
    const isLt2M = file.size / 1024 / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t('create_panel.modal.addNewReport.file.error_size'));
    }
    return isJPGorPDF && isLt2M;
  }

  function uploadFile(e) {
    console.log(e)
  }

  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  if (sourceCreated) {
    hide()
  }

  const sourceType = form.getFieldsValue(['source_type']).source_type;
  console.log('sourceType', sourceType)
  const fileList = (fileLists['source']) ? fileLists['source'].fileList : [];

  return (
    <Modal
      visible={visible}
      className="create-panel-modal"
      onCancel={hide}
      width={900}
      footer={null}
    >
      <div className="create-panel-modal-header">
        <h2>
          {t('create_panel.modal.addNewReport.title')}
        </h2>
      </div>

      <Form onSubmit={handleSubmit} className="search-form">
        <FormItem
          {...formItemLayout}
          label={t('create_panel.modal.addNewReport.type')}
        >
          {getFieldDecorator(`source_type`, {
            initialValue: 'Report',
          })(
            <Select>
              <OptGroup label={t('create_panel.modal.addNewReport.types.publication.title')}>
                <Option value="Book">{t('create_panel.modal.addNewReport.types.publication.book')}</Option>
                <Option value="BookSection">{t('create_panel.modal.addNewReport.types.publication.bookSection')}</Option>
                <Option value="ArticlePeriodical">{t('create_panel.modal.addNewReport.types.publication.article')}</Option>
                <Option value="pReport">{t('create_panel.modal.addNewReport.types.publication.pReport')}</Option>
                <Option value="ArchMap">{t('create_panel.modal.addNewReport.types.archive.archMap')}</Option>
                <Option value="Dissertation">{t('create_panel.modal.addNewReport.types.publication.dissertation')}</Option>
                <Option value="ThesisAbstract">{t('create_panel.modal.addNewReport.types.publication.thesis_abstract')}</Option>
              </OptGroup>
              <OptGroup label={t('create_panel.modal.addNewReport.types.archive.title')}>
                <Option value="Report">{t('create_panel.modal.addNewReport.types.archive.report')}</Option>
                <Option value="ResearchReport">{t('create_panel.modal.addNewReport.types.archive.research_report')}</Option>
                <Option value="FieldReport">{t('create_panel.modal.addNewReport.types.archive.field_report')}</Option>
                {/*<Option value="ScientificDoc">{t('create_panel.modal.addNewReport.types.archive.scientificDoc')}</Option>*/}
                </OptGroup>
            </Select>)}
        </FormItem>
        <FormItem
          label={t('common.author.title')}
          {...formItemLayout}
        >
            {getFieldDecorator('author', { initialValue: authorName }
          )(<Input disabled />)}
        </FormItem>
        {sourceType === 'Book' &&
          <CreateBookForm
            getFieldDecorator={getFieldDecorator}
            formItemLayout={formItemLayout}
            newName={newReportName}
            form={form}
            t={t}
          />
        }
        {sourceType === 'BookSection' &&
          <CreateBookSectionForm
            getFieldDecorator={getFieldDecorator}
            formItemLayout={formItemLayout}
            newName={newReportName}
            form={form}
            t={t}
          />
        }
        {sourceType === 'ArticlePeriodical' &&
          <CreateArticleForm
            getFieldDecorator={getFieldDecorator}
            formItemLayout={formItemLayout}
            newName={newReportName}
            form={form}
            t={t}
          />
        }
        {sourceType === 'pReport' &&
          <CreatePReportForm
            getFieldDecorator={getFieldDecorator}
            formItemLayout={formItemLayout}
            newName={newReportName}
            form={form}
            t={t}
          />
        }
        {sourceType === 'ArchMap' &&
          <CreateArchMapForm
            getFieldDecorator={getFieldDecorator}
            formItemLayout={formItemLayout}
            newName={newReportName}
            form={form}
            t={t}
          />
        }
        {sourceType === 'Dissertation' &&
          <CreateDissertationForm
            getFieldDecorator={getFieldDecorator}
            formItemLayout={formItemLayout}
            newName={newReportName}
            form={form}
            t={t}
          />
        }
        {sourceType === 'ThesisAbstract' &&
          <CreateThesisAbstractForm
            getFieldDecorator={getFieldDecorator}
            formItemLayout={formItemLayout}
            newName={newReportName}
            form={form}
            t={t}
          />
        }
        {sourceType === 'Report' &&
          <CreateReportForm
            getFieldDecorator={getFieldDecorator}
            formItemLayout={formItemLayout}
            newName={newReportName}
            form={form}
            t={t}
          />
        }
        {sourceType === 'ResearchReport' &&
          <CreateResearchReportForm
            getFieldDecorator={getFieldDecorator}
            formItemLayout={formItemLayout}
            newName={newReportName}
            form={form}
            t={t}
          />
        }
        {sourceType === 'FieldReport' &&
          <CreateFieldReportForm
            getFieldDecorator={getFieldDecorator}
            formItemLayout={formItemLayout}
            newName={newReportName}
            form={form}
            t={t}
          />
        }
        <FormItem
          {...formItemLayout}
          label={t('create_panel.modal.addNewReport.file.title')}
        >
          {getFieldDecorator('photo', {
          })(
            <Upload.Dragger
              name="files"
              onChange={(list) => {
                handleFileListChange({list, label: 'source'})
              }}
              beforeUpload={beforeUpload}
              customRequest={uploadFile}
              fileList={fileList}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">{t('common.hints.file.drag_drop')}</p>
              <p >{t('common.hints.file.max_size_2Gb_110symb')}</p>
            </Upload.Dragger>)}
        </FormItem>
        <FormItem
          wrapperCol={{ offset: 10, span: 6 }}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            className="create-form-button"
            disabled={loading}
          >
            {t('common.add')}
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
}

const AddReportModal = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      console.log('new report', fieldsValue)
      if (err) {
        setSubmitting(false);
        return;
      }

      const params = {};
      const sourceType = fieldsValue.source_type;

      Object.keys(fieldsValue).forEach(key => {
        if (fieldsValue[key]) {
          params[key] = fieldsValue[key]
        }
      });

      params.local_name = params.ru_name;
      params.author = props.authorId;
      params.coauthors = props.createData.data.collaborators.map(c => c.uid);
      params.collaborators = props.createData.data.collaborators.map(c => c.uid);

      params.place_data = [{
        organization: params.org_id,
        save_number: params.save_number,
        fond_number: params.fond_number,
        inventory_number: params.inventory_number,
        sheet_number: params.sheet_number,
        code: params.code,
      }];

      if (sourceType === "ResearchReport" && params.org_id) {
        params.organization = [
          params.org_id,
        ];
      }

      if (sourceType === "Dissertation" || sourceType === "ThesisAbstract") {
        const degree = params.degree;
        const speciality = params.speciality;

        params.attributes = {};

        if (degree) {
          params.attributes.DissertationScienceDegree = [{ uid: degree }]
        }
        if (speciality) {
          params.attributes.DissertationSpeciality = [{ uid: speciality }]
        }
        if (params.official_opponent) {
          params.official_opponent = [
            params.official_opponent,
          ];
        }
        if (params.advisor) {
          params.advisor = [
            params.advisor,
          ];
        }
        if (params.external_reviewer) {
          params.external_reviewer = [
            params.external_reviewer,
          ];
        }
      }

      params.files = (fieldsValue.photo) ? fieldsValue.photo.fileList : [];
      console.log('new source', params, props)
      props.handleSubmit(params);
      setTimeout(setSubmitting(false), 1000);
    })
  },
})(InnerForm));

export default AddReportModal;
