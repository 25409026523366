import { put, fork, takeLatest, call, all } from 'redux-saga/effects'

import { CRS } from 'leaflet'

import { graphqlPost } from 'redux/api/graphql'
import {
  getPropertyByNameString,
  getEmptyTabs,
} from 'utility/transformData/constants'
import { constants, actions } from '../modules/map'

function* fetchMaps(state) {
  const params = {
    query: `
    query postQuery {
      maps {
        edges {
          node {
            uid
            ruName
            enName
            localName
            url
            tms
            crs
            xx
            isOverlay
            isGrey
            group
            center
            checked
            maxZoom
            minZoom
            opacity
            zoomOffset
            subdomains
          }
        }
      }
    }`,
    vars: {},
  }
  const items = yield call(graphqlPost, params)
  const maps = items.response.data.maps.edges || []

  const mapTabs = getEmptyTabs()
  const overlayMapTabs = getEmptyTabs()

  maps.map(map => {
    switch (map.node.crs) {
      case 'EPSG3395':
        map.node.crs = CRS.EPSG3395
        break
      case 'Earth':
        map.node.crs = CRS.Earth
        break
      case 'EPSG900913':
        map.node.crs = CRS.EPSG900913
        break
      case 'EPSG4326':
        map.node.crs = CRS.EPSG4326
        break
      default:
        delete map.node.crs
    }
    if (map.node.xx) {
      map.node.options = {
        xx: c => parseInt(c.x / 1000, 10),
        yy: c => parseInt(c.y / 1000, 10),
      }
    }
    const mapGroup = getPropertyByNameString(map, 'node.group').toLowerCase()
    const choosenType = map.node.isOverlay ? overlayMapTabs : mapTabs
    choosenType[mapGroup].push(map.node)
  })
  yield put(actions.fetchMapsSuccess({maps, mapTabs, overlayMapTabs}))
}

function* watchActions() {
  yield takeLatest(constants.FETCH_MAPS, fetchMaps)
}

export const mapSaga = [fork(watchActions)]
