import React from 'react';
import getUrl from 'utility/images'
import './index.scss'

const osu = getUrl('footer/osu.jpg')
const osuW = getUrl('footer/osu.webp')

const Footer = (props) => (
  <footer className="footer">
   <span className="copyright">
      <a href="https://www.osu.eu" target="_blank" rel="noopener noreferrer">
        {`© ${new Date().getFullYear()} ${props.t('footer.copyright')}`}
      </a>

    </span>
    <div className="logos">
      <a href="mailto:test@test.info">test@test.info</a>
      <picture>
        <source srcSet={osuW} type="image/webp" />
        <source srcSet={osu} type="image/jpeg" />
        <img src={osu} alt={props.t('footer.osu')} title={props.t('footer.osu')} />
      </picture>
    </div>
  </footer>
)

export default Footer;
