import React, { Component } from 'react';
import { withFormik } from 'formik'
import { Form, Input, Button, Alert, Spin } from 'antd'
import PropTypes from 'prop-types';
import DatePeriod from 'components/Date/Period'

const renderErrors = (error, t) => {
  const keys = Object.keys(error)
  const result = []
  keys.forEach(item => {
    result.push(
      <Alert
        key={item}
        message={`${t(`signUp.form.${item}.title`)}: ${error[item]}`}
        type="error"
      />,
    )
  })
  return result
}

const InnerForm = ({
  handleSubmit,
  isError,
  date,
  form,
  t,
}) => {
  const { getFieldDecorator } = form

  return (
    <Form onSubmit={handleSubmit} className="">
      {isError && renderErrors(isError, t)}
      <DatePeriod
        from={date.since}
        to={date.to}
        t={t}
        form={form}
        getFieldDecorator={getFieldDecorator}
      />
      <input type="submit" id="researcher_form_submit" hidden />
    </Form>
  )
}

const ResearcherForm = Form.create()(
  withFormik({
    // Submission handler
    handleSubmit: (values, { props, setSubmitting }) => {
      values.form.validateFields((err, fieldsValue) => {
        if (err) {
          setSubmitting(false)
          return
        }
        const body = {}

        body.date = {}
        body.date.since = fieldsValue.organization_date_from ? fieldsValue.organization_date_from.format('DD.MM.YYYY') : ''
        body.date.to = fieldsValue.organization_date_to ? fieldsValue.organization_date_to.format('DD.MM.YYYY') : null
        console.log(body)
        props.handleSubmit(body)
        setTimeout(setSubmitting(false), 1000)
      })
    },
  })(InnerForm),
)

export default ResearcherForm
