import { put, fork, takeLatest, call } from 'redux-saga/effects'

import { constants as utils } from '../modules/utils'
import { actions as recoveryActions } from '../modules/recovery'
import { actions as showActions } from '../modules/example'

import { setLocale } from '../api/locale'

export function* fetchChangeLang(action) {
  yield call(setLocale, action.payload)
}

export function* fetchLocationChange() {
  yield put(recoveryActions.resetRecovery())
  yield put(showActions.showPageClean())
}

function* watchUserAccountActions() {
  yield takeLatest(utils.CHANGE_LANG, fetchChangeLang)
  yield takeLatest(utils.PAGE_LOCATION_CHANGE, fetchLocationChange)
}

export const utilsSaga = [fork(watchUserAccountActions)]
