import React from 'react'
import { Tabs } from 'antd'
import { ProfileTabMessagesToMe } from './ToMe'
import { ProfileTabMessagesMy } from './My'

const { TabPane } = Tabs

const ProfileTabMessages = props => {
  const { t } = props

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab={t('request_access.tabs.to_me')} key="1">
        <ProfileTabMessagesToMe t={t} />
      </TabPane>
      <TabPane tab={t('request_access.tabs.my')} key="2">
        <ProfileTabMessagesMy t={t} />
      </TabPane>
    </Tabs>
  )
}

export default ProfileTabMessages
