import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  fullNameToShort,
  getCoauthors,
  getResearches,
  addProperty,
} from 'utility/transformData/constants';

export default function getBookFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const { author } = d;
      const result = [
        createLinkField('author', author.uid, 'author',
          fullNameToShort(author)),
        createCommonField('coauthors', getCoauthors(d.coauthors)),
        createCommonField('research', getResearches(d.research)),
      ];
      addProperty('city', d, result);
      addProperty('year', d, result);
      addProperty('edition', d, result);
      addProperty('series', d, result);
      addProperty('volume', d, result);
      addProperty('doi', d, result);
      addProperty('isbn', d, result);
      return result;
    }
    case TAB_TYPE.COLLAPSE: {
      return d.results.map((item) => {
        const itemObj = {}
        itemObj.content = [
          createLinkField('label', item.uid, `source/${item.label}`,
            'Перейти к источнику'),
          createCommonField('label', item.label),
        ]
        itemObj.name = `${item.author.public_name}, ${item.year}, ${getLangTitle(item)}`
        return itemObj;
      })
    }
    default:
      return [];
  }
}
