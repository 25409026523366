export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export function setCookie(name, value, options = {}) {
  const optionsVar = {
    path: '/',
    ...options,
  }
  let { expires } = optionsVar

  if (typeof expires === 'number' && expires) {
    const d = new Date()
    d.setTime(d.getTime() + expires * 1000)
    optionsVar.expires = d
    expires = d
  }
  if (expires && expires.toUTCString) {
    optionsVar.expires = expires.toUTCString()
  }

  const encodedValue = encodeURIComponent(value)

  let updatedCookie = `${name}=${encodedValue}`
  Object.keys(optionsVar).forEach(propName => {
    updatedCookie += `; ${propName}`
    const propValue = optionsVar[propName]
    if (propValue !== true) {
      updatedCookie += `=${propValue}`
    }
  })
  document.cookie = updatedCookie
}

export function deleteCookie(name) {
  setCookie(name, '', {
    expires: -1,
  })
}
