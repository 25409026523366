import React from 'react';
import { withFormik } from 'formik';
import { Form, Input, Alert, Modal } from 'antd';

import { formItemLayout } from 'utility/constants'
import FetchInputSelect from 'components/FetchInputSelect';

const FormItem = Form.Item;
const { TextArea } = Input;

const getSearchUrlOrganization = (orgUrl, path, value) => (
  `${orgUrl}/${path}/?search=${value}`
)

const renderErrors = (error, t) => {
  const keys = Object.keys(error);
  const result = [];
  keys.forEach(item => {
    result.push(
      <Alert key={item} message={`${t(`signUp.form.${item}.title`)}: ${error[item]}`} type="error" />,
    )
  });
  return result;
}

const InnerForm = ({
  handleSubmit,
  handleCancel,
  isLoading,
  isError,
  isModal,
  form,
  t,
}) => {
  const { getFieldDecorator } = form;

  const config = {
    // passport_serial: {
    //   rules: [{
    //     required: false, message: t('signUp.form.passport_serial.error'),
    //   }],
    // },
    // passport_no: {
    //   rules: [{
    //     required: false, message: t('signUp.form.passport_no.error'),
    //   }],
    // },
    about: {
      rules: [{
        required: false, message: t('signUp.form.about.error'),
      }],
    },
  };
  return (
    <Modal
      title={t('profile.maybe_you.get_account')}
      visible={isModal}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <Form onSubmit={handleSubmit} className="">
        {isError && renderErrors(isError, t)}
        {/* <FormItem
          label={t('signUp.form.passport_serial.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('passport_serial', config.passport_serial
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('signUp.form.passport_no.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('passport_no', config.passport_no
          )(<Input />)}
        </FormItem> */}
        <FormItem
          label={t('signUp.form.about.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('about', config.about
          )(<TextArea />)}
        </FormItem>
        <FetchInputSelect
          getFieldDecorator={getFieldDecorator}
          inputName="organization"
          title={t('signUp.form.confirmation_users.organizations.title')}
          path="api/organization/registration"
          value=""
          getSearchUrl={getSearchUrlOrganization}
          style={{ width: '100%' }}
        />
      </Form>
    </Modal>
  )
};

// Wrap our form with the using withFormik HoC
const ExistingUsersForm = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (values,
    {
      props,
      setSubmitting,
    }) => {
    values.form.validateFields((err, fieldsValue) => {
      if (err) {
        setSubmitting(false);
        return;
      }
      const body = {};
      const {
        passport_serial,
        passport_no,
        organization,
        about,
      } = fieldsValue;
      if (organization) {
        body.organization = organization
      }
      // if (passport_no) {
      //   body.passport_no = passport_no
      // }
      // if (passport_serial) {
      //   body.passport_serial = passport_serial
      // }
      if (about) {
        body.about = about
      }
      console.log(body);
      props.handleSubmit(body);
      setTimeout(setSubmitting(false), 1000);
    });
  },
})(InnerForm));

export default ExistingUsersForm;
