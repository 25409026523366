import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { actions } from 'redux/modules/heatmap';
import { getWords } from 'utility/constants';
import TCloud from '../../components/TagCloud';
import './index.css';

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  setSubject: actions.whatSubmit,
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class WhatView extends Component {
  handleClickTag = (e) => {
    this.props.setSubject({
      subject: e.target.dataset.subj,
      history: this.props.history,
    })
    console.log('click tag', e.target.dataset.subj);
  }

  render() {
    const words = getWords(this.props.t);
    return (
      <div className="what-page">
        <TCloud
          words={words}
          handleClickTag={this.handleClickTag}
        />
      </div>
    );
  }
}

export default WhatView;
