import React, { Component } from 'react';
import FetchInputSelect from 'components/FetchInputSelect';
import { Form } from 'antd';
import { formItemLayout } from 'utility/constants'
import './index.css'

const FormItem = Form.Item;

class CountryCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: props.userInfo.country || null,
    }
  }

  getSearchUrl = (url, path, encValue) => (
    `${url}/${path}&search=ru_name%3D${encValue}%7Cen_name%3D${encValue}%7Clocal_name%3D${encValue}`
  )

  handleCountrySelect = (country) => {
    this.setState({
      selectedCountry: country.key,
    })
  }

  render() {
    const { t, userInfo, getFieldDecorator } = this.props;
    const { selectedCountry } = this.state;
    return (
      <div className="coutry-city">
        <FormItem
          label={t('profile.form.country.title')}
          {...formItemLayout}
        >
          <FetchInputSelect
            getFieldDecorator={getFieldDecorator}
            initialValue={{
              path: 'api/country',
              id: userInfo.country,
            }}
            handleSelect={this.handleCountrySelect}
            inputName="country"
            path="api/country/list"
            style={{ width: '100%' }}
          />
        </FormItem>
        <FormItem
          label={t('profile.form.city.title')}
          {...formItemLayout}
        >
          <FetchInputSelect
            getFieldDecorator={getFieldDecorator}
            initialValue={{
              path: 'api/city',
              id: userInfo.city,
            }}
            inputName="city"
            getSearchUrl={this.getSearchUrl}
            path={`api/city/list${selectedCountry ? `/?relation=Country%3D${selectedCountry}` : ''}`}
            style={{ width: '100%' }}
          />
        </FormItem>
      </div>
    );
  }
}

export default CountryCity
