import { getResearchUrl } from 'utility/transformData/constants'

export const getLinkByLabel = (label: string, uid: string): string => {
  switch (label) {
    case 'Author':
      return `/show/author/${uid}`
    case 'ArchaeologyResearch':
    case 'EncyclopediaResearch':
    case 'Research':
      return `/show/${getResearchUrl(label)}/${uid}`
    default:
      return `/show/subjects/${label}/${uid}`
  }
}

export const getTypeByLabel = (label: string): string => {
  console.log('getTypeByLabel', label)
  switch (label) {
    case 'Author':
      return 'author'
    case 'ArchaeologyResearch':
    case 'EncyclopediaResearch':
    case 'Research':
      return getResearchUrl(label)
    default:
      return `subjects/${label}`
  }
}
