import * as request from '../../utility/request';

export const createAuthor = async (data) => {
  const path = 'api/eauthor/create/';
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH());
  return { response, error };
}

export const removeAuthor = async (id) => {
  const path = `api/eauthor/uid/${id}`;
  const { response, error } = await request.safelyGet(path, request.HEADERS_POST_AUTH(), 'DELETE');
  return { response, error };
}
