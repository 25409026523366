import React, { PureComponent } from 'react';
import { InputNumber, Form } from 'antd';

const FormItem = Form.Item;

class NumberInput extends PureComponent {
  render() {
    const {
      getFieldDecorator,
      inputName,
      formLayout,
      title,
      searchType,
      t,
      min,
      max,
    } = this.props;

    return (
      <FormItem
        label={t(`search_panel.form.field.${searchType}.${title}`)}
        {...formLayout}
      >
        {getFieldDecorator(`${inputName}`, {
        })(
          <InputNumber
            min={min || 1700}
            max={max || new Date().getFullYear()}
            name={inputName}
          />)}
      </FormItem>
    );
  }
}

export default NumberInput;
