const lexemeQueries = {
  Variant: {
    q: `query postQuery($uid: String) {
      lexemes (uid_Exact: $uid) {
        edges {
          node {
            variant {
              edges {
                node {
                  names
                  uid
                  sense
                  edgeLexeme {
                    groupNumber
                  }
                  locality {
                    edges {
                      node {
                        spatialReference {
                          edges {
                            node {
                              points
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,
    dataPath: [
      'lexemes',
      'variant',
    ],
    spatialPath: [
      'lexeme',
      'variant',
      'locality',
      'spatialReference',
    ],
    dataForSpatial: [
      {
        title: 'lexemeIndex',
        node: 'lexeme',
        fieldPath: 'index',
      },
      {
        title: 'groupNumber',
        node: 'variant',
        fieldPath: ['edgeLexeme', 'groupNumber'],
      },
      {
        title: 'variantIndex',
        node: 'variant',
        fieldPath: 'index',
      },
      {
        title: 'title',
        node: 'variant',
        fieldPath: ['names'],
      },
      {
        title: 'subject_id',
        node: 'variant',
        fieldPath: ['uid'],
      },
    ],
  },
};

export default lexemeQueries
